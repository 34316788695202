import React, { Component, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import { Button, Divider, DialogActions, Switch, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

import { NotificationManager } from 'react-notifications';

import CancelIcon from '@material-ui/icons/Cancel'
import commonClasses from './ics.module.css'

import HttpHandler from './HttpHandler';
import ErrorDialog from './components/ErrorDialog'

const styles = theme => ({
});


export default function ProbableCaseDialog(props) {

    const [state, setState] = useState({
        tests: [],
        probableCancerType : 'NO'
    });

    const _onClose = () => {
        props.onClose()
    }

    const _loadTestList = () => {
        HttpHandler.sendGet('/api/v1/test/all?offset=0&limit=200', (d) => {
            let selectedTests = []
            let probableCancerType = 'NO'
            if (props.recordId) {
                HttpHandler.sendGet(`/api/v1/camp/data/${props.recordId}/probableInfo`, (resp) => {
                    if (resp.status === 'success') {
                        selectedTests = resp.tests
                        probableCancerType = resp.cancerType
                        for (var i = 0; i < d.result.length; i++) {
                            for (const j in selectedTests) {
                                if (d.result[i].id === selectedTests[j].test) {
                                    d.result[i].selected = true;
                                }
                            }
                        }
                        setState({ ...state, tests: d.result, probableCancerType })
                    } else {
                        for (var i = 0; i < d.result.length; i++) {
                            d.result[i].selected = false;
                        }
                        setState({ ...state, tests: d.result, probableCancerType })
                    }
                }, (errorCode, errorMessage) => {
                    console.log(errorMessage);
                    for (var i = 0; i < d.result.length; i++) {
                        d.result[i].selected = false;
                    }
                    setState({ ...state, tests: d.result, probableCancerType })
                })
            }
        }, (errorCode, errorMessage) => {
            console.log(errorMessage);
        })
    }

    const _switchFieldSelected = (row) => {
        row.selected = !row.selected;
        setState({ ...state });
    }

    useEffect(() => {
        if (props.isVisible) {
            _loadTestList();
        }
    }, [props.recordId, props.isVisible]);

    const _onSave = () => {

        var testsSelected = [];
        for (var i = 0; i < state.tests.length; i++) {
            if (state.tests[i].selected) {
                testsSelected.push({ id: state.tests[i].id });
            }
        }
        var req = { cancerType : state.probableCancerType, tests: testsSelected }
        // if(state.probableCancerType != 'NO'){
            HttpHandler.sendPost('/api/v1/camp/' + props.campId + '/' + props.recordId + '/probable', req , (d) => {
                NotificationManager.success('Probable case details saved.', 'Case');
                props.onSave();
            }, (errorCode, errorMessage) => {
                ErrorDialog.show("There was an error saving test recommendation.");
            })
        // } else {
        //     ErrorDialog.show("Please select cancer type");
        // }
    }

    const _handleCancerTypeChange = (v) => {
        setState({
            ...state,
            probableCancerType: v.target.value
        })
    }

    return (
        <Dialog open={props.isVisible} onClose={props.onClose}>
            <DialogContent>
            <div style={{ width: 550 }}>
            <div style={{ padding: 5, width: "100%" }}>
                <div className={commonClasses.headerBar} style={{ width:'100%'}}>Probable case</div>
            </div>
            <div style={{ width: '100%', maxHeight: 800, backgroundColor: '#FAFAFA' }}>
                <FormControl style={{ flex: 1, marginTop: 16, marginLeft: 10, width:150, marginBottom:10 }}>
                    <InputLabel id="probableCancerType-label">Cancer Type</InputLabel>
                    <Select
                        labelId="probableCancerType-label"
                        id="probableCancerType-select"
                        value={state.probableCancerType}
                        onChange={_handleCancerTypeChange}
                    >
                        <MenuItem value="NO">None</MenuItem>
                        <MenuItem value="OR">Oral</MenuItem>
                        <MenuItem value="BR">Breast</MenuItem>
                        <MenuItem value="CE">Cervical</MenuItem>
                        <MenuItem value="OT">Others</MenuItem>
                    </Select>
                </FormControl>

                <div style={{ padding: 10, fontFamily: 'Metropolis-Medium' }}>Select the tests
                <Divider></Divider>
                </div>

                {state.tests.map((row, key) => {
                    return (
                        <div key={row.id} style={{ padding: 10, paddingBottom: 5, paddingTop: 5, }}>
                            <div style={{
                                borderRadius: 5, boxShadow: '1px 1px 2px #999', backgroundColor: '#FFF',
                                padding: 10, flexDirection: 'row', display: 'flex', alignItems: 'center'
                            }}>
                                <div style={{ flex: 1, fontFamily: 'Metropolis-Medium' }}>{row.name}</div>
                                <Switch
                                    checked={row.selected}
                                    onChange={() => _switchFieldSelected(row)}
                                    name={row.name}
                                    color="primary"
                                />
                            </div>
                        </div>
                    )
                })}
            </div>
            </div>
            <Divider />
            </DialogContent>
            <DialogActions>
                <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'flex-end'}}>
                    <Button
                        onClick={_onSave}
                        variant="contained" color="primary" style={{ marginLeft: 20 }}>Save</Button>
                    <Button
                        onClick={_onClose}
                        variant="contained" color="default" style={{ marginLeft: 20 }}>Close</Button>
                </div>
            </DialogActions>
        </Dialog>
    )
}