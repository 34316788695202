import React, { useEffect, useState } from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'

import { CssBaseline, Paper } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import HomeScreen from './HomeScreen'
import ErrorDialog from './components/ErrorDialog'
import LoginUI from './LoginUI';
import HttpHandler from './HttpHandler'
import Utils from './Utils';
import ConfirmationDialog from './components/ConfirmationDialog';
import DateSelectionDialog from './components/DateSelectionDialog';
import TemplateComponentDialog from './TemplateComponentDialog';

const theme = createMuiTheme({
  overrides: {
    MuiInput: {
      input: {
        fontFamily: 'Metropolis-Regular',
      }
    },
    MuiFormHelperText: {
      root: {
        fontFamily: 'Metropolis-Regular',
      }
    },
    MuiFormLabel: {
      root: {
        fontFamily: 'Metropolis-Regular',
      }
    },
    MuiFormControlLabel: {
      label: {
        fontFamily: 'Metropolis-Regular',
      }
    },
    MuiMenuItem: {
      root: {
        fontFamily: 'Metropolis-Regular',
      }
    },
    MuiButton: {
      root: {
        minWidth: 100,
      },
      label: {
        fontFamily: 'Metropolis-Regular'
      }
    }
  },

});

function ICSConnect(props) {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: 30 }}>
      <div style={{ width: 300, height: 100, backgroundColor: '#E1BEE7', border: '1px solid #9C27B0', borderRadius: 10 }}>
        <div style={{ color: '#000', fontFamily: 'Metropolis-Medium', fontSize: 16, textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          Connecting to ICS Server
        </div>
      </div>
    </div>
  )
}

export default function MainScreen(props) {

  Utils.checkAndUpdateURL();

  const [state, setState] = useState({
    checkInProgress: true
  });

  const _checkSession = () => {
    HttpHandler.sendGet('/api/v1/auth/session', (data) => {
      HttpHandler.FULL_NAME = data.fullName;
      HttpHandler.USER_TYPE = data.userType;
      Utils.loadDistrictsAndStates(() => {
        setState({
          ...state,
          checkInProgress: false
        })
        _getDeviceId();
      }, () => {

      });

      

    }, (errorCode, errorMessage) => {
      console.log(errorCode, errorMessage);
      HttpHandler.SESSION_SECRET = null;
      setState({
        ...state,
        checkInProgress: false
      })
    });
  }

  const _getDeviceId = () => {
    HttpHandler.sendGet('/api/v1/device/deviceid/my', (d) => {
      HttpHandler.DEVICE_ID = d.deviceId;
      
    }, (errorCode, errorMessage) => {
      console.log(errorMessage);
    })
  }

  useEffect(() => {
    if (HttpHandler.SESSION_SECRET === "") {
      //Check the session storage
      HttpHandler.SESSION_SECRET = window.sessionStorage.getItem("SESSION_SECRET");
      HttpHandler.FULL_NAME = window.sessionStorage.getItem("FULL_NAME");
      HttpHandler.USER_ID = window.sessionStorage.getItem("USER_ID");
    }
    _checkSession();
  }, []);

  const _onLoginSuccess = () => {

    Utils.loadDistrictsAndStates(() => {
      setState({
        ...state,
        checkInProgress: false
      })
      _getDeviceId();
    }, () => {
      setState({
        ...state,
        checkInProgress: false
      })
    });
  }

  return (
    <React.Fragment>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <ErrorDialog />
          <DateSelectionDialog />
          <TemplateComponentDialog />
          <ConfirmationDialog />
          {state.checkInProgress &&
            <ICSConnect></ICSConnect>
          }
          {(state.checkInProgress === false &&
            (HttpHandler.SESSION_SECRET === null || HttpHandler.SESSION_SECRET === '')) &&
            <LoginUI onSuccess={_onLoginSuccess} />
          }

          {(state.checkInProgress === false &&
            (HttpHandler.SESSION_SECRET !== null && HttpHandler.SESSION_SECRET !== '')) &&
            <HomeScreen />
          }

        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </React.Fragment>
  )
}
