import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles'

import { Fab, Paper, Divider, Tooltip, TextField, Button, DialogActions, DialogContent, Dialog, ButtonGroup, FormControlLabel, Switch } from '@material-ui/core';
import BackupRoundedIcon from '@material-ui/icons/BackupRounded';
import NoteAddOutlinedIcon from '@material-ui/icons/NoteAddOutlined';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@material-ui/icons/KeyboardArrowUpRounded';
import PostAddRoundedIcon from '@material-ui/icons/PostAddRounded';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';

import { NotificationManager } from 'react-notifications';


import PagePath from './components/PagePath';
import commonClasses from './ics.module.css'
import TemplateComponentDialog from './TemplateComponentDialog';
import TemplateField from './components/TemplateField';
import ConfirmationDialog from './components/ConfirmationDialog'
import ErrorDialog from './components/ErrorDialog'

import HttpHandler from './HttpHandler';
import NestedCondition from './NestedCondition';


const useStyles = makeStyles({

    fieldContainer: {
        paddingTop: 5,
        display: 'flex',
        flexDirection: 'row',
        paddingBottom: 5
    }
});
var sections = [];
var currentTemplate = null;
var sectionEdit = null;
var templateId = -1;

function TemplateGroup(props) {

    const classes = useStyles();

    const [state, setState] = useState({
        templateFields: sections[props.index].rows
    });

    const _addComponentClicked = () => {
        TemplateComponentDialog.show((c) => {
            c.sequence = state.templateFields.length;
            if (c != null) {
                var templateFields = sections[props.sequence].rows;
                templateFields.push(c);
                setState({
                    ...state,
                    templateFields: templateFields
                })
            }
        }, state.templateFields, null, false);
    }

    const _editComponentClicked = (record) => {
        TemplateComponentDialog.show((v) => {
            for (var i = 0; i < sections.length; i++) {
                var section = sections[i];
                for (var j = 0; j < section.rows.length; j++) {
                    if (section.rows[j].id === v.id) {
                        section.rows[j] = v;
                        break;

                    }
                }
            }


            setState({ ...state })
        }, state.templateFields, record, false);
    }

    const _viewClicked = (record) => {
        TemplateComponentDialog.show(() => { setState({ ...state }) }, state.templateFields, record, true);
    }

    const _moveUpClicked = () => {
        if (props.sequence === 0) {
            return;
        }
        else {
            var item = sections[props.sequence];
            sections.splice(props.sequence, 1);
            sections.splice(props.sequence - 1, 0, item);
            for (var i = 0; i < sections.length; i++) {
                sections[i].sequence = i;
            }

            props.onOrderChanged();

        }
    }

    const _moveDownClicked = () => {
        if (props.sequence === sections.length - 1) {
            return;
        }
        else {
            var item = sections[props.sequence];
            sections.splice(props.sequence, 1);
            sections.splice(props.sequence + 1, 0, item);
            for (var i = 0; i < sections.length; i++) {
                sections[i].sequence = i;
            }

            props.onOrderChanged();

        }
    }

    const _moveFieldUpClicked = (c) => {
        if (c.sequence === 0) {
            return;
        }
        var fields = state.templateFields;
        fields.splice(c.sequence, 1);
        fields.splice(c.sequence - 1, 0, c);
        for (var i = 0; i < fields.length; i++) {
            fields[i].sequence = i;
        }
        setState({
            ...state,
            templateFields: fields
        })
    }

    const _moveFieldDownClicked = (c) => {
        if (c.sequence === state.templateFields.length - 1) {
            return;
        }
        var fields = state.templateFields;
        fields.splice(c.sequence, 1);
        fields.splice(c.sequence + 1, 0, c);
        for (var i = 0; i < fields.length; i++) {
            fields[i].sequence = i;
        }
        setState({
            ...state,
            templateFields: fields
        })
    }

    const _deleteFieldClicked = (row) => {

        ConfirmationDialog.show('Are you sure you want to delete the field ?', () => {

            var fields = state.templateFields;
            fields.splice(row.sequence, 1);
            for (var i = 0; i < fields.length; i++) {
                fields[i].sequence = i;
            }

            //Remove from the section
            var sequenceToBeDeleted = row.sequence;
            for (var i = 0; i < sections.length; i++) {
                var section = sections[i];

                var found = false;
                for (var j = 0; j < section.rows.length; j++) {
                    if (section.rows[j].id === row.id) {
                        section.rows.splice(j, 1);
                        found = true;
                        break;

                    }
                }

                //Update the sequence
                if (found) {
                    for (var j = 0; j < section.rows.length; j++) {
                        if (section.rows[j].sequence > sequenceToBeDeleted) {
                            section.rows[j].sequence--;

                        }
                    }
                    break;
                }

            }

            setState({
                ...state,
                templateFields: fields
            })
        });
    }

    const _deleteGroupClicked = () => {
        ConfirmationDialog.show('Are you sure you want to delete this group?', () => {
            sections.splice(props.sequence, 1);
            for (var i = 0; i < sections.length; i++) {
                sections[i].sequence = i;
            }

            props.sectionRemoved();
        })
    }

    const _editClicked = () => {
        props.onEdit();
    }

    return (
        <Paper style={{ padding: 10, minWidth: 500 }}>
            <div style={{ flexDirection: 'row', display: 'flex', paddingBottom: 5, alignItems: 'center' }}>
                <div style={{ fontFamily: 'Metropolis-Regular', flex: 1 }}>{props.name}</div>
                {(HttpHandler.USER_TYPE === 'admin') &&
                    <ButtonGroup
                        disabled={props.disabled}
                        style={{ marginLeft: 10 }}
                        size="small" variant="contained" color="primary" aria-label="contained primary button group">
                        <Tooltip title="Delete Group" arrow>
                            <Button
                                onClick={_deleteGroupClicked}
                                style={{ color: props.disabled ? "" : '#FF5252' }}>
                                <DeleteForeverOutlinedIcon></DeleteForeverOutlinedIcon>
                            </Button>
                        </Tooltip>
                        <Tooltip title="Edit Group Name" arrow>
                            <Button onClick={_editClicked}>
                                <EditRoundedIcon></EditRoundedIcon>
                            </Button>
                        </Tooltip>
                        <Tooltip title="Add New Field" arrow>
                            <Button onClick={_addComponentClicked}>
                                <PostAddRoundedIcon></PostAddRoundedIcon>
                            </Button>
                        </Tooltip>
                        <Tooltip title="Move Up" arrow>
                            <Button onClick={_moveUpClicked} disabled={props.sequence === 0}>
                                <KeyboardArrowUpRoundedIcon></KeyboardArrowUpRoundedIcon>
                            </Button>
                        </Tooltip>
                        <Tooltip title="Move Down" arrow>
                            <Button onClick={_moveDownClicked} disabled={props.sequence === sections.length - 1}>
                                <KeyboardArrowDownRoundedIcon></KeyboardArrowDownRoundedIcon>
                            </Button>
                        </Tooltip>
                    </ButtonGroup>
                }


            </div>
            <Divider></Divider>
            <div style={{ paddingTop: 3, flexDirection: 'column', display: 'flex' }}>
                {state.templateFields.map((row, key) => {
                    return (
                        <div key={key} className={classes.fieldContainer}
                            style={{ borderBottom: (row.sequence === state.templateFields.length - 1 ? "" : "1px solid #EEE") }}>
                            <div style={{ flex: 1, paddingRight: 10 }}>
                                <TemplateField key={row.name} def={row} />
                            </div>
                            <div>
                                {(HttpHandler.USER_TYPE === 'admin') &&
                                    <ButtonGroup

                                        style={{ marginLeft: 10 }}
                                        size="small" variant="outlined" aria-label="outlined  button group">
                                        <Tooltip title="Delete Field" arrow>
                                            <Button disabled={props.disabled}
                                                onClick={() => _deleteFieldClicked(row)}
                                                style={{ color: props.disabled ? "" : '#FF5252' }} >
                                                <DeleteForeverOutlinedIcon></DeleteForeverOutlinedIcon>
                                            </Button>
                                        </Tooltip>
                                        {(!props.disabled) &&
                                            <Tooltip title="Edit Field" arrow>
                                                <Button disabled={props.disabled} onClick={() => _editComponentClicked(row)}>
                                                    <EditRoundedIcon style={{ color: props.disabled ? "" : "#2979FF" }}></EditRoundedIcon>
                                                </Button>
                                            </Tooltip>
                                        }
                                        {(props.disabled) &&
                                            <Tooltip title="View" arrow>
                                                <Button onClick={() => _viewClicked(row)}>
                                                    <VisibilityRoundedIcon style={{ color: "#2979FF" }}></VisibilityRoundedIcon>
                                                </Button>
                                            </Tooltip>
                                        }
                                        <Tooltip title="Move Up" arrow>
                                            <Button disabled={props.disabled} onClick={() => _moveFieldUpClicked(row)} disabled={row.sequence === 0}>
                                                <KeyboardArrowUpRoundedIcon style={{ color: (row.sequence === 0 || props.disabled ? "" : "#2979FF") }}></KeyboardArrowUpRoundedIcon>
                                            </Button>
                                        </Tooltip>
                                        <Tooltip title="Move Down" arrow>
                                            <Button disabled={props.disabled}
                                                onClick={() => _moveFieldDownClicked(row)} disabled={row.sequence === state.templateFields.length - 1}>
                                                <KeyboardArrowDownRoundedIcon
                                                    style={{ color: (row.sequence === state.templateFields.length - 1 || props.disabled ? "" : "#2979FF") }}></KeyboardArrowDownRoundedIcon>
                                            </Button>
                                        </Tooltip>
                                    </ButtonGroup>
                                }

                                {(HttpHandler.USER_TYPE === 'coordinator') &&
                                    <ButtonGroup
                                        disabled={props.disabled}
                                        style={{ marginLeft: 10 }}
                                        size="small" variant="outlined" aria-label="outlined  button group">

                                    </ButtonGroup>
                                }
                            </div>
                        </div>
                    )
                })}
            </div>
        </Paper>
    )
}

function BaseInfoComp(props) {

    return (
        <Paper style={{ padding: 10, minWidth: 500 }}>
            <div style={{ flexDirection: 'row', display: 'flex', paddingBottom: 5, alignItems: 'center' }}>
                <div style={{ fontFamily: 'Metropolis-Regular', flex: 1 }}>Personal - Basic (Default)</div>
            </div>
            <Divider></Divider>

            <div style={{ paddingTop: 3, flexDirection: 'column', display: 'flex', marginTop: 10 }}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{
                        paddingLeft: 5, paddingRight: 5, paddingTop: 3, paddingBottom: 3, borderRadius: 3,
                        backgroundColor: '#DDD', color: '#000', fontFamily: 'Metropolis-Regular'
                    }}>Name</div>
                    <div style={{
                        marginLeft: 10,
                        paddingLeft: 5, paddingRight: 5, paddingTop: 3, paddingBottom: 3, borderRadius: 3,
                        backgroundColor: '#DDD', color: '#000', fontFamily: 'Metropolis-Regular'
                    }}>Address</div>
                     <div style={{
                        marginLeft: 10,
                        paddingLeft: 5, paddingRight: 5, paddingTop: 3, paddingBottom: 3, borderRadius: 3,
                        backgroundColor: '#DDD', color: '#000', fontFamily: 'Metropolis-Regular'
                    }}>District</div>
                     <div style={{
                        marginLeft: 10,
                        paddingLeft: 5, paddingRight: 5, paddingTop: 3, paddingBottom: 3, borderRadius: 3,
                        backgroundColor: '#DDD', color: '#000', fontFamily: 'Metropolis-Regular'
                    }}>State</div>
                     <div style={{
                        marginLeft: 10,
                        paddingLeft: 5, paddingRight: 5, paddingTop: 3, paddingBottom: 3, borderRadius: 3,
                        backgroundColor: '#DDD', color: '#000', fontFamily: 'Metropolis-Regular'
                    }}>Pin</div>
                    <div style={{
                        marginLeft: 10,
                        paddingLeft: 5, paddingRight: 5, paddingTop: 3, paddingBottom: 3, borderRadius: 3,
                        backgroundColor: '#DDD', color: '#000', fontFamily: 'Metropolis-Regular'
                    }}>Email</div>
                          <div style={{
                        marginLeft: 10,
                        paddingLeft: 5, paddingRight: 5, paddingTop: 3, paddingBottom: 3, borderRadius: 3,
                        backgroundColor: '#DDD', color: '#000', fontFamily: 'Metropolis-Regular'
                    }}>Mobile</div>
                    <div style={{
                        marginLeft: 10,
                        paddingLeft: 5, paddingRight: 5, paddingTop: 3, paddingBottom: 3, borderRadius: 3,
                        backgroundColor: '#DDD', color: '#000', fontFamily: 'Metropolis-Regular'
                    }}>Age</div>
                    <div style={{
                        marginLeft: 10,
                        paddingLeft: 5, paddingRight: 5, paddingTop: 3, paddingBottom: 3, borderRadius: 3,
                        backgroundColor: '#DDD', color: '#000', fontFamily: 'Metropolis-Regular'
                    }}>Gender</div>
                </div>
            </div>
        </Paper>
    )
}

export default function Template(props) {

    const classes = useStyles();

    const [state, setState] = useState({
        showAddEditSectionDialog: false,
        sectionName: '',
        sectionNameError: false,
        sectionNameErrorMessage: '',
        isTemplateEditable: false,
        nestedCondition: false
    });

    var _pathRef = useRef(['Templates']);
    const nestedFieldRef = React.createRef();

    const _saveClicked = () => {
        //Check if there any sections, without any rows.
        var sectionWithoutRows = false;
        for (var i = 0; i < sections.length; i++) {
            var section = sections[i];
            if (section.rows.length === 0) {
                sectionWithoutRows = true;
                break;
            }
        }

        if (sectionWithoutRows) {
            ErrorDialog.show("Groups without fields not allowed");
            return;
        }




        //Check if editing is allowed.
        HttpHandler.sendGet('/api/v1/template/' + currentTemplate.id + '/editable', (d) => {
            if (!d.editable) {
                var msg = "Template is in used by a active or a completed camp.";
                if (currentTemplate.type === 'SC') {
                    msg = "Template is in used by a active or a completed survey.";
                }
                ErrorDialog.show(msg);
                return;
            }
            else {
                var req = {
                    name: currentTemplate.name,
                    type: currentTemplate.type,
                    sections: sections
                }

                HttpHandler.sendPost('/api/v1/template/' + currentTemplate.id, req, (d) => {
                    NotificationManager.success("Template saved.", currentTemplate.name);
                }, (errorCode, errorMessage) => {
                    console.log(errorMessage);
                    ErrorDialog.show("Internal error saving the template");
                })
            }
        }, (errorCode, errorMessage) => {
            console.log(errorMessage);
            ErrorDialog.show("Internal error saving the template");
        })
    };

    const _showAddSectionDialog = () => {
        sectionEdit = null;
        setState({
            ...state,
            showAddEditSectionDialog: true,
            nestedCondition: false,
            sectionNameError: false,
            sectionNameErrorMessage: ''
        })
    }

    const _orderChanged = () => {
        setState({
            ...state,
        })
    }

    const _sectionRemoved = () => {
        setState({
            ...state,
        })
    }

    const _editGroupClicked = (row) => {
        sectionEdit = row;
        setState({
            ...state,
            showAddEditSectionDialog: true,
            sectionName: row.name
        })
    }

    const _closeCreateSectionDialog = () => {
        setState({
            ...state,
            sectionName: '',
            showAddEditSectionDialog: false
        })
    }

    const _saveSectionClicked = () => {
        var name = state.sectionName.trim();
        if (name.length < 3 || name.length > 100) {
            setState({
                ...state,
                sectionNameError: true,
                sectionNameErrorMessage: 'Name should have 3-100 characters'
            });
            return;
        }
        for (var i = 0; i < sections.length; i++) {
            if (sections[i].name === name && sections[i] !== sectionEdit) {
                setState({
                    ...state,
                    sectionNameError: true,
                    sectionNameErrorMessage: 'Name already used'
                });
                return;
            }
        }

        //Check if we have nested options selected
        var nestedCondition = null;
        if (state.nestedCondition) {
            if (!nestedFieldRef.current.isValid()) {
                return;
            }

            //Create nested conditoon object.
            nestedCondition = {};



        }

        if (sectionEdit === null) {
            var section = { name: name, sequence: sections.length, rows: [] };
            //var sections = state.sections;
            sections.push(section);
        }
        else {
            sectionEdit.name = name;
        }

        setState({
            ...state,
            sectionName: '',
            showAddEditSectionDialog: false
        })

    };

    const _loadTemplate = () => {
        HttpHandler.sendGet('/api/v1/template/' + templateId, (data) => {
            currentTemplate = data.template;
            if (currentTemplate.type === 'CA') {
                _pathRef.current.push('Camp templates');
            }
            else if (currentTemplate.type === 'SU') {
                _pathRef.current.push('Survey templates');
            }
            else if (currentTemplate.type === 'CM') {
                _pathRef.current.push('Medical templates');
            }
            _pathRef.current.push(currentTemplate.name);
            _loadTemplateDetails();
        }, (errorCode, errorMessage) => {
            console.log(errorMessage);
            ErrorDialog.show('Internal error in loading template details.');
        })
    }

    const _loadTemplateDetails = () => {
        HttpHandler.sendGet('/api/v1/template/' + templateId + '/details', (data) => {
            sections.splice(0, sections.length);
            var len = data.sections.length;
            for (let i = 0; i < len; i++) {
                sections.push(data.sections[i]);
            };

            sections.sort(function (x, y) {
                if (x.sequence < y.sequence) {
                    return -1;
                }
                if (x.sequence > y.sequence) {
                    return 1;
                }
                return 0;
            });

            _checkTemplateInUse({ ...state });
        }, (errorCode, errorMessage) => {
            console.log(errorMessage);
            ErrorDialog.show('Internal error in loading template details.');
        })
    }

    const _checkTemplateInUse = (istate) => {
        HttpHandler.sendGet('/api/v1/template/' + currentTemplate.id + '/editable', (d) => {
            setState({ ...istate, isTemplateEditable: d.editable });
        }, (errorCode, errorMessage) => {
            console.log(errorMessage);
            ErrorDialog.show('Internal error in loading template details.');
        })
    }

    useEffect(() => {
        templateId = props.match.params.templateId;
        _loadTemplate();
    }, []);

    const _handleNestedConditionChange = (e) => {
        setState({
            ...state,
            nestedCondition: e.target.checked
        })
    }

    return (
        <div>
            {(HttpHandler.USER_TYPE !== 'admin' && HttpHandler.USER_TYPE !== 'coordinator') &&
                <div style={{ paddingTop: 40, color: '#FF0000', fontFamily: 'Metropolis-Regular', fontSize: 15, textAlign: 'center' }}>You are not authorized to access contents of this page.</div>
            }
            {(HttpHandler.USER_TYPE === 'admin' || HttpHandler.USER_TYPE === 'coordinator') &&
                <div>
                    <Dialog onClose={_closeCreateSectionDialog} open={state.showAddEditSectionDialog}
                        fullWidth={true} maxWidth="xs">
                        <div style={{ padding: 5, width: '100%' }}>
                            <div className={commonClasses.headerBar}>Template Group</div>
                        </div>
                        <DialogContent>
                            <div style={{ width: 300, flexDirection: 'row', display: 'flex', paddingBottom: 10 }}>
                                <form noValidate autoComplete="off" style={{ width: '100%' }}>
                                    <TextField
                                        required

                                        error={state.sectionNameError}
                                        id='sectionName'
                                        label="Group Name"
                                        fullWidth
                                        value={state.sectionName}
                                        margin="normal"
                                        helperText={state.sectionNameErrorMessage}
                                        onChange={(v) => {
                                            setState({
                                                ...state,
                                                sectionName: v.target.value,
                                                sectionNameError: false,
                                                sectionNameErrorMessage: ''
                                            })
                                        }}
                                    />
                                </form>

                            </div>
                            {(false) &&
                                <div>
                                    <FormControlLabel
                                        style={{ marginTop: 10 }}
                                        control={
                                            <Switch
                                                checked={state.nestedCondition}
                                                onChange={_handleNestedConditionChange}
                                                name="checkedB"
                                                color="primary"
                                            />
                                        }
                                        label="Conditional section"
                                    />
                                </div>
                            }
                            {(sections.length > 0 && state.nestedCondition) &&
                                <div style={{ width: 300, marginTop: 20 }}>
                                    <NestedCondition ref={nestedFieldRef} sections={sections} />
                                </div>
                            }
                        </DialogContent>
                        <Divider style={{}}></Divider>
                        <DialogActions>
                            <div style={{ paddingTop: 10, flexDirection: 'row', display: 'flex', justifyContent: 'flex-end' }}>
                                <Button onClick={_saveSectionClicked} variant="contained" color="primary">Save</Button>
                                <Button
                                    onClick={_closeCreateSectionDialog}
                                    variant="contained" color="default" style={{ marginLeft: 20 }}>Close</Button>
                            </div>
                        </DialogActions>
                    </Dialog>

                    <div style={{ marginRight: 20 }}>
                        <PagePath path={_pathRef.current}>
                            {(HttpHandler.USER_TYPE === 'admin') &&
                                <Tooltip title="Add section" arrow>

                                    <Fab
                                        disabled={!state.isTemplateEditable}
                                        onClick={_showAddSectionDialog}
                                        color="primary" aria-label="add" size="medium">
                                        <NoteAddOutlinedIcon />

                                    </Fab>

                                </Tooltip>
                            }{(HttpHandler.USER_TYPE === 'admin') &&
                                <Tooltip title="Save Template" arrow>
                                    <Fab
                                        disabled={!state.isTemplateEditable}
                                        onClick={_saveClicked}
                                        style={{ marginLeft: 20 }}
                                        color="primary" aria-label="add" size="medium">
                                        <BackupRoundedIcon />
                                    </Fab>
                                </Tooltip>
                            }
                        </PagePath>
                    </div>
                    <div style={{ marginTop: 20, paddingBottom: 30 }}>

                        <div style={{
                            display: 'flex', justifyContent: 'center',

                        }}>
                            <div>
                                <div style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 10 }}>
                                    <BaseInfoComp></BaseInfoComp>
                                </div>
                                {sections.map((row, key) => {
                                    return (
                                        <div key={key} style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 10 }}>
                                            <TemplateGroup
                                                disabled={!state.isTemplateEditable}
                                                onEdit={() => { _editGroupClicked(row) }}
                                                sectionRemoved={_sectionRemoved}
                                                onOrderChanged={_orderChanged}
                                                sequence={row.sequence} key={row.name} name={row.name} index={key} />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>

                </div>
            }
        </div>
    )
}
