import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles'

import SearchableSelect from './SearchableSelect.js';

const styles = theme => ({
});

const defs = {
    name : {
        title : 'Name',
        align : 'left'
    },
    district : {
        title : 'District',
        align : 'left',
        renderer: (row, data) => {
            return (
                <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center' }} >
                    {row.address.district.name}
                </div>
            )
        }
    }
    
}

class OrgSearchSelect extends Component {
    
    selectedOrg = null;
    

    _orgSelected(v) {
        this.props.orgSelected(v);
        this.selectedOrg = v;
    }

    render() {
        return (
            <SearchableSelect
                style={this.props.style}
                error={this.props.error}
                helperText={this.props.helperText}
                defs = {defs}
                value={this.props.value}
                url='/api/v1/org/list?'
                displayField='name'
                onSelect={this._orgSelected.bind(this)}
                label={this.props.label}
                isRequired = {this.props.isRequired}
            ></SearchableSelect>
        );
    }
}

OrgSearchSelect.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(OrgSearchSelect);