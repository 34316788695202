import React, { useRef, useEffect, useState, createRef } from 'react';
import { Paper, Divider, TextField, FormControl, Select, MenuItem, InputLabel, Fab, CircularProgress, DialogActions } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { NotificationManager } from 'react-notifications';
import LocalHospitalRoundedIcon from '@material-ui/icons/ControlPoint';
import BackupRoundedIcon from '@material-ui/icons/BackupRounded';
import CopyrightIcon from '@material-ui/icons/Copyright';

import PagePath from './components/PagePath';
import HttpHandler from './HttpHandler'
import TemplateField from './components/TemplateField';
import Utils from './Utils'


export default function CampMedicalRecord(props) {

    let campId = useRef(0);

    const [state, setState] = useState({
        campName: '',


        sections: [],
        generalMarital: 'M',
        generalGender: 'M',
        loadMessage: '',
        showLoading: false,
        data: {},
        medicalAdded: props.medicalAdded
    });

    const _loadCampDetails = (istate) => {
        HttpHandler.sendGet('/api/v1/camp/' + campId.current, (d) => {
            istate = { ...istate, campName: d.camp.name };
            setState(istate);
            _getSectionDetails(istate, d.camp.medicalTemplate.id)
        }, (errorCode, errorMessage) => {
            console.log(errorMessage);
        });
    }

    const _getSectionDetails = (istate, templateId) => {
        HttpHandler.sendGet('/api/v1/template/' + templateId + '/details', (d) => {
            if (typeof props.recordId === 'undefined' || typeof props.medicalAdded === 'undefined' || !props.medicalAdded) {
                istate = { ...istate, sections: d.sections, showLoading: false };
                setState(istate);
                return;
            }
            else {
                istate = { ...istate, sections: d.sections };
                setState(istate);
            }

            _loadRecord(istate);

        }, (errorCode, errorMessage) => {
            console.log(errorMessage);
        })
    }

    useEffect(() => {
        campId.current = props.campId;
        var msg = 'Loading questions';
        if (typeof props.recordId !== 'undefined' && typeof props.medicalAdded !== 'undefined' && props.medicalAdded) {
            msg = 'Loading questions and data to edit';
        }
        var istate = { ...state, loadMessage: msg, showLoading: true }
        setState(istate);
        _loadCampDetails(istate);
    }, [props.campId]);

    const _isValid = (istate) => {
        var valid = true;
        for (var i = 0; i < state.sections.length; i++) {
            var section = state.sections[i];
            for (var j = 0; j < section.rows.length; j++) {
                var field = section.rows[j];
                valid = field.ref.current.validate() && valid;
            }
        }

        return valid;

    }

    const _saveClicked = () => {
        var istate = state;

        if (!_isValid(istate)) {
            setState({ ...state, istate });
            NotificationManager.error("Incorrect/ Missing Data - Please check", "Error");
            return;
        }

        //Get individual data
        var allData = {}
        for (var i = 0; i < state.sections.length; i++) {
            var section = state.sections[i];
            var sectionData = {};
            for (var j = 0; j < section.rows.length; j++) {
                var field = section.rows[j];
                var val = field.ref.current.getValue();
                val = { value: val, id: field.id, type: field.type };
                sectionData[field.id] = val;
            }

            allData[section.id] = sectionData;
        }

        HttpHandler.sendPost('/api/v1/camp/' + campId.current + '/' + props.recordId + '/medical', { data: allData }, (d) => {
            props.onSave();

            setState({ ...state, medicalAdded: true });
            NotificationManager.success('Medical record saved');

        }, (errorCode, errorMessage) => {
            console.log(errorMessage);
        })
    }

    const _loadRecord = (istate) => {
        HttpHandler.sendGet('/api/v1/camp/' + campId.current + '/data/' + props.recordId + '/medical', (d) => {
            istate = {
                ...istate, showLoading: false,
                data: d.data
            };

            setState(istate);
        }, (errorCode, errorMessage) => {
            NotificationManager.error("Error loading questions and record. Try again or contact administrator.", "Error");
            props.onClose();
        });
    }

    const _closeClicked = () => {
        props.onClose();
    }

    const _probableCaseClicked = () => {
        props.onProbaleCase();
    }
    const _counsellingCaseClicked = () => {
        props.openCouncil();
    }

    return (
        <div style={{ width: '100%' }}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                {state.showLoading &&
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <div style={{ fontFamily: 'Metropolis-Regular', paddingRight: 20 }}>{state.loadMessage} </div>
                        <CircularProgress />
                    </div>
                }
                {(state.showLoading === false) &&
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div>
                            <div style={{ flex: 1, display: 'flex', justifyContent: 'center', paddingTop: 30, flexDirection: 'column' }}>
                                <div>
                                    {state.sections.map((row, key) => {
                                        return (
                                            <Paper key={row.id} style={{ padding: 10, minWidth: 500, marginTop: 20, marginBottom: 20 }}>
                                                <div style={{ flexDirection: 'row', display: 'flex', paddingBottom: 5, alignItems: 'center' }}>
                                                    <div style={{ fontFamily: 'Metropolis-Medium', flex: 1 }}>{row.name}</div>
                                                </div>
                                                <Divider></Divider>
                                                {row.rows.map((field, fkey) => {
                                                    var ref = createRef();
                                                    field.ref = ref;
                                                    var val = state.data[field.id];
                                                    if (typeof val === 'undefined') {
                                                        val = {};
                                                    }

                                                    return (
                                                        <div key={field.id} style={{ flex: 1, paddingRight: 10, paddingTop: 10 }}>
                                                            <TemplateField value={val.value} ref={ref} key={field.name} def={field} />
                                                        </div>
                                                    )
                                                })}
                                            </Paper>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                        <div style={{ paddingTop: 30, paddingLeft: 10, position: "fixed", marginLeft: '32%' }}>
                            <Fab
                                onClick={_saveClicked}
                                disabled={HttpHandler.USER_TYPE === 'coordinator' && props.campStatus != 'RU'}
                                style={{ marginRight: 20 }}
                                color="primary" aria-label="add" size="medium">
                                <BackupRoundedIcon />
                            </Fab>

                            <Fab
                                disabled={state.medicalAdded === false}
                                onClick={_probableCaseClicked}
                                style={{ marginRight: 30 }}
                                color="primary" aria-label="add" size="medium">
                                <img src={require('./images/cancer_check.png')} style={{ width: 24, height: 24 }} />
                            </Fab>
                            <Fab
                                disabled={state.medicalAdded === false}
                                onClick={_counsellingCaseClicked}
                                style={{ marginRight: 30 }}
                                color="primary" aria-label="add" size="medium">
                                <CopyrightIcon style={{ fontSize: 35 }} />
                            </Fab>

                            <Fab
                                onClick={_closeClicked}
                                style={{ marginRight: 30 }}
                                color="default" aria-label="add" size="medium">
                                <CloseRoundedIcon />
                            </Fab>
                        </div>
                    </div>
                }
            </div>
        </div >
    )
}