import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles'

import { Fab, Paper, TextField, Divider, Button, DialogActions, Dialog } from '@material-ui/core';

import AddIcon from '@material-ui/icons/AddRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';

import { NotificationManager } from 'react-notifications';

import commonClasses from './ics.module.css'

import PagePath from './components/PagePath';
import HttpHandler from './HttpHandler'
import ErrorDialog from './components/ErrorDialog';
import ConfirmationDialog from './components/ConfirmationDialog';
import Utils from './Utils';

import CTable from './components/CTable'

const useStyles = makeStyles({
});

function DonorCreateEdit(props) {

    const [state, setState] = useState({
        donorName: '',
        donorNameError: false,
        donorNameErrorMessage: '',
        contactPerson: '',
        contactPersonError: false,
        contactPersonErrorMessage: '',
        contactPhone: '',
        contactPhoneError: false,
        contactPhoneErrorMessage: '',
        contactEmail: '',
        contactEmailError: false,
        contactEmailErrorMessage: '',
        projectMEmail: '',
        projectMEmailError: false,
        projectMEmailErrorMessage: ''
    });

    const _onPhoneChanged = (v) => {
        var val = v.target.value;
        if (val.length > 11) {
            val = state.contactPhone
        }
        else if (val.indexOf('.') >= 0) {
            val = state.contactPhone;
        }
        else if (isNaN(val)) {
            val = state.contactPhone;
        }
        setState({
            ...state,
            contactPhone: val,
            contactPhoneError: false,
            contactPhoneErrorMessage: ''
        })
    }

    const _saveClicked = () => {
        var valid = true;
        var myState = { ...state };

        var name = state.donorName.trim();
        if (name.length === 0) {
            valid = false;
            myState.donorNameError = true;
            myState.donorNameErrorMessage = "Mandatory";
        } else if (name.length > 100) {
            valid = false;
            myState.donorNameError = true;
            myState.donorNameErrorMessage = "Name should not exceed 100 characters";
        } else if (name.length < 3) {
            valid = false;
            myState.donorNameError = true;
            myState.donorNameErrorMessage = "Minimum 3 characters required";
        } else if (!Utils.isValidName(name)) {
            valid = false;
            myState.donorNameError = true;
            myState.donorNameErrorMessage = "Invalid Name";
        }

        var contactPerson = state.contactPerson.trim();

        if (contactPerson.length === 0) {
            valid = false;
            myState.contactPersonError = true;
            myState.contactPersonErrorMessage = "Mandatory";
        } else if (contactPerson.length > 100) {
            valid = false;
            myState.contactPersonError = true;
            myState.contactPersonErrorMessage = "Maximum 100 characters allowed";
        } else if (contactPerson.length < 3) {
            valid = false;
            myState.contactPersonError = true;
            myState.contactPersonErrorMessage = "Minimum 3 characters required";
        } else if (!Utils.isValidDotAccept(contactPerson)) {
            valid = false;
            myState.contactPersonError = true;
            myState.contactPersonErrorMessage = "Invalid Name";
        }

        var phone = state.contactPhone.trim();
        if (phone === '') {
            valid = false;
            myState.contactPhoneError = true;
            myState.contactPhoneErrorMessage = 'Mandatory'
        } else if (!Utils.isValidPhone(phone)) {
            valid = false;
            myState.contactPhoneError = true;
            myState.contactPhoneErrorMessage = 'Invalid phone'
        }

        var contactEmail = state.contactEmail.trim();
        if (contactEmail.length > 150 || (contactEmail.length !== 0 && !Utils.isValidEmail(contactEmail))) {
            myState.contactEmailError = true;
            myState.contactEmailErrorMessage = "Invalid Email";
            valid = false;
        }

        var projectMEmail = state.projectMEmail.trim();
        if (projectMEmail.length > 150 || (projectMEmail.length !== 0 && !Utils.isValidEmail(projectMEmail))) {
            myState.projectMEmailError = true;
            myState.projectMEmailErrorMessage = "Invalid Email";
            valid = false;
        }


        if (!valid) {
            setState(myState);
            return;
        }

        var req = {
            name: name,
            contact: contactPerson,
            phone: phone,
            email: contactEmail,
            icsPMEmail: projectMEmail
        }

        var url = '/api/v1/donor/new';
        if (props.donorId > 0) {
            url = '/api/v1/donor/' + props.donorId;
        }
        HttpHandler.sendPost(url, req, (data) => {
            if (props.donorId > 0) {
                NotificationManager.success("Donor details updated.", name);
            }
            else {
                NotificationManager.success("Donor registered.", name);
            }
            props.onDone();
        }, (errorCode, errorMessage) => {
            console.log(errorMessage);
            ErrorDialog.show("There was error in saving donor details");
        })
    }

    const _closeClicked = () => {
        props.onClose();
    }

    const _loadDonorDetails = () => {
        HttpHandler.sendGet('/api/v1/donor/' + props.donorId, (d) => {
            var istate = {
                ...state,
                donorName: d.donor.name,
                contactPerson: d.donor.contact,
                contactPhone: d.donor.phone === null ? '' : d.donor.phone,
                contactEmail: d.donor.email === null ? '' : d.donor.email,
                projectMEmail: d.donor.icsPMEmail === null ? '' : d.donor.icsPMEmail
            }

            setState(istate);
        }, (errorCoder, errorMessage) => {
            props.onClose();
        })
    }


    useEffect(() => {
        if (props.donorId > 0) {
            _loadDonorDetails();
        }
        else {
            setState({
                ...state,
                donorName: '',
                donorNameError: false,
                donorNameErrorMessage: '',
                contactPerson: '',
                contactPersonError: false,
                contactPersonErrorMessage: '',
                contactPhone: '',
                contactPhoneError: false,
                contactPhoneErrorMessage: '',
                contactEmail: '',
                contactEmailError: false,
                contactEmailErrorMessage: '',
                projectMEmail: '',
                projectMEmailError: false,
                projectMEmailErrorMessage: ''
            })
        }
    }, [props.donorId]);

    return (
        <div>
            <Paper style={{ padding: 10, width: 500 }}>
                <div className={commonClasses.headerBar}>{props.heading}</div>
                <div style={{ paddingBottom: 20 }}>
                    <form noValidate autoComplete="off">
                        <TextField
                            required
                            error={state.donorNameError}
                            id='donorName'
                            label="Name"
                            fullWidth
                            value={state.donorName}
                            margin="normal"
                            helperText={state.donorNameErrorMessage}

                            onChange={(v) => {
                                setState({
                                    ...state,
                                    donorName: v.target.value,
                                    donorNameError: false,
                                    donorNameErrorMessage: ''
                                })
                            }}
                        />

                        <TextField
                            required
                            error={state.contactPersonError}
                            id='contactPerson'
                            label="Contact Person"
                            fullWidth
                            value={state.contactPerson}
                            margin="normal"
                            helperText={state.contactPersonErrorMessage}
                            onChange={(v) => {
                                setState({
                                    ...state,
                                    contactPerson: v.target.value,
                                    contactPersonError: false,
                                    contactPersonErrorMessage: ''
                                })
                            }}
                        />
                        <TextField
                            required
                            error={state.contactPhoneError}
                            id='contactPhone'
                            label="Contact Phone Number"
                            fullWidth
                            value={state.contactPhone}
                            margin="normal"
                            helperText={state.contactPhoneErrorMessage}
                            onChange={_onPhoneChanged}
                        />
                        <TextField
                            error={state.contactEmailError}
                            id='contactEmail'
                            label="Email"
                            fullWidth
                            value={state.contactEmail}
                            margin="normal"
                            helperText={state.contactEmailErrorMessage}
                            onChange={(v) => {
                                setState({
                                    ...state,
                                    contactEmail: v.target.value,
                                    contactEmailError: false,
                                    contactEmailErrorMessage: ''
                                })
                            }}
                        />
                        <TextField
                            error={state.projectMEmailError}
                            id='projectMEmail'
                            label="ICS Project Manager Email"
                            fullWidth
                            value={state.projectMEmail}
                            margin="normal"
                            helperText={state.projectMEmailErrorMessage}
                            onChange={(v) => {
                                setState({
                                    ...state,
                                    projectMEmail: v.target.value,
                                    projectMEmailError: false,
                                    projectMEmailErrorMessage: ''
                                })
                            }}
                        />

                    </form>
                </div>
                <Divider style={{ marginLeft: -10, marginRight: -10 }}></Divider>
                <div style={{ paddingTop: 10, flexDirection: 'row', display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        onClick={_saveClicked}
                        variant="contained" color="primary">Save</Button>
                    <Button
                        onClick={_closeClicked}
                        variant="contained" color="default" style={{ marginLeft: 20 }}>Close</Button>
                </div>
            </Paper>
        </div>
    )
}


export default function Donors(props) {


    const dataTable = useRef(null);

    const [state, setState] = useState({
        editCreateDialog: false,
        donors: [],
        selectedDonorId: -1,
        addEditUIHeading: "Register a Donor"
    });

    const donorTableDef = [
        {
            title: "Name",
            field: 'name',
            align: 'left',
            renderer: (row, data) => {
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center' }} >
                        {row.name}
                    </div>
                )
            }

        },
        {
            title: "Contact Person",
            field: 'contactName',
            align: 'left',
            renderer: (row, data) => {
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center' }} >
                        {row.contact}
                    </div>
                )
            }
        },
        {
            title: "",
            field: 'id',
            style: { minWidth: 120, width: 120 },
            renderer: (row, data) => {
                return (
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        {(HttpHandler.USER_TYPE === 'admin') &&
                            <div
                                onClick={() => _editClicked(row)}
                                style={{ height: 24, cursor: 'pointer' }}>
                                <EditRoundedIcon style={{ color: '#03A9F4' }}></EditRoundedIcon>
                            </div>
                        }
                        {(HttpHandler.USER_TYPE === 'admin') &&
                            <div
                                onClick={() => _deleteClicked(row)}
                                style={{ marginLeft: 20, height: 24, cursor: 'pointer' }}>
                                <DeleteForeverRoundedIcon style={{ color: '#D32F2F' }}></DeleteForeverRoundedIcon>
                            </div>
                        }

                    </div>
                )
            }

        }
    ]

    const _editClicked = (row) => {
        setState({ ...state, editCreateDialog: true, selectedDonorId: row.id, addEditUIHeading: "Modify Donor" })
    }

    const _deleteClicked = (row) => {
        ConfirmationDialog.show('Are you sure you want to delete this donor?', () => {
            HttpHandler.sendDelete('/api/v1/donor/' + row.id, (d) => {
                if (d.alreadyInUse) {
                    ErrorDialog.show(d.ErrMessage);
                    return;
                }
                NotificationManager.success("Donor record deleted.", row.name);
                dataTable.current.reload();
            }, (errorCode, errorMessage) => {
                ErrorDialog.show("There was error in deleting donor record");
            })
        });
    }

    useEffect(() => {

    }, []);

    const _createDonor = () => {
        setState({
            ...state,
            editCreateDialog: true,
            selectedDonorId: -1,
            addEditUIHeading: "Register a Donor"
        });

    }

    const _getDonorData = (cb, offset, limit, searchString) => {
        HttpHandler.sendGet('/api/v1/donor/all?offset=' + offset + '&limit=' + limit + '&s=' + searchString, (data) => {
            cb(data.result, data.count)
        }, (errorCode, errorMessage) => {
            console.log(errorMessage);
        })
    }

    const _donorAdded = () => {
        setState({
            ...state,
            editCreateDialog: false
        });
        dataTable.current.reload();
    }


    return (
        <div>
            {(HttpHandler.USER_TYPE !== 'admin' && HttpHandler.USER_TYPE !== 'coordinator') &&
                <div style={{ paddingTop: 40, color: '#FF0000', fontFamily: 'Metropolis-Regular', fontSize: 15, textAlign: 'center' }}>You are not authorized to access contents of this page.</div>
            }
            {(HttpHandler.USER_TYPE === 'admin' || HttpHandler.USER_TYPE === 'coordinator') &&
                <div>
                    <div style={{ marginRight: 20 }}>
                        <PagePath path={['Donors', 'All']}>
                            {(HttpHandler.USER_TYPE === 'admin') &&
                                <Fab
                                    onClick={_createDonor}
                                    color="primary" aria-label="add" size="medium" title="New Donor">
                                    <AddIcon />
                                </Fab>
                            }
                        </PagePath>
                    </div>
                    <div style={{ marginTop: 20, paddingBottom: 30, paddingRight: 10 }}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            {state.editCreateDialog &&
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <DonorCreateEdit donorId={state.selectedDonorId}
                                        heading={state.addEditUIHeading}
                                        onDone={_donorAdded}
                                        onClose={() => { setState({ ...state, editCreateDialog: false }) }} />
                                </div>
                            }
                            {(state.editCreateDialog === false) &&
                                <Paper style={{ padding: 10 }}>
                                    <div style={{ width: 900 }}>
                                        <CTable
                                            ref={dataTable}
                                            data={_getDonorData}
                                            columns={donorTableDef}>
                                        </CTable>
                                    </div>
                                </Paper>
                            }
                        </div>
                    </div>
                </div>
            }
        </div>

    )
}

