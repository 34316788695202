import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles'

import { Fab, Paper, TextField, Divider, Button, DialogActions, Dialog } from '@material-ui/core';

import AddIcon from '@material-ui/icons/AddRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';

import { NotificationManager } from 'react-notifications';

import commonClasses from './ics.module.css'

import PagePath from './components/PagePath';
import HttpHandler from './HttpHandler'
import ErrorDialog from './components/ErrorDialog';
import ConfirmationDialog from './components/ConfirmationDialog';
import Utils from './Utils';


import CTable from './components/CTable'

const useStyles = makeStyles({
});


function StateCreateEdit(props) {

    const [thisState, setState] = useState({
        stateName: '',
        stateNameError: false,
        stateNameErrorMessage: '',

    });

    const _saveClicked = () => {
        var valid = true;
        var myState = { ...thisState };

        var name = thisState.stateName.trim();
        if (name.length === 0) {
            valid = false;
            myState.stateNameError = true;
            myState.stateNameErrorMessage = "Mandatory";
        }

        if (name.length > 100) {
            valid = false;
            myState.stateNameError = true;
            myState.stateNameErrorMessage = "Name should not exceed 100 characters";
        }


        if (!valid) {
            setState(myState);
            return;
        }

        //Check for duplicate code or name

        var req = {
            name: name,
        }

        //Duplicate check can be done locally.
        var duplicate = false;
        for (var i = 0; i < Utils.states.length; i++) {
            var state = Utils.states[i];
            if (state.id !== props.stateId && state.name.toUpperCase() === name.toUpperCase()) {
                duplicate = true;
                break;
            }
        }

        if (duplicate) {
            setState({ ...thisState, stateNameError: true, stateNameErrorMessage: 'Name already used' });
            return;
        }

        var url = '/api/v1/location/state/new';
        if (props.stateId > 0) {
            url = '/api/v1/location/state/' + props.stateId;
        }
        HttpHandler.sendPost(url, req, (data) => {
            if (props.stateId > 0) {
                NotificationManager.success("State record updated.", name);

                //Change the name
                for (var i = 0; i < Utils.states.length; i++) {
                    var state = Utils.states[i];
                    if (state.id === props.stateId) {
                        state.name = name;
                        break;
                    }
                }
                Utils.sortStates();

            }
            else {
                NotificationManager.success("State record created.", name);

                //Add to Utils state
                Utils.states.push({ name: name, id: data.id });
                Utils.statesAndDistrictsMap[name] = { id: data.id, districtCount: 0, districts: [] };
                Utils.sortStates();
            }
            props.onDone();
        }, (errorCode, errorMessage) => {
            console.log(errorMessage);
            ErrorDialog.show("There was error in saving state record");
        })

    }

    const _closeClicked = () => {
        props.onClose();
    }

    useEffect(() => {
        if (props.stateId > 0) {
            for (var i = 0; i < Utils.states.length; i++) {
                var state = Utils.states[i];
                if (state.id === props.stateId) {
                    setState({
                        ...thisState,
                        stateName: state.name,
                        stateNameError: false,
                        stateNameErrorMessage: '',
                    })
                    break;
                }
            }

        }
        else {
            setState({
                ...thisState,
                stateName: '',
                stateNameError: false,
                stateNameErrorMessage: '',
            })
        }
    }, [props.stateId]);

    return (
        <div>
            <Paper style={{ padding: 10, width: 500 }}>
                <div className={commonClasses.headerBar}>State</div>
                <div style={{ paddingBottom: 20 }}>
                    <form noValidate autoComplete="off">
                        <TextField
                            required
                            error={thisState.stateNameError}
                            id='stateName'
                            label="Name"
                            fullWidth
                            value={thisState.stateName}
                            margin="normal"
                            helperText={thisState.stateNameErrorMessage}

                            onChange={(v) => {
                                setState({
                                    ...thisState,
                                    stateName: v.target.value,
                                    stateNameError: false,
                                    stateNameErrorMessage: ''
                                })
                            }}
                        />

                    </form>
                </div>
                <Divider style={{ marginLeft: -10, marginRight: -10 }}></Divider>
                <div style={{ paddingTop: 10, flexDirection: 'row', display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        onClick={_saveClicked}
                        variant="contained" color="primary">Save</Button>
                    <Button
                        onClick={_closeClicked}
                        variant="contained" color="default" style={{ marginLeft: 20 }}>Close</Button>
                </div>
            </Paper>
        </div>
    )
}


export default function States(props) {


    const dataTable = useRef(null);

    const [state, setState] = useState({
        editCreateDialog: false,
        states: [],
        selectedStateId: -1,
    });

    const stateTableDef = [
        {
            title: "Name",
            field: 'name',
            align: 'left',
            renderer: (row, data) => {
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center', fontFamily: 'Metropolis-Regular' }} >
                        {row.name}
                    </div>
                )
            }

        },
        {
            title: "",
            field: 'id',
            style: { minWidth: 120, width: 120 },
            renderer: (row, data) => {
                return (
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        {(HttpHandler.USER_TYPE === 'admin') &&
                            <div
                                onClick={() => _editClicked(row)}
                                style={{ height: 24, cursor: 'pointer' }}>
                                <EditRoundedIcon style={{ color: '#03A9F4' }}></EditRoundedIcon>
                            </div>
                        }
                    </div>
                )
            }

        }
    ]

    const _editClicked = (row) => {
        setState({ ...state, editCreateDialog: true, selectedStateId: row.id })
    }

    const _deleteClicked = (row) => {
        if (Utils.statesAndDistrictsMap[row.name].districtCount == 0) {
            ConfirmationDialog.show('Are you sure you want to delete this state?', () => {
                HttpHandler.sendDelete('/api/v1/location/state/' + row.id, (d) => {
                    NotificationManager.success("State deleted.", row.name);

                    //Delete
                    var indexToDelete = -1;
                    for (var i = 0; i < Utils.states.length; i++) {
                        var state = Utils.states[i];
                        if (state.id === row.id) {
                            indexToDelete = i;
                            break;
                        }
                    }

                    Utils.states.splice(indexToDelete, 1);
                    delete Utils.statesAndDistrictsMap[row.name];

                    //We don't have to worry about districts as we don't allow any districts to be there when deleting state


                    Utils.sortStates();

                    dataTable.current.reload();
                }, (errorCode, errorMessage) => {
                    ErrorDialog.show("There was error in deleting state");
                })
            });
        }
    }

    useEffect(() => {

    }, []);

    const _createState = () => {
        setState({
            ...state,
            editCreateDialog: true,
            selectedStateId: -1
        });

    }

    const _stateSearchResult = (searchString) => {
        var res = [];
        for (var i = 0; i < Utils.states.length; i++) {
            if (Utils.states[i].name.toLowerCase().indexOf(searchString) >= 0) {
                res.push(Utils.states[i]);
            }

        }

        return res;
    }

    const _getStateData = (cb, offset, limit, searchString) => {
        var res = _stateSearchResult(searchString.toLowerCase());
        if (res.length < offset) {
            cb([], res.length);
        }
        else {
            var finalRes = [];
            var diff = res.length - offset;
            diff = diff > limit ? limit : diff;
            for (var i = 0; i < diff; i++) {
                finalRes.push(res[offset + i]);
            }
            cb(finalRes, res.length);
        }

    }

    const _stateCreated = () => {
        setState({
            ...state,
            editCreateDialog: false
        });
        dataTable.current.reload();
    }

    return (
        <div>
            <div style={{ marginRight: 20 }}>
                <PagePath path={['State', 'All']}>
                    {(HttpHandler.USER_TYPE === 'admin') &&
                        <Fab
                            onClick={_createState}
                            color="primary" aria-label="add" size="medium" title="New state">
                            <AddIcon />
                        </Fab>
                    }
                </PagePath>
            </div>

            <div style={{ marginTop: 20, paddingBottom: 30, paddingRight: 10 }}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {state.editCreateDialog &&
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <StateCreateEdit stateId={state.selectedStateId}
                                onDone={_stateCreated}
                                onClose={() => { setState({ ...state, editCreateDialog: false }) }} />
                        </div>
                    }
                    {(state.editCreateDialog === false) &&
                        <Paper style={{ padding: 10 }}>
                            <div style={{ width: 600 }}>
                                <CTable
                                    ref={dataTable}
                                    data={_getStateData}
                                    columns={stateTableDef}>
                                </CTable>
                            </div>
                        </Paper>
                    }
                </div>
            </div>
        </div>
    )
}

