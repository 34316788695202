import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Fab, Paper, TextField, InputLabel, Select, MenuItem, Divider, Button, FormControl, FormHelperText } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DatePicker } from "@material-ui/pickers";
import { Link, useHistory } from 'react-router-dom';

import AddIcon from '@material-ui/icons/AddRounded';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';


import moment from 'moment';
import Moment from 'react-moment';
import { NotificationManager } from 'react-notifications';

import commonClasses from './ics.module.css'
import PagePath from './components/PagePath';
import HttpHandler from './HttpHandler'
import Utils from './Utils'
import OrgSearchSelect from './components/OrgSearchSelect';
import DonorSearchSelect from './components/DonorSearchSelect';

import ErrorDialog from './components/ErrorDialog';
import CTable from './components/CTable'
import ConfirmationDialog from './components/ConfirmationDialog';

const surveyStatusMapping = {
    'SC': 'Scheduled',
    'RU': 'Running',
    'CA': 'Cancelled',
    'CO': 'Completed'
}


const useStyles = makeStyles({
});

function SurveyCreateEditUI(props) {

    const classes = useStyles();

    const [state, setState] = useState({
        surveyName: '',
        surveyNameError: false,
        surveyNameErrorMessage: '',
        address: '',
        addressError: false,
        addressErrorMessage: '',
        surveyPHC: '',
        surveyPHCError: false,
        surveyPHCErrorMessage: '',
        surveyCity: '',
        surveyCityError: false,
        surveyCityErrorMessage: '',
        startDate: moment(),
        startDateError: false,
        startDateErrorMessage: '',
        endDate: moment(),
        endDateError: false,
        endDateErrorMessage: '',
        district: -1,
        districtName: '',
        districtError: false,
        districtErrorMessage: '',
        surveyState: ' - Select a district - ',
        surveyStateError: false,
        surveyStateErrorMessage: '',
        suveyPIN: '',
        suveyPINError: false,
        suveyPINErrorMessage: '',
        organizer: null,
        organizerError: false,
        organizerErrorMessage: '',
        donor: null,
        donorError: false,
        donorErrorMessage: '',
        surveyTemplate: '',
        surveyTemplateError: false,
        surveyTemplateErrorMessage: ''
    });

    const _loadSurveyDetails = () => {
        HttpHandler.sendGet('/api/v1/survey/' + props.surveyId, (d) => {
            var donor = d.survey.donor;
            var istate = {
                ...state,
                surveyName: d.survey.name,
                address: d.survey.address.address,
                startDate: moment(new Date(d.survey.startDate)),
                endDate: moment(new Date(d.survey.endDate)),
                suveyPIN: d.survey.address.pin,
                surveyTemplate: d.survey.template.id,
                surveyPHC: d.survey.phc,
                surveyCity: d.survey.city,
            }

            istate = _setDistrictData(istate, d.survey.address.district.id)
            setState(istate);

            HttpHandler.sendGet('/api/v1/org/' + d.survey.organizer.id, (d) => {
                istate = istate;
                istate = { ...istate, organizer: d.org };

                HttpHandler.sendGet('/api/v1/donor/' + donor.id, (d) => {
                    istate = istate;
                    setState({ ...istate, donor: d.donor });


                }, (errorCode, errorMessage) => {
                    NotificationManager.error("Error loading details for edit.", "Survey")
                    props.onClose();
                })


            }, (errorCode, errorMessage) => {
                NotificationManager.error("Error loading details for edit.", "Survey")
                props.onClose();
            })

        }, (errorCode, errorMessage) => {
            NotificationManager.error("Error loading details for edit.", "Survey")
            props.onClose();
        })
    }

    const _setDistrictData = (istate, id) => {
        if (id === null) {
            istate = {
                ...istate,
                district: '',
                districtName: '',
                districtError: false,
                districtErrorMessage: '',
                surveyState: ' - Select a district - '
            };
            return istate;
        }
        var len = Utils.statesAndDistricts.length;
        for (var i = 0; i < len; i++) {
            if (Utils.statesAndDistricts[i].id === id) {
                istate = {
                    ...istate,
                    district: id,
                    districtName: Utils.statesAndDistricts[i].title,
                    districtError: false,
                    districtErrorMessage: '',
                    surveyState: Utils.statesAndDistricts[i].stateName,
                    surveyNameError: false,
                    surveyNameErrorMessage: '',
                    addressError: false,
                    addressErrorMessage: '',
                    startDateError: false,
                    startDateErrorMessage: '',
                    endDateError: false,
                    endDateErrorMessage: '',
                    districtError: false,
                    districtErrorMessage: '',
                    suveyPINError: false,
                    suveyPINErrorMessage: '',
                    organizerError: false,
                    organizerErrorMessage: '',
                    surveyTemplateError: false,
                    surveyTemplateErrorMessage: ''
                };
                return istate;
            }
        }

    }

    useEffect(() => {
        if (props.surveyId > 0) {
            _loadSurveyDetails();
        }
        else {
            setState({
                ...state,
                surveyName: '',
                surveyNameError: false,
                surveyNameErrorMessage: '',
                address: '',
                addressError: false,
                addressErrorMessage: '',
                surveyPHC: '',
                surveyPHCError: false,
                surveyPHCErrorMessage: '',
                surveyCity: '',
                surveyCityError: false,
                surveyCityErrorMessage: '',
                startDate: moment(),
                startDateError: false,
                startDateErrorMessage: '',
                endDate: moment(),
                endDateError: false,
                endDateErrorMessage: '',
                district: -1,
                districtError: false,
                districtErrorMessage: '',
                surveyState: ' - Select a district - ',
                surveyStateError: false,
                surveyStateErrorMessage: '',
                suveyPIN: '',
                suveyPINError: false,
                suveyPINErrorMessage: '',
                organizer: null,
                organizerError: false,
                organizerErrorMessage: '',
                surveyTemplate: '',
                surveyTemplateError: false,
                surveyTemplateErrorMessage: '',
                donor: null,
                donorError: false,
                donorErrorMessage: '',
            });
        }
    }, [props.surveyId]);


    const _handleStartDateChange = (v) => {
        setState({
            ...state,
            startDate: v,
            startDateError: false,
            startDateErrorMessage: ''
        })
    };

    const _handleEndDateChange = (v) => {
        setState({
            ...state,
            endDate: v,
            endDateError: false,
            endDateErrorMessage: ''
        })
    }

    const _userSelected = (v) => {
        if (v !== null) {
            setState({
                ...state,
                organizer: v,
                organizerError: false,
                organizerErrorMessage: ''
            })
        }
        else {
            setState({
                ...state,
                organizer: v,
                organizerError: true,
                organizerErrorMessage: "Organizer is mandatory"
            })
        }
    }

    const _donorSelected = (v) => {
        if (v !== null) {
            setState({
                ...state,
                donor: v,
                donorError: false,
                donorErrorMessage: ''
            })
        }
        else {
            setState({
                ...state,
                donor: v,
                donorError: true,
                donorErrorMessage: "Donor is mandatory"
            })
        }
    }

    const _districtChanged = (v, newValue) => {
        setState({
            ...state,
            district: newValue === null ? -1 : newValue.id,
            districtError: false,
            districtErrorMessage: '',
            organizerError: false,
            organizerErrorMessage: '',
            surveyState: newValue == null ? ' - Select a district - ' : newValue.stateName
        })
    }

    const _onInputChanged = (v, newValue) => {
        setState({ ...state, districtName: newValue })
    }

    const _onPinChanged = (v) => {
        var val = v.target.value;
        if (val.length > 6) {
            val = state.suveyPIN
        }
        else if (val.indexOf('.') >= 0) {
            val = state.suveyPIN;
        }
        else if (isNaN(val)) {
            val = state.suveyPIN;
        }
        setState({
            ...state,
            suveyPINErrorMessage: '',
            suveyPINError: false,
            suveyPIN: val
        });
    }

    const _saveClicked = () => {
        //Validate.
        var valid = true;
        let myState = { ...state };

        var name = state.surveyName.trim();
        if (name.length === 0) {
            valid = false;
            myState.surveyNameError = true;
            myState.surveyNameErrorMessage = "Name cannot be empty";
        }
        else if (name.length < 3 || name.length > 100) {
            valid = false;
            myState.surveyNameError = true;
            myState.surveyNameErrorMessage = "Length should be in range 3-100";
        }

        var address = state.address.trim();
        if (address.length === 0) {
            valid = false;
            myState.addressError = true;
            myState.addressErrorMessage = "Location cannot be empty";
        }
        else if (address.length < 3 || address.length > 500) {
            valid = false;
            myState.addressError = true;
            myState.addressErrorMessage = "Length should be in range 3-500";
        }

        var city = state.surveyCity.trim();
        if (city.length === 0) {
            valid = false;
            myState.surveyCityError = true;
            myState.surveyCityErrorMessage = "City/Town cannot be empty";
        }

        if (state.district === -1) {
            valid = false;
            myState.districtError = true;
            myState.districtErrorMessage = "Dsitrict cannot be empty";
        }

        if (state.suveyPIN === '') {
            valid = false;
            myState.suveyPINError = true;
            myState.suveyPINErrorMessage = "Mandatory"
        }

        if (state.organizer === null) {
            valid = false;
            myState.organizerError = true;
            myState.organizerErrorMessage = "Organizer is mandatory"
        }

        if (state.donor === null) {
            valid = false;
            myState.donorError = true;
            myState.donorErrorMessage = "Donor is mandatory"
        }

        var st = state.startDate.toDate().getTime();
        // if (st < new Date().getTime()) {
        //     myState.startDateError = true;
        //     myState.startDateErrorMessage = "Date should be in future"
        //     valid = false;
        // }

        var et = state.endDate.toDate().getTime();
        // if (et < new Date().getTime()) {
        //     myState.endDateError = true;
        //     myState.endDateErrorMessage = "Date should be in future"
        //     valid = false;
        // }


        if (et < st) {
            myState.endDateError = true;
            myState.endDateErrorMessage = "End date should NOT be before start date"
            valid = false;
        }

        var template = state.surveyTemplate;
        if (template === null || template === '') {
            myState.surveyTemplateError = true;
            myState.surveyTemplateErrorMessage = "Template cannot be empty"
            valid = false;
        }

        if (!valid) {
            setState(myState);
            return;
        }

        var req = {
            name: name,
            address: address,
            donor: state.donor.id,
            template: state.surveyTemplate,
            district: state.district,
            pin: parseInt(state.suveyPIN),
            organizer: state.organizer.id,
            startDate: state.startDate.toDate().getTime(),
            endDate: state.endDate.toDate().getTime(),
            phc: state.surveyPHC,
            city: state.surveyCity
        }

        var url = '/api/v1/survey/new';
        if (props.surveyId > 0) {
            url = '/api/v1/survey/' + props.surveyId;
        }
        HttpHandler.sendPost(url, req, (data) => {
            if (data.duplicate) {
                setState({ ...state, surveyNameError: true, surveyNameErrorMessage: 'Already used' });
                return;
            }
            if (props.surveyId > 0) {
                NotificationManager.success("Survey record updated.", name);
            }
            else {
                NotificationManager.success("Survey record created.", name);
            }
            props.onCreate(data.id);
        }, (errorCode, errorMessage) => {
            console.log(errorCode);
            console.log(errorMessage);
            ErrorDialog.show('There was an error in saving the survey details.')
        })

    }

    const _closeDialog = () => {
        props.onClose();
    }

    const _handleTemplateChange = (e) => {
        setState({
            ...state,
            surveyTemplate: e.target.value,
            surveyTemplateError: false,
            surveyTemplateErrorMessage: ''
        });
    }

    return (
        <Paper style={{ padding: 10, width: 500 }}>
            <div className={commonClasses.headerBar}>{props.heading}</div>
            <div style={{ paddingBottom: 20 }}>
                <form noValidate autoComplete="off">
                    <TextField
                        required
                        error={state.surveyNameError}
                        id='surveyName'
                        label="Survey Name"
                        fullWidth
                        value={state.surveyName}
                        margin="normal"
                        helperText={state.surveyNameErrorMessage}
                        onChange={(v) => {
                            setState({
                                ...state,
                                surveyName: v.target.value,
                                surveyNameError: false,
                                surveyNameErrorMessage: ''
                            })
                        }}
                    />

                    <TextField
                        required
                        error={state.addressError}
                        id='address'
                        label="Location"
                        fullWidth
                        multiline={true}
                        rowsMax={4}
                        rows={4}
                        value={state.address}
                        margin="normal"
                        helperText={state.addressErrorMessage}
                        onChange={(v) => {
                            setState({
                                ...state,
                                address: v.target.value,
                                addressError: false,
                                addressErrorMessage: ''
                            })
                        }}
                    />
                    <TextField
                        error={state.surveyPHCError}
                        id='surveyPHC'
                        label="Primary Health Center(PHC)"
                        fullWidth
                        value={state.surveyPHC}
                        margin="normal"
                        helperText={state.surveyPHCErrorMessage}
                        onChange={(v) => {
                            setState({
                                ...state,
                                surveyPHC: v.target.value,
                                surveyPHCError: false,
                                surveyPHCErrorMessage: ''
                            })
                        }}
                    />
                    <TextField
                        required
                        error={state.surveyCityError}
                        id='surveyCity'
                        label="City/Town"
                        fullWidth
                        value={state.surveyCity}
                        margin="normal"
                        helperText={state.surveyCityErrorMessage}
                        onChange={(v) => {
                            setState({
                                ...state,
                                surveyCity: v.target.value,
                                surveyCityError: false,
                                surveyCityErrorMessage: ''
                            })
                        }}
                    />
                    <Autocomplete
                        onChange={_districtChanged}
                        id="campDistrict"
                        onInputChange={_onInputChanged}
                        inputValue={state.districtName}
                        getOptionLabel={(v) => v.title}
                        options={Utils.statesAndDistricts.map((option) => option)}
                        renderInput={(params) => (
                            <TextField {...params} required label="District" margin="normal"
                                helperText={state.districtErrorMessage}
                                error={state.districtError} />
                        )}
                    />

                    <div style={{ width: '100%', flexDirection: 'row', display: 'flex' }}>
                        <TextField
                            required
                            error={state.surveyStateError}
                            id='surveyState'
                            label="State"
                            style={{ flex: 1 }}
                            value={state.surveyState}
                            margin="normal"
                            InputProps={{
                                readOnly: true,
                            }}
                            helperText={state.surveyStateErrorMessage}
                            onChange={(v) => {
                                setState({
                                    ...state,
                                    surveyState: v.target.value,
                                    surveyStateError: false,
                                    surveyStateErrorMessage: ''
                                })
                            }}
                        />
                        <TextField
                            required
                            error={state.suveyPINError}
                            id='suveyPIN'
                            label="PIN"
                            style={{ width: 100, marginLeft: 20 }}
                            value={state.suveyPIN}
                            margin="normal"
                            helperText={state.suveyPINErrorMessage}
                            onChange={_onPinChanged}
                        />
                    </div>

                    <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'flex-start', marginTop: 5 }}>
                        <div style={{ flex: 1 }}>
                            <DatePicker
                                helperText={state.startDateErrorMessage}
                                error={state.startDateError}
                                label="Start date"
                                format="DD/MMM/yyyy"
                                minDateMessage={"Date should be in future"}
                                value={state.startDate}
                                onChange={_handleStartDateChange}
                                animateYearScrolling
                            />
                        </div>
                        <div style={{ flex: 1, paddingLeft: 20 }}>
                            <DatePicker
                                helperText={state.endDateErrorMessage}
                                error={state.endDateError}
                                minDate={state.startDate}
                                label="End date"
                                format="DD/MMM/yyyy"
                                minDateMessage={"Date should be in future"}
                                value={state.endDate}
                                onChange={_handleEndDateChange}
                                animateYearScrolling
                            />
                        </div>
                    </div>


                    <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'flex-end' }}>
                        <div style={{ flex: 1, paddingRight: 10 }}>
                            <OrgSearchSelect
                                style={{ flex: 1 }}
                                label="Organizer"
                                value={state.organizer}
                                error={state.organizerError}
                                helperText={state.organizerErrorMessage}
                                orgSelected={_userSelected}
                                isRequired = {true}
                            ></OrgSearchSelect>
                        </div>

                        <div style={{ flex: 1, paddingLeft: 10 }}>
                            <DonorSearchSelect
                                style={{ flex: 1 }}
                                label="Donor"
                                value={state.donor}
                                error={state.donorError}
                                helperText={state.donorErrorMessage}
                                donorSelected={_donorSelected}
                            ></DonorSearchSelect>
                        </div>

                    </div>
                    <div>
                        <FormControl style={{ minWidth: 200 }} error={state.surveyTemplateError}>
                            <InputLabel id="surveyTempateSelection-label">Template *</InputLabel>
                            <Select
                                labelId="surveyTempateSelection-label"
                                id="surveyTempateSelection"
                                value={state.surveyTemplate}
                                onChange={_handleTemplateChange}
                            >
                                {props.templates.map((row, key) => {
                                    return (
                                        <MenuItem value={row.id} key={key}>{row.name}</MenuItem>
                                    )
                                })}
                            </Select>
                            <FormHelperText>{state.surveyTemplateErrorMessage} </FormHelperText>
                        </FormControl>
                    </div>
                    <div style={{ marginTop: 10 }}>

                    </div>
                </form>
            </div>
            <Divider style={{ marginLeft: -10, marginRight: -10 }}></Divider>
            <div style={{ paddingTop: 10, flexDirection: 'row', display: 'flex', justifyContent: 'flex-end' }}>
                <Button onClick={_saveClicked} variant="contained" color="primary">Save</Button>
                <Button
                    onClick={_closeDialog}
                    variant="contained" color="default" style={{ marginLeft: 20 }}>Close</Button>
            </div>

        </Paper>
    );
}


export default function Surveys() {

    const classes = useStyles();
    let history = useHistory();

    const dataTable = useRef(null);

    useEffect(() => {
        _loadTemplatesList();
    }, []);

    const [state, setState] = useState({
        showSurveyCreateDialog: false,
        templates: [],
        currentSurveyId: -1,
        addEditUIHeading: "Create Survey"
    });

    const _getSurveyData = (cb, offset, limit, searchString) => {
        HttpHandler.sendGet('/api/v1/survey/list?offset=' + offset + '&limit=' + limit + '&s=' + searchString, (data) => {
            cb(data.surveys, data.count)
        }, (errorCode, errorMessage) => {
            console.log(errorMessage);
        })
    }

    const surveyTableDef = [
        {
            title: "Name",
            field: 'name',
            align: 'left',
            renderer: (row, data) => {
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center' }} >
                        <Link to={"/ics/survey/" + row.id} >{data}</Link>
                    </div>
                )
            }

        },
        {
            title: "Start date",
            field: 'startDate',
            align: 'left',
            renderer: (row, data) => {
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center' }} >
                        <Moment format="DD-MMM-YYYY">{new Date(data)}</Moment>
                    </div>
                )
            }
        },
        ,
        {
            title: "End date",
            field: 'endDate',
            align: 'left',
            renderer: (row, data) => {
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center' }} >
                        <Moment format="DD-MMM-YYYY">{new Date(data)}</Moment>
                    </div>
                )
            }
        },
        {
            title: "Status",
            field: "status",
            align: 'left',
            renderer: (row, data) => {
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center' }} >
                        {surveyStatusMapping[row.status]}
                    </div>
                )
            }
        },
        {
            title: "Location",
            field: "location",
            align: 'left',
            renderer: (row, data) => {
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center' }} >
                        {row.address.district.name + ', ' + row.address.state.name}
                    </div>
                )
            }
        },
        {
            title: "",
            field: 'id',
            style: { minWidth: 120, width: 120 },
            renderer: (row, data) => {
                return (
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        {(HttpHandler.USER_TYPE === 'coordinator' || HttpHandler.USER_TYPE === 'admin') &&
                            <div
                                onClick={() => _editClicked(row)}
                                style={{ height: 24, cursor: (row.status === 'SC' || row.status == 'CA') ? 'pointer' : 'not-allowed' }}>
                                <EditRoundedIcon style={{ color: (row.status === 'SC' || row.status == 'CA') ? '#03A9F4' : '#CCC' }}></EditRoundedIcon>
                            </div>
                        }
                        {(HttpHandler.USER_TYPE === 'coordinator' || HttpHandler.USER_TYPE === 'admin') &&
                            <div
                                onClick={() => _deleteClicked(row)}
                                style={{ marginLeft: 20, height: 24, cursor: (row.status === 'SC' || row.status == 'CA') ? 'pointer' : 'not-allowed' }}>
                                <DeleteForeverRoundedIcon
                                    style={{ color: (row.status === 'SC' || row.status == 'CA') ? '#D32F2F' : '#CCC' }}></DeleteForeverRoundedIcon>
                            </div>
                        }

                    </div>
                )
            }

        }
    ]

    const _editClicked = (row) => {
        if (row.status === 'SC' || row.status == 'CA') {
            setState({ ...state, currentSurveyId: row.id, showSurveyCreateDialog: true, addEditUIHeading: "Modify Survey" })
        }
    }


    const _loadTemplatesList = () => {
        HttpHandler.sendGet('/api/v1/template/list?type=SU&offset=0&limit=100', (data) => {
            setState({
                ...state,
                templates: data.templates
            });
        }, (errorCode, errorMessage) => {
            NotificationManager.error("Error loading list of trmplates", "Templates")
        })
    };

    const _deleteClicked = (row) => {
        if (row.status === 'SC' || row.status == 'CA') {
            ConfirmationDialog.show('Are you sure you want to delete this survey?', () => {
                HttpHandler.sendDelete('/api/v1/survey/' + row.id, (d) => {
                    NotificationManager.success("Survey deleted.", row.name);
                    dataTable.current.reload();
                }, (errorCode, errorMessage) => {
                    ErrorDialog.show("There was error in deleting survey");
                })
            });
        }
    }


    const _createSurvey = () => {
        setState({
            ...state,
            currentSurveyId: -1,
            showSurveyCreateDialog: true,
            addEditUIHeading: "Create Survey"
        })
    }

    const _closeCreateDialog = () => {
        setState({
            ...state,
            showSurveyCreateDialog: false
        })
    }

    const _surveyCreated = (id) => {
        if (state.currentSurveyId > 0) {
            setState({
                ...state,
                showSurveyCreateDialog: false,
                currentSurveyId: -1
            })
        }
        else {
            history.push('/ics/survey/' + id);
        }
    }

    return (
        <div>
            {(HttpHandler.USER_TYPE !== 'coordinator' && HttpHandler.USER_TYPE !== 'survey') &&
                <div style={{ paddingTop: 40, color: '#FF0000', fontFamily: 'Metropolis-Regular', fontSize: 15, textAlign: 'center' }}>You are not authorized to access contents of this page.</div>
            }
            {(HttpHandler.USER_TYPE === 'coordinator' || HttpHandler.USER_TYPE === 'survey') &&
                <div>
                    <div style={{ marginRight: 20 }}>
                        <PagePath path={['Survey', 'All']}>
                            {(HttpHandler.USER_TYPE === 'coordinator' && state.showSurveyCreateDialog === false) &&
                                <Fab
                                    onClick={_createSurvey}
                                    color="primary" aria-label="add" size="medium">
                                    <AddIcon />
                                </Fab>
                            }
                        </PagePath>
                    </div>
                    <div style={{ marginTop: 20, paddingBottom: 30 }}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            {state.showSurveyCreateDialog &&
                                <SurveyCreateEditUI
                                    surveyId={state.currentSurveyId}
                                    heading={state.addEditUIHeading}
                                    onCreate={_surveyCreated}
                                    templates={state.templates} onClose={_closeCreateDialog} />
                            }
                            {(state.showSurveyCreateDialog === false) &&
                                <Paper style={{ padding: 10 }}>
                                    <div style={{ width: 1000 }}>
                                        <CTable
                                            ref={dataTable}
                                            data={_getSurveyData}
                                            columns={surveyTableDef}>
                                        </CTable>
                                    </div>
                                </Paper>
                            }
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}
