import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles'
import Collapse from '@material-ui/core/Collapse';

import { Button, Menu, MenuItem, Dialog, DialogContent, DialogActions, Divider, TextField } from '@material-ui/core';

import ListAltIcon from '@material-ui/icons/ListAltRounded';
import PersonIcon from '@material-ui/icons/PersonRounded';
import HomeIcon from '@material-ui/icons/HomeRounded';
import LocalHospitalIcon from '@material-ui/icons/LocalHospitalRounded';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@material-ui/icons/ExpandLessRounded';

import { HashRouter as Router, Route, Link, Switch } from 'react-router-dom';

import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';


import UsersUI from './UsersUI';
import Templates from './Templates';
import Camps from './Camps';
import Camp from './Camp';
import Hospitals from './Hospitals';
import Labs from './Labs';
import Tests from './Tests';
import Template from './Template';
import SearchFieldModal from './components/SearchFieldModal';
import HttpHandler from './HttpHandler';
import Orgs from './Orgs';
import Org from './Org';
import Surveys from './Surveys';
import Survey from './Survey';
import CampRegister from './CampRegister';
import SurveyData from './SurveyData';
import SurveyRecordView from './SurveyRecordView'
import SurveyRecordEdit from './SurveyRecordEdit';
import CampData from './CampData';
import CampRecordView from './CampRecordView'
import ErrorDialog from './components/ErrorDialog';

import commonClasses from './ics.module.css'

import Utils from './Utils';
import ProbableDetails from './ProbaleDetails';
import Donors from './Donors';
import CampPreTeam from './CampPreTeam';
import CampTeamType from './CampTeamType';
import SurveyPreTeam from './SurveyPreTeam';
import SyncUI from './SyncUI';
import States from './States';
import Districts from './Districts';
import FollowUpCamps from './FollowUpCamps'
import FollowUpRecords from './FollowUpRecords'
import ProbableFollowupDetails from './ProbableFollowUpdetails'
import ProbableCounselling from './ProbableCounselling';
import Sync from './Sync'


const useStyles = makeStyles({
    mainContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
        width: '100%',
    },

    leftNavContainer: {
        width: 215,
        backgroundColor: '#262835',
        position: 'fixed',
        height: '100vh',
        left: 0, top: 0,
        paddingTop: 0,
        paddingLeft: 10
    },
    contentContainer: {
        flexGrow: 1,
        overFlow: 'auto',
        maxHeight: 'inherit',
        marginLeft: 215,
    },

    logoContainer: {
        width: 80,
        height: 80,
        borderRadius: 100,
        backgroundColor: '#FFF',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        marginLeft: 55,
        marginBottom: 20,
        marginTop: 20
    },

    menuItem: {
        fontFamily: 'Metropolis-Light',
        color: '#DDD',
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
        paddingRight: 10,
        height: 40
    },

    selectedItem: {
        fontFamily: 'Metropolis-SemiBold',
        color: '#FFF'
    },
    normalItem: {

    },
    subMenuContainer: {
        paddingLeft: 40,
        backgroundColor: '#2d303f',
        marginLeft: -10
    },

    expandButton: {
        paddingTop: 10,
        cursor: 'pointer'
    },
    topNavContainer: {
        width: '100%',
        height: 50,
        boxShadow: '0px 2px 4px #AAA',
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
    contentArea: {
        paddingTop: 20,
        paddingLeft: 10
    },
    navUserName: {
        fontFamily: 'Metropolis-Regular',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        height: 50,
        marginRight: 20,
        cursor: 'pointer'
    }
});


export default function HomeScreen(props) {

    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const [state, setState] = useState({
        selectedMenu: 'camps',
        selectedMenuItem: 'users',

        showChangePasswordDialog: false,
        currentPassword: '',
        currentPasswordError: false,
        currentPasswordErrorMessage: '',
        newPassword: '',
        newPasswordError: false,
        newPasswordErrorMessage: '',
        retypePassword: '',
        retypePasswordError: false,
        retypePasswordErrorMessage: '',

        showMyProfileUpdate: false,
        myFullName: '',
        myFullNameError: false,
        myFullNameErrorMessage: '',
        myPhone: '',
        myPhoneError: false,
        myPhoneErrorMessage: ''
    });

    const _handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const _handleChangePassword = () => {
        setAnchorEl(null);
        setState({
            ...state,
            currentPassword: '',
            currentPasswordError: false,
            currentPasswordErrorMessage: '',
            newPassword: '',
            newPasswordError: false,
            newPasswordErrorMessage: '',
            retypePassword: '',
            retypePasswordError: false,
            retypePasswordErrorMessage: '',
            showChangePasswordDialog: true,
            myUserType: ''
        })
    }

    const _handleMyProfile = () => {
        setAnchorEl(null);

        _loadMyProfileDetails();

    }

    const _handleLogout = () => {
        setAnchorEl(null);
        HttpHandler.sendPost('/api/v1/auth/logout', {}, (d) => {
            HttpHandler.SESSION_SECRET = '';
            HttpHandler.FULL_NAME = '';
            HttpHandler.USER_ID = '';
            HttpHandler.SCREENING_MEMBER_TYPE = '';
            window.location = '/';
            const enableSyncButton = sessionStorage.getItem('disable')
            if(enableSyncButton)
            {
                sessionStorage.removeItem("disable");
            }
        }, (errorCode, errorMessage) => {
            ErrorDialog.show("There was an error in trying to logout.");
        })
    }

    const _handleMenuClose = () => {
        setAnchorEl(null);
    };


    const _toggleSelectedSubMenu = (name) => {
        if (state.selectedMenu === name) {
            setState({
                ...state,
                selectedMenu: 'camps'
            })
        }
        else {
            setState({
                ...state,
                selectedMenu: name
            })
        }
    }

    const _onChangePasswordDialogClose = () => {
        setState({ ...state, showChangePasswordDialog: false })
    }



    const _savePasswordClicked = () => {
        var currentPass = state.currentPassword.trim();
        var newPass = state.newPassword.trim();
        var retypePass = state.retypePassword.trim();

        var valid = true;

        var istate = { ...state };

        if (currentPass === '') {
            istate.currentPasswordError = true;
            istate.currentPasswordErrorMessage = 'Mandatory';
            valid = false;
        }

        if (newPass === '') {
            istate.newPasswordError = true;
            istate.newPasswordErrorMessage = 'Mandatory'
            valid = false;
        }

        if (retypePass === '') {
            istate.retypePasswordError = true;
            istate.retypePasswordErrorMessage = 'Mandatory'
            valid = false;
        }

        if (newPass != retypePass) {
            istate.newPasswordError = true;
            istate.newPasswordErrorMessage = 'Passwords do not match'
            istate.retypePasswordError = true;
            istate.retypePasswordErrorMessage = 'Passwords do not match'
            valid = false;
        }


        if (!valid) {
            setState(istate);
            return;
        }

        var req = {
            password: currentPass
        }

        HttpHandler.sendPost('/api/v1/auth/isvalidpass', req, (d) => {

            if (!d.isValid) {
                setState({ ...state, currentPasswordError: true, currentPasswordErrorMessage: 'Invalid password' });
                return;
            }

            var req = {
                currentPass: currentPass,
                newPass: newPass
            }

            HttpHandler.sendPost('/api/v1/auth/changepass', req, (d) => {
                NotificationManager.success('Password changed.', HttpHandler.FULL_NAME);
                setState({ ...state, showChangePasswordDialog: false })
            }, (errorCode, errorMessage) => {
                ErrorDialog.show("Error in changing password.");
            })

        }, (errorCode, errorMessage) => {
            ErrorDialog.show("Error in validating password.");
        });
    }

    const _onMyProfileClose = () => {
        setState({ ...state, showMyProfileUpdate: false })
    }

    const _loadMyProfileDetails = () => {
        HttpHandler.sendGet('/api/v1/auth/myprofile', (d) => {
            setState({
                ...state,
                showMyProfileUpdate: true,
                myFullName: d.profile.fullName,
                myFullNameError: false,
                myFullNameErrorMessage: '',
                myPhone: d.profile.phone === null ? '' : d.profile.phone,
                myPhoneError: false,
                myPhoneErrorMessage: '',
                myUserType: d.profile.userType
            })
        }, (errorCode, errorMessage) => {
            ErrorDialog.show("Error on loading your profile details.", "Profile");
        })
    }

    const _saveMyProfileClicked = () => {
        var fullName = state.myFullName.trim();
        var phone = state.myPhone.trim();

        var istate = { ...state };
        var valid = true;

        if (fullName.length === 0) {
            valid = false;
            istate.myFullNameError = true;
            istate.myFullNameErrorMessage = "Mandatory"
        }
        else if (fullName.length < 3 || fullName.length > 120) {
            valid = false;
            istate.myFullNameError = true;
            istate.myFullNameErrorMessage = "Length should be between 3 and 120"
        }

        if (phone === '') {
            valid = false;
            istate.myPhoneError = true;
            istate.myPhoneErrorMessage = 'Mandatory'
        }
        else if (!Utils.isValidPhone(phone)) {
            valid = false;
            istate.myPhoneError = true;
            istate.myPhoneErrorMessage = 'Invalid phone'
        }

        if (!valid) {
            setState(istate);
            return;
        }

        var req = { fullName: fullName, phone: phone };

        HttpHandler.sendPost('/api/v1/auth/saveprofile', req, (d) => {
            HttpHandler.FULL_NAME = fullName;
            NotificationManager.success("Profile saved", "Profile");
            setState({ ...state, showMyProfileUpdate: false });
        }, (errorCode, errorMessage) => {
            console.log(errorMessage);
            ErrorDialog.show("Error in saving your profile details.");
        })


    }

    const _onPhoneChanged = (v) => {
        var val = v.target.value;
        if (val.length > 11) {
            val = state.myPhone
        }
        else if (val.indexOf('.') >= 0) {
            val = state.myPhone;
        }
        else if (isNaN(val)) {
            val = state.myPhone;
        }
        setState({
            ...state,
            myPhone: val,
            myPhoneError: false,
            myPhoneErrorMessage: ''
        })
    }



    return (
        <Router>
            <div className={classes.mainContainer}>

                <Dialog onClose={_onMyProfileClose.bind(this)} open={state.showMyProfileUpdate}>
                    <div style={{ padding: 5, width: 400 }}>
                        <div className={commonClasses.headerBar}>My Profile</div>
                    </div>
                    <DialogContent style={{ width: 400, alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                        <div style={{ width: '100%', paddingBottom: 10 }}>
                            <div style={{ flexDirection: 'row', display: 'flex', paddingTop: 5, paddingBottom: 5, width: '100%' }}>
                                <div style={{ fontFamily: 'Metropolis-Regular', flex: 1, textAlign: 'right' }}>Email/User Id</div>
                                <div style={{ paddingLeft: 5, paddingRight: 5 }}> : </div>
                                <div style={{ fontFamily: 'Metropolis-Medium', flex: 2 }}>{HttpHandler.USER_ID}</div>
                            </div>

                            <div style={{ flexDirection: 'row', display: 'flex', paddingTop: 5, paddingBottom: 5, width: '100%' }}>
                                <div style={{ fontFamily: 'Metropolis-Regular', flex: 1, textAlign: 'right' }}>User type</div>
                                <div style={{ paddingLeft: 5, paddingRight: 5 }}> : </div>
                                <div style={{ fontFamily: 'Metropolis-Medium', flex: 2 }}>{Utils.USER_TYPE_MAP[state.myUserType]}</div>
                            </div>
                            <Divider></Divider>
                        </div>

                        <form noValidate autoComplete="off">
                            <TextField
                                required
                                error={state.myFullNameError}
                                id='myFullName'
                                label="Full Name"
                                fullWidth
                                value={state.myFullName}
                                margin="normal"
                                helperText={state.myFullNameErrorMessage}
                                onChange={(v) => {
                                    setState({
                                        ...state,
                                        myFullName: v.target.value,
                                        myFullNameError: false,
                                        myFullNameErrorMessage: ''
                                    })
                                }}
                            />

                            <TextField
                                required
                                error={state.myPhoneError}
                                id='myPhone'
                                label="Phone"
                                fullWidth
                                value={state.myPhone}
                                margin="normal"
                                helperText={state.myPhoneErrorMessage}
                                onChange={_onPhoneChanged}
                            />
                        </form>
                    </DialogContent>
                    <Divider />
                    <DialogActions>
                        <Button onClick={_saveMyProfileClicked} variant="contained" color="primary">Save</Button>
                        <Button
                            onClick={_onMyProfileClose}
                            variant="contained" color="default" style={{ marginLeft: 20 }}>Close</Button>
                    </DialogActions>
                </Dialog>

                <Dialog onClose={_onChangePasswordDialogClose.bind(this)} open={state.showChangePasswordDialog}>
                    <div style={{ padding: 5, width: 400 }}>
                        <div className={commonClasses.headerBar}>Change password</div>
                    </div>
                    <DialogContent style={{ width: 400, alignItems: 'center', display: 'flex', flexDirection: 'column' }}>

                        <form noValidate autoComplete="off">
                            <TextField
                                required
                                error={state.currentPasswordError}
                                id='currentPassword'
                                label="Current password"
                                fullWidth
                                type="password"
                                value={state.currentPassword}
                                margin="normal"
                                helperText={state.currentPasswordErrorMessage}
                                onChange={(v) => {
                                    setState({
                                        ...state,
                                        currentPassword: v.target.value,
                                        currentPasswordError: false,
                                        currentPasswordErrorMessage: ''
                                    })
                                }}
                            />

                            <TextField
                                required
                                error={state.newPasswordError}
                                id='newPassword'
                                label="New password"
                                fullWidth
                                type="password"
                                value={state.newPassword}
                                margin="normal"
                                helperText={state.newPasswordErrorMessage}
                                onChange={(v) => {
                                    setState({
                                        ...state,
                                        newPassword: v.target.value,
                                        newPasswordError: false,
                                        newPasswordErrorMessage: ''
                                    })
                                }}
                            />

                            <TextField
                                required
                                error={state.retypePasswordError}
                                id='retypePassword'
                                label="Retype password"
                                fullWidth
                                type="password"
                                value={state.retypePassword}
                                margin="normal"
                                helperText={state.retypePasswordErrorMessage}
                                onChange={(v) => {
                                    setState({
                                        ...state,
                                        retypePassword: v.target.value,
                                        retypePasswordError: false,
                                        retypePasswordErrorMessage: ''
                                    })
                                }}
                            />
                        </form>
                    </DialogContent>
                    <Divider />
                    <DialogActions>
                        <Button onClick={_savePasswordClicked} variant="contained" color="primary">Save</Button>
                        <Button
                            onClick={_onChangePasswordDialogClose}
                            variant="contained" color="default" style={{ marginLeft: 20 }}>Close</Button>
                    </DialogActions>
                </Dialog>
                <SearchFieldModal />
                <div className={classes.leftNavContainer}>
                    <div className={classes.logoContainer}>
                        <img al="logo" src={require('./images/logo.png')} style={{ width: 68, height: 68 }} />
                    </div>
                    <div style={{ width: '100%', backgroundColor: '#0e0f14', height: 1 }}></div>
                    <div style={{ width: '100%', backgroundColor: '#404359', height: 1 }}></div>



                    {(HttpHandler.USER_TYPE === 'admin' || HttpHandler.USER_TYPE === 'coordinator' || HttpHandler.USER_TYPE === 'data entry' || HttpHandler.USER_TYPE === 'doctor' || HttpHandler.USER_TYPE === 'screening') &&
                        <div
                            style={{ textDecoration: 'none' }}>
                            <div className={[classes.menuItem].join(' ')} style={{}}>
                                <div style={{ flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer' }} onClick={() => { _toggleSelectedSubMenu('camp') }}>
                                    <LocalHospitalIcon style={{ width: 18, height: 18 }}></LocalHospitalIcon>
                                    <span className={state.selectedMenu === 'camp' ? classes.selectedItem : classes.normalItem} style={{ marginLeft: 10, marginTop: 4 }}>Screening Camp</span>
                                </div>
                                <div onClick={() => { _toggleSelectedSubMenu('camp') }} className={classes.expandButton}>
                                    {(state.selectedMenu === 'camp') &&
                                        <ExpandLessRoundedIcon style={{ width: 18, height: 18 }} />
                                    }
                                    {(state.selectedMenu !== 'camp') &&
                                        <ExpandMoreRoundedIcon style={{ width: 18, height: 18 }} />
                                    }
                                </div>
                            </div>
                        </div>
                    }
                    <Collapse className={classes.subMenuContainer} in={state.selectedMenu === 'camp'}>
                        {(HttpHandler.USER_TYPE === 'admin' || HttpHandler.USER_TYPE === 'coordinator') &&
                            <Link
                                style={{ textDecoration: 'none' }}
                                onClick={() => { setState({ ...state, selectedMenuItem: 'camptemplates' }) }}
                                to='/ics/camptemplates'>
                                <div className={[classes.menuItem].join(' ')}>
                                    <span className={state.selectedMenuItem === 'camptemplates' ? classes.selectedItem : classes.normalItem}>Register Templates</span>
                                </div>
                            </Link>
                        }
                        {(HttpHandler.USER_TYPE === 'admin' || HttpHandler.USER_TYPE === 'coordinator') &&
                            <Link
                                style={{ textDecoration: 'none' }}
                                onClick={() => { setState({ ...state, selectedMenuItem: 'medicaltemplates' }) }}
                                to='/ics/medicaltemplates'>
                                <div className={[classes.menuItem].join(' ')}>
                                    <span className={state.selectedMenuItem === 'medicaltemplates' ? classes.selectedItem : classes.normalItem}>Medical Templates</span>
                                </div>
                            </Link>
                        }


                        {(HttpHandler.USER_TYPE === 'admin' || HttpHandler.USER_TYPE === 'coordinator' || HttpHandler.USER_TYPE === 'doctor' || HttpHandler.USER_TYPE === 'data entry' || (HttpHandler.USER_TYPE === 'screening' && HttpHandler.SCREENING_MEMBER_TYPE !== 'Follow Up' && HttpHandler.SCREENING_MEMBER_TYPE !== 'Counsellor')) &&
                            <Link
                                style={{ textDecoration: 'none' }}
                                onClick={() => { setState({ ...state, selectedMenuItem: 'camps' }) }}
                                to='/ics/camps'>
                                <div className={[classes.menuItem].join(' ')}>
                                    <span className={state.selectedMenuItem === 'camps' ? classes.selectedItem : classes.normalItem}>Screening Camps</span>
                                </div>
                            </Link>
                        }
                        {(HttpHandler.USER_TYPE === 'coordinator' || HttpHandler.SCREENING_MEMBER_TYPE === 'Follow Up') &&
                            <Link
                                style={{ textDecoration: 'none' }}
                                onClick={() => { setState({ ...state, selectedMenuItem: 'followUpInvest' }) }}
                                to='/ics/followUpInvestigation'>
                                <div className={[classes.menuItem].join(' ')}>
                                    <span className={state.selectedMenuItem === 'followUpInvest' ? classes.selectedItem : classes.normalItem}>Follow Up Investigation</span>
                                </div>
                            </Link>
                        }
                        {(HttpHandler.USER_TYPE === 'coordinator' || HttpHandler.SCREENING_MEMBER_TYPE === 'Counsellor') &&
                            <Link
                                style={{ textDecoration: 'none' }}
                                onClick={() => { setState({ ...state, selectedMenuItem: 'counselling' }) }}
                                to='/ics/followUpCounselling'>
                                <div className={[classes.menuItem].join(' ')}>
                                    <span className={state.selectedMenuItem === 'counselling' ? classes.selectedItem : classes.normalItem}>Follow Up Counselling</span>
                                </div>
                            </Link>
                        }
                    </Collapse>

                    {(HttpHandler.USER_TYPE === 'admin' || HttpHandler.USER_TYPE === 'coordinator' || HttpHandler.USER_TYPE === 'survey') &&
                        <div style={{ textDecoration: 'none' }}>
                            <div className={[classes.menuItem].join(' ')} style={{}}>
                                <div style={{ flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer' }} onClick={() => { _toggleSelectedSubMenu('survey') }}>
                                    <ListAltIcon style={{ width: 18, height: 18 }}></ListAltIcon>
                                    <span className={state.selectedMenu === 'survey' ? classes.selectedItem : classes.normalItem} style={{ marginLeft: 10, marginTop: 4 }}>Survey</span>
                                </div>
                                <div onClick={() => { _toggleSelectedSubMenu('survey') }} className={classes.expandButton}>
                                    {(state.selectedMenu === 'survey') &&
                                        <ExpandLessRoundedIcon style={{ width: 18, height: 18 }} />
                                    }
                                    {(state.selectedMenu !== 'survey') &&
                                        <ExpandMoreRoundedIcon style={{ width: 18, height: 18 }} />
                                    }
                                </div>
                            </div>
                        </div>
                    }

                    {(HttpHandler.USER_TYPE === 'admin' || HttpHandler.USER_TYPE === 'coordinator' || HttpHandler.USER_TYPE === 'survey') &&
                        <Collapse className={classes.subMenuContainer} in={state.selectedMenu === 'survey'}>
                            {(HttpHandler.USER_TYPE === 'admin' || HttpHandler.USER_TYPE === 'coordinator') &&
                                <Link
                                    style={{ textDecoration: 'none' }}
                                    onClick={() => { setState({ ...state, selectedMenu: 'survey' }) }}
                                    to='/ics/surveytemplates'>
                                    <div className={[classes.menuItem].join(' ')}>
                                        Templates
                                    </div>
                                </Link>
                            }
                            {(HttpHandler.USER_TYPE === 'survey' || HttpHandler.USER_TYPE === 'coordinator') &&
                                <Link
                                    style={{ textDecoration: 'none' }}
                                    onClick={() => { setState({ ...state, selectedMenuItem: 'surveys' }) }}
                                    to='/ics/surveys'>
                                    <div className={[classes.menuItem].join(' ')}>
                                        <span className={state.selectedMenuItem === 'surveys' ? classes.selectedItem : classes.normalItem}>Surveys</span>
                                    </div>
                                </Link>
                            }
                        </Collapse>
                    }
                    {(HttpHandler.USER_TYPE === 'doctor' || HttpHandler.USER_TYPE === 'coordinator' || HttpHandler.USER_TYPE === 'admin' || HttpHandler.USER_TYPE === 'screening') &&
                        <div style={{ textDecoration: 'none' }}>
                            <div className={[classes.menuItem].join(' ')} style={{}}>
                                <div style={{ flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer' }} onClick={() => { _toggleSelectedSubMenu('admin') }}>
                                    <PersonIcon style={{ width: 18, height: 18 }}></PersonIcon>
                                    <span className={state.selectedMenu === 'admin' ? classes.selectedItem : classes.normalItem} style={{ marginLeft: 10, marginTop: 4 }}>Administration</span>
                                </div>
                                <div onClick={() => { _toggleSelectedSubMenu('admin') }} className={classes.expandButton}>
                                    {(state.selectedMenu === 'admin') &&
                                        <ExpandLessRoundedIcon style={{ width: 18, height: 18 }} />
                                    }
                                    {(state.selectedMenu !== 'admin') &&
                                        <ExpandMoreRoundedIcon style={{ width: 18, height: 18 }} />
                                    }
                                </div>
                            </div>
                        </div>
                    }

                    <Collapse className={classes.subMenuContainer} in={state.selectedMenu === 'admin'}>
                        {(HttpHandler.USER_TYPE === 'admin') &&
                            <Link
                                style={{ textDecoration: 'none' }}
                                onClick={() => { setState({ ...state, selectedMenuItem: 'users' }) }}
                                to='/ics/users'>
                                <div className={[classes.menuItem].join(' ')}>
                                    <span className={state.selectedMenuItem === 'users' ? classes.selectedItem : classes.normalItem}>Users</span>
                                </div>
                            </Link>
                        }
                        {(HttpHandler.USER_TYPE === 'admin' || HttpHandler.USER_TYPE === 'coordinator') &&
                            <Link
                                style={{ textDecoration: 'none' }}
                                onClick={() => { setState({ ...state, selectedMenuItem: 'states' }) }}
                                to='/ics/states'>
                                <div className={[classes.menuItem].join(' ')}>
                                    <span className={state.selectedMenuItem === 'states' ? classes.selectedItem : classes.normalItem}>States</span>
                                </div>

                            </Link>
                        }
                        {(HttpHandler.USER_TYPE === 'admin' || HttpHandler.USER_TYPE === 'coordinator') &&
                            <Link
                                style={{ textDecoration: 'none' }}
                                onClick={() => { setState({ ...state, selectedMenuItem: 'districts' }) }}
                                to='/ics/districts'>
                                <div className={[classes.menuItem].join(' ')}>
                                    <span className={state.selectedMenuItem === 'districts' ? classes.selectedItem : classes.normalItem}>Districts</span>
                                </div>

                            </Link>
                        }
                        {(HttpHandler.USER_TYPE === 'admin' || HttpHandler.USER_TYPE === 'coordinator') &&
                            <Link
                                style={{ textDecoration: 'none' }}
                                onClick={() => { setState({ ...state, selectedMenuItem: 'orgs' }) }}
                                to='/ics/org'>
                                <div className={[classes.menuItem].join(' ')}>
                                    <span className={state.selectedMenuItem === 'orgs' ? classes.selectedItem : classes.normalItem}>Organizations</span>
                                </div>
                            </Link>
                        }
                        {(HttpHandler.USER_TYPE === 'admin' || HttpHandler.USER_TYPE === 'doctor' || HttpHandler.USER_TYPE === 'coordinator' || HttpHandler.USER_TYPE === 'screening') &&
                            <Link
                                style={{ textDecoration: 'none' }}
                                onClick={() => { setState({ ...state, selectedMenuItem: 'hospitals' }) }}
                                to='/ics/hospitals'>
                                <div className={[classes.menuItem].join(' ')}>
                                    <span className={state.selectedMenuItem === 'hospitals' ? classes.selectedItem : classes.normalItem}>Hospitals</span>
                                </div>
                            </Link>
                        }
                        {(HttpHandler.USER_TYPE === 'admin' || HttpHandler.USER_TYPE === 'doctor' || HttpHandler.USER_TYPE === 'coordinator' || HttpHandler.USER_TYPE === 'screening') &&
                            <Link
                                style={{ textDecoration: 'none' }}
                                onClick={() => { setState({ ...state, selectedMenuItem: 'labs' }) }}
                                to='/ics/labs'>
                                <div className={[classes.menuItem].join(' ')}>
                                    <span className={state.selectedMenuItem === 'labs' ? classes.selectedItem : classes.normalItem}>Labs</span>
                                </div>
                            </Link>
                        }
                        {(HttpHandler.USER_TYPE === 'admin' || HttpHandler.USER_TYPE === 'coordinator') &&
                            <Link
                                style={{ textDecoration: 'none' }}
                                onClick={() => { setState({ ...state, selectedMenuItem: 'donors' }) }}
                                to='/ics/donors'>
                                <div className={[classes.menuItem].join(' ')}>
                                    <span className={state.selectedMenuItem === 'donors' ? classes.selectedItem : classes.normalItem}>Donors</span>
                                </div>
                            </Link>
                        }

                        {(HttpHandler.USER_TYPE === 'admin' || HttpHandler.USER_TYPE === 'doctor' || HttpHandler.USER_TYPE === 'screening') &&
                            <Link
                                style={{ textDecoration: 'none' }}
                                onClick={() => { setState({ ...state, selectedMenuItem: 'tests' }) }}
                                to='/ics/tests'>
                                <div className={[classes.menuItem].join(' ')}>
                                    <span className={state.selectedMenuItem === 'tests' ? classes.selectedItem : classes.normalItem}>Tests</span>
                                </div>

                            </Link>
                        }
                        {(HttpHandler.USER_TYPE === 'admin' || HttpHandler.USER_TYPE === 'coordinator') &&
                            <Link
                                style={{ textDecoration: 'none' }}
                                onClick={() => { setState({ ...state, selectedMenuItem: 'campteams' }) }}
                                to='/ics/campteams'>
                                <div className={[classes.menuItem].join(' ')}>
                                    <span className={state.selectedMenuItem === 'campteams' ? classes.selectedItem : classes.normalItem}>Screening Team</span>
                                </div>

                            </Link>
                        }
                        {(HttpHandler.USER_TYPE === 'admin' || HttpHandler.USER_TYPE === 'coordinator') &&
                            <Link
                                style={{ textDecoration: 'none' }}
                                onClick={() => { setState({ ...state, selectedMenuItem: 'surveyteams' }) }}
                                to='/ics/surveyteams'>
                                <div className={[classes.menuItem].join(' ')}>
                                    <span className={state.selectedMenuItem === 'surveyteams' ? classes.selectedItem : classes.normalItem}>Survey Team</span>
                                </div>

                            </Link>
                        }
                        {(HttpHandler.USER_TYPE === 'admin' || HttpHandler.USER_TYPE === 'coordinator') &&
                            <Link
                                style={{ textDecoration: 'none' }}
                                onClick={() => { setState({ ...state, selectedMenuItem: 'sync' }) }}
                                to='/ics/sync'>
                                <div className={[classes.menuItem].join(' ')}>
                                    <span className={state.selectedMenuItem === 'sync' ? classes.selectedItem : classes.normalItem}>Sync</span>
                                </div>

                            </Link>
                        }


                    </Collapse>
                </div>
                <div className={classes.contentContainer}>
                    <div className={classes.topNavContainer}>
                        <div>
                            <Button style={{}} aria-controls="user-menu" aria-haspopup="true" onClick={_handleMenuClick} >{HttpHandler.FULL_NAME}</Button>
                        </div>
                        <Menu
                            id="user-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={_handleMenuClose}
                        >
                            <MenuItem onClick={_handleChangePassword}>Change password</MenuItem>
                            <MenuItem onClick={_handleMyProfile}>My profile</MenuItem>
                            <MenuItem onClick={_handleLogout}>Logout</MenuItem>
                        </Menu>
                    </div>
                    <div className={classes.contentArea}>
                        <Switch>
                            <Route path='/ics/camps' render={(props) => <CampsComp {...props} />} />
                            <Route path='/ics/states' render={(props) => <States {...props} />} />
                            <Route path='/ics/districts' render={(props) => <Districts {...props} />} />
                            <Route path='/ics/surveys' render={(props) => <SurveysComp {...props} />} />
                            <Route path='/ics/org/:orgId' render={(props) => <OrgUIComp {...props} />} />
                            <Route path='/ics/org' render={(props) => <OrgsUIComp {...props} />} />
                            <Route path='/ics/users' render={(props) => <UsersUIComp {...props} />} />
                            <Route path='/ics/probable/:recordId' render={(props) => <ProbaleDetailsComp {...props} />} />
                            <Route path='/ics/tests' render={(props) => <TestsUIComp {...props} />} />
                            <Route path='/ics/campteams' render={(props) => <CampPreTeam {...props} />} />
                            <Route path='/ics/sync' render={(props) => <Sync {...props} />} />
                            <Route path='/ics/surveyteams' render={(props) => <SurveyPreTeam {...props} />} />
                            <Route path='/ics/campteamtypes' render={(props) => <CampTeamType {...props} />} />
                            <Route path='/ics/labs' render={(props) => <LabsUIComp {...props} />} />
                            <Route path='/ics/hospitals' render={(props) => <HospitalsUIComp {...props} />} />
                            <Route path='/ics/donors' render={(props) => <Donors {...props} />} />
                            <Route path='/ics/camptemplates' render={(props) => <CampTemplatesComp {...props} />} />
                            <Route path='/ics/medicaltemplates' render={(props) => <MedicalTemplatesComp {...props} />} />
                            <Route path='/ics/template/:templateId' render={(props) => <TemplateComp {...props} />} />
                            <Route path='/ics/surveytemplates' render={(props) => <SurveyTemplatesComp {...props} />} />
                            <Route path='/ics/camp/:campId/data/:recordId' render={(props) => <CampRecordViewComp {...props} />} />
                            <Route path='/ics/camp/:campId/register' render={(props) => <CampRegisterComp {...props} />} />
                            <Route path='/ics/camp/:campId/data' render={(props) => <CampDataComp {...props} />} />
                            <Route path='/ics/camp/:campId' render={(props) => <CampComp {...props} />} />
                            <Route path='/ics/survey/:surveyId/data/:recordId' render={(props) => <SurveyRecordViewComp {...props} />} />
                            <Route path='/ics/survey/:surveyId/data' render={(props) => <SurveyDataComp {...props} />} />
                            <Route path='/ics/survey/:surveyId/new' render={(props) => <SurveyRecordEditComp {...props} />} />
                            <Route path='/ics/survey/:surveyId/:recordId' render={(props) => <SurveyRecordEditComp {...props} />} />
                            <Route path='/ics/survey/:surveyId' render={(props) => <SurveyComp {...props} />} />
                            <Route path='/ics/followUpInvestigation' render={(props) => <FollowUpCampsComp {...props} />} />
                            <Route path='/ics/followUpCounselling' render={(props) => <FollowUpCounsellingComp {...props} />} />
                            <Route path='/ics/followUpInvestigationCamp/:campId' render={(props) => <FollowUpInvestigationRecord {...props} />} />
                            <Route path='/ics/followUpCounsellingCamp/:campId' render={(props) => <FollowUpCounsellingRecord {...props} />} />
                            <Route path='/ics/probableCase/:recordId/followUpInvestigation' render={(props) => <ProbaleFollowupDetailsComp {...props} />} />
                            <Route path='/ics/probableCase/:recordId/followUpCounselling' render={(props) => <ProbaleCounsellingComp {...props} />} />


                        </Switch>
                    </div>


                </div>
                <NotificationContainer />
            </div>
        </Router>
    );
}

function ProbaleDetailsComp(v) {
    return (
        <ProbableDetails {...v}></ProbableDetails>
    )
}


function OrgsUIComp(v) {
    return (
        <Orgs {...v}></Orgs>
    )
}

function OrgUIComp(v) {
    return (
        <Org {...v}></Org>
    )
}
function TestsUIComp(v) {
    return (
        <Tests {...v}></Tests>
    )
}
function HospitalsUIComp(v) {
    return (
        <Hospitals {...v}></Hospitals>
    )
}
function LabsUIComp(v) {
    return (
        <Labs {...v}></Labs>
    )
}
function CampsComp(v) {
    return (
        <Camps {...v}></Camps>
    )
}

function SurveysComp(v) {
    return (
        <Surveys {...v}></Surveys>
    )
}

function SurveyComp(v) {
    return (
        <Survey {...v}></Survey>
    )
}

function CampComp(v) {
    return (
        <Camp {...v}></Camp>
    )
}

function CampTemplatesComp(v) {
    return (
        <Templates {...v} templateType='CA'></Templates>
    )
}

function SurveyTemplatesComp(v) {
    return (
        <Templates {...v} templateType='SU'></Templates>
    )
}

function MedicalTemplatesComp(v) {
    return (
        <Templates {...v} templateType='CM'></Templates>
    )
}

function UsersUIComp(v) {
    return (
        <UsersUI {...v}></UsersUI>
    )
}

function CampRegisterComp(v) {
    return (
        <CampRegister {...v} ></CampRegister>
    )
}

function TemplateComp(v) {
    return (
        <Template {...v} templateType='SU'></Template>
    )
}


function SurveyDataComp(v) {
    return (
        <SurveyData {...v} ></SurveyData>
    )
}

function SurveyRecordViewComp(v) {
    return (
        <SurveyRecordView {...v}></SurveyRecordView>
    )
}

function SurveyRecordEditComp(v) {
    return (
        <SurveyRecordEdit surveyId={v.match.params.surveyId} recordId={v.match.params.recordId} {...v}></SurveyRecordEdit>
    )
}


function CampDataComp(v) {
    return (
        <CampData {...v} ></CampData>
    )
}




function CampRecordViewComp(v) {
    return (
        <CampRecordView {...v}></CampRecordView>
    )
}

function FollowUpCampsComp(v) {
    return (
        <FollowUpCamps {...v} followUpType='IN'></FollowUpCamps>
    )
}
function FollowUpCounsellingComp(v) {
    return (
        <FollowUpCamps {...v} followUpType='CN'></FollowUpCamps>
    )
}

function FollowUpCounsellingRecord(v) {
    return (
        <FollowUpRecords {...v} followUpType='CN'></FollowUpRecords>
    )
}
function FollowUpInvestigationRecord(v) {
    return (
        <FollowUpRecords {...v} followUpType='IN'></FollowUpRecords>
    )
}
function ProbaleFollowupDetailsComp(v) {
    return (
        <ProbableFollowupDetails {...v}></ProbableFollowupDetails>
    )
}
function ProbaleCounsellingComp(v) {
    return (
        <ProbableCounselling {...v}></ProbableCounselling>
    )
}
