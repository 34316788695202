import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles'

import { Fab, Paper, TextField, Divider, Button, DialogActions, Dialog } from '@material-ui/core';

import Autocomplete from '@material-ui/lab/Autocomplete';
import AddIcon from '@material-ui/icons/AddRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';

import { NotificationManager } from 'react-notifications';

import commonClasses from './ics.module.css'

import PagePath from './components/PagePath';
import HttpHandler from './HttpHandler'
import ErrorDialog from './components/ErrorDialog';
import ConfirmationDialog from './components/ConfirmationDialog';
import Utils from './Utils';

import CTable from './components/CTable'

const useStyles = makeStyles({
});


class LabView extends React.Component {

    state = {
        loadingDetails: false,
        name: '',
        address: '',
        city: '',
        districtName: '',
        stateName: '',
        pin: '',
        phone: '',
        email: '',
        contactPerson: ''
    }

    _loadDetails() {
        HttpHandler.sendGet('/api/v1/lab/' + this.props.labId, (d) => {
            this.setState({
                name: d.lab.name,
                address: d.lab.address.address,
                districtName: d.lab.address.district.name,
                stateName: d.lab.address.state.name,
                pin: d.lab.address.pin,
                phone: d.lab.contactPhone,
                email: d.lab.contactEmail,
                contactPerson: d.lab.contactName,
                city: d.lab.city
            })
        }, (errorCode, errorMessage) => {
            NotificationManager.error("There was an error in loading lab details for edit.", "Lab");
            this.props.onClose();
        })
    }

    componentDidMount() {
        this._loadDetails();
    }

    _onClose() {
        this.props.onClose();
    }

    render() {
        return (
            <Dialog open={this.props.isVisible} onClose={this.props.onClose}>
                <div style={{ padding: 5, width: 600 }}>
                    <div className={commonClasses.headerBar}>Lab</div>
                </div>
                <div style={{ width: '100%' }}>
                    {(this.state.loadingDetails === false) &&
                        <div>
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', paddingTop: 5, paddingBottom: 5 }}>
                                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end', fontFamily: 'Metropolis-Regular' }}>Name</div>
                                <div style={{ paddingLeft: 10, paddingRight: 10 }}>:</div>
                                <div style={{ flex: 2, fontFamily: 'Metropolis-Medium' }}>{this.state.name}</div>
                            </div>
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', paddingTop: 5, paddingBottom: 5 }}>
                                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end', fontFamily: 'Metropolis-Regular' }}>Address</div>
                                <div style={{ paddingLeft: 10, paddingRight: 10 }}>:</div>
                                <div style={{ flex: 2, fontFamily: 'Metropolis-Medium' }}>
                                    {this.state.address.split("\n").map((i, key) => {
                                        return <div key={key}>{i}</div>;

                                    })}
                                    <div style={{ fontFamily: 'Metropolis-Medium' }}>{this.state.city}</div>
                                    <div style={{ fontFamily: 'Metropolis-Medium' }}>{this.state.districtName}</div>
                                    <div style={{ fontFamily: 'Metropolis-Medium' }}>{this.state.stateName}</div>
                                    <div style={{ fontFamily: 'Metropolis-Medium' }}>{'PIN : ' + this.state.pin}</div>
                                </div>
                            </div>
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', paddingTop: 5, paddingBottom: 5 }}>
                                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end', fontFamily: 'Metropolis-Regular' }}>Contact person</div>
                                <div style={{ paddingLeft: 10, paddingRight: 10 }}>:</div>
                                <div style={{ flex: 2, fontFamily: 'Metropolis-Medium' }}>{this.state.contactPerson}</div>
                            </div>
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', paddingTop: 5, paddingBottom: 5 }}>
                                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end', fontFamily: 'Metropolis-Regular' }}>Phone</div>
                                <div style={{ paddingLeft: 10, paddingRight: 10 }}>:</div>
                                <div style={{ flex: 2, fontFamily: 'Metropolis-Medium' }}>{this.state.phone}</div>
                            </div>

                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', paddingTop: 5, paddingBottom: 5 }}>
                                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end', fontFamily: 'Metropolis-Regular' }}>Email</div>
                                <div style={{ paddingLeft: 10, paddingRight: 10 }}>:</div>
                                <div style={{ flex: 2, fontFamily: 'Metropolis-Medium' }}>{this.state.email}</div>
                            </div>
                        </div>
                    }
                </div>
                <Divider />
                <DialogActions>
                    <div style={{ paddingTop: 10, flexDirection: 'row', display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            onClick={this._onClose.bind(this)}
                            variant="contained" color="default" style={{ marginLeft: 20 }}>Close</Button>
                    </div>
                </DialogActions>
            </Dialog >
        )
    }
}


function LabEditCreate(props) {

    const [state, setState] = useState({
        labAddress: '',
        labAddressError: false,
        labAddressErrorMessage: '',
        labName: '',
        labNameError: false,
        labNameErrorMessage: '',
        district: -1,
        districtName: '',
        districtError: false,
        districtErrorMessage: '',
        labState: ' - Select a district - ',
        labStateError: false,
        labStateErrorMessage: '',
        labPIN: '',
        labPINError: false,
        labPINErrorMessage: '',
        labEmail: '',
        labEmailError: false,
        labEmailErrorMessage: '',
        labPhone: '',
        labPhoneError: false,
        labPhoneErrorMessage: '',
        contactPerson: '',
        contactPersonError: false,
        contactPersonErrorMessage: '',
        surveyCity: '',
        surveyCityError: false,
        surveyCityErrorMessage: ''
    });

    const _districtChanged = (v, newValue) => {

        setState({
            ...state,
            district: newValue.id,
            districtError: false,
            districtErrorMessage: '',
            labState: newValue == null ? ' - Select a district - ' : newValue.stateName
        })
    }

    const _onInputChanged = (v, newValue) => {
        setState({ ...state, districtName: newValue })
    }

    const _onPinChanged = (v) => {
        var val = v.target.value;
        if (val.length > 6) {
            val = state.labPIN
        }
        else if (val.indexOf('.') >= 0) {
            val = state.labPIN;
        }
        else if (isNaN(val)) {
            val = state.labPIN;
        }

        setState({
            ...state,
            labPINErrorMessage: '',
            labPINError: false,
            labPIN: val
        });
    }

    const _onPhoneChanged = (v) => {
        var val = v.target.value;
        if (val.length > 11) {
            val = state.labPhone
        }
        else if (val.indexOf('.') >= 0) {
            val = state.labPhone;
        }
        else if (isNaN(val)) {
            val = state.labPhone;
        }
        setState({
            ...state,
            labPhone: val,
            labPhoneError: false,
            labPhoneErrorMessage: ''
        })
    }

    const _saveClicked = () => {
        var valid = true;
        var myState = { ...state };

        var name = state.labName.trim();
        if (name.length === 0) {
            valid = false;
            myState.labNameError = true;
            myState.labNameErrorMessage = "Name cannot be empty";
        } else if (name.length < 3) {
            valid = false;
            myState.labNameError = true;
            myState.labNameErrorMessage = "Minimum 3 characters required";
        } else if (name.length > 120) {
            valid = false;
            myState.labNameError = true;
            myState.labNameErrorMessage = "Name should not exceed 120 characters";
        } else if (!Utils.isValidName(name)) {
            valid = false;
            myState.labNameError = true;
            myState.labNameErrorMessage = "Invalid Name";
        }

        var address = state.labAddress.trim();
        if (address.length === 0) {
            valid = false;
            myState.labAddressError = true;
            myState.labAddressErrorMessage = "Address cannot be empty";
        } else if (address.length > 500) {
            valid = false;
            myState.labAddressError = true;
            myState.labAddressErrorMessage = "Address should not exceed 500 characters";
        } else if (address.length < 3) {
            valid = false;
            myState.labAddressError = true;
            myState.labAddressErrorMessage = "Minimum 3 characters required";
        }

        var city = state.surveyCity.trim();
        if (city.length === 0) {
            valid = false;
            myState.surveyCityError = true;
            myState.surveyCityErrorMessage = "City/Town cannot be empty";
        }

        if (state.district <= 0) {
            valid = false;
            myState.districtError = true;
            myState.districtErrorMessage = "District cannot be empty"

        }

        if (state.labPIN === '') {
            valid = false;
            myState.labPINError = true;
            myState.labPINErrorMessage = "Mandatory"
        }
        else if ((state.labPIN + "").length != 6) {
            valid = false;
            myState.labPINError = true;
            myState.labPINErrorMessage = "Invalid PIN"
        }

        var phone = state.labPhone.trim();
        if (phone === '') {
            valid = false;
            myState.labPhoneError = true;
            myState.labPhoneErrorMessage = 'Mandatory'
        }
        else if (!Utils.isValidPhone(phone)) {
            valid = false;
            myState.labPhoneError = true;
            myState.labPhoneErrorMessage = 'Invalid phone'
        }

        var contactPerson = state.contactPerson.trim();
        if (contactPerson.length === 0) {
            valid = false;
            myState.contactPersonError = true;
            myState.contactPersonErrorMessage = "Mandatory";
        } else if (contactPerson.length < 3) {
            valid = false;
            myState.contactPersonError = true;
            myState.contactPersonErrorMessage = "Minimum 3 characters required";
        } else if (contactPerson.length > 120) {
            valid = false;
            myState.contactPersonError = true;
            myState.contactPersonErrorMessage = "Name should not exceed 120 characters";
        } else if (!Utils.isValidDotAccept(contactPerson)) {
            valid = false;
            myState.contactPersonError = true;
            myState.contactPersonErrorMessage = "Invalid Name";
        }

        if (contactPerson.length > 120) {
            valid = false;
            myState.v = true;
            myState.contactPersonErrorMessage = "Name should not exceed 120 characters";
        }

        var labEmail = state.labEmail.trim();
        if (labEmail.length > 150 || (labEmail.length !== 0 && !Utils.isValidEmail(labEmail))) {
            myState.labEmailError = true;
            myState.labEmailErrorMessage = "Invalid Email";
            valid = false;
        }

        if (!valid) {
            setState(myState);
            return;
        }

        var req = {
            name: name,
            address: address,
            contactEmail: state.labEmail.trim(),
            contactPhone: phone,
            contactName: contactPerson,
            pin: parseInt(state.labPIN),
            district: state.district,
            city: state.surveyCity
        }

        var url = '/api/v1/lab/new';
        if (props.labId > 0) {
            url = '/api/v1/lab/' + props.labId;
        }
        HttpHandler.sendPost(url, req, (data) => {
            if (props.labId > 0) {
                NotificationManager.success("Lab record updated.", name);
            }
            else {
                NotificationManager.success("Lab record created.", name);
            }
            props.onDone();
        }, (errorCode, errorMessage) => {
            console.log(errorMessage);
            ErrorDialog.show("There was error in saving lab");
        })
    }

    const _closeClicked = () => {
        props.onClose();
    }

    const _loadLabDetails = () => {
        HttpHandler.sendGet('/api/v1/lab/' + props.labId, (d) => {
            var istate = {
                ...state,
                labName: d.lab.name,
                labAddress: d.lab.address.address,
                labPIN: d.lab.address.pin,
                labEmail: d.lab.contactEmail,
                labPhone: d.lab.contactPhone,
                contactPerson: d.lab.contactName,
                surveyCity: d.lab.city !== null ? d.lab.city : ''
            }


            istate = _setDistrictData(istate, d.lab.address.district.id)
            setState(istate);
        }, (errorCoder, errorMessage) => {
            props.onClose();
        })
    }

    const _setDistrictData = (istate, id) => {
        console.log(id);
        if (id === null) {
            istate = {
                ...istate,
                district: '',
                districtName: '',
                districtError: false,
                districtErrorMessage: '',
                labState: ' - Select a district - '
            };
            return istate;
        }
        var len = Utils.statesAndDistricts.length;
        for (var i = 0; i < len; i++) {
            if (Utils.statesAndDistricts[i].id === id) {
                istate = {
                    ...istate,
                    district: id,
                    districtName: Utils.statesAndDistricts[i].title,
                    districtError: false,
                    districtErrorMessage: '',
                    labState: Utils.statesAndDistricts[i].stateName
                };
                return istate;
            }
        }

    }

    useEffect(() => {
        if (props.labId > 0) {
            _loadLabDetails();
        }
        else {
            setState({
                ...state,
                labAddress: '',
                labAddressError: false,
                labAddressErrorMessage: '',
                labName: '',
                labNameError: false,
                labNameErrorMessage: '',
                district: -1,
                districtName: '',
                districtError: false,
                districtErrorMessage: '',
                labState: ' - Select a district - ',
                labStateError: false,
                labStateErrorMessage: '',
                labPIN: '',
                labPINError: false,
                labPINErrorMessage: '',
                labEmail: '',
                labEmailError: false,
                labEmailErrorMessage: '',
                labPhone: '',
                labPhoneError: false,
                labPhoneErrorMessage: '',
                contactPerson: '',
                contactPersonError: false,
                contactPersonErrorMessage: '',
                surveyCity: '',
                surveyCityError: false,
                surveyCityErrorMessage: ''
            })
        }
    }, [props.labId]);

    return (
        <div>
            <Paper style={{ padding: 10, width: 500 }}>
                <div className={commonClasses.headerBar}>{props.heading}</div>
                <div style={{ paddingBottom: 20 }}>
                    <form noValidate autoComplete="off">
                        <TextField
                            required
                            error={state.labNameError}
                            id='labName'
                            label="Name"
                            fullWidth
                            value={state.labName}
                            margin="normal"
                            helperText={state.labNameErrorMessage}

                            onChange={(v) => {
                                setState({
                                    ...state,
                                    labName: v.target.value,
                                    labNameError: false,
                                    labNameErrorMessage: ''
                                })
                            }}
                        />
                        <TextField
                            required
                            error={state.labAddressError}
                            id='labAddress'
                            label="Address"
                            fullWidth
                            multiline={true}
                            rows={4}
                            value={state.labAddress}
                            margin="normal"
                            helperText={state.labAddressErrorMessage}
                            onChange={(v) => {
                                setState({
                                    ...state,
                                    labAddress: v.target.value,
                                    labAddressError: false,
                                    labAddressErrorMessage: ''
                                })
                            }}
                        />
                        <TextField
                            required
                            error={state.surveyCityError}
                            id='surveyCity'
                            label="City/Town"
                            fullWidth
                            value={state.surveyCity}
                            margin="normal"
                            helperText={state.surveyCityErrorMessage}
                            onChange={(v) => {
                                setState({
                                    ...state,
                                    surveyCity: v.target.value,
                                    surveyCityError: false,
                                    surveyCityErrorMessage: ''
                                })
                            }}
                        />
                        <Autocomplete
                            onChange={_districtChanged}
                            id="labDistrict"
                            onInputChange={_onInputChanged}
                            getOptionLabel={(v) => v.title}
                            inputValue={state.districtName}
                            options={Utils.statesAndDistricts.map((option) => option)}
                            renderInput={(params) => (
                                <TextField {...params} required label="District" margin="normal"
                                    helperText={state.districtErrorMessage}
                                    error={state.districtError} />
                            )}
                        />

                        <div style={{ width: '100%', flexDirection: 'row', display: 'flex' }}>
                            <TextField
                                required
                                error={state.labStateError}
                                id='labState'
                                label="State"
                                style={{ flex: 1 }}
                                value={state.labState}
                                margin="normal"
                                InputProps={{
                                    readOnly: true,
                                }}
                                helperText={state.labStateErrorMessage}
                                onChange={(v) => {
                                    setState({
                                        ...state,
                                        labState: v.target.value,
                                        labStateError: false,
                                        labStateErrorMessage: ''
                                    })
                                }}
                            />
                            <TextField
                                required
                                error={state.labPINError}
                                id='labPIN'
                                label="PIN"
                                style={{ width: 100, marginLeft: 20 }}
                                value={state.labPIN}
                                margin="normal"
                                helperText={state.labPINErrorMessage}
                                onChange={_onPinChanged}
                            />
                        </div>

                        <TextField
                            required
                            error={state.contactPersonError}
                            id='contactPerson'
                            label="Contact Person"
                            fullWidth
                            value={state.contactPerson}
                            margin="normal"
                            helperText={state.contactPersonErrorMessage}

                            onChange={(v) => {
                                setState({
                                    ...state,
                                    contactPerson: v.target.value,
                                    contactPersonError: false,
                                    contactPersonErrorMessage: ''
                                })
                            }}
                        />
                        <div style={{ width: '100%', flexDirection: 'row', display: 'flex' }}>
                            <TextField
                                error={state.labEmailError}
                                id='labEmail'
                                label="Email"
                                style={{ flex: 1 }}
                                value={state.labEmail}
                                margin="normal"
                                helperText={state.labEmailErrorMessage}
                                onChange={(v) => {
                                    setState({
                                        ...state,
                                        labEmail: v.target.value,
                                        labEmailError: false,
                                        labEmailErrorMessage: ''
                                    })
                                }}
                            />
                            <TextField
                                required
                                error={state.labPhoneError}
                                id='labPhone'
                                label="Phone"
                                style={{ width: 200, marginLeft: 20 }}
                                value={state.labPhone}
                                margin="normal"
                                helperText={state.labPhoneErrorMessage}
                                onChange={_onPhoneChanged}
                            />
                        </div>
                    </form>
                </div>
                <Divider style={{ marginLeft: -10, marginRight: -10 }}></Divider>
                <div style={{ paddingTop: 10, flexDirection: 'row', display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        onClick={_saveClicked}
                        variant="contained" color="primary">Save</Button>
                    <Button
                        onClick={_closeClicked}
                        variant="contained" color="default" style={{ marginLeft: 20 }}>Close</Button>
                </div>
            </Paper>
        </div>
    )
}


export default function Labs(props) {


    const dataTable = useRef(null);

    const [state, setState] = useState({
        editCreateDialog: false,
        labs: [],
        selectedLabId: -1,
        isLabViewVisible: false,
        addEditUIHeading: "Register Lab"
    });

    const _showLabView = (row) => {
        setState({ ...state, isLabViewVisible: true, selectedLabId: row.id })
    }

    const labTableDef = [
        {
            title: "Name",
            field: 'name',
            align: 'left',
            renderer: (row, data) => {
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center' }} >
                        <div
                            onClick={() => { _showLabView(row) }}
                            style={{
                                fontFamily: 'Metropolis-Regular', color: '#4139B9',
                                cursor: 'pointer',
                                borderBottom: '1px solid #4139B9'
                            }}>{row.name}</div>
                    </div>
                )
            }

        },
        {
            title: "Contact Person",
            field: 'contactName',
            align: 'left',
            renderer: (row, data) => {
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center' }} >
                        {row.contactName}
                    </div>
                )
            }
        },
        {
            title: "Contact phone",
            field: 'phone',
            align: 'left',
            renderer: (row, data) => {
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center' }} >
                        {row.contactPhone}
                    </div>
                )
            }
        }
        ,
        {
            title: "Contact Email",
            field: 'contactEmail',
            align: 'left',
            renderer: (row, data) => {
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center' }} >
                        {row.contactEmail}
                    </div>
                )
            }
        },
        {
            title: "",
            field: 'id',
            style: { minWidth: 120, width: 120 },
            renderer: (row, data) => {
                return (
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        {(HttpHandler.USER_TYPE === 'admin') &&
                            <div
                                onClick={() => _editClicked(row)}
                                style={{ height: 24, cursor: 'pointer' }}>
                                <EditRoundedIcon style={{ color: '#03A9F4' }}></EditRoundedIcon>
                            </div>
                        }
                        {(HttpHandler.USER_TYPE === 'admin') &&
                            <div
                                onClick={() => _deleteClicked(row)}
                                style={{ marginLeft: 20, height: 24, cursor: 'pointer' }}>
                                <DeleteForeverRoundedIcon style={{ color: '#D32F2F' }}></DeleteForeverRoundedIcon>
                            </div>
                        }

                    </div>
                )
            }

        }
    ]

    const _editClicked = (row) => {
        setState({ ...state, editCreateDialog: true, selectedLabId: row.id, addEditUIHeading: "Modify Lab" })
    }

    const _deleteClicked = (row) => {
        ConfirmationDialog.show('Are you sure you want to delete this lab?', () => {
            HttpHandler.sendDelete('/api/v1/lab/' + row.id, (d) => {
                NotificationManager.success("Lab deleted.", row.name);
                dataTable.current.reload();
            }, (errorCode, errorMessage) => {
                ErrorDialog.show("There was error in deleting lab");
            })
        });
    }

    useEffect(() => {

    }, []);

    const _createLab = () => {
        setState({
            ...state,
            editCreateDialog: true,
            selectedLabId: -1,
            addEditUIHeading: "Register Lab"
        });

    }

    const _getLabData = (cb, offset, limit, searchString) => {
        HttpHandler.sendGet('/api/v1/lab/all?offset=' + offset + '&limit=' + limit + '&s=' + searchString, (data) => {
            cb(data.result, data.count)
        }, (errorCode, errorMessage) => {
            console.log(errorMessage);
        })
    }

    const _labCreated = () => {
        setState({
            ...state,
            editCreateDialog: false
        });
        dataTable.current.reload();
    }

    const _onLabViewClose = () => {
        setState({ ...state, isLabViewVisible: false })
    }

    return (
        <div>
            <div style={{ marginRight: 20 }}>
                <PagePath path={['Labs', 'All']}>
                    {(HttpHandler.USER_TYPE === 'admin') &&
                        <Fab
                            onClick={_createLab}
                            color="primary" aria-label="add" size="medium" title="New lab">
                            <AddIcon />
                        </Fab>
                    }
                </PagePath>
            </div>
            {state.isLabViewVisible &&
                <LabView
                    labId={state.selectedLabId}
                    isVisible={state.isLabViewVisible} onClose={_onLabViewClose} />
            }
            <div style={{ marginTop: 20, paddingBottom: 30, paddingRight: 10 }}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {state.editCreateDialog &&
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <LabEditCreate labId={state.selectedLabId}
                                heading={state.addEditUIHeading}
                                onDone={_labCreated}
                                onClose={() => { setState({ ...state, editCreateDialog: false }) }} />
                        </div>
                    }
                    {(state.editCreateDialog === false) &&
                        <Paper style={{ padding: 10 }}>
                            <div style={{ width: 900 }}>
                                <CTable
                                    ref={dataTable}
                                    data={_getLabData}
                                    columns={labTableDef}>
                                </CTable>
                            </div>
                        </Paper>
                    }
                </div>
            </div>
        </div>
    )
}

