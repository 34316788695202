import React, { useRef, useEffect, useState, createRef } from 'react';

import { Paper, Divider, TextField, FormControl, Select, MenuItem, InputLabel, Fab, CircularProgress, Tooltip } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { NotificationManager } from 'react-notifications';

import BackupRoundedIcon from '@material-ui/icons/BackupRounded';


import PagePath from './components/PagePath';
import HttpHandler from './HttpHandler'
import TemplateField from './components/TemplateField';
import Utils from './Utils'


export default function SurveyRecordEdit(props) {

    let surveyId = useRef(0);
    let history = useHistory();

    const [state, setState] = useState({
        surveyName: '',
        generalName: '',

        generalNameError: false,
        generalNameErrorMessage: '',
        generalAddress: '',
        generalAddressError: false,
        generalAddressErrorMessage: '',
        generalDistrict: '',
        generalDistrictName: '',
        generalDistrictError: false,
        generalDistrictErrorMessage: '',
        generalState: '',
        generalStateError: false,
        generalStateErrorMessage: '',
        generalStateId: '',
        generalPIN: '',
        generalPINError: false,
        generalPINErrorMessage: '',
        generalPhone: '',
        generalPhoneError: false,
        generalPhoneMessage: '',
        generalAge: '',
        generalAgeError: false,
        generalAgeErrorMessage: '',
        sections: [],
        generalGender: 'M',
        generalIdentifierType: 'None',
        generalIdentifierTypeError: false,
        generalIdentifierTypeErrorMessage: '',
        generalIdentifierID: '',
        generalIdentifierIDError: false,
        generalIdentifierIDErrorMessage: '',
        generalAshaName: '',
        generalAshaNameError: false,
        generalAshaNameErrorMessage: '',
        loadMessage: '',
        showLoading: false,
        data: {}
    });

    const _loadSurveyDetails = (istate) => {
        HttpHandler.sendGet('/api/v1/survey/' + surveyId.current, (d) => {
            istate = {
                ...istate,
                surveyName: d.survey.name,
                generalPIN: d.survey.address.pin,
                generalState: d.survey.address.state.name,
                generalStateId: d.survey.address.state.id,
                generalDistrictName: d.survey.address.district.name,
                generalDistrict: d.survey.address.district.id,
                generalAddress: d.survey.address.address

            };
            setState(istate);
            _getSectionDetails(istate, d.survey.template.id)
        }, (errorCode, errorMessage) => {
            console.log(errorMessage);
        });
    }

    const _getSectionDetails = (istate, templateId) => {
        HttpHandler.sendGet('/api/v1/template/' + templateId + '/details', (d) => {
            if (typeof props.recordId === 'undefined') {
                istate = { ...istate, sections: d.sections, showLoading: false };
                setState(istate);
                return;
            }
            else {
                istate = { ...istate, sections: d.sections };
                setState(istate);
            }

            _loadRecord(istate);

        }, (errorCode, errorMessage) => {
            console.log(errorMessage);
        })
    }

    useEffect(() => {
        surveyId.current = props.surveyId;
        var msg = 'Loading questions for ';
        if (typeof props.recordId !== 'undefined') {
            msg = 'Loading questions and data to edit';
        }
        var istate = { ...state, loadMessage: msg, showLoading: true }
        setState(istate);
        _loadSurveyDetails(istate);
    }, [props.surveyId]);

    const _districtChanged = (v, newValue) => {
        setState({
            ...state,
            generalDistrict: newValue === null ? '' : newValue.id,
            generalDistrictError: false,
            generalDistrictErrorMessage: '',
            generalState: newValue == null ? ' - Select a district - ' : newValue.stateName
        })
    }

    const _setDistrictData = (istate, id) => {
        if (id === null) {
            setState({
                ...istate,
                generalDistrict: '',
                generalDistrictError: false,
                generalDistrictErrorMessage: '',
                generalState: ' - Select a district - '
            });
            return;
        }
        var len = Utils.statesAndDistricts.length;
        for (var i = 0; i < len; i++) {
            if (Utils.statesAndDistricts[i].id === id) {
                setState({
                    ...istate,
                    generalDistrict: id,
                    generalDistrictName: Utils.statesAndDistricts[i].title,
                    generalDistrictError: false,
                    generalDistrictErrorMessage: '',
                    generalState: Utils.statesAndDistricts[i].stateName
                });
                return;
            }
        }

    }

    const _onInputChanged = (v, newValue) => {
        setState({ ...state, generalDistrictName: newValue });
    }

    const _onPinChanged = (v) => {
        var val = v.target.value;
        if (val.length > 6) {
            val = state.generalPIN
        }
        else if (val.indexOf('.') >= 0) {
            val = state.generalPIN;
        }
        else if (isNaN(val)) {
            val = state.generalPIN;
        }
        setState({
            ...state,
            generalPINErrorMessage: '',
            generalPINError: false,
            generalPIN: val
        });
    }

    const _onPhoneChanged = (v) => {
        var val = v.target.value;
        if (val.length > 11) {
            val = state.generalPhone
        }
        else if (val.indexOf('.') >= 0) {
            val = state.generalPhone;
        }
        else if (isNaN(val)) {
            val = state.generalPhone;
        }
        setState({
            ...state,
            generalPhone: val,
            generalPhoneError: false,
            generalPhoneErrorMessage: ''
        })
    }

    const _onAgeChanged = (v) => {
        var val = v.target.value;
        if (val.length > 3) {
            val = state.generalAge
        }
        else if (val.indexOf('.') >= 0) {
            val = state.generalAge;
        }
        else if (isNaN(val)) {
            val = state.generalAge;
        }
        setState({
            ...state,
            generalAgeErrorMessage: '',
            generalAgeError: false,
            generalAge: val
        });
    }

    const _generalIdentifierType = (v) => {
        setState({
            ...state,
            generalIdentifierType: v.target.value,
            generalIdentifierID: v.target.value === "None" ? '' : state.generalIdentifierID,
            generalIdentifierIDError: false,
            generalIdentifierIDErrorMessage: ''
        })
    }

    const _handleGenderChange = (v) => {
        setState({
            ...state,
            generalGender: v.target.value
        })
    }

    const _isValid = (istate) => {
        var valid = true;

        var asha = state.generalAshaName.trim();
        if (asha === '') {
            istate.generalAshaNameError = true;
            istate.generalAshaNameErrorMessage = "Mandatory";
            valid = false;
        } else if (asha.length > 100) {
            istate.generalAshaNameError = true;
            istate.generalAshaNameErrorMessage = "Too long";
            valid = false;
        } else if (asha.length < 3) {
            valid = false;
            istate.generalAshaNameError = true;
            istate.generalAshaNameErrorMessage = "Minimum 3 characters required";
        } else if (!Utils.isValidDotAccept(asha)) {
            valid = false;
            istate.generalAshaNameError = true;
            istate.generalAshaNameErrorMessage = "Invalid Name";
        }

        var name = state.generalName.trim();
        if (name === '') {
            istate.generalNameError = true;
            istate.generalNameErrorMessage = "Mandatory";
            valid = false;
        } else if (name.length > 100) {
            istate.generalNameError = true;
            istate.generalNameErrorMessage = "Too long";
            valid = false;
        } else if (name.length < 3) {
            valid = false;
            istate.generalNameError = true;
            istate.generalNameErrorMessage = "Minimum 3 characters required";
        } else if (!Utils.isValidDotAccept(name)) {
            valid = false;
            istate.generalNameError = true;
            istate.generalNameErrorMessage = "Invalid Name";
        }

        var identifierId = state.generalIdentifierID.trim();
        var identifierType = state.generalIdentifierType;
        if (identifierType == "Aadhar" && identifierId.length != 16) {
            istate.generalIdentifierIDError = true;
            istate.generalIdentifierIDErrorMessage = "Please enter 16 characters for Aadhar";
            valid = false;
        } else if (identifierType == "Voter ID" && identifierId.length != 10) {
            istate.generalIdentifierIDError = true;
            istate.generalIdentifierIDErrorMessage = "Please enter 10 characters for Voter ID";
            valid = false;
        } else if (identifierId.length > 20) {
            istate.generalIdentifierIDError = true;
            istate.generalIdentifierIDErrorMessage = "Maximum 20 characters allowed";
            valid = false;
        }

        var address = state.generalAddress.trim();
        if (address === '') {
            istate.generalAddressError = true;
            istate.generalAddressErrorMessage = "Mandatory";
            valid = false;
        }

        if (address.length > 500) {
            istate.generalAddressError = true;
            istate.generalAddressErrorMessage = "Too long";
            valid = false;
        }

        if (state.generalDistrict === '') {
            istate.generalDistrictError = true;
            istate.generalDistrictErrorMessage = "Mandatory";
            valid = false;
        }


        if ((state.generalPIN + '').length != 6) {
            istate.generalPINError = true;
            istate.generalPINErrorMessage = "Invalid PIN";
            valid = false;
        }

        var phone = state.generalPhone.trim();
        if (phone.length !== 0 && !Utils.isValidPhone(phone)) {
            valid = false;
            istate.generalPhoneError = true;
            istate.generalPhoneErrorMessage = 'Invalid phone'
        }

        var age = state.generalAge;
        if (age + '' === '') {
            istate.generalAgeError = true;
            istate.generalAgeErrorMessage = "Mandatory";
            valid = false;
        } else if (age < 1 || age > 100) {
            istate.generalAgeError = true;
            istate.generalAgeErrorMessage = "Enter between (1-100)";
            valid = false;
        }

        for (var i = 0; i < state.sections.length; i++) {
            var section = state.sections[i];
            for (var j = 0; j < section.rows.length; j++) {
                var field = section.rows[j];
                valid = field.ref.current.validate() && valid;
            }
        }

        return valid;

    }

    const _saveClicked = () => {
        var istate = state;

        if (!_isValid(istate)) {
            setState({ ...state, istate });
            return;
        }

        //Prepare the request general section.
        var id = -1;
        if (typeof props.recordId !== 'undefined') {
            id = parseInt(props.recordId);
        }
        var general = {
            id: id,
            regId: "__CHANGE_AT_SERVER__",
            asha: state.generalAshaName,
            name: state.generalName,
            address: state.generalAddress,
            district: parseInt(state.generalDistrict),
            pin: parseInt(state.generalPIN),
            phone: state.generalPhone,
            gender: state.generalGender,
            identifierType: state.generalIdentifierType,
            identifierId: state.generalIdentifierID,
            age: parseInt(state.generalAge)
        }

        //Get individual data
        var allData = {}
        for (var i = 0; i < state.sections.length; i++) {
            var section = state.sections[i];
            var sectionData = {};
            for (var j = 0; j < section.rows.length; j++) {
                var field = section.rows[j];
                var val = field.ref.current.getValue();
                val = { value: val, id: field.id, type: field.type };
                sectionData[field.id] = val;
            }

            allData[section.id] = sectionData;
        }

        HttpHandler.sendPost('/api/v1/survey/' + surveyId.current + '/data', { general: general, data: allData }, (d) => {
            if (typeof props.recordId === 'undefined') {
                props.onSave();
            }
            else {
                history.goBack();
                NotificationManager.success("Record saved.", "Survey");
            }
        }, (errorCode, errorMessage) => {
            NotificationManager.error("There was an error saving the survey record.", "Survey");
        })
    }

    const _loadRecord = (istate) => {
        HttpHandler.sendGet('/api/v1/survey/' + surveyId.current + '/data/' + props.recordId, (d) => {

            var general = d.general;
            let districtName = ""
            var len = Utils.statesAndDistricts.length;
            for (var i = 0; i < len; i++) {
                if (Utils.statesAndDistricts[i].id === general.district) {
                    districtName = Utils.statesAndDistricts[i].title
                }
            }

            istate = {
                ...istate, showLoading: false,
                generalAshaName: general.asha !== null ? general.asha : '',
                generalName: general.name,
                generalAddress: general.address,
                generalPhone: general.phone,
                generalDistrict: general.district,
                generalDistrictName: districtName,
                generalPIN: general.pin,
                generalPhone: general.phone,
                generalAge: general.age,
                generalGender: general.gender,
                generalIdentifierType: general.identifierType,
                generalIdentifierID: general.asha !== null ? general.identifierId : '',
                data: d.data
            };

            setState(istate);
            _setDistrictData(istate, general.district);



        }, (errorCode, errorMessage) => {
            NotificationManager.error("Error loading questions and record. Try again or contact administrator.", "Error");
            props.onClose();
        });
    }

    const _closeClicked = () => {
        if (typeof props.onClose === 'undefined') {
            history.goBack();
            return;
        }
        props.onClose();
    }

    return (
        <div style={{ width: '100%' }}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                {state.showLoading &&
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <div style={{ fontFamily: 'Metropolis-Regular', paddingRight: 20 }}>{state.loadMessage} </div>
                        <CircularProgress />
                    </div>
                }
                {(state.showLoading === false) &&
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div>
                            <div style={{ flex: 1, display: 'flex', justifyContent: 'center', paddingTop: 30, flexDirection: 'column' }}>
                                <div>
                                    <Paper style={{ padding: 10, minWidth: 500 }}>
                                        <div style={{ flexDirection: 'row', display: 'flex', paddingBottom: 5, alignItems: 'center' }}>
                                            <div style={{ fontFamily: 'Metropolis-Medium', flex: 1 }}>Personal</div>
                                        </div>
                                        <Divider></Divider>

                                        <div style={{ paddingTop: 3, flexDirection: 'column', display: 'flex', marginTop: 10 }}>
                                            <form noValidate autoComplete="off">
                                                <TextField
                                                    required
                                                    error={state.generalAshaNameError}
                                                    id='generalAshaName'
                                                    label="Name of ASHA/Volunteer"
                                                    fullWidth
                                                    value={state.generalAshaName}
                                                    margin="normal"
                                                    helperText={state.generalAshaNameErrorMessage}
                                                    onChange={(v) => {
                                                        setState({
                                                            ...state,
                                                            generalAshaName: v.target.value,
                                                            generalAshaNameError: false,
                                                            generalAshaNameErrorMessage: ''
                                                        })
                                                    }}
                                                />
                                            </form>
                                            <form noValidate autoComplete="off">
                                                <TextField
                                                    required
                                                    error={state.generalNameError}
                                                    id='generalName'
                                                    label="Name"
                                                    fullWidth
                                                    value={state.generalName}
                                                    margin="normal"
                                                    helperText={state.generalNameErrorMessage}
                                                    onChange={(v) => {
                                                        setState({
                                                            ...state,
                                                            generalName: v.target.value,
                                                            generalNameError: false,
                                                            generalNameErrorMessage: ''
                                                        })
                                                    }}
                                                />
                                            </form>

                                            <FormControl style={{ flex: 1, marginTop: 10 }}>
                                                <InputLabel id="generalIdentifierType-label">Identifier Type *</InputLabel>
                                                <Select
                                                    required
                                                    labelId="generalIdentifierType-label"
                                                    id="generalIdentifierType-select"
                                                    value={state.generalIdentifierType}
                                                    onChange={_generalIdentifierType}
                                                >
                                                    <MenuItem value="None">None</MenuItem>
                                                    <MenuItem value="Aadhar">Aadhar</MenuItem>
                                                    <MenuItem value="UID">UID</MenuItem>
                                                    <MenuItem value="Voter ID">Voter ID</MenuItem>
                                                </Select>
                                            </FormControl>

                                            <form noValidate autoComplete="off">
                                                <TextField
                                                    disabled={state.generalIdentifierType === 'None' ? true : false}
                                                    error={state.generalIdentifierIDError}
                                                    id='generalIdentifierID'
                                                    label="Identifier ID"
                                                    fullWidth
                                                    value={state.generalIdentifierID}
                                                    margin="normal"
                                                    helperText={state.generalIdentifierIDErrorMessage}
                                                    onChange={(v) => {
                                                        setState({
                                                            ...state,
                                                            generalIdentifierID: v.target.value,
                                                            generalIdentifierIDError: false,
                                                            generalIdentifierIDErrorMessage: ''
                                                        })
                                                    }}
                                                />
                                            </form>


                                            <form noValidate autoComplete="off">
                                                <TextField
                                                    required
                                                    error={state.generalAddressError}
                                                    id='generalAddress'
                                                    label="Address"
                                                    fullWidth
                                                    multiline={true}
                                                    rows={3}
                                                    value={state.generalAddress}
                                                    margin="normal"
                                                    helperText={state.generalAddressErrorMessage}
                                                    onChange={(v) => {
                                                        setState({
                                                            ...state,
                                                            generalAddress: v.target.value,
                                                            generalAddressError: false,
                                                            generalAddressErrorMessage: ''
                                                        })
                                                    }}
                                                />

                                                <Autocomplete
                                                    onChange={_districtChanged}
                                                    id="generalDistrict"
                                                    onInputChange={_onInputChanged}
                                                    defaultValue={{
                                                        "id": state.generalDistrict,
                                                        "title": state.generalDistrictName,
                                                        "stateName": state.generalState,
                                                        "stateId": state.generalStateId
                                                    }}
                                                    getOptionLabel={(v) => v.title}
                                                    getOptionSelected={(option, value) => option.title === value.title}
                                                    options={Utils.statesAndDistricts.map((option) => option)}
                                                    renderInput={(params) => (
                                                        <TextField {...params} required label="District" margin="normal" value={state.generalDistrictName}
                                                            helperText={state.generalDistrictErrorMessage}
                                                            error={state.generalDistrictError} />
                                                    )}
                                                />

                                                <div style={{ width: '100%', flexDirection: 'row', display: 'flex' }}>
                                                    <TextField
                                                        required
                                                        error={state.generalStateError}
                                                        id='generalState'
                                                        label="State"
                                                        style={{ flex: 1 }}
                                                        value={state.generalState}
                                                        margin="normal"
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        helperText={state.generalStateErrorMessage}
                                                        onChange={(v) => {
                                                            setState({
                                                                ...state,
                                                                generalState: v.target.value,
                                                                generalStateError: false,
                                                                generalStateErrorMessage: ''
                                                            })
                                                        }}
                                                    />
                                                    <TextField
                                                        required
                                                        error={state.generalPINError}
                                                        id='generalPIN'
                                                        label="PIN"
                                                        style={{ width: 100, marginLeft: 20 }}
                                                        value={state.generalPIN}
                                                        margin="normal"
                                                        helperText={state.generalPINErrorMessage}
                                                        onChange={_onPinChanged}
                                                    />
                                                </div>

                                                <div style={{ width: '100%', flexDirection: 'row', display: 'flex' }}>
                                                    <TextField
                                                        error={state.generalPhoneError}
                                                        id='generalPhone'
                                                        label="Phone"
                                                        style={{ width: 200, marginRight: 20 }}
                                                        value={state.generalPhone}
                                                        margin="normal"
                                                        helperText={state.generalPhoneErrorMessage}
                                                        onChange={_onPhoneChanged}
                                                    />
                                                    <TextField
                                                        required
                                                        error={state.generalAgeError}
                                                        id='generalAge'
                                                        label="Age"
                                                        style={{ width: 100 }}
                                                        value={state.generalAge}
                                                        margin="normal"
                                                        helperText={state.generalAgeErrorMessage}
                                                        onChange={_onAgeChanged}
                                                    />
                                                    <FormControl style={{ flex: 1, marginTop: 16, marginLeft: 20 }}>
                                                        <InputLabel id="generalGender-label">Gender</InputLabel>
                                                        <Select
                                                            labelId="generalGender-label"
                                                            id="generalGender-select"
                                                            value={state.generalGender}
                                                            onChange={_handleGenderChange}
                                                        >
                                                            <MenuItem value="M">Male</MenuItem>
                                                            <MenuItem value="F">Female</MenuItem>
                                                            <MenuItem value="O">Transgender</MenuItem>
                                                        </Select>
                                                    </FormControl>

                                                </div>

                                            </form>
                                        </div>
                                    </Paper>
                                </div>

                                <div>
                                    {state.sections.map((row, key) => {
                                        return (
                                            <Paper key={row.id} style={{ padding: 10, minWidth: 500, marginTop: 20, marginBottom: 20 }}>
                                                <div style={{ flexDirection: 'row', display: 'flex', paddingBottom: 5, alignItems: 'center' }}>
                                                    <div style={{ fontFamily: 'Metropolis-Medium', flex: 1 }}>{row.name}</div>
                                                </div>
                                                <Divider></Divider>
                                                {row.rows.map((field, fkey) => {
                                                    var ref = createRef();
                                                    field.ref = ref;
                                                    var val = state.data[field.id];
                                                    if (typeof val === 'undefined') {
                                                        val = {};
                                                    }

                                                    return (
                                                        <div key={field.id} style={{ flex: 1, paddingRight: 10, paddingTop: 10 }}>
                                                            <TemplateField value={val.value} name={row.name} gender={state.generalGender} ref={ref} key={field.name} def={field} />
                                                        </div>
                                                    )
                                                })}
                                            </Paper>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                        <div style={{ paddingTop: 30, paddingLeft: 10 }}>
                            <Tooltip title="SAVE" arrow>
                                <Fab
                                    onClick={_saveClicked}
                                    style={{ marginRight: 20 }}
                                    color="primary" aria-label="add" size="medium">
                                    <BackupRoundedIcon />
                                </Fab>
                            </Tooltip>
                            <Tooltip title="CLOSE" arrow>
                                <Fab
                                    onClick={_closeClicked}
                                    style={{ marginRight: 30 }}
                                    color="default" aria-label="add" size="medium">
                                    <CloseRoundedIcon />
                                </Fab>
                            </Tooltip>
                        </div>
                    </div>
                }
            </div>
        </div >
    )
}