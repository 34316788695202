import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles'
import { Dialog, DialogContent, DialogActions, Button, TextField, Divider, FormControl, InputLabel, Select, Switch } from '@material-ui/core'
import { MenuItem, FormControlLabel } from '@material-ui/core';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import AddBoxIcon from '@material-ui/icons/AddBox';

import commonClasses from './ics.module.css'
import NumericTextField from './components/NumericTextField'

const styles = theme => ({
    optionValue: {
        padding: 5,
        borderRadius: 3,
        backgroundColor: '#F4F4F4',
        fontFamily: 'Metropolis-Regular',
        marginTop: 5,
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center'
    }
});


class TemplateComponentDialog extends Component {

    static currentRef = null;
    callBack = null;
    fieldsToCheck = null;
    currentRecord = null;

    state = {
        dialogOpen: false,
        parameterName: '',
        parameterNameError: false,
        parameterNameErrorMessage: '',
        templateParamaterType: 'boolean',
        optionsValue: '',
        optionsErrorMessage: '',
        optionsValueError: false,
        optionsValueErrorMessage: '',
        optionValues: [],
        numericMaxValue: '',
        numericMaxValueError: false,
        numericMaxValueErrorMessage: '',
        numericMinValue: '',
        numericMinValueError: false,
        numericMinValueErrorMessage: '',
        decimalMaxValue: '',
        decimalMaxValueError: false,
        decimalMaxValueErrorMessage: '',
        decimalMinValue: '',
        decimalMinValueError: false,
        decimalMinValueErrorMessage: '',
        textMinLength: 0,
        textMinLengthError: false,
        textMinLengthErrorMessage: '',
        textMaxLength: 1000,
        textMaxLengthError: false,
        textMaxLengthErrorMessage: '',
        optional: false,
        readOnly: false

    }

    componentDidMount() {
        TemplateComponentDialog.currentRef = this;
    }

    static show(cb, fieldsToCheck, record, readOnly) {
        TemplateComponentDialog.currentRef.showDialog(cb, fieldsToCheck, record, readOnly);
    }

    showDialog(cb, fieldsToCheck, record, readOnly) {
        this.fieldsToCheck = fieldsToCheck;

        var state = {
            parameterName: '',
            parameterNameError: false,
            parameterNameErrorMessage: '',
            templateParamaterType: 'boolean',
            optionsValue: '',
            optionsValueError: false,
            optionsValueErrorMessage: '',
            optionValues: [],
            optionsErrorMessage: '',
            numericMaxValue: '',
            numericMaxValueError: false,
            numericMaxValueErrorMessage: '',
            numericMinValue: '',
            numericMinValueError: false,
            numericMinValueErrorMessage: '',
            decimalMaxValue: '',
            decimalMaxValueError: false,
            decimalMaxValueErrorMessage: '',
            decimalMinValue: '',
            decimalMinValueError: false,
            decimalMinValueErrorMessage: '',
            textMinLength: 0,
            textMinLengthError: false,
            textMinLengthErrorMessage: '',
            textMaxLength: 1000,
            textMaxLengthError: false,
            textMaxLengthErrorMessage: '',
            dialogOpen: true,
            optional: false,
            readOnly: readOnly
        }

        if (typeof record !== 'undefined' && record != null) {
            state.templateParamaterType = record.type;
            state.parameterName = record.name;
            state.optional = record.optional;
            this.currentRecord = record;
            switch (record.type) {
                case 'text': {
                    state.textMinLength = record.def.minLength;
                    state.textMaxLength = record.def.maxLength;
                }
                    break;
                case 'numeric': {
                    state.numericMinValue = record.def.min;
                    state.numericMaxValue = record.def.max;
                }
                    break;
                case 'decimal': {
                    state.decimalMinValue = record.def.min;
                    state.decimalMaxValue = record.def.max;
                }
                    break;
                case 'options': {
                    state.optionValues = record.def.options;
                }
            }
            this.setState(state);
        }
        else {
            this.currentRecord = null;
            this.setState(state);
        }

        this.callBack = cb;
    }

    _onClose() {
        this.setState({
            dialogOpen: false,
        })
    }

    _saveClicked() {
        //Validate based on the type.
        var valid = true;
        var state = {};

        var name = this.state.parameterName.trim();
        if (name === '') {
            state.parameterNameError = true;
            state.parameterNameErrorMessage = 'Mandatory';
            valid = false;
        }
        else if (name.length < 3 || name.length > 100) {
            state.parameterNameError = true;
            state.parameterNameErrorMessage = 'Length should be 3-100';
            valid = false;
        }

        //Check if the name is already used.
        if (typeof this.fieldsToCheck !== 'undefined' ** this.fieldsToCheck !== null) {
            for (var i = 0; i < this.fieldsToCheck.length; i++) {
                var f = this.fieldsToCheck[i];
                if (f.name === name) {
                    if (typeof this.currentRecord === 'undefined' || this.currentRecord === null) {
                        state.parameterNameError = true;
                        state.parameterNameErrorMessage = 'Name already used';
                        valid = false;
                        break;
                    }
                    else if (this.currentRecord.sequence !== f.sequence) {
                        state.parameterNameError = true;
                        state.parameterNameErrorMessage = 'Name already used';
                        valid = false;
                        break;
                    }
                }
            }
        }

        switch (this.state.templateParamaterType) {
            case 'numeric': {
                let max = this.state.numericMaxValue;
                if (max === '') {
                    state.numericMaxValueErrorMessage = 'Mandatory';
                    state.numericMaxValueError = true;
                    valid = false;
                }

                let min = this.state.numericMinValue;
                if (min === '') {
                    state.numericMinValueErrorMessage = 'Mandatory';
                    state.numericMinValueError = true;
                    valid = false;
                }

                if (min !== '' && max !== '') {
                    if (min >= max) {
                        state.numericMaxValueErrorMessage = 'Invalid range';
                        state.numericMaxValueError = true;
                        state.numericMinValueErrorMessage = 'Invalid range';
                        state.numericMinValueError = true;
                        valid = false;
                    }
                }

            }
                break;
            case 'decimal': {
                let max = this.state.decimalMaxValue;
                if (max === '') {
                    state.decimalMaxValueErrorMessage = 'Mandatory';
                    state.decimalMaxValueError = true;
                    valid = false;
                }
                else {
                    max = parseFloat(this.state.decimalMaxValue);
                }

                let min = this.state.decimalMinValue;
                if (min === '') {
                    state.decimalMinValueErrorMessage = 'Mandatory';
                    state.decimalMinValueError = true;
                    valid = false;
                }
                else {
                    min = parseFloat(this.state.decimalMinValue);
                }

                if (min !== '' && max !== '') {
                    if (min >= max) {
                        state.decimalMaxValueErrorMessage = 'Invalid range';
                        state.decimalMaxValueError = true;
                        state.decimalMinValueErrorMessage = 'Invalid range';
                        state.decimalMinValueError = true;
                        valid = false;
                    }
                }
            }
                break;
            case 'text': {
                let max = this.state.textMaxLength;
                if (max === '') {
                    state.textMaxLengthErrorMessage = 'Mandatory';
                    state.textMaxLengthError = true;
                    valid = false;
                }

                let min = this.state.textMinLength;
                if (min === '') {
                    state.textMinLengthErrorMessage = 'Mandatory';
                    state.textMinLengthError = true;
                    valid = false;
                }

                if (min !== '' && max !== '') {
                    if (min >= max) {
                        state.textMaxLengthErrorMessage = 'Invalid range';
                        state.textMaxLengthError = true;
                        state.textMinLengthErrorMessage = 'Invalid range';
                        state.textMinLengthError = true;
                        valid = false;
                    }
                }
            }
                break;
            case 'options': {
                if (this.state.optionValues.length === 0) {
                    state.optionsErrorMessage = 'At least one option value is required';
                    valid = false;
                }
            }
        }

        if (!valid) {
            this.setState(state);
            return;
        }

        var def = {
        }

        switch (this.state.templateParamaterType) {
            case 'decimal': {
                def = {
                    max: parseFloat(this.state.decimalMaxValue),
                    min: parseFloat(this.state.decimalMinValue)
                }
            }
                break;
            case 'numeric': {
                def = {
                    max: this.state.numericMaxValue,
                    min: this.state.numericMinValue
                }
            }
                break;
            case 'text': {
                def = {
                    maxLength: this.state.textMaxLength,
                    minLength: this.state.textMinLength
                }
            }
                break;
            case 'options': {
                def = {
                    options: this.state.optionValues
                }
            }

        }
        var v = {};
        if (this.currentRecord != null) {
            v = this.currentRecord;
        }

        v.name = name;
        v.type = this.state.templateParamaterType
        v.def = def;
        v.optional = this.state.optional;

        this.setState({
            dialogOpen: false
        })
        this.callBack(v);

    }

    _handleParameterTypeChange(v) {
            this.setState({
                templateParamaterType: v.target.value
            })
    }

    _addValueClicked() {
        var optionValues = this.state.optionValues;
        var value = this.state.optionsValue.trim();
        if (value === '') {
            return;
        }

        var optionValues = this.state.optionValues;
        var index = -1;
        for (var i = 0; i < optionValues.length; i++) {
            if (optionValues[i] === value) {
                index = i;
                break;
            }
        }

        if (index >= 0) {
            //Already exists.
            return;
        }

        var o = value;
        optionValues.push(o);
        this.setState({
            optionValues: optionValues,
            optionsValue: '',
            optionsErrorMessage: ''
        })
    }

    _removeOptionValue(row) {
        var optionValues = this.state.optionValues;
        var index = -1;
        for (var i = 0; i < optionValues.length; i++) {
            if (optionValues[i] === row) {
                index = i;
                break;
            }
        }

        if (index >= 0) {
            optionValues.splice(index, 1);
            this.setState({
                optionValues: optionValues
            })
        }
    }

    _handleOptionalChange(e) {
        this.setState({
            optional: e.target.checked
        })
    }

    render() {
        const { classes } = this.props;

        return (
            <Dialog onClose={this._onClose.bind(this)} open={this.state.dialogOpen} fullWidth={true} maxWidth="sm">
                <div style={{ padding: 5 }}>
                    <div className={commonClasses.headerBar}>Template paramater</div>
                </div>
                <DialogContent>
                    <div style={{ flexDirection: 'row', display: 'flex', paddingBottom: 10 }}>
                        <div style={{ flex: 1 }}>
                            <form noValidate autoComplete="off">
                                <TextField
                                    required
                                    disabled={this.state.readOnly}
                                    error={this.state.parameterNameError}
                                    id='parameterName'
                                    label="Name"
                                    fullWidth
                                    value={this.state.parameterName}
                                    margin="normal"
                                    helperText={this.state.parameterNameErrorMessage}
                                    onChange={(v) => {
                                        this.setState({
                                            parameterName: v.target.value,
                                            parameterNameError: false,
                                            parameterNameErrorMessage: ''
                                        })
                                    }}
                                />
                                <div>
                                    <FormControl className={classes.formControl} disabled={this.state.readOnly}>
                                        <InputLabel id="templateParamaterTypeLabel">Type</InputLabel>
                                        <Select
                                            labelId="templateParamaterTypeLabel"
                                            id="templateParamaterType"
                                            value={this.state.templateParamaterType}
                                            onChange={this._handleParameterTypeChange.bind(this)}
                                        >
                                            <MenuItem value='numeric'>Numeric</MenuItem>
                                            <MenuItem value='decimal'>Decimal</MenuItem>
                                            <MenuItem value='text'>Text</MenuItem>
                                            <MenuItem value='boolean'>Boolean</MenuItem>
                                            <MenuItem value='options'>Options</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div>
                                    <FormControlLabel disabled={this.state.readOnly}
                                        style={{ marginTop: 20 }}
                                        control={
                                            <Switch
                                                checked={this.state.optional}
                                                onChange={this._handleOptionalChange.bind(this)}
                                                name="checkedB"
                                                color="primary"
                                            />
                                        }
                                        label="Optional"
                                    />
                                </div>
                            </form>
                        </div>
                        <div style={{ width: 1, backgroundColor: '#EEE', marginLeft: 20, marginRight: 20 }}>

                        </div>
                        <div style={{ flex: 1 }}>
                            {(this.state.templateParamaterType === 'options') &&
                                <div>

                                    <form noValidate autoComplete="off">
                                        <div style={{ flexDirection: 'row', display: 'flex' }}>
                                            <TextField
                                                required
                                                disabled={this.state.readOnly}
                                                error={this.state.optionsValueError}
                                                id='optionsValue'
                                                label="Values"
                                                fullWidth
                                                style={{ flex: 1 }}
                                                value={this.state.optionsValue}
                                                margin="normal"
                                                helperText={this.state.optionsValueErrorMessage}
                                                onChange={(v) => {
                                                    this.setState({
                                                        optionsValue: v.target.value,
                                                        optionsValueError: false,
                                                        optionsValueErrorMessage: ''
                                                    })
                                                }}
                                            />
                                            {(!this.state.readOnly) &&
                                                <div
                                                    onClick={this._addValueClicked.bind(this)}
                                                    style={{ alignSelf: 'flex-end', marginLeft: 10, cursor: 'pointer' }}>
                                                    <AddBoxIcon style={{ color: '#303F9F' }} />
                                                </div>
                                            }
                                        </div>
                                    </form>
                                    {this.state.optionsErrorMessage !== ''} {
                                        <div style={{ fontFamily: 'Metropolis-Regular', color: '#FF5252' }}>{this.state.optionsErrorMessage}</div>
                                    }
                                    <div>
                                        {this.state.optionValues.map((row) => {
                                            return (
                                                <div key={row} className={classes.optionValue}><div style={{ flex: 1 }}>{row}</div>
                                                {(!this.state.readOnly) &&
                                                    <div
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => { this._removeOptionValue(row) }}
                                                    >
                                                        <DeleteForeverRoundedIcon style={{ color: '#EF5350' }} />
                                                    </div>
                                        }
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            }

                            {(this.state.templateParamaterType === 'numeric') &&
                                <div>
                                    <form noValidate autoComplete="off">
                                        <div style={{ flexDirection: 'row', display: 'flex' }}>
                                            <NumericTextField
                                                required
                                                disabled={this.state.readOnly}
                                                error={this.state.numericMinValueError}
                                                id='numericMinValue'
                                                label="Min"
                                                fullWidth
                                                style={{ flex: 1 }}
                                                value={this.state.numericMinValue}
                                                margin="normal"
                                                decimal={false}
                                                helperText={this.state.numericMinValueErrorMessage}
                                                onChange={(v) => {
                                                    if (this.state.numericMaxValue !== '') {
                                                        this.setState({
                                                            numericMinValueError: false,
                                                            numericMinValueErrorMessage: '',
                                                            numericMinValue: v == '' ? '' : parseInt(v),
                                                            numericMaxValueError: false,
                                                            numericMaxValueErrorMessage: ''
                                                        })
                                                    }
                                                    else {
                                                        this.setState({
                                                            numericMinValue: v == '' ? '' : parseInt(v),
                                                            numericMinValueError: false,
                                                            numericMinValueErrorMessage: ''
                                                        })
                                                    }
                                                }}
                                            />
                                            <div style={{ width: 20 }}></div>
                                            <NumericTextField
                                                required
                                                disabled={this.state.readOnly}
                                                error={this.state.numericMaxValueError}
                                                id='numericMaxValue'
                                                label="Max"
                                                fullWidth
                                                style={{ flex: 1 }}
                                                value={this.state.numericMaxValue}
                                                margin="normal"
                                                decimal={false}
                                                helperText={this.state.numericMaxValueErrorMessage}
                                                onChange={(v) => {
                                                    if (this.state.numericMinValue !== '') {
                                                        this.setState({
                                                            numericMinValueError: false,
                                                            numericMinValueErrorMessage: '',
                                                            numericMaxValue: v == '' ? '' : parseInt(v),
                                                            numericMaxValueError: false,
                                                            numericMaxValueErrorMessage: ''
                                                        })
                                                    }
                                                    else {
                                                        this.setState({
                                                            numericMaxValue: v == '' ? '' : parseInt(v),
                                                            numericMaxValueError: false,
                                                            numericMaxValueErrorMessage: ''
                                                        })
                                                    }
                                                }}
                                            />


                                        </div>
                                    </form>
                                </div>
                            }


                            {(this.state.templateParamaterType === 'decimal') &&
                                <div>
                                    <form noValidate autoComplete="off">
                                        <div style={{ flexDirection: 'row', display: 'flex' }}>
                                            <NumericTextField
                                                required
                                                disabled={this.state.readOnly}
                                                error={this.state.decimalMinValueError}
                                                id='decimalMinValue'
                                                label="Min"
                                                fullWidth
                                                style={{ flex: 1 }}
                                                value={this.state.decimalMinValue}
                                                margin="normal"
                                                decimal={true}
                                                helperText={this.state.decimalMinValueErrorMessage}
                                                onChange={(v) => {
                                                    if (this.state.decimalMaxValue !== '') {
                                                        this.setState({
                                                            decimalMinValue: v,
                                                            decimalMinValueError: false,
                                                            decimalMinValueErrorMessage: '',
                                                            decimalMaxValueError: false,
                                                            decimalMaxValueErrorMessage: ''
                                                        })
                                                    }
                                                    else {
                                                        this.setState({
                                                            decimalMinValue: v,
                                                            decimalMinValueError: false,
                                                            decimalMinValueErrorMessage: ''
                                                        })
                                                    }
                                                }}
                                            />
                                            <div style={{ width: 20 }}></div>
                                            <NumericTextField
                                                required
                                                disabled={this.state.readOnly}
                                                error={this.state.decimalMaxValueError}
                                                id='decimalMaxValue'
                                                label="Max"
                                                fullWidth
                                                style={{ flex: 1 }}
                                                value={this.state.decimalMaxValue}
                                                margin="normal"
                                                decimal={true}
                                                helperText={this.state.decimalMaxValueErrorMessage}
                                                onChange={(v) => {
                                                    if (this.state.decimalMinValue !== '') {
                                                        this.setState({
                                                            decimalMaxValue: v,
                                                            decimalMaxValueError: false,
                                                            decimalMaxValueErrorMessage: '',
                                                            decimalMinValueError: false,
                                                            decimalMinValueErrorMessage: ''
                                                        })
                                                    }
                                                    this.setState({
                                                        decimalMaxValue: v,
                                                        decimalMaxValueError: false,
                                                        decimalMaxValueErrorMessage: ''
                                                    })
                                                }}
                                            />
                                        </div>
                                    </form>
                                </div>
                            }


                            {(this.state.templateParamaterType === 'text') &&
                                <div>
                                    <form noValidate autoComplete="off">
                                        <div style={{ flexDirection: 'row', display: 'flex' }}>
                                            <NumericTextField
                                                required
                                                disabled={this.state.readOnly}
                                                error={this.state.textMinLengthError}
                                                id='textMinLength'
                                                label="Min length"
                                                fullWidth
                                                style={{ flex: 1 }}
                                                value={this.state.textMinLength}
                                                margin="normal"
                                                decimal={false}
                                                helperText={this.state.textMinLengthErrorMessage}
                                                onChange={(v) => {
                                                    if (this.state.textMaxLength !== '') {
                                                        this.setState({
                                                            textMinLength: v == '' ? '' : parseInt(v),
                                                            textMinLengthError: false,
                                                            textMinLengthErrorMessage: '',
                                                            textMaxLengthError: false,
                                                            textMaxLengthErrorMessage: ''
                                                        })
                                                    }
                                                    else {
                                                        this.setState({
                                                            textMinLength: v == '' ? '' : parseInt(v),
                                                            textMinLengthError: false,
                                                            textMinLengthErrorMessage: ''
                                                        })
                                                    }
                                                }}
                                            />
                                            <div style={{ width: 20 }}></div>
                                            <NumericTextField
                                                required
                                                disabled={this.state.readOnly}
                                                error={this.state.textMaxLengthError}
                                                id='textMaxLength'
                                                label="Max length"
                                                fullWidth
                                                style={{ flex: 1 }}
                                                value={this.state.textMaxLength}
                                                margin="normal"
                                                decimal={false}
                                                helperText={this.state.textMaxLengthErrorMessage}
                                                onChange={(v) => {
                                                    if (this.state.textMinLength !== '') {
                                                        this.setState({
                                                            textMaxLength: v == '' ? '' : parseInt(v),
                                                            textMaxLengthError: false,
                                                            textMaxLengthErrorMessage: '',
                                                            textMinLengthError: false,
                                                            textMinLengthErrorMessage: ''
                                                        })
                                                    }
                                                    else {
                                                        this.setState({
                                                            textMaxLength: v == '' ? '' : parseInt(v),
                                                            textMaxLengthError: false,
                                                            textMaxLengthErrorMessage: ''
                                                        })
                                                    }
                                                }}
                                            />


                                        </div>
                                    </form>
                                </div>
                            }

                        </div>

                    </div>
                    <Divider />
                </DialogContent>
                <DialogActions>
                    <div style={{ paddingTop: 10, flexDirection: 'row', display: 'flex', justifyContent: 'flex-end' }}>
                    {(!this.state.readOnly) &&
                        <Button onClick={this._saveClicked.bind(this)} variant="contained" color="primary">Save</Button>
                    }
                        <Button
                            onClick={this._onClose.bind(this)}
                            variant="contained" color="default" style={{ marginLeft: 20 }}>Close</Button>
                    </div>
                </DialogActions>
            </Dialog>
        )
    }

}

TemplateComponentDialog.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TemplateComponentDialog);