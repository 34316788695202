import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles'

import { Paper, Divider, Button, Dialog, DialogActions, TextField, CircularProgress } from '@material-ui/core'
import AddIcon from '@material-ui/icons/AddRounded';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import OpenInBrowserRoundedIcon from '@material-ui/icons/OpenInBrowserRounded';
import Moment from 'react-moment';
import { Link, useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';

import { NotificationManager } from 'react-notifications';

import PagePath from './components/PagePath'
import HttpHandler from './HttpHandler';
import SearchFieldModal from './components/SearchFieldModal';
import ErrorDialog from './components/ErrorDialog';
import ConfirmationDialog from './components/ConfirmationDialog';
import CampRegister from './CampRegister';
import commonClasses from './ics.module.css'
import CampTeamTypeSearchSelect from './components/CampTeamTypeSearchSelect';
import CampData from './CampData';

const useStyles = makeStyles({
    mainTitle: {
        fontFamily: 'Metropolis-SemiBold',
        color: '#FFF',
        fontSize: 24
    },

    smallCompTitle: {
        fontFamily: 'Metropolis-Regular',
        color: '#FFF'
    },

    smallCompValue: {
        fontFamily: 'Metropolis-Medium',
        color: '#FFF',
        fontSize: 16,
        paddingLeft: 10
    },

    locationTitle: {
        fontFamily: 'Metropolis-Regular',
        color: '#FFF',
        fontSize: 16
    },

    organizer: {
        fontFamily: 'Metropolis-Regular',
        color: '#FFF',
        fontSize: 16
    },

    donorTitle: {
        fontFamily: 'Metropolis-Regular',
        color: '#000',
        fontSize: 16,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },

    donorContainer: {
        marginTop: 2,
        padding: 3,
        paddingLeft: 10,
        backgroundColor: '#EFEFEF',
        fontFamily: 'Metropolis-Regular',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },

    buttonContainer: {
        height: 32,
        width: 32,
        border: '1px solid #1E88E5',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#1E88E5',
        borderRadius: 16,
        marginBottom: 7,
        marginLeft: 5,
        cursor: 'pointer',
        fontSize: 30,
    },

    closeButton: {
        background: '#EF6C00',
        color: '#FFF',
        "&:hover": {
            backgroundColor: "#E65100"
        }
    },

    cancelButton: {
        background: '#B71C1C',
        color: '#FFF',
        "&:hover": {
            backgroundColor: "#B71C1C"
        }
    }


});

const campStatusMapping = {
    'SC': 'Scheduled',
    'RU': 'Running',
    'CA': 'Cancelled',
    'CO': 'Completed'
}



const registeredUserSearchDef = {
    name: {
        title: 'Name',
        align: 'left'
    },
    type: {
        title: 'Type',
        align: 'left',
        renderer: (row, data) => {
            return (
                <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center' }} >
                    {row.type.name}
                </div>
            )
        }
    },
    organization: {
        title: 'Organization'
    }
}


function NewTeamMemberDialog(props) {

    const [state, setState] = useState({
        name: '',
        nameError: false,
        nameErrorMessage: '',
        type: '',
        typeError: false,
        typeErrorMessage: '',
        org: '',
        orgError: false,
        orgErrorMessage: '',
    });


    const _saveClicked = () => {
        var valid = true;
        var myState = { ...state };

        var name = state.name.trim();
        if (name.length === 0) {
            valid = false;
            myState.nameError = true;
            myState.nameErrorMessage = "Mandatory";
        }

        if (name.length > 100) {
            valid = false;
            myState.nameError = true;
            myState.nameErrorMessage = "Maximum 100 characters";
        }


        if (!valid) {
            setState(myState);
            return;
        }

        var req = {
            name: name,
            type: state.type.name,
            typeId: state.type.id,
            registered: false,
            organization: state.org.trim()
        }

        HttpHandler.sendPost('/api/v1/camp/' + props.campId + '/team/new', req, () => {
            NotificationManager.success('Team member assigned to camp', name);
            props.saveDone();
        }, (errorCode, errorMessage) => {
            ErrorDialog.show("Error in assigning team member to camp")
        })
    }

    const _closeClicked = () => {
        props.onClose();
    }


    useEffect(() => {
        setState({
            ...state,
            name: '',
            nameError: false,
            nameErrorMessage: '',
            type: '',
        })
    }, []);


    const _typeSelected = (v) => {
        setState({
            ...state,
            type: v
        })
    }

    return (
        <Dialog open={props.isVisible} onClose={props.onClose}>
            <div style={{ padding: 5, width: 500 }}>
                <div className={commonClasses.headerBar}>Camp Team</div>
            </div>
            <div style={{ width: '100%', padding: 10 }}>
                <form noValidate autoComplete="off">
                    <TextField
                        required
                        error={state.nameError}
                        id='name'
                        label="Name"
                        fullWidth
                        value={state.name}
                        margin="normal"
                        helperText={state.nameErrorMessage}

                        onChange={(v) => {
                            setState({
                                ...state,
                                name: v.target.value,
                                nameError: false,
                                nameErrorMessage: ''
                            })
                        }}
                    />


                    <div style={{ flex: 1, paddingLeft: 0 }}>
                        <CampTeamTypeSearchSelect
                            style={{ flex: 1 }}
                            label="Type"
                            value={state.type}
                            error={state.typeError}
                            helperText={state.typeErrorMessage}
                            typeSelected={_typeSelected}
                        ></CampTeamTypeSearchSelect>
                    </div>

                    <TextField
                        error={state.orgError}
                        id='org'
                        label="Organization"
                        fullWidth
                        value={state.org}
                        margin="normal"
                        helperText={state.orgErrorMessage}
                        inputProps={{ maxLength: 100 }}
                        onChange={(v) => {
                            setState({
                                ...state,
                                org: v.target.value,
                                orgError: false,
                                orgErrorMessage: ''
                            })
                        }}
                    />

                </form>
            </div>

            <Divider style={{}}></Divider>
            <DialogActions>
                <div style={{ paddingTop: 10, flexDirection: 'row', display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        onClick={_saveClicked}
                        variant="contained" color="primary">Save</Button>
                    <Button
                        onClick={_closeClicked}
                        variant="contained" color="default" style={{ marginLeft: 20 }}>Close</Button>
                </div>
            </DialogActions>
        </Dialog>
    )
}

export default function Camp(props) {

    const classes = useStyles();
    var campId = useRef(0);
    const history = useHistory();
    const location = useLocation();


    let isRegisterUi = false
    if (location.state && HttpHandler.USER_TYPE == 'data entry') {
        console.log("props values are", props)
        isRegisterUi = true

    }


    const [state, setState] = useState({
        campName: '',
        campDate: new Date(),
        organizer: '',
        donor: '',
        address: {},
        districtName: '',
        stateName: '',
        regTemplateName: '',
        regTemplateId: -1,
        medicalTemplateName: '',
        campStatus: 'SC',
        medicalTemplateId: -1,
        registrations: [],
        showRegisterUI: isRegisterUi,
        loadingTeamDetails: false,
        teamLoadError: false,
        team: [],
        showAddMemberDialog: false,
        loadingInProgress: true,
        hasAccess: false
    });




    const _getCampDetails = () => {
        HttpHandler.sendGet('/api/v1/camp/' + campId.current, (v) => {
            if (v === null) {
                return;
            }

            var istate = {
                ...state, campName: v.camp.name,
                campDate: new Date(v.camp.campDate),
                address: v.camp.address,
                campStatus: v.camp.status,
                organizer: v.camp.organizer.name,
                donor: v.camp.donor == null ? 'Not assigned' : v.camp.donor.name,
                districtName: v.camp.address.district.name,
                stateName: v.camp.address.state.name,
                regTemplateName: v.camp.regTemplate.name,
                regTemplateId: v.camp.regTemplate.id,
                medicalTemplateName: v.camp.medicalTemplate.name,
                medicalTemplateId: v.camp.medicalTemplate.id,
                loadingInProgress: false,
                hasAccess: true
            };

            _loadCampTeam(istate);

        }, (errorCode, errorMessage) => {
            console.log(errorMessage)
            if (errorCode === 401) {
                setState({ ...state, hasAccess: false, loadingInProgress: false })
            }
        });
    };

    useEffect(() => {
        campId.current = props.match.params.campId;
        _getCampDetails();
    }, []);


    const _submitStartRequest = () => {
        HttpHandler.sendPost('/api/v1/camp/' + campId.current + '/status', { status: 'RU' }, () => {
            NotificationManager.success("Camp started", state.campName);
            setState({ ...state, campStatus: 'RU' })
        }, (errorCode, errorMessage) => {
            ErrorDialog.show("Internal error starting the camp");
        })
    }

    const _startClicked = () => {
        //If start date is not same as current date, then camp start confirmation should be shown.
        if (state.team.length === 0) {
            ErrorDialog.show("Add team member before starting the camp");
        } else {
            var cdate = new Date();
            var cstr = cdate.getFullYear + "" + cdate.getMonth() + "" + cdate.getDate();
            var cdstr = state.campDate.getFullYear + "" + state.campDate.getMonth() + "" + state.campDate.getDate();
            if (cstr !== cdstr) {
                ConfirmationDialog.show('Camp start date is not today. Do you really want to start ?', () => {
                    _submitStartRequest();
                })
            }
            else {
                _submitStartRequest();
            }
        }
    }

    const _cancelClicked = () => {
        ConfirmationDialog.show("Are you sure you want to cancel the camp ?", () => {
            HttpHandler.sendPost('/api/v1/camp/' + campId.current + '/status', { status: 'CA' }, () => {
                NotificationManager.success("Camp cancelled", state.campName);
                setState({ ...state, campStatus: 'CA' })
            }, (errorCode, errorMessage) => {
                ErrorDialog.show("Internal error cancelling the camp");
            })
        })
    }

    const _closeClicked = () => {
        ConfirmationDialog.show("Are you sure you want to close the camp ?", () => {
            HttpHandler.sendPost('/api/v1/camp/' + campId.current + '/status', { status: 'CO' }, () => {
                NotificationManager.success("Camp closed", state.campName);
                setState({ ...state, campStatus: 'CO' })
            }, (errorCode, errorMessage) => {
                ErrorDialog.show("Internal error closing the camp");
            })
        })
    }

    const _registerClicked = () => {
        setState({ ...state, showRegisterUI: true })
    }

    const _closeRegister = () => {
        setState({ ...state, showRegisterUI: false })
    }












    const _onSaveRegisterShowMedicalUi = (recordId) => {

        HttpHandler.sendGet('/api/v1/camp/' + campId.current + '/data/' + recordId + '/register', (d) => {

            history.push({
                pathname: '/ics/camp/' + campId.current + '/data',
                state: { row: { ...(d.general), showMedicalUi: true } }
            })


        }, (errorCode, errorMessage) => {
            NotificationManager.error("Error loading questions and record. Try again or contact administrator.", "Error");
            props.onClose();
        });
    }



    const _onRegisterSave = (reopen = false, recordId = -1) => {
        setState({ ...state, showRegisterUI: false })
        NotificationManager.success("Record saved.", state.surveyName);


        if (reopen && HttpHandler.USER_TYPE !== 'data entry') {
            setTimeout(() => {
                setState({ ...state, showRegisterUI: true })
            }, 500);

        }
        else {
            _onSaveRegisterShowMedicalUi(recordId)

        }
    }

    const _dataClicked = () => {
        history.push('/ics/camp/' + campId.current + '/data');
    }

    const _addRegisteredUserClicked = () => {
        SearchFieldModal.show('/api/v1/campteam/all?', '', registeredUserSearchDef, (v) => {
            if (v !== null) {
                var req = {
                    registered: true,
                    regId: v.id,
                    name: v.name,
                    type: v.type.name,
                    typeId: v.type.id,
                    typeId: v.typeId,
                    organization: v.organization,
                    userId: v.userId
                }
                HttpHandler.sendPost('/api/v1/camp/' + campId.current + '/team/new', req, (d) => {
                    NotificationManager.success('Team member assigned to camp', v.fullName);
                    SearchFieldModal.hide();
                    _loadCampTeam(state);
                }, (errorCode, errorMessage) => {
                    ErrorDialog.show("Error in assigning team member to camp")
                })
            }
        });
    }

    const _addExternalUserClicked = () => {
        setState({ ...state, showAddMemberDialog: true })
    }
    const _loadCampTeam = (istate) => {
        setState({ ...istate, loadingTeamDetails: true, teamLoadError: false });
        HttpHandler.sendGet('/api/v1/camp/' + campId.current + '/team', (d) => {
            setState({ ...istate, loadingTeamDetails: false, team: d.team });
        }, (errorCode, errorConsole) => {
            setState({ ...istate, loadingTeamDetails: false, teamLoadError: true });
        })
    }

    const _addUserDone = () => {
        var istate = { ...state, showAddMemberDialog: false };
        setState(istate);
        _loadCampTeam(istate);
    }

    const _deleteTeamMemberClicked = (row) => {
        ConfirmationDialog.show('Are you sure you want to delete this entry?', () => {
            HttpHandler.sendDelete('/api/v1/camp/' + campId.current + '/team/' + row.id, (d) => {
                NotificationManager.success("Team member removed.", row.name);
                _loadCampTeam(state);
            }, (errorCode, errorMessage) => {
                ErrorDialog.show("There was error in deleting");
            })
        });
    }

    return (
        <div style={{ width: '100%' }}>
            {state.loadingInProgress &&
                <div style={{ paddingTop: 40, color: '#000', fontFamily: 'Metropolis-Regular', fontSize: 15, textAlign: 'center' }}>We are loading the camp details.</div>
            }


            {(((HttpHandler.USER_TYPE !== 'coordinator' && HttpHandler.USER_TYPE !== 'data entry' && HttpHandler.USER_TYPE !== 'screening' && HttpHandler.USER_TYPE !== 'doctor'&& HttpHandler.USER_TYPE !== 'admin') || !state.hasAccess) && state.loadingInProgress === false) &&
                <div style={{ paddingTop: 40, color: '#FF0000', fontFamily: 'Metropolis-Regular', fontSize: 15, textAlign: 'center' }}>You are not authorized to access contents of this page.</div>
            }

            {(((HttpHandler.USER_TYPE === 'coordinator' || HttpHandler.USER_TYPE === 'data entry' || HttpHandler.USER_TYPE === 'screening' || HttpHandler.USER_TYPE === 'doctor'|| HttpHandler.USER_TYPE === 'admin') && state.hasAccess) && state.loadingInProgress === false) &&
                <div>
                    <PagePath path={['Screening Camps', state.campName + " " + (state.campStatus === 'CA' ? "(Cancelled)" : "")]}>
                        {(state.showRegisterUI === false) &&
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                {(state.campStatus !== 'CA') &&
                                    <div style={{ marginRight: 10 }}>
                                        {(state.campStatus === 'RU' && (HttpHandler.USER_TYPE === 'screening' || HttpHandler.USER_TYPE === 'data entry' || HttpHandler.USER_TYPE === 'coordinator')) &&
                                            <Button
                                                color="primary"
                                                onClick={_registerClicked}
                                                variant="contained" style={{ marginRight: 10 }}>Register</Button>

                                        }
                                        {(state.campStatus === 'RU' || state.campStatus === 'CO' || state.campStatus === 'CA') && HttpHandler.USER_TYPE !== 'admin' &&
                                            <Button
                                                onClick={_dataClicked}
                                                variant="contained"
                                                color="primary"
                                                style={{ marginRight: 10 }}>Data</Button>
                                        }
                                        {(state.campStatus === 'SC' && (HttpHandler.USER_TYPE === 'coordinator')) &&

                                            <Button
                                                onClick={_startClicked}
                                                variant="contained" color="primary" style={{ marginRight: 10 }}>Start</Button>


                                        }
                                        {(state.campStatus === 'RU' && HttpHandler.USER_TYPE === 'coordinator') &&
                                            <Button
                                                classes={{ root: classes.closeButton }}
                                                onClick={_closeClicked}
                                                variant="contained" style={{ marginRight: 10 }}>Close Camp</Button>
                                        }
                                        {/* {(state.campStatus === 'SC' && HttpHandler.USER_TYPE === 'coordinator') &&
                                            <Button
                                                onClick={_cancelClicked}
                                                variant="contained" classes={{ root: classes.cancelButton }} >Cancel</Button>
                                        } */}
                                    </div>
                                }
                            </div>
                        }

                    </PagePath>

                    <NewTeamMemberDialog saveDone={_addUserDone} campId={campId.current} isVisible={state.showAddMemberDialog} onClose={() => { setState({ ...state, showAddMemberDialog: false }) }} />

                    <div style={{ marginTop: 30, paddingLeft: 10, flexDirection: 'row', display: 'flex', justifyContent: 'center' }}>
                        {state.showRegisterUI &&
                            <div>
                                <CampRegister
                                    onSave={_onRegisterSave}
                                    onClose={_closeRegister}
                                    campId={campId.current} />
                            </div>
                        }
                        {(state.showRegisterUI === false) &&
                            <div style={{ width: 800, maxWidth: 800 }}>
                                <div style={{ width: '100%' }}>
                                    <div style={{ padding: 10, display: 'flex', flexDirection: 'row', paddingLeft: 0, paddingRight: 0 }}>
                                        <Paper style={{ flex: 2, backgroundColor: '#43A047', padding: 10, display: 'flex', flexDirection: 'column' }}>
                                            <div className={classes.mainTitle} style={{ flex: 1 }}>{state.campName}</div>
                                            <div className={classes.locationTitle} style={{ marginTop: 10 }}>{state.districtName}</div>
                                            <div className={classes.locationTitle}>{state.stateName} </div>
                                        </Paper>
                                        <div style={{ width: 20 }}>

                                        </div>
                                        <Paper style={{ flex: 3, backgroundColor: '#43A047', padding: 10, paddingTop: 10, }}>
                                            <div>
                                                <div style={{ flexDirection: 'row', display: 'flex' }}>
                                                    <div style={{ flex: 1 }}>
                                                        <div className={classes.smallCompTitle}>When</div>
                                                        <div className={classes.smallCompValue}>
                                                            <Moment format="DD-MMM-YYYY">{state.campDate}</Moment>
                                                        </div>
                                                    </div>
                                                    <div style={{ flex: 1 }}>
                                                        <div className={classes.smallCompTitle}>Status</div>
                                                        <div className={classes.smallCompValue}>
                                                            <div>{campStatusMapping[state.campStatus]}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{ flexDirection: 'row', display: 'flex', paddingTop: 10 }}>
                                                    <div style={{ flex: 1 }}>
                                                        <div className={classes.smallCompTitle}>Organizer</div>
                                                        <div className={classes.smallCompValue}>
                                                            <div>{state.organizer}</div>
                                                        </div>
                                                    </div>
                                                    <div style={{ flex: 1 }}>
                                                        <div className={classes.smallCompTitle}>Donor</div>
                                                        <div className={classes.smallCompValue}>
                                                            <div>{state.donor}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{ flexDirection: 'row', display: 'flex', paddingTop: 10 }}>
                                                    <div style={{ flex: 1 }}>
                                                        <div className={classes.smallCompTitle}>Reg. template</div>
                                                        <div className={classes.smallCompValue}>
                                                            <div>{state.regTemplateName}</div>
                                                        </div>
                                                    </div>
                                                    <div style={{ flex: 1 }}>
                                                        <div className={classes.smallCompTitle}>Medical template</div>
                                                        <div className={classes.smallCompValue}>
                                                            <div>{state.medicalTemplateName}</div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </Paper>
                                    </div>
                                </div>

                                <div style={{ width: '100%' }}>
                                    <Paper style={{ padding: 10, width: '100%' }}>
                                        <div style={{ paddingBottom: 10 }}>
                                            <div style={{ fontFamily: 'Metropolis-Medium', paddingLeft: 10, paddingTop: 5, paddingBottom: 5 }}>Camp Team</div>
                                            <Divider />
                                        </div>

                                        {state.loadingTeamDetails &&
                                            <div style={{
                                                paddingTop: 20, paddingBottom: 20,
                                                display: 'flex', fontFamily: 'Metropolis-Medium', fontSize: 15, justifyContent: 'center',
                                                alignItems: 'center'
                                            }}>
                                                <CircularProgress></CircularProgress><div style={{ paddingLeft: 10 }}>Loading camp team details</div>
                                            </div>
                                        }
                                        {(state.loadingTeamDetails === false && state.teamLoadError) &&
                                            <div style={{
                                                paddingTop: 20, paddingBottom: 20,
                                                display: 'flex', fontFamily: 'Metropolis-Medium', fontSize: 15, justifyContent: 'center', color: '#FF0000',
                                                alignItems: 'center'
                                            }}>There was an error in loading team details
                                            </div>
                                        }

                                        {(state.loadingTeamDetails === false && state.teamLoadError === false) &&
                                            <div style={{ fontFamily: 'Metropolis-Regular' }}>
                                                {(HttpHandler.USER_TYPE === 'coordinator') && (state.campStatus === 'RU' || state.campStatus === 'SC') &&
                                                    <div style={{ paddingBottom: 10, display: 'flex', justifyContent: 'flex-end' }}>
                                                        <Button onClick={_addRegisteredUserClicked} variant="contained" color="primary" style={{ marginRight: 10 }}>Add screening team member</Button>
                                                        {/* <Button onClick={_addExternalUserClicked} variant="contained" color="primary">Add other members</Button> */}
                                                    </div>
                                                }
                                                {(state.team.length === 0) &&
                                                    <div style={{
                                                        paddingTop: 20, paddingBottom: 20,
                                                        display: 'flex', fontFamily: 'Metropolis-Regular', fontSize: 15, justifyContent: 'center', color: '#000',
                                                        alignItems: 'center'
                                                    }}>There are no members in the camp team. Please add few.
                                                    </div>
                                                }
                                                {(state.team.length > 0) &&
                                                    <table style={{ width: '100%' }} cellPadding={0} cellSpacing={0}>
                                                        <thead>
                                                            <tr>
                                                                <th style={{ textAlign: 'left', paddingLeft: 10, fontWeight: 'normal', backgroundColor: '#37474F', color: '#FFF', paddingTop: 7, paddingBottom: 7 }}>Name</th>
                                                                <th style={{ paddingLeft: 1, textAlign: 'left', width: 200 }}>
                                                                    <div style={{ paddingLeft: 10, fontWeight: 'normal', backgroundColor: '#37474F', color: '#FFF', paddingTop: 7, paddingBottom: 7 }}>Type</div>
                                                                </th>
                                                                <th style={{ paddingLeft: 1, textAlign: 'left', width: 200 }}>
                                                                    <div style={{ paddingLeft: 10, fontWeight: 'normal', backgroundColor: '#37474F', color: '#FFF', paddingTop: 7, paddingBottom: 7 }}>Organization</div>
                                                                </th>
                                                                <th style={{ paddingLeft: 1, width: 120, textAlign: 'left' }}>
                                                                    <div style={{ paddingLeft: 10, fontWeight: 'normal', backgroundColor: '#37474F', color: '#FFF', paddingTop: 7, paddingBottom: 7 }}>Registetered</div>
                                                                </th>
                                                                {(HttpHandler.USER_TYPE === 'coordinator') && (state.campStatus === 'RU' || state.campStatus === 'SC') &&
                                                                    <th style={{ paddingLeft: 1, width: 50, textAlign: 'left' }}>
                                                                        <div style={{ paddingLeft: 10, fontWeight: 'normal', backgroundColor: '#37474F', color: '#37474F', paddingTop: 7, paddingBottom: 7 }}>Actions</div>
                                                                    </th>
                                                                }
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {state.team.map((row, key) => {
                                                                return (
                                                                    <tr key={key}>
                                                                        <td style={{ paddingBottom: 1, textAlign: 'left' }}>
                                                                            <div style={{ paddingLeft: 10, fontWeight: 'normal', backgroundColor: '#F7F7F7', color: '#000', paddingTop: 7, paddingBottom: 7 }}>{row.name}</div>
                                                                        </td>

                                                                        <td style={{ paddingBottom: 1, textAlign: 'left', width: 200 }}>
                                                                            <div style={{ paddingLeft: 10, fontWeight: 'normal', backgroundColor: '#F7F7F7', color: '#000', paddingTop: 7, paddingBottom: 7 }}>{row.type}</div>
                                                                        </td>
                                                                        {(typeof row.organization === 'undefined' || row.organization === null || row.organization === '') &&
                                                                            <td style={{ paddingBottom: 1, textAlign: 'left', width: 200 }}>
                                                                                <div style={{ paddingLeft: 10, fontWeight: 'normal', backgroundColor: '#F7F7F7', color: '#F7F7F7', paddingTop: 7, paddingBottom: 7 }}>SSSSS</div>
                                                                            </td>
                                                                        }
                                                                        {(typeof row.organization !== 'undefined' && row.organization !== null && row.organization !== '') &&
                                                                            <td style={{ paddingBottom: 1, textAlign: 'left', width: 200 }}>
                                                                                <div style={{ paddingLeft: 10, fontWeight: 'normal', backgroundColor: '#F7F7F7', color: '#000', paddingTop: 7, paddingBottom: 7 }}>{row.organization}</div>
                                                                            </td>
                                                                        }

                                                                        <td style={{ paddingBottom: 1, width: 120, textAlign: 'left' }}>
                                                                            <div style={{ paddingLeft: 10, fontWeight: 'normal', backgroundColor: '#F7F7F7', color: '#000', paddingTop: 7, paddingBottom: 7 }}>{row.registered ? "Yes" : "No"}</div>
                                                                        </td>
                                                                        <td style={{ paddingBottom: 1, overflow: 'hidden' }}>
                                                                            {(HttpHandler.USER_TYPE === 'coordinator') && (state.campStatus === 'RU' || state.campStatus === 'SC') &&
                                                                                <div
                                                                                    onClick={() => _deleteTeamMemberClicked(row)}
                                                                                    style={{
                                                                                        height: '100%', cursor: 'pointer', backgroundColor: '#F7F7F7', paddingTop: 5,
                                                                                        paddingBottom: 5, display: 'flex', justifyContent: 'center'
                                                                                    }}>
                                                                                    <DeleteForeverRoundedIcon style={{ color: '#D32F2F' }}></DeleteForeverRoundedIcon>
                                                                                </div>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>
                                                }
                                            </div>
                                        }

                                    </Paper>
                                </div>

                            </div>
                        }
                    </div>
                </div>
            }
        </div >
    );
}
