import React, { useEffect, useState, useRef } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';

import { Fab, Paper, TextField, Dialog, DialogActions, MenuItem, Divider, Button, FormControl, FormHelperText } from '@material-ui/core';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';

import { DatePicker } from "@material-ui/pickers";
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddIcon from '@material-ui/icons/AddRounded';
import { Link, useHistory } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';

import moment from 'moment';
import Moment from 'react-moment';

import commonClasses from './ics.module.css'
import PagePath from './components/PagePath';
import Utils from './Utils'
import HttpHandler from './HttpHandler';
import ErrorDialog from './components/ErrorDialog';
import CTable from './components/CTable'
import ConfirmationDialog from './components/ConfirmationDialog';


const useStyles = makeStyles({
});

class OrgView extends React.Component {

    state = {
        orgName: '',
        orgAddress: {},
        orgCity: '',
        loadingDetails: true,
        phone: '',
        email: '',
        contact: '',
        type: ''
    }

    _loadDetails() {
        HttpHandler.sendGet('/api/v1/org/' + this.props.orgId, (v) => {
           
            this.setState({
                orgName: v.org.name,
                orgAddress: v.org.address,
                orgCity: v.org.city,
                phone: v.org.phone,
                email: v.org.email,
                contact: v.org.contact,
                type: v.org.type,
                loadingDetails: false
            })
        }, (errorCode, errorMessage) => {
            NotificationManager.error("There was an error in loading details.", "Organization");
            this.props.onClose();
        })
    }

    componentDidMount() {
        this._loadDetails();
    }

    _onClose() {
        this.props.onClose();
    }

    render() {
        return (
            <Dialog open={this.props.isVisible} onClose={this.props.onClose}>
                <div style={{ padding: 5, width: 600 }}>
                    <div className={commonClasses.headerBar}>Organization</div>
                </div>
                <div style={{ width: '100%' }}>
                    {(this.state.loadingDetails === false) &&
                        <div>

                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', paddingTop: 5, paddingBottom: 5 }}>
                                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end', fontFamily: 'Metropolis-Regular' }}>Name</div>
                                <div style={{ paddingLeft: 10, paddingRight: 10 }}>:</div>
                                <div style={{ flex: 2, fontFamily: 'Metropolis-Medium' }}>{this.state.orgName}</div>
                            </div>

                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', paddingTop: 5, paddingBottom: 5 }}>
                                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end', fontFamily: 'Metropolis-Regular' }}>Type</div>
                                <div style={{ paddingLeft: 10, paddingRight: 10 }}>:</div>
                                <div style={{ flex: 2, fontFamily: 'Metropolis-Medium' }}>{this.state.type}</div>
                            </div>

                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', paddingTop: 5, paddingBottom: 5 }}>
                                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end', fontFamily: 'Metropolis-Regular' }}>Address</div>
                                <div style={{ paddingLeft: 10, paddingRight: 10 }}>:</div>
                                <div style={{ flex: 2, fontFamily: 'Metropolis-Medium' }}>
                                    {this.state.orgAddress.address.split("\n").map((i, key) => {
                                        return <div key={key}>{i}</div>;

                                    })}
                                    <div style={{ fontFamily: 'Metropolis-Medium' }}>{this.state.orgCity}</div>
                                    <div style={{ fontFamily: 'Metropolis-Medium' }}>{this.state.orgAddress.district.name}</div>
                                    <div style={{ fontFamily: 'Metropolis-Medium' }}>{this.state.orgAddress.state.name}</div>
                                    <div style={{ fontFamily: 'Metropolis-Medium' }}>{'PIN : ' + this.state.orgAddress.pin}</div>
                                </div>
                            </div>
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', paddingTop: 5, paddingBottom: 5 }}>
                                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end', fontFamily: 'Metropolis-Regular' }}>Contact person</div>
                                <div style={{ paddingLeft: 10, paddingRight: 10 }}>:</div>
                                <div style={{ flex: 2, fontFamily: 'Metropolis-Medium' }}>{this.state.contact}</div>
                            </div>
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', paddingTop: 5, paddingBottom: 5 }}>
                                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end', fontFamily: 'Metropolis-Regular' }}>Phone</div>
                                <div style={{ paddingLeft: 10, paddingRight: 10 }}>:</div>
                                <div style={{ flex: 2, fontFamily: 'Metropolis-Medium' }}>{this.state.phone}</div>
                            </div>

                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', paddingTop: 5, paddingBottom: 5 }}>
                                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end', fontFamily: 'Metropolis-Regular' }}>Email</div>
                                <div style={{ paddingLeft: 10, paddingRight: 10 }}>:</div>
                                <div style={{ flex: 2, fontFamily: 'Metropolis-Medium' }}>{this.state.email}</div>
                            </div>
                        </div>
                    }
                </div>
                <Divider />
                <DialogActions>
                    <div style={{ paddingTop: 10, flexDirection: 'row', display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            onClick={this._onClose.bind(this)}
                            variant="contained" color="default" style={{ marginLeft: 20 }}>Close</Button>
                    </div>
                </DialogActions>
            </Dialog >
        )
    }
}



function OrgCreateEditUI(props) {
    const history = useHistory();
    const classes = useStyles();
    const [state, setState] = useState({
        orgName: '',
        orgNameError: false,
        orgNameErrorMessage: '',
        address: '',
        addressError: false,
        addressErrorMessage: '',
        orgDate: moment(),
        district: -1,
        districtName: '',
        districtError: false,
        districtErrorMessage: '',
        orgState: ' - Select a district - ',
        orgStateError: false,
        orgStateErrorMessage: '',
        orgPIN: '',
        orgPINError: false,
        orgPINErrorMessage: '',
        orgEmail: '',
        orgEmailError: false,
        orgEmailErrorMessage: '',
        orgPhone: '',
        orgPhoneError: false,
        orgPhoneMessage: '',
        orgType: '',
        orgTypeError: false,
        orgTypeErrorMessage: '',
        contactPerson: '',
        contactPersonError: false,
        contactPersonErrorMessage: '',
        surveyCity: '',
        surveyCityError: false,
        surveyCityErrorMessage: ''
    });

    const _userSelected = (v) => {
        setState({
            ...state,
            organizer: v
        })
    }

    const _districtChanged = (v, newValue) => {
        setState({
            ...state,
            district: newValue === null ? -1 : newValue.id,
            districtError: false,
            districtErrorMessage: '',
            orgState: newValue == null ? ' - Select a district - ' : newValue.stateName
        })
    }

    const _onInputChanged = (v, newValue) => {
        setState({ ...state, districtName: newValue })
    }

    const _onPinChanged = (v) => {
        var val = v.target.value;
        if (val.length > 6) {
            val = state.orgPIN
        }
        else if (val.indexOf('.') >= 0) {
            val = state.orgPIN;
        }
        else if (isNaN(val)) {
            val = state.orgPIN;
        }
        setState({
            ...state,
            orgPINErrorMessage: '',
            orgPINError: false,
            orgPIN: val
        });
    }

    const _onPhoneChanged = (v) => {
        var val = v.target.value;
        if (val.length > 11) {
            val = state.orgPhone
        }
        else if (val.indexOf('.') >= 0) {
            val = state.orgPhone;
        }
        else if (isNaN(val)) {
            val = state.orgPhone;
        }
        setState({
            ...state,
            orgPhone: val,
            orgPhoneError: false,
            orgPhoneErrorMessage: ''
        })
    }

    const _saveClicked = () => {

        //Validate.
        var valid = true;
        var myState = { ...state };

        var name = state.orgName.trim();
        if (name.length === 0) {
            valid = false;
            myState.orgNameError = true;
            myState.orgNameErrorMessage = "Mandatory";
        } else if (!Utils.organizationValid(name)) {
            valid = false;
            myState.orgNameError = true;
            myState.orgNameErrorMessage = "Invalid Name";
        }

        var type = state.orgType.trim();
        if (type.length === 0) {
            valid = false;
            myState.orgTypeError = true;
            myState.orgTypeErrorMessage = "Mandatory";
        }

        var contact = state.contactPerson.trim();
        if (contact.length === 0) {
            valid = false;
            myState.contactPersonError = true;
            myState.contactPersonErrorMessage = "Mandatory";
        }

        var address = state.address.trim();
        if (address.length === 0) {
            valid = false;
            myState.addressError = true;
            myState.addressErrorMessage = "Mandatory";
        }
        else if (address.length < 3 || address.length > 500) {
            valid = false;
            myState.addressError = true;
            myState.addressErrorMessage = "Length should be in range 3-500";
        }

        var city = state.surveyCity.trim();
        if (city.length === 0) {
            valid = false;
            myState.surveyCityError = true;
            myState.surveyCityErrorMessage = "City/Town cannot be empty";
        }

        if (state.district === -1) {
            valid = false;
            myState.districtError = true;
            myState.districtErrorMessage = "Mandatory";
        }

        if (state.orgPIN === '') {
            valid = false;
            myState.orgPINError = true;
            myState.orgPINErrorMessage = "Mandatory"
        }
        else if ((state.orgPIN + "").length != 6) {
            valid = false;
            myState.orgPINError = true;
            myState.orgPINErrorMessage = "Invalid PIN"
        }

        var phone = state.orgPhone.trim();
        if (phone.length === 0) {
            myState.orgPhoneError = true;
            myState.orgPhoneErrorMessage = "Mandatory";
            valid = false;
        }
        else if (!Utils.isValidPhone(phone)) {
            valid = false;
            myState.orgPhoneError = true;
            myState.orgPhoneErrorMessage = "Invalid phone";
        }

        var email = state.orgEmail.trim();
        if (email.length > 150 || (email.length !== 0 && !Utils.isValidEmail(email))) {
            myState.orgEmailError = true;
            myState.orgEmailErrorMessage = "Invalid Email";
            valid = false;
        }


        if (!valid) {
            setState(myState);
            return;
        }

        var req = {
            name: name,
            type: type,
            contact: contact,
            address: state.address,
            district: state.district,
            pin: parseInt(state.orgPIN),
            phone: state.orgPhone,
            email: state.orgEmail,
            address: state.address.trim(),
            city: state.surveyCity
        }

        var url = '/api/v1/org/new';
        if (props.orgId > 0) {
            url = '/api/v1/org/' + props.orgId;
        }
        HttpHandler.sendPost(url, req, (data) => {
            if (props.orgId > 0) {
                NotificationManager.success("Organization record updated.", name);
            }
            else {
                NotificationManager.success("Organization record created.", name);
            }
            props.onDone();
        }, (errorCode, errorMessage) => {
            ErrorDialog.show('There was an error in saving the organization details.')
        })

    }

    const _closeDialog = () => {
        props.onClose();
    }

    const _loadOrgDetails = () => {
        HttpHandler.sendGet('/api/v1/org/' + props.orgId, (d) => {
            var istate = {
                ...state,
                orgName: d.org.name,
                address: d.org.address.address,
                orgPIN: d.org.address.pin,
                orgEmail: d.org.email,
                orgPhone: d.org.phone,
                contactPerson: d.org.contact,
                orgType: d.org.type,
                surveyCity: d.org.city !== null ? d.org.city : ''
            }

            setState(istate);
            _setDistrictData(istate, d.org.address.district.id)
        }, (errorCode, errorConsole) => {
            console.log(errorConsole);
        })
    }

    const _setDistrictData = (istate, id) => {
        if (id === null) {
            setState({
                ...istate,
                district: '',
                districtName: '',
                districtError: false,
                districtErrorMessage: '',
                orgState: ' - Select a district - '
            });
            return;
        }
        var len = Utils.statesAndDistricts.length;
        for (var i = 0; i < len; i++) {
            if (Utils.statesAndDistricts[i].id === id) {
                setState({
                    ...istate,
                    district: id,
                    districtName: Utils.statesAndDistricts[i].title,
                    districtError: false,
                    districtErrorMessage: '',
                    orgState: Utils.statesAndDistricts[i].stateName
                });
                return;
            }
        }

    }

    useEffect(() => {

        if (props.orgId > 0) {
            _loadOrgDetails();
        }
        else {
            setState({
                ...state,
                orgName: '',
                orgNameError: false,
                orgNameErrorMessage: '',
                address: '',
                addressError: false,
                addressErrorMessage: '',
                orgDate: moment(),
                district: -1,
                districtName: '',
                districtError: false,
                districtErrorMessage: '',
                orgState: ' - Select a district - ',
                orgStateError: false,
                orgStateErrorMessage: '',
                orgPIN: '',
                orgPINError: false,
                orgPINErrorMessage: '',
                orgEmail: '',
                orgEmailError: false,
                orgEmailErrorMessage: '',
                orgPhone: '',
                orgPhoneError: false,
                orgPhoneMessage: '',
                orgType: '',
                orgTypeError: false,
                orgTypeErrorMessage: '',
                contactPerson: '',
                contactPersonError: false,
                contactPersonErrorMessage: '',
                surveyCity: '',
                surveyCityError: false,
                surveyCityErrorMessage: ''
            })
        }
    }, [props.orgId]);

    return (
        <Paper style={{ padding: 10, width: 500 }}>
            <div className={commonClasses.headerBar}>{props.heading}</div>
            <div style={{ paddingBottom: 20 }}>
                <form noValidate autoComplete="off">
                    <TextField
                        required
                        error={state.orgNameError}
                        id='orgName'
                        label="Name"
                        fullWidth
                        value={state.orgName}
                        margin="normal"
                        inputProps={{ maxLength: 100 }}
                        helperText={state.orgNameErrorMessage}
                        onChange={(v) => {
                            setState({
                                ...state,
                                orgName: v.target.value,
                                orgNameError: false,
                                orgNameErrorMessage: ''
                            })
                        }}
                    />

                    <TextField
                        required
                        error={state.orgTypeError}
                        id='orgType'
                        label="Type"
                        fullWidth
                        value={state.orgType}
                        margin="normal"
                        inputProps={{ maxLength: 100 }}
                        helperText={state.orgTypeErrorMessage}
                        onChange={(v) => {
                            setState({
                                ...state,
                                orgType: v.target.value,
                                orgTypeError: false,
                                orgTypeErrorMessage: ''
                            })
                        }}
                    />

                    <TextField
                        required
                        error={state.contactPersonError}
                        id='contactPerson'
                        label="Contact person"
                        fullWidth
                        value={state.contactPerson}
                        margin="normal"
                        inputProps={{ maxLength: 100 }}
                        helperText={state.contactPersonErrorMessage}
                        onChange={(v) => {
                            setState({
                                ...state,
                                contactPerson: v.target.value,
                                contactPersonError: false,
                                contactPersonErrorMessage: ''
                            })
                        }}
                    />




                    <TextField
                        required
                        error={state.addressError}
                        id='address'
                        label="Address"
                        fullWidth
                        multiline={true}
                        rowsMax={4}
                        rows={4}
                        value={state.address}
                        margin="normal"
                        helperText={state.addressErrorMessage}
                        onChange={(v) => {
                            setState({
                                ...state,
                                address: v.target.value,
                                addressError: false,
                                addressErrorMessage: ''
                            })
                        }}
                    />
                    <TextField
                        required
                        error={state.surveyCityError}
                        id='surveyCity'
                        label="City/Town"
                        fullWidth
                        value={state.surveyCity}
                        margin="normal"
                        helperText={state.surveyCityErrorMessage}
                        onChange={(v) => {
                            setState({
                                ...state,
                                surveyCity: v.target.value,
                                surveyCityError: false,
                                surveyCityErrorMessage: ''
                            })
                        }}
                    />
                    <Autocomplete
                        onChange={_districtChanged}
                        id="orgDistrict"
                        onInputChange={_onInputChanged}
                        getOptionLabel={(v) => v.title}
                        inputValue={state.districtName}
                        options={Utils.statesAndDistricts.map((option) => option)}
                        renderInput={(params) => (
                            <TextField {...params} required label="District" margin="normal"
                                helperText={state.districtErrorMessage}
                                error={state.districtError} />
                        )}
                    />

                    <div style={{ width: '100%', flexDirection: 'row', display: 'flex' }}>
                        <TextField
                            required
                            error={state.orgStateError}
                            id='orgState'
                            label="State"
                            style={{ flex: 1 }}
                            value={state.orgState}
                            margin="normal"
                            InputProps={{
                                readOnly: true,
                            }}
                            helperText={state.orgStateErrorMessage}
                            onChange={(v) => {
                                setState({
                                    ...state,
                                    orgState: v.target.value,
                                    orgStateError: false,
                                    orgStateErrorMessage: ''
                                })
                            }}
                        />
                        <TextField
                            required
                            error={state.orgPINError}
                            id='orgPIN'
                            label="PIN"
                            style={{ width: 100, marginLeft: 20 }}
                            value={state.orgPIN}
                            margin="normal"
                            helperText={state.orgPINErrorMessage}
                            onChange={_onPinChanged}
                        />
                    </div>

                    <div style={{ width: '100%', flexDirection: 'row', display: 'flex' }}>
                        <TextField
                            error={state.orgEmailError}
                            id='orgEmail'
                            label="Email"
                            style={{ flex: 1 }}
                            value={state.orgEmail}
                            margin="normal"
                            helperText={state.orgEmailErrorMessage}
                            onChange={(v) => {
                                setState({
                                    ...state,
                                    orgEmail: v.target.value,
                                    orgEmailError: false,
                                    orgEmailErrorMessage: ''
                                })
                            }}
                        />
                        <TextField
                            required
                            error={state.orgPhoneError}
                            id='orgPhone'
                            label="Phone"
                            style={{ width: 200, marginLeft: 20 }}
                            value={state.orgPhone}
                            margin="normal"
                            helperText={state.orgPhoneErrorMessage}
                            onChange={_onPhoneChanged}
                        />
                    </div>

                    <div style={{ marginTop: 10 }}>

                    </div>
                </form>
            </div>
            <Divider style={{ marginLeft: -10, marginRight: -10 }}></Divider>
            <div style={{ paddingTop: 10, flexDirection: 'row', display: 'flex', justifyContent: 'flex-end' }}>
                <Button onClick={_saveClicked} variant="contained" color="primary">Save</Button>
                <Button
                    onClick={_closeDialog}
                    variant="contained" color="default" style={{ marginLeft: 20 }}>Close</Button>
            </div>

        </Paper>
    )
}



export default function Orgs() {

    const dataTable = useRef(null);
    const classes = useStyles();
    const [state, setState] = useState({
        showCreateOrgDialog: false,
        currentOrgId: -1,
        isOrgViewVisible: false,
        viewOrgId: -1,
        addEditUIHeading: "Create Organization"
    });

    const _showOrgView = (row) => {
        setState({ ...state, isOrgViewVisible: true, viewOrgId: row.id })
    }


    const orgTableDef = [
        {
            title: "Name",
            field: 'name',
            align: 'left',
            renderer: (row, data) => {
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center' }} >
                        <div
                            onClick={() => { _showOrgView(row) }}
                            style={{
                                fontFamily: 'Metropolis-Regular', color: '#4139B9',
                                cursor: 'pointer',
                                borderBottom: '1px solid #4139B9'
                            }}>{row.name}</div>
                    </div>
                )
            }

        },
        {
            title: "Type",
            field: 'type',
            align: 'left',
            renderer: (row, data) => {
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center' }} >
                        {row.type}
                    </div>
                )
            }

        },
        {
            title: "Phone",
            field: 'phone',
            align: 'left',
            renderer: (row, data) => {
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center' }} >
                        {row.phone}
                    </div>
                )
            }

        },
        {
            title: "Email",
            field: 'email',
            align: 'left',
            renderer: (row, data) => {
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center' }} >
                        {row.email}
                    </div>
                )
            }
        },
        {
            title: "",
            field: 'id',
            style: { minWidth: 120, width: 120 },
            renderer: (row, data) => {
                return (
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        {(HttpHandler.USER_TYPE === 'admin') &&
                            <div
                                onClick={() => _editClicked(row)}
                                style={{ height: 24, cursor: 'pointer' }}>
                                <EditRoundedIcon style={{ color: '#03A9F4' }}></EditRoundedIcon>
                            </div>
                        }
                        {(HttpHandler.USER_TYPE === 'admin') &&
                            <div
                                onClick={() => _deleteClicked(row)}
                                style={{ marginLeft: 20, height: 24, cursor: 'pointer' }}>
                                <DeleteForeverRoundedIcon style={{ color: '#D32F2F' }}></DeleteForeverRoundedIcon>
                            </div>
                        }

                    </div>
                )
            }

        }
    ]

    const _editClicked = (row) => {
        setState({ ...state, currentOrgId: row.id, showCreateOrgDialog: true, addEditUIHeading: "Modify Organization" })
    }

    const _deleteClicked = (row) => {
        ConfirmationDialog.show('Are you sure you want to delete this organization?', () => {
            HttpHandler.sendDelete('/api/v1/org/' + row.id, (d) => {
                if (d.alreadyInUse) {
                    ErrorDialog.show(d.ErrMessage);
                    return;
                }
                NotificationManager.success("Organization deleted.", row.name);
                dataTable.current.reload();
            }, (errorCode, errorMessage) => {
                ErrorDialog.show("There was error in deleting organization");
            })
        });
    }

    const _createOrg = () => {
        setState({
            ...state,
            currentOrgId: -1,
            showCreateOrgDialog: true,
            addEditUIHeading: "Create Organization"
        })
    }

    const _closeCreateDialog = () => {
        setState({
            ...state,
            showCreateOrgDialog: false
        })
    }

    const _orgSaved = () => {
        setState({
            ...state,
            showCreateOrgDialog: false
        })
        dataTable.current.reload();
    }

    useEffect(() => {
    }, []);


    const _getOrgList = (cb, offset, limit, searchString) => {
        HttpHandler.sendGet('/api/v1/org/list?offset=' + offset + '&limit=' + limit + '&s=' + searchString, (data) => {
            cb(data.result, data.count)
        }, (errorCode, errorMessage) => {
            console.log(errorMessage);
        })
    }


    const _mOrgViewClose = () => {
        setState({ ...state, isOrgViewVisible: false })
    }


    return (
        <div>
            {(HttpHandler.USER_TYPE !== 'admin' && HttpHandler.USER_TYPE !== 'coordinator') &&
                <div style={{ paddingTop: 40, color: '#FF0000', fontFamily: 'Metropolis-Regular', fontSize: 15, textAlign: 'center' }}>You are not authorized to access contents of this page.</div>
            }
            {(HttpHandler.USER_TYPE === 'admin' || HttpHandler.USER_TYPE === 'coordinator') &&
                <div>
                    <div style={{ marginRight: 20 }}>
                        <PagePath path={['Organization', 'All']}>
                            {(HttpHandler.USER_TYPE === 'admin') &&
                                <Fab
                                    onClick={_createOrg}
                                    color="primary" aria-label="add" size="medium" title="New Organization">
                                    <AddIcon />
                                </Fab>
                            }
                        </PagePath>
                    </div>
                    {state.isOrgViewVisible &&
                        <OrgView
                            orgId={state.viewOrgId}
                            isVisible={state.isOrgViewVisible} onClose={_mOrgViewClose} />
                    }
                    <div style={{ marginTop: 20, paddingBottom: 30 }}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            {(state.showCreateOrgDialog === true) &&
                                <OrgCreateEditUI orgId={state.currentOrgId} heading={state.addEditUIHeading} onClose={_closeCreateDialog} onDone={_orgSaved} />
                            }
                            {(state.showCreateOrgDialog === false) &&
                                <Paper style={{ padding: 10 }}>
                                    <div style={{ width: 800 }}>
                                        <CTable
                                            ref={dataTable}
                                            data={_getOrgList}
                                            columns={orgTableDef}>
                                        </CTable>
                                    </div>
                                </Paper>
                            }
                        </div>
                    </div>
                </div>
            }
        </div >
    );
}
