import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles'

import { Fab, Paper, TextField, Divider, Button, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddRounded';
import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';

import { NotificationManager } from 'react-notifications';
import { Link, useHistory } from 'react-router-dom';
import Moment from 'react-moment';


import PagePath from './components/PagePath';
import commonClasses from './ics.module.css'
import HttpHandler from './HttpHandler';
import ErrorDialog from './components/ErrorDialog';
import FullPageErrorMessage from './components/FullPageErrorMessage';
import CTable from './components/CTable';
import ConfirmationDialog from './components/ConfirmationDialog';

const useStyles = makeStyles({

});


function CreateEditTemplate(props) {

    const classes = useStyles();

    useEffect(() => {
        if (typeof props.name !== 'undefined') {
            setState({
                ...state, templateName: props.name, templateNameError: false,
                templateNameErrorMessage: ''
            }
            )
        }
    }, [props.mode, props.name, props.errorclear]);


    const [state, setState] = useState({
        templateName: '',
        templateNameError: false,
        templateNameErrorMessage: ''
    });

    const _closeDialog = () => {
        props.onClose();
    }

    const _saveClicked = () => {
        var name = state.templateName.trim();
        if (name === '' || name.length < 3 || name.length > 100) {
            setState({
                ...state,
                templateNameError: true,
                templateNameErrorMessage: 'Name should be of 3-100 characters in length'
            });

            return;
        }

        //Check for duplicate
        var req = {
            name: name,
            id: -1,
            type: props.type
        }
        HttpHandler.sendPost('/api/v1/template/duplicate', req, (d) => {
            if (d.duplicate) {
                if(props.name == state.templateName){
                    setState({
                        ...state,
                        templateNameError: true,
                        templateNameErrorMessage: 'No change is done'
                    });
                }
                else{
                    setState({
                        ...state,
                        templateNameError: true,
                        templateNameErrorMessage: 'Name already used'
                    });
                }
                
            }
            else {
                props.onSave(name, props.mode, props.id);
            }
        }, (errorCode, errorMessage) => {

            ErrorDialog.show('Internal error in validaing template.')
        })

    }


    return (
        <Paper style={{ padding: 10, width: 500 }}>
            <div className={commonClasses.headerBar}>Template</div>
            <div style={{ paddingBottom: 20 }}>
                <form noValidate autoComplete="off">
                    <TextField
                        required
                        error={state.templateNameError}
                        id='templateName'
                        label="Template Name"
                        fullWidth
                        value={state.templateName}
                        margin="normal"
                        helperText={state.templateNameErrorMessage}
                        onChange={(v) => {
                            setState({
                                ...state,
                                templateName: v.target.value,
                                templateNameError: false,
                                templateNameErrorMessage: ''
                            })
                        }}
                    />
                </form>
            </div>
            <Divider style={{ marginLeft: -10, marginRight: -10 }}></Divider>
            <div style={{ paddingTop: 10, flexDirection: 'row', display: 'flex', justifyContent: 'flex-end' }}>
                <Button onClick={_saveClicked} variant="contained" color="primary">Save</Button>
                <Button
                    onClick={_closeDialog}
                    variant="contained" color="default" style={{ marginLeft: 20 }}>Close</Button>
            </div>
        </Paper>
    )
}


export default function Templates(props) {
    const classes = useStyles();
    let history = useHistory();
    const _paths = useRef(["Templates"])
    const dataTable = useRef(null);


    const templateTableDef = [
        {
            title: "Name",
            field: 'name',
            align: 'left',
            renderer: (row, data) => {
                let url = "/ics/template/" + row.id;
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center' }} >
                        {(HttpHandler.USER_TYPE === 'coordinator' || HttpHandler.USER_TYPE === 'admin') &&
                            <Link to={url} >{data}</Link>
                        }
                        {!(HttpHandler.USER_TYPE === 'coordinator' || HttpHandler.USER_TYPE === 'admin') &&
                            <div to={url} >{data}</div>
                        }
                    </div>
                )
            }

        },
        {
            title: "Created On",
            field: 'createdOn',
            align: 'left',
            renderer: (row, data) => {
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center' }} >
                        <Moment format="DD-MM-YYYY">{new Date(data)}</Moment>
                    </div>
                )
            }
        }
        ,
        {
            title: "",
            field: 'id',
            style: { minWidth: 120, width: 120 },
            renderer: (row, data) => {
                return (
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        {(HttpHandler.USER_TYPE === 'admin') &&
                            <Tooltip title="Edit name" arrow>
                                <div
                                    onClick={() => _editNameClicked(row)}
                                    style={{ marginLeft: 10, height: 24, cursor: 'pointer' }}>
                                    <EditRoundedIcon style={{ color: '#03A9F4' }}></EditRoundedIcon>
                                </div>
                            </Tooltip>
                        }

                        {(HttpHandler.USER_TYPE === 'admin') &&
                            <Tooltip title="Create a copy" arrow>
                                <div
                                    onClick={() => _duplicateTemplateClicked(row)}
                                    style={{ marginLeft: 10, height: 24, cursor: 'pointer' }}>
                                    <FileCopyRoundedIcon style={{ color: '#03A9F4' }}></FileCopyRoundedIcon>
                                </div>
                            </Tooltip>
                        }

                        {(HttpHandler.USER_TYPE === 'admin') &&
                            <Tooltip title="Delete" arrow>
                                <div
                                    onClick={() => _deleteClicked(row)}
                                    style={{ marginLeft: 10, height: 24, cursor: 'pointer' }}>
                                    <DeleteForeverRoundedIcon style={{ color: '#D32F2F' }}></DeleteForeverRoundedIcon>
                                </div>
                            </Tooltip>
                        }

                    </div>
                )
            }

        }
    ]

    const _confirmDelete = (row) => {
        HttpHandler.sendDelete('/api/v1/template/' + row.id, (d) => {
            NotificationManager.success("Template deleted.", row.name);
            dataTable.current.reload();
        }, (errorCode, errorMessage) => {
            ErrorDialog.show("There was an error deleting the template. Try again later.");
        })
    }

    const _deleteClicked = (row) => {

        //Check if they are being used
        HttpHandler.sendGet('/api/v1/template/' + row.id + '/inuse', (d) => {
            if (d.inuse) {
                ErrorDialog.show("Template is in use. Cannot be deleted.");
            }
            else {
                ConfirmationDialog.show('Are you sure you want to delete this template ?', () => _confirmDelete(row))
            }
        }, (errorCode, errorconsole) => {
            ErrorDialog.show("There was an error checking if template is in use. Try again later.");
        });


    }

    const _duplicateTemplateClicked = (row) => {
        setState({
            ...state,
            showCreateTemplateDialog: true,
            mode: 'duplicate',
            newName: 'Copy of ' + row.name,
            currentId: row.id
        })
    }

    const _editNameClicked = (row) => {
        setState({
            ...state,
            showCreateTemplateDialog: true,
            mode: 'edit',
            newName: row.name,
            currentId: row.id
        })
    }

    const [state, setState] = useState({
        loadTemplateFailed: false,
        templateList: [],
        showCreateTemplateDialog: false,
        mode: 'new',
        newName: '',
        currentId: -1,
        errorclear: false
    });

    useEffect(() => {
        if (props.templateType === 'CA') {
            _paths.current.push('Camp templates');
        }
        else if (props.templateType === 'SU') {
            _paths.current.push('Survey templates');
        }
        else if (props.templateType === 'CM') {
            _paths.current.push('Medical templates');
        }
    }, []);

    const _createTemplate = () => {
        setState({
            ...state,
            newName: '',
            mode: 'new',
            errorclear: !state.errorclear,
            showCreateTemplateDialog: true
        })
    }

    const _onSaveTemplate = (name, mode, id) => {

        if (mode === 'duplicate') {
            HttpHandler.sendPost('/api/v1/template/' + id + '/duplicate', { name: name }, (data) => {
                NotificationManager.success("A copy of template created.", name);
                history.push('/ics/template/' + data.id);
            }, (errorCode, errorMessage) => {
                ErrorDialog.show("There was error in saving the template");
            });
            return;
        }

        if (mode === 'edit') {
            HttpHandler.sendPost('/api/v1/template/' + id + '/updatename', { name: name }, (data) => {
                NotificationManager.success("Template updated.", name);
                setState({ ...state, showCreateTemplateDialog: false })
            }, (errorCode, errorMessage) => {
                ErrorDialog.show("There was error in saving the template");
            });
            return;
        }

        var req = {
            name: name,
            type: props.templateType,
            sections: []
        };



        HttpHandler.sendPost('/api/v1/template/new', req, (data) => {
            NotificationManager.success("Template created.", name);
            history.push('/ics/template/' + data.id);
        }, (errorCode, errorMessage) => {
            ErrorDialog.show("There was error in saving the template");
        })

    }

    const _getTemplateData = (cb, offset, limit, searchString) => {
        setState({
            ...state,
            loadTemplateFailed: false
        })
        HttpHandler.sendGet('/api/v1/template/list?type=' + props.templateType + '&offset=' + offset + "&limit=" + limit + "&s=" + searchString, (data) => {
            cb(data.templates, data.count)
        }, (errorCode, errorMessage) => {
            setState({
                ...state,
                loadTemplateFailed: true
            })
        })
    }

    return (
        <div>
            {(HttpHandler.USER_TYPE !== 'admin' && HttpHandler.USER_TYPE !== 'coordinator') &&
                <div style={{ paddingTop: 40, color: '#FF0000', fontFamily: 'Metropolis-Regular', fontSize: 15, textAlign: 'center' }}>You are not authorized to access contents of this page.</div>
            }
            {(HttpHandler.USER_TYPE === 'admin' || HttpHandler.USER_TYPE === 'coordinator') &&
                <div>
                    <div style={{ marginRight: 20 }}>
                        <PagePath path={_paths.current}>
                            {(HttpHandler.USER_TYPE === 'admin') &&
                                <Fab
                                    disabled={!(HttpHandler.USER_TYPE === 'staff' || HttpHandler.USER_TYPE === 'doctor' || HttpHandler.USER_TYPE === 'admin')}
                                    onClick={_createTemplate}
                                    color="primary" aria-label="add" size="medium" title="New Template">
                                    <AddIcon />
                                </Fab>
                            }
                        </PagePath>
                    </div>
                    <div style={{ marginTop: 20, paddingBottom: 30 }}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            {state.showCreateTemplateDialog &&
                                <CreateEditTemplate
                                    mode={state.mode}
                                    name={state.newName}
                                    id={state.currentId}
                                    type={props.templateType}
                                    onClose={() => {
                                        setState({
                                            ...state,
                                            showCreateTemplateDialog: false
                                        })
                                    }}
                                    onSave={_onSaveTemplate}
                                    errorclear={state.errorclear} />
                            }
                            {(state.showCreateTemplateDialog === false &&
                                state.loadTemplateInProress === false && state.loadTemplateFailed) &&
                                <div style={{
                                    fontFamily: 'Metropolis-Regular', fontSize: 16,
                                    marginTop: 30, display: 'flex', flexDirection: 'column', alignItems: 'center'
                                }}>
                                    <FullPageErrorMessage message="There was an error loading list of templates" />
                                </div>
                            }
                            {(state.showCreateTemplateDialog === false &&
                                state.loadTemplateFailed === false) &&
                                <Paper style={{ padding: 10 }}>
                                    <div style={{ width: 500 }}>
                                        <CTable
                                            ref={dataTable}
                                            data={_getTemplateData}
                                            columns={templateTableDef}>
                                        </CTable>
                                    </div>
                                </Paper>
                            }
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}