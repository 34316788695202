import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles'
import { TextField } from '@material-ui/core'

const styles = theme => ({
});

class NumericTextField extends Component {

    _onChange(e) {
        var v = e.target.value;
        if (v === '' || v.trim === '') {
            this.props.onChange(v);
            return;
        }
        var len = v.length;
        //Trim to see if some spaces are added.
        v = v.trim();
        if (len != v.length) {
            this.props.onChange(this.props.value);
            return;
        }

        if (isNaN(v)) {
            this.props.onChange(this.props.value);
            return;
        }
        console.log(this.props.decimal);
        if (!this.props.decimal) {
            if (v.indexOf('.') >= 0) {
                this.props.onChange(this.props.value);
                return;
            }
        }


        this.props.onChange(v);
    }

    render() {
        return (
            <TextField
                required={this.props.required}
                disabled={this.props.disabled}
                error={this.props.error}
                id={this.props.id}
                label={this.props.label}
                fullWidth={this.props.fullWidth}
                value={this.props.value}
                margin={this.props.margin}
                helperText={this.props.helperText}
                onChange={this._onChange.bind(this)}
            >

            </TextField>
        )
    }
}

NumericTextField.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NumericTextField);