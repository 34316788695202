import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles'

import { Button } from '@material-ui/core';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIosRounded';
import SearchIcon from '@material-ui/icons/SearchRounded';
import SendRoundedIcon from '@material-ui/icons/SendRounded';

const styles = theme => ({
    tableGlobalStyle: {
        fontFamily: 'Metropolis-Regular',
        backgroundColor: '#FFF',
        borderRadius: 3,
        overflow: 'hidden',
        border: '1px solid #EEE',
        width: '100%'
    },

    tableHead: {
        backgroundColor: '#EEE',
    },

    clickableTableRow: {
        cursor: 'pointer'
    },

    generalRow: {

    },

    tableHeadRow: {
        borderRadius: 10,
        overflow: 'hidden'
    },

    tableHeaderCellStyle: {
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 10,
        paddingRight: 10,
        fontFamily: 'Metropolis-Regular',
        minWidth: 100,
        color: '#000',
    },

    tableDataCell: {
        borderTop: '1px solid #DDD',
        height: 38,
        paddingLeft: 10,
        paddingRight: 10,
        display: 'flex',
        justifyContent: 'left'
    },
    paginationContainer: {
        fontFamily: 'Metropolis-Medium',
        display: 'flex',
        flexDirection: 'row',
        lineHeight: '28px',
        alignSelf: 'flex-end'
    },
    pageInputContainer: {
        height: 30,
        border: '1px solid #CCC',
        borderRadius: 3,
        backgroundColor: '#FFF',
        paddingLeft: 5,
        display: 'flex',
        flexDirection: 'row',
        overflow: 'hidden'
    },
    pageInput: {
        fontFamily: 'Metropolis-Regular',
        fontSize: 14,
        outline: 'none',
        borderWidth: 0,
        width: 40
    },
    tableFooter: {
        display: 'flex',
        flexDirection: 'row',
        paddingTop: 10,
        paddingBottom: 10
    },
    tableHeader: {
        display: 'flex',
        flexDirection: 'row',
        paddingTop: 10,
        paddingBottom: 10
    },
    searchBarContainer: {
        height: 28,
        border: '1px solid #CCC',
        borderRadius: 3,
        backgroundColor: '#FFF',
        maxWidth: 200,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        alignContent: 'center',
        marginRight: 10
    },
    searchInput: {
        fontFamily: 'Metropolis-Regular',
        fontSize: 14,
        outline: 'none',
        borderWidth: 0,
        flex: 1,
        marginLeft: 5,
        maxWidth: 170
    },
});



class _CTableHeadRow extends Component {

    render() {
        const { classes } = this.props;
        return (
            <tr>
                {this.props.children}
            </tr>
        )
    }
}
const CTableHeadRow = withStyles(styles)(_CTableHeadRow);

class _CTableRow extends Component {

    _rowClicked() {
        if (typeof this.props.onRowClick !== 'undefined') {
            this.props.onRowClick();
        }
    }

    render() {
        const { classes } = this.props;

        var tableRowClass = this.props.clickableRow ? classes.clickableTableRow : classes.generalRow;
        return (
            <tr
                onClick={this._rowClicked.bind(this)}
                className={tableRowClass}>
                {this.props.children}
            </tr>
        )
    }
}
const CTableRow = withStyles(styles)(_CTableRow);


class _CTableHead extends Component {

    render() {
        const { classes } = this.props;
        return (
            <thead className={classes.tableHead}>
                {this.props.children}
            </thead>
        )
    }
}
const CTableHead = withStyles(styles)(_CTableHead);



class _CTH extends Component {

    render() {
        const { classes } = this.props;
        var headerStyle = Object.assign({},
            {
                textAlign: (typeof this.props.align !== 'undefined' && this.props.align != null) ?
                    this.props.align : 'center'
            },
            this.props.style
        );
        return (
            <th className={classes.tableHeaderCellStyle}
                style={headerStyle}>
                {this.props.children}
            </th>
        )
    }
}
const CTH = withStyles(styles)(_CTH);



class _CTableBody extends Component {

    render() {
        return (
            <tbody>
                {this.props.children}
            </tbody>
        )
    }
}
const CTableBody = withStyles(styles)(_CTableBody);


class _CTableData extends Component {

    render() {
        const { classes } = this.props;
        var cellStyle = Object.assign({},
            {
                textAlign: (typeof this.props.column.align !== 'undefined' && this.props.column.align != null) ?
                    this.props.column.align : 'center'
            },
            this.props.style
        );
        return (
            <td >
                <div className={classes.tableDataCell}
                    style={cellStyle}>
                    {this.props.children}
                </div>
            </td>
        )
    }
}
const CTableData = withStyles(styles)(_CTableData);



class _PaginationBar extends Component {

    render() {
        const { classes } = this.props;
        var totalPageCount = this.props.maxRecords / this.props.pageSize;
        totalPageCount = parseInt(totalPageCount);
        if (this.props.maxRecords % this.props.pageSize > 0) {
            totalPageCount++;
        }
        return (
            <div className={classes.paginationContainer} >
                <Button
                    onClick={this.props.onPrevious}
                    disabled={this.props.actualCurrentPage === 1}
                    style={{ minWidth: 10 }} size="small" variant="contained" color="primary">
                    <ArrowBackIosIcon style={{ height: 20 }}></ArrowBackIosIcon>
                </Button>
                <span style={{ marginLeft: 10, marginRight: 5 }}>Page</span>
                <div className={classes.pageInputContainer}>
                    <input
                        onChange={this.props.onPageChange} onKeyDown={this.props.onPaginationEnter} value={totalPageCount === 0 ? "" : this.props.currentPage} className={classes.pageInput}></input>
                    <div
                        onClick={this.props.onGotoPage}
                        style={{ backgroundColor: '#303F9F', paddingRight: 5, paddingLeft: 5, color: '#FFF', cursor: 'pointer' }}>GO</div>
                </div>
                {(this.props.maxRecords !== -1) &&
                    <span style={{ marginLeft: 5 }}> of {totalPageCount}</span>
                }
                <Button
                    disabled={this.props.actualCurrentPage === totalPageCount}
                    onClick={this.props.onNext}
                    style={{ marginLeft: 10, minWidth: 10 }} size="small" variant="contained" color="primary">
                    <ArrowForwardIosIcon style={{ height: 20 }}></ArrowForwardIosIcon>
                </Button>

            </div>
        )
    }
}

const PaginationBar = withStyles(styles)(_PaginationBar);



class CTable extends Component {

    state = {
        tableData: [],
        maxRecords: 0,
        currentPage: 1,
        currentOpenPage: 1,
        searchString: '',
        actualCurrentPage: 1
    }

    componentDidMount() {
        this.props.data((tableData, totalCount) => {
            this.setState({
                tableData: tableData,
                maxRecords: totalCount
            })
        }, 0, this.props.pageSize, this.state.searchString);
    }

    nextClicked() {
        var totalPageCount = parseInt(this.state.maxRecords / this.props.pageSize);
        if (this.state.maxRecords % this.props.pageSize > 0) {
            totalPageCount++;
        }
        var cp = 1;
        if (this.state.currentPage == "") {
            cp = 1;
        } else if (this.state.currentPage + 1 > totalPageCount) {
            cp = totalPageCount;
        } else {
            cp = parseInt(this.state.currentPage) + 1;
        }

        this.setState({
            currentPage: cp,
            actualCurrentPage: cp
        });
        this.props.data((tableData, totalCount) => {
            this.setState({
                tableData: tableData,
                maxRecords: totalCount
            })
        }, (cp - 1) * this.props.pageSize, this.props.pageSize, this.state.searchString);

    }


    prevClicked() {
        var cp = this.state.currentPage - 1 > 0 ? this.state.currentPage - 1 : 1;

        this.setState({
            currentPage: cp,
            actualCurrentPage: cp
        });
        this.props.data((tableData, totalCount) => {
            this.setState({
                tableData: tableData,
                maxRecords: totalCount
            })
        }, (cp - 1) * this.props.pageSize, this.props.pageSize, this.state.searchString);

    }

    onPageChange(v) {
        const re = /^[0-9\b]+$/;
        var value = v.target.value;
        if (value.trim() === '') {
            this.setState({
                currentPage: ''
            });
            return;
        }

        value = value.trim();
        value = parseInt(value);
        if (re.test(v.target.value)) {
            this.setState({
                currentPage: value
            })
        }
    }

    onPaginationEnter = (event) => {
        if (event.key === "Enter") {
            this.gotoPageClicked();
        }
    }

    gotoPageClicked() {
        //Check if we have enough records to go to page.
        var offset = this.props.pageSize * (this.state.currentPage - 1) - 1;
        var cp = this.state.currentPage;
        if (offset > this.state.maxRecords) {
            cp = parseInt(this.state.maxRecords / this.props.pageSize);
            if (this.state.maxRecords % this.props.pageSize > 0) {
                cp++;
            }
        } else if (cp < 1) {
            cp = 1;
        }
        this.setState({
            currentPage: cp,
            actualCurrentPage: cp
        });
        this.props.data((tableData, totalCount) => {
            this.setState({
                tableData: tableData,
                maxRecords: totalCount
            })
        }, (cp - 1) * this.props.pageSize, this.props.pageSize, this.state.searchString);
    }

    _onSearchFieldChange(v) {
        this.setState({
            searchString: v.target.value
        })
    }

    onSearchFieldEnter = (event) => {
        if (event.key === "Enter") {
            this._searchClicked();
        }
    }

    _searchClicked() {
        this.props.data((tableData, totalCount) => {
            this.setState({
                tableData: tableData,
                maxRecords: totalCount,
                currentPage: totalCount > 0 ? 1 : ''
            })
        }, 0, this.props.pageSize, this.state.searchString);
    }

    reload() {
        this.props.data((tableData, totalCount) => {
            this.setState({
                tableData: tableData,
                maxRecords: totalCount
            })
        }, (this.state.currentPage - 1) * this.props.pageSize, this.props.pageSize, this.state.searchString);
    }

    _renderCell(v) {
        return (
            <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center' }} >{v}
            </div>
        )
    }

    render() {
        const { classes } = this.props;
        return (
            <div style={{}}>
                <div className={classes.tableHeader}>
                    <div style={{ flex: 1, paddingRight: 0 }}>
                        <div className={classes.searchBarContainer}>
                            <input
                                value={this.state.searchString}
                                onChange={this._onSearchFieldChange.bind(this)}
                                onKeyDown={this.onSearchFieldEnter}
                                placeholder='Name'
                                className={classes.searchInput}>
                            </input>
                            <div
                                style={{ flexDirection: 'column', justifyContent: 'center', display: 'flex', cursor: 'pointer' }}
                                onClick={this._searchClicked.bind(this)}
                            >
                                <SearchIcon style={{ color: '#999' }}></SearchIcon>
                            </div>
                        </div>
                    </div>
                    {(this.state.maxRecords > 0) &&
                        <PaginationBar
                            onNext={this.nextClicked.bind(this)}
                            onPrevious={this.prevClicked.bind(this)}
                            onGotoPage={this.gotoPageClicked.bind(this)}
                            onPageChange={this.onPageChange.bind(this)}
                            onPaginationEnter={this.onPaginationEnter}
                            actualCurrentPage={this.state.actualCurrentPage}
                            currentPage={this.state.currentPage} maxRecords={this.state.maxRecords} pageSize={this.props.pageSize}></PaginationBar>
                    }
                </div>
                {(this.state.maxRecords === 0) &&
                    <div style={{ fontFamily: "Metropolis-Regular", textAlign: 'center', paddingTop: 30, paddingBottom: 30 }}>No record available</div>
                }
                {(this.state.maxRecords > 0) &&
                    <table className={classes.tableGlobalStyle} cellPadding={0} cellSpacing={0}>
                        <CTableHead>
                            <CTableHeadRow>
                                {this.props.columns.map((row, key) => {
                                    return (
                                        <CTH style={row.style} align={row.align} key={key}>{row.title}</CTH>
                                    )
                                }
                                )
                                }
                            </CTableHeadRow>
                        </CTableHead>
                        <CTableBody>
                            {this.state.tableData.map((row, rowKey) => {
                                return (
                                    <CTableRow
                                        key={rowKey}
                                        clickableRow={typeof this.props.onRowClick !== 'undefined'}
                                        onRowClick={() => {
                                            if (typeof this.props.onRowClick !== 'undefined') {
                                                this.props.onRowClick(row)
                                            }
                                        }}>
                                        {this.props.columns.map((columHeader, rowColKey) => {
                                            return (
                                                <CTableData className={classes.tableDataCell}
                                                    column={columHeader}
                                                    record={row} key={rowColKey}>
                                                    {typeof columHeader.renderer !== 'undefined' ?
                                                        columHeader.renderer(row, row[columHeader.field]) :
                                                        this._renderCell(row[columHeader.field])}
                                                </CTableData>
                                            )
                                        })
                                        }
                                    </CTableRow>
                                )
                            }
                            )
                            }
                        </CTableBody>


                    </table>
                }
                {(this.state.maxRecords > 0) &&
                    <div className={classes.tableFooter}>
                        <div style={{ flex: 1 }}></div>
                        <PaginationBar
                            onNext={this.nextClicked.bind(this)}
                            onPrevious={this.prevClicked.bind(this)}
                            onGotoPage={this.gotoPageClicked.bind(this)}
                            onPageChange={this.onPageChange.bind(this)}
                            onPaginationEnter={this.onPaginationEnter}
                            actualCurrentPage={this.state.actualCurrentPage}
                            currentPage={this.state.currentPage} maxRecords={this.state.maxRecords} pageSize={this.props.pageSize}></PaginationBar>
                    </div>
                }
            </div>
        )
    }
}

CTable.propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.func.isRequired
};

CTable.defaultProps = {
    paging: false,
    pageSize: 10,
    maxRecords: -1
}

export default withStyles(styles)(CTable);

