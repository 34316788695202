import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles'
import { Switch, TextField, Select, FormControl, InputLabel, MenuItem, FormHelperText, FormControlLabel } from '@material-ui/core'
import NumericTextField from './NumericTextField';
import Utils from '../Utils'

const styles = theme => ({
});


/*
  This class is just a wrapper around individual components to make it easy to render the template components from the defintion.
*/


class TemplateField extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fieldError: false,
            fieldErrorMessage: '',
            fieldValue: ''
        }

        if (typeof props.value !== 'undefined') {
            this.state.fieldValue = props.value;
        }
        else if (props.def.type === 'boolean') {
            this.state.fieldValue = false;
        }
        else if (props.def.type === 'options') {
            this.state.fieldValue = '*';
        }

    }

    _textFeldOnChange(e) {
        var v = e.target.value;
        if (v.length > this.props.def.def.maxLength) {
            return;
        }
        this.setState({
            fieldErrorMessage: '',
            fieldError: false,
            fieldValue: v
        })
    }

    _numericFieldValueChange(v) {
        this.setState({
            fieldErrorMessage: '',
            fieldError: false,
            fieldValue: v == '' ? '' : parseInt(v),
        })
    }

    _decimalFieldValueChange(v) {
        this.setState({
            fieldErrorMessage: '',
            fieldError: false,
            fieldValue: v,
        })
    }

    _selectFieldChange(v) {
        this.setState({
            fieldErrorMessage: '',
            fieldError: false,
            fieldValue: v.target.value
        })
    }

    _switchFieldSelected(e) {
        this.setState({
            fieldValue: e.target.checked,
            fieldErrorMessage: '',
            fieldError: false,
        });
    }

    componentDidMount() {
        console.log("gender", this.props.value)
        var value = typeof this.props.value === 'undefined' ? (this.props.def.type === 'boolean' ? false :  '') : this.props.value;
        if(this.props.def.type === 'options' && (typeof value === 'undefined' || value === '')) {
            value = '*';
        }
        if(this.props.def.type === 'boolean' && (typeof value === 'undefined' || value === '')) {
            value = false   ;
        }
        this.setState({
            fieldValue : value
        })
    }

    validate() {
        var valid = true;
        var name = this.props.name
        var gender = this.props.gender
        var def = this.props.def;
        var state = {};
        if(typeof this.state.fieldValue === 'undefined') {
            this.state.fieldValue = '';
        }
        switch (def.type) {
            case 'text': {
                if( name == "QUESTIONS FOR WOMEN ONLY" && gender == "M" && this.state.fieldValue.length != 0){
                    state.fieldError = true;
                    state.fieldErrorMessage = 'This field is for Women only';
                    valid = false;
                }
               else if (def.def.minLength > 0 && this.state.fieldValue.length === 0 && def.optional === false) {
                    state.fieldError = true;
                    state.fieldErrorMessage = 'Mandatory';
                    valid = false;
                }
                else if (def.def.minLength > 0 && this.state.fieldValue.length < def.def.minLength && def.optional === false) {
                    state.fieldError = true;
                    state.fieldErrorMessage = 'Min length ' + def.def.minLength;
                    valid = false;
                }
                else if (this.state.fieldValue.length > 0 && def.def.minLength > 0 && this.state.fieldValue.length < def.def.minLength && def.optional === true) {
                    state.fieldError = true;
                    state.fieldErrorMessage = 'Min length ' + def.def.minLength;
                    valid = false;
                }
                else{
                    state.fieldError = false;
                    state.fieldErrorMessage = '';
                }
            }
                break;

            case 'numeric':
            case 'decimal':
                    if (typeof this.state.fieldValue !== 'undefined' && this.state.fieldValue !== null && this.state.fieldValue !== '') {
                        if( name == "QUESTIONS FOR WOMEN ONLY" && gender == "M"){
                            state.fieldError = true;
                            state.fieldErrorMessage = 'This field is for Women only';
                            valid = false;
                        } 
                        else if (this.state.fieldValue > def.def.max || this.state.fieldValue < def.def.min) {
                            state.fieldError = true;
                            state.fieldErrorMessage = 'Range :  ' + def.def.min + ' - ' + def.def.max;
                            valid = false;
                        }
                        else{
                            state.fieldError = false;
                            state.fieldErrorMessage = '';
                        }
                    }
                    else if (def.optional === false) {
                        state.fieldError = true;
                        state.fieldErrorMessage = 'Mandatory';
                        valid = false;

                    }
                    else{
                        state.fieldError = false;
                        state.fieldErrorMessage = '';
                    }
                break;
            case 'options': {
                console.log(this.state.fieldValue );
               
                if (this.state.fieldValue === '') {
                    if( name == "QUESTIONS FOR WOMEN ONLY" && gender == "M"){
                        state.fieldError = true;
                        state.fieldErrorMessage = 'This field is for Women only';
                        valid = false;
                    }
                    else{
                    state.fieldError = true;
                    state.fieldErrorMessage = 'Invalid option';
                    valid = false;
                    }
                }
                if (this.state.fieldValue === '*' && def.optional === false) {
                    state.fieldError = true;
                    state.fieldErrorMessage = 'Mandatory';
                    valid = false;
                }
            }
            break;
            case 'boolean':{
                if( name == "QUESTIONS FOR WOMEN ONLY" && gender == "M" && this.state.fieldValue == true ){
                    state.fieldError = true;
                    state.fieldErrorMessage = 'This field is for Women only';
                    valid = false;
                }else{
                    state.fieldError = false;
                    state.fieldErrorMessage = '';
                }
            }
        }
        
        this.setState(state)
        return valid;
    }

    getValue() {
        if(this.props.def.type === 'options' && this.state.fieldValue === '*') {
            return null;
        }
        else if(this.props.def.type === 'decimal' && this.state.fieldValue !== '') {
            return parseFloat(this.state.fieldValue);
        }
        return this.state.fieldValue;
    }

    

    render() {
        var def = this.props.def;
        const { classes } = this.props;
        switch (def.type) {
            case 'text': {
                return (
                    <TextField
                        required={!this.props.def.optional}
                        error={this.state.fieldError}
                        id={Utils.generateIdFromName(def.name)}
                        label={def.name}
                        fullWidth={true}
                        value={typeof this.state.fieldValue === 'undefined' || typeof this.state.fieldValue === 'boolean' ? '' : this.state.fieldValue}
                        helperText={this.state.fieldErrorMessage}
                        onChange={this._textFeldOnChange.bind(this)}
                    ></TextField>
                )
            }
            case 'numeric': {
                return (
                    <NumericTextField
                        required={!this.props.def.optional}
                        error={this.state.fieldError}
                        id={Utils.generateIdFromName(def.name)}
                        decimal={false}
                        label={def.name}
                        fullWidth={true}
                        value={typeof this.state.fieldValue === 'undefined' || typeof this.state.fieldValue === 'boolean' ? '' : this.state.fieldValue}
                        helperText={this.state.fieldErrorMessage}
                        onChange={this._numericFieldValueChange.bind(this)}
                    ></NumericTextField>
                )
            }
            case 'decimal': {
                return (
                    <NumericTextField
                        required={!this.props.def.optional}
                        error={this.state.fieldError}
                        id={Utils.generateIdFromName(def.name)}
                        decimal={true}
                        label={def.name}
                        fullWidth={true}
                        value={typeof this.state.fieldValue === 'undefined' || typeof this.state.fieldValue === 'boolean' ? '' : this.state.fieldValue}
                        helperText={this.state.fieldErrorMessage}
                        onChange={this._decimalFieldValueChange.bind(this)}
                    ></NumericTextField>
                )
            }
            case 'options': {
                return (
                    <FormControl style={{width:'100%'}} required={!this.props.def.optional} className={classes.formControl} error={this.state.fieldError} >
                        <InputLabel id={'o' + Utils.generateIdFromName(def.name) + '-labelid'}>{def.name}</InputLabel>
                        <Select
                            labelId={'o' + Utils.generateIdFromName(def.name) + '-labelid'}
                            id={'o' + Utils.generateIdFromName(def.name) + '-id'}
                            value={typeof this.state.fieldValue === 'undefined' ? '*' : this.state.fieldValue}
                            style={{ minWidth: 100 }}
                            onChange={this._selectFieldChange.bind(this)}
                        >
                            <MenuItem value='*'><em>None</em></MenuItem>
                            {def.def.options.map((row) => {
                                return (
                                    <MenuItem key={row} value={row}>{row}</MenuItem>
                                )
                            })}

                        </Select>
                        <FormHelperText>{this.state.fieldErrorMessage}</FormHelperText>
                    </FormControl>
                )
            }
            case 'boolean': {
                return (
                    <FormControl error={this.state.fieldError}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={typeof this.state.fieldValue === 'undefined' ? false : this.state.fieldValue}
                                    onChange={this._switchFieldSelected.bind(this)}
                                    name={def.name}
                                    color="primary"
                                />
                            }
                            label={def.name}
                        /> 
                        <FormHelperText>{this.state.fieldErrorMessage}</FormHelperText>
                    </FormControl>

                )
            }
            default: {
                return (
                    <div>
                        Invalid type.
                    </div>
                )
            }

        }
    }
}

TemplateField.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TemplateField);