import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles'

import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';

const styles = theme => ({
    errorMessageContainer: {
        fontFamily: 'Metropolis-Regular',
        fontSize: 14
    },
    closeButtonContainer: {
        marginTop: 10,
        backgroundColor: '#EF5350',
        width: 300,
        height: 50,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        fontFamily: 'Metropolis-Medium',
        color: '#FFF'
    }
});


class DataValue extends Component {


    render() {
        return (
            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', paddingTop: 5, paddingBottom: 5 }}>
                <div style={{ flex: 1, fontFamily: 'Metropolis-Regular', fontSize: 14, textAlign: 'right' }}>{this.props.title}</div>
                <div style={{ fontFamily: 'Metropolis-Regular', fontSize: 14, textAlign: 'right', paddingLeft: 5, paddingRight: 5 }}>:</div>
                {(typeof this.props.booleanValue !== 'undefined') &&
                    <div style={{ flex:1, display:'flex', alignItems:'center'}}>
                        {this.props.booleanValue &&
                            <CheckRoundedIcon style={{color:'#4CAF50', height:20, width:20}} />
                        }

                        {(this.props.booleanValue === false) &&
                            <ClearRoundedIcon style={{color:'#F44336', height:20, width:20}} />
                        }
                    </div>
                }

                {(typeof this.props.value !== 'undefined') &&
                    <div style={{ flex: 1, fontFamily: 'Metropolis-Medium', fontSize: 14, textAlign: 'left' }}>{this.props.value} </div>
                }
            </div>
        )
    }
}

DataValue.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DataValue);

