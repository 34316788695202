import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';

const styles = theme => ({
    errorMessageContainer: {
        fontFamily: 'Metropolis-Regular',
        fontSize: 14
    },
    closeButtonContainer: {
        backgroundColor: '#FFF',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        fontFamily: 'Metropolis-Medium',
        color: '#000',
        flex: 1
    }
});


class ConfirmationDialog extends Component {

    static currentRef = null;

    onAccept = null;
    state = {
        dialogOpen: false
    }

    showDialog(message, onAccept) {
        this.setState({
            dialogOpen: true,
            message: message
        });

        this.onAccept = onAccept;
    }

    static show(message, onAccept) {
        ConfirmationDialog.currentRef.showDialog(message, onAccept);
    }

    componentDidMount() {
        ConfirmationDialog.currentRef = this;
    }

    _closeClicked() {
        this.setState({
            dialogOpen: false
        })
    }

    _confirmClicked() {
        this.onAccept();
        this.setState({
            dialogOpen: false
        })
    }

    render() {
        const { classes } = this.props;
        return (
            <Dialog onClose={this.props.dialogClosed} open={this.state.dialogOpen}>
                <DialogContent style={{ width: 300, alignItems: 'center', display: 'flex', flexDirection: 'column', paddingBottom:20 }}>
                    <WarningRoundedIcon style={{ width: 50, height: 50, color: '#FFC107', marginBottom: 20 }} />
                    <div className={classes.errorMessageContainer}>
                        <span style={{ wordWrap: 'normal' }}>{this.state.message}</span>
                    </div>
                </DialogContent>
                <div style={{ display: 'flex', flexDirection: 'row', borderTop: '1px solid #DDD', height:50 }}>
                    <div
                        onClick={this._confirmClicked.bind(this)}
                        className={classes.closeButtonContainer}>
                        <span>YES</span>
                    </div>
                    <div style={{ width: 1, height: '100%', backgroundColor: '#EEE' }}></div>
                    <div
                        onClick={this._closeClicked.bind(this)}
                        className={classes.closeButtonContainer}>
                        <span>NO</span>
                    </div>
                </div>
                
            </Dialog>
        )
    }
}


ConfirmationDialog.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ConfirmationDialog);
