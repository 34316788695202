import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles'
import PersonRoundedIcon from '@material-ui/icons/PersonRounded';
import { Paper, Tooltip, Fab } from '@material-ui/core'
import Moment from 'react-moment';
import { Link, useHistory } from 'react-router-dom';
import PagePath from './components/PagePath'
import HttpHandler from './HttpHandler';
import CTable from './components/CTable'
import LocalHospitalRoundedIcon from '@material-ui/icons/ControlPoint';

const useStyles = makeStyles({
    mainTitle: {
        fontFamily: 'Metropolis-SemiBold',
        color: '#FFF',
        fontSize: 24
    },

    smallCompTitle: {
        fontFamily: 'Metropolis-Regular',
        color: '#FFF'
    },

    smallCompValue: {
        fontFamily: 'Metropolis-Medium',
        color: '#FFF',
        fontSize: 16,
        // paddingLeft: 10
    },

    locationTitle: {
        fontFamily: 'Metropolis-Regular',
        color: '#FFF',
        fontSize: 16
    },

});

const campStatusMapping = {
    'SC': 'Scheduled',
    'RU': 'Running',
    'CA': 'Cancelled',
    'CO': 'Completed'
}
const cancerTypeMapping = {
    'OR': 'Oral',
    'BR': 'Breast',
    'CE': 'Cervical',
    'OT': 'Others',
    'NO': 'None'
}
const counsellingTypeMapping = {
    'FI': 'Financial',
    'RE': 'Refusal',
    'FE': 'Fear',
    'OT': 'Others',
    'TC': 'Tobacco Cessation Session'
}

export default function Camp(props) {

    const classes = useStyles();
    var campId = useRef(0);
    const history = useHistory();
    const dataTable = useRef(null);


    const [state, setState] = useState({
        campName: '',
        campDate: new Date(),
        organizer: '',
        donor: '',
        address: {},
        districtName: '',
        stateName: '',
        regTemplateName: '',
        regTemplateId: -1,
        medicalTemplateName: '',
        campStatus: 'SC',
        medicalTemplateId: -1,
        registrations: [],
        showRegisterUI: false,
        loadingTeamDetails: false,
        teamLoadError: false,
        team: [],
        showAddMemberDialog: false,
        loadingInProgress: true,
        hasAccess: false,
        someDate: new Date(),
    });
    

    
    const _showDetailsClicked = (id) => {
        history.push('/ics/camp/' + props.match.params.campId + '/data/' + id);
    }

    

    const _getCampDetails = () => {
        HttpHandler.sendGet('/api/v1/camp/' + campId.current, (v) => {
            if (v === null) {
                return;
            }

            var istate = {
                ...state, campName: v.camp.name,
                campDate: new Date(v.camp.campDate),
                address: v.camp.address,
                campStatus: v.camp.status,
                organizer: v.camp.organizer.name,
                donor: v.camp.donor == null ? 'Not assigned' : v.camp.donor.name,
                districtName: v.camp.address.district.name,
                stateName: v.camp.address.state.name,
                regTemplateName: v.camp.regTemplate.name,
                regTemplateId: v.camp.regTemplate.id,
                medicalTemplateName: v.camp.medicalTemplate.name,
                medicalTemplateId: v.camp.medicalTemplate.id,
                loadingInProgress: false,
                hasAccess: true
            };

            setState({ ...istate })

        }, (errorCode, errorMessage) => {
            console.log(errorMessage)
            if (errorCode === 401) {
                setState({ ...state, hasAccess: false, loadingInProgress: false })
            }
        });
    };

    useEffect(() => {
        campId.current = props.match.params.campId;
        _getCampDetails();
    }, []);

    const _getCounsellingData = (cb, offset, limit, searchString) => {
        HttpHandler.sendGet('/api/v1/camp/' + props.match.params.campId + '/CounsellingCaseData?offset=' + offset + '&limit=' + limit + '&s=' + searchString, (data) => {
            cb(data.data, data.count)

            HttpHandler.sendGet('/api/v1/camp/' + props.match.params.campId, (d) => {
                setState({
                    ...state,
                    campStatus: d.camp.status,
                });
            }, (ec, em) => {
                console.log(em);
            });

        }, (errorCode, errorMessage) => {
            console.log(errorMessage);
            if (errorCode === 401) {
                setState({ ...state, hasAccess: false });
            }
        })
    }

    const _getCampData = (cb, offset, limit, searchString) => {
        HttpHandler.sendGet('/api/v1/camp/' + props.match.params.campId + '/probableCaseData?offset=' + offset + '&limit=' + limit + '&s=' + searchString, (data) => {
            cb(data.data, data.count)

            HttpHandler.sendGet('/api/v1/camp/' + props.match.params.campId, (d) => {
                setState({
                    ...state,
                    campStatus: d.camp.status,
                });
            }, (ec, em) => {
                console.log(em);
            });

        }, (errorCode, errorMessage) => {
            console.log(errorMessage);
            if (errorCode === 401) {
                setState({ ...state, hasAccess: false });
            }
        })
    }

    const _showProbableDetailsCliced = (row) => {
        history.push('/ics/probableCase/' + row.id + '/followupInvestigation');
    }
    const _showCounsellingDetailsCliced = (row) => {
        history.push('/ics/probableCase/' + row.id + '/followupCounselling');
    }


    const campDataTableDef = [
        {
            title: "Reg No.",
            field: "regNum",
            align: 'left',
            style: { minWidth: 140, width: 140 },
            renderer: (row, data) => {
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'left' }} >
                        {row.regNum}
                    </div>
                )
            }
        },
        {
            title: "Name",
            field: 'name',
            align: 'left',
            style: { minWidth: 250, width: 250 },
            renderer: (row, data) => {
                return (
                    <div
                        onClick={() => { _showProbableDetailsCliced(row) }}
                        style={{ flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'left' }}>
                        <p style={{
                            fontFamily: 'Metropolis-Regular', color: '#4139B9',
                            cursor: 'pointer',
                            borderBottom: '1px solid #4139B9',
                        }}>{row.name}</p></div>
                )
            }

        },

        // {
        //     title: "Probable Cancer Ribbon",
        //     field: "probableCancer",
        //     align: 'left',
        //     style: { minWidth: 80, width: 80 },
        //     renderer: (row, data) => {
        //         return (
        //             <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        //                 <Tooltip title="Probable case" arrow>
        //                 <div
        //                     onClick={() => _showProbableDetailsCliced(row)}
        //                     style={{ marginLeft:15, height: 24, cursor: 'pointer' }}>
        //                     <img src={require('./images/cancer_probable.png')} style={{ height: 24, width: 24 }}></img>
        //                 </div>
        //                 </Tooltip>
        //             </div>
        //         )
        //     }
        // },
        {
            title: "Probable Cancer Type",
            field: 'probableType',
            align: 'left',
            style: { minWidth: 120, width: 120 },
            renderer: (row, data) => {
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'right' }} >
                        {cancerTypeMapping[row.cancerType]}
                    </div>
                )
            }

        },
        {
            title: "Test Status",
            field: 'status',
            align: 'left',
            style: { minWidth: 100, width: 100 },
            renderer: (row, data) => {
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'left' }} >
                        {row.testStatus}
                    </div>
                )
            }

        },
        {
            title: "",
            field: 'id',
            style: { minWidth: 100, width: 100 },
            renderer: (row, data) => {
                return (
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <Tooltip title="Person Details" arrow>
                            <div
                                onClick={() => _showDetailsClicked(row.id)}
                                style={{ height: 24, cursor: 'pointer' }}>
                                <PersonRoundedIcon style={{ color: '#03A9F4' }}></PersonRoundedIcon>
                            </div>
                        </Tooltip>
                    </div>
                )}
        }
    ]
    const campCouncellingTableDef = [
        {
            title: "Reg No.",
            field: "regNum",
            align: 'left',
            style: { minWidth: 140, width: 140 },
            renderer: (row, data) => {
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'left' }} >
                        {row.regNum}
                    </div>
                )
            }
        },

        {
            title: "Name",
            field: 'name',
            align: 'left',
            style: { minWidth: 250, width: 250 },
            renderer: (row, data) => {
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'left' }} >
                        <Link to={'/ics/probableCase/' + row.id + '/followupCounselling'}>{row.name}</Link>
                    </div>
                )
            }

        },

        // {
        //     title: "Counselling Required Indicator",
        //     field: "indicator",
        //     align: 'left',
        //     style: { minWidth: 80, width: 80 },
        //     renderer: (row, data) => {
        //         return (
        //             <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        //                 <Tooltip title="Counselling Required" arrow>
        //                 <div
        //                     onClick={() => _showCounsellingDetailsCliced(row)}
        //                     style={{ marginLeft:15, height: 24, cursor: 'pointer' }}>
        //                     <LocalHospitalRoundedIcon style={{fontSize: 30 , color: 'yellow'}} />
        //                 </div>
        //                 </Tooltip>
        //             </div>
        //         )
        //     }
        // },
        {
            title: "Counselling Type",
            field: 'counsellingType',
            align: 'left',
            style: { minWidth: 150, width: 150 },
            renderer: (row, data) => {
                return (
                    <div style={{ flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'right' }} >
                        {counsellingTypeMapping[row.counsellingType]}
                    </div>
                )
            }

        },
        {
            title: "Counselling Status",
            field: 'counsellingstatus',
            align: 'left',
            style: { minWidth: 100, width: 100 },
            renderer: (row, data) => {
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'left' }} >
                        {row.counsellingStatus}
                    </div>
                )
            }

        },
        {
            title: "",
            field: 'id',
            style: { minWidth: 100, width: 100 },
            renderer: (row, data) => {
                return (
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <Tooltip title="Person Details" arrow>
                            <div
                                onClick={() => _showDetailsClicked(row.id)}
                                style={{ height: 24, cursor: 'pointer' }}>
                                <PersonRoundedIcon style={{ color: '#03A9F4' }}></PersonRoundedIcon>
                            </div>
                        </Tooltip>
                    </div>
                )}
        }
    ]

    return (
        <div style={{ width: '100%' }}>
            {state.loadingInProgress &&
                <div style={{ paddingTop: 40, color: '#000', fontFamily: 'Metropolis-Regular', fontSize: 15, textAlign: 'center' }}>We are loading the camp details.</div>
            }
            {(((HttpHandler.USER_TYPE !== 'coordinator' && HttpHandler.USER_TYPE !== 'screening' && HttpHandler.USER_TYPE !== 'doctor') || !state.hasAccess) && state.loadingInProgress === false) &&
                <div style={{ paddingTop: 40, color: '#FF0000', fontFamily: 'Metropolis-Regular', fontSize: 15, textAlign: 'center' }}>You are not authorized to access contents of this page.</div>
            }
            {(((HttpHandler.USER_TYPE === 'coordinator' || HttpHandler.USER_TYPE === 'screening' || HttpHandler.USER_TYPE === 'doctor') && state.hasAccess) && state.loadingInProgress === false) &&
                <div>
                    <PagePath path={[props.followUpType == 'IN' ? 'Follow Up Investigation' : 'Follow Up Counselling', state.campName + " " + (state.campStatus === 'CA' ? "(Cancelled)" : "")]}>

                    </PagePath>
                    <div style={{ marginTop: 30, paddingLeft: 10, flexDirection: 'row', display: 'flex', justifyContent: 'center' }}>

                        {(state.showRegisterUI === false) &&
                            <div style={{ width: 800, maxWidth: 800 }}>
                                <div style={{ width: '100%' }}>
                                    <div style={{ padding: 10, display: 'flex', flexDirection: 'row', paddingLeft: 0, paddingRight: 0 }}>
                                        <Paper style={{ flex: 2, backgroundColor: '#43A047', padding: 10, display: 'flex', flexDirection: 'column' }}>
                                            <div className={classes.mainTitle} style={{ flex: 1 }}>{state.campName}</div>
                                            <div className={classes.locationTitle} style={{ marginTop: 10 }}>{state.districtName}</div>
                                            <div className={classes.locationTitle}>{state.stateName} </div>
                                        </Paper>
                                        <div style={{ width: 20 }}>

                                        </div>
                                        <Paper style={{ flex: 3, backgroundColor: '#43A047', padding: 10, paddingTop: 10, }}>
                                            <div>
                                                <div style={{ flexDirection: 'row', display: 'flex' }}>
                                                    <div style={{ flex: 1 }}>
                                                        <div className={classes.smallCompTitle}>When</div>
                                                        <div className={classes.smallCompValue}>
                                                            <Moment format="DD-MMM-YYYY">{state.campDate}</Moment>
                                                        </div>
                                                    </div>
                                                    <div style={{ flex: 1 }}>
                                                        <div className={classes.smallCompTitle}>Status</div>
                                                        <div className={classes.smallCompValue}>
                                                            <div>{campStatusMapping[state.campStatus]}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{ flexDirection: 'row', display: 'flex', paddingTop: 10 }}>
                                                    <div style={{ flex: 1 }}>
                                                        <div className={classes.smallCompTitle}>Organizer</div>
                                                        <div className={classes.smallCompValue}>
                                                            <div>{state.organizer}</div>
                                                        </div>
                                                    </div>
                                                    <div style={{ flex: 1 }}>
                                                        <div className={classes.smallCompTitle}>Donor</div>
                                                        <div className={classes.smallCompValue}>
                                                            <div>{state.donor}</div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </Paper>
                                    </div>
                                </div>
                                <div>
                                    <Paper style={{ padding: 10 }}>
                                        <div style={{}}>
                                            <CTable
                                                ref={dataTable}
                                                dt={state.someDate}
                                                pageSize={10}
                                                data={props.followUpType == 'IN' ? _getCampData : _getCounsellingData}
                                                columns={props.followUpType == 'IN' ? campDataTableDef : campCouncellingTableDef}>
                                            </CTable>
                                        </div>
                                    </Paper>
                                </div>
                            </div>

                        }
                    </div>

                </div>
            }
        </div >
    );
}
