import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import CancelIcon from '@material-ui/icons/Cancel'


const styles = theme => ({
    errorMessageContainer : {
        fontFamily : 'Metropolis-Regular',
        fontSize:14
    },
    closeButtonContainer : {
        marginTop:10,
        backgroundColor: '#EF5350', 
        width: 300,
        height: 50,
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center', 
        cursor:'pointer' ,
        fontFamily : 'Metropolis-Medium',
        color:'#FFF'
    }
});


class ErrorDialog extends Component {

    static currentRef = null;

    state = {
        dialogOpen : false
    }

    showDialog(message) {
        this.setState({
            dialogOpen : true,
            message : message
        })
    }

    static show(errorMessage) {
        ErrorDialog.currentRef.showDialog(errorMessage);
    }

    componentDidMount() {
        ErrorDialog.currentRef = this;
    }

    _closeClicked() {
        this.setState({
            dialogOpen:false
        })
    }

    _onClose() {
        this.setState({
            dialogOpen:false
        })
    }

    render() {
        const { classes } = this.props;
        return (
            <Dialog onClose={this._onClose.bind(this)} open={this.state.dialogOpen}>
                <DialogContent style={{width:300, alignItems:'center', display:'flex', flexDirection:'column'}}>
                    <CancelIcon  style={{width:50, height:50,color:'#EF5350', marginBottom:20}} />
                    <div className={classes.errorMessageContainer}>
                        <span style={{wordWrap:'normal'}}>{this.state.message}</span>
                    </div>
                </DialogContent>
                <div
                    onClick={this._closeClicked.bind(this)} 
                    className={classes.closeButtonContainer}>
                    <span>CLOSE</span>
                </div>
            </Dialog>
        )
    }
}


ErrorDialog.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ErrorDialog);
