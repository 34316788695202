import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
    parentHeaders: {
        fontFamily: 'Metropolis-Regular'
    },
    lastHeader: {
        fontFamily: 'Metropolis-SemiBold'
    }
});

class PagePath extends Component {

    render() {

        var items = "";
        var len = this.props.path.length;

        if (len > 1) {
            for (var i = 0; i < len -1; i++) {
                if (i > 0) {
                    items = items + " / ";
                }
                items = items + this.props.path[i]
                
            }
            items = items + " / ";
        }

        return (
            <div style={{ flexDirection: 'row', display: 'flex',  alignItems:'center', fontSize:15 }}>
                <div style={{flex:1  }}>
                    <span style={{ flex: 1, fontFamily: 'Metropolis-Regular' }}>{items}</span>
                    <span style={{ flex: 1, fontFamily: 'Metropolis-SemiBold' }}>{this.props.path[this.props.path.length-1]}</span>
                </div>

                <div>
                    {this.props.children}
                </div>
            </div>
        )
    }
}

PagePath.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PagePath);