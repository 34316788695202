import React, { createRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Paper, FormHelperText, Divider, Dialog, DialogActions, Select, FormControl, MenuItem, Button, InputLabel, TextField } from '@material-ui/core';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { NotificationManager } from 'react-notifications';
import Moment from 'react-moment';
import moment from 'moment';

import TodayRoundedIcon from '@material-ui/icons/TodayRounded';
import Box from '@material-ui/core/Box';
import PagePath from './components/PagePath';
import HttpHandler from './HttpHandler';
import ErrorDialog from './components/ErrorDialog'
import DateSelectionDialog from './components/DateSelectionDialog'

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box >
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
const counsellingTypeMapping = {
    'FI': 'Financial',
    'RE': 'Refusal',
    'FE': 'Fear',
    'OT': 'Others',
    'TC': 'Tobacco Cessation Session'
}


export default function ProbableCounselling(props) {

    const [state, setState] = useState({
        general: {},
        regSections: [],
        regSectionData: {},
        medicalSections: [],
        medicalSectionData: {},
        tests: [],
        selectedTab: 0,
        sessions: [],
        treatments: [],
        showTreatmentDetailsDialog: false,
        loadTreatmentDetailsInProgress: false,
        loadTreatmentDetailsError: false,
        loadTreatmentDetailsErrorMessage: '',
        loadingInProgress : false,
        hasAccess : false,
        addComment: '',
        campDate:'',
        notes: ''
    });

    const docFileRef = createRef();

    const _loadSessions = (istate, loadTreatment) => {
        HttpHandler.sendGet('/api/v1/treatment/' + props.match.params.recordId + '/sessions', (d) => {
            istate = { ...istate, sessions: d.sessions, loadingInProgress: false };
            setState(istate);
           
        }, (errorCode, errorMessage) => {
            istate = { ...istate, loadingInProgress: false };
            setState(istate);
            console.log(errorMessage);
            NotificationManager.error('Error loading counsessling session details', "Session")
        })
    }

    const _getGeneralDetailsForProbableCase = (istate) => {
        HttpHandler.sendGet('/api/v1/camp/data/' + props.match.params.recordId + '/register', (d) => {
            istate = { ...istate };

            istate.general = d.general;
            istate.regSections = d.sections;
            istate.regSectionData = d.data;
            istate.campDate = new Date(d.campDate);

            setState(istate);
            _loadSessions(state, true);

            HttpHandler.sendGet('/api/v1/camp/data/' + props.match.params.recordId + '/medical', (d) => {
                var state = {
                    ...istate,
                    medicalSections: d.sections,
                    medicalSectionData: d.data,
                    loadingMedicalDetails: false,
                }
                setState(state); 
                 _loadSessions(state, true);
              

            }, (errorCode, errorMessage) => {
                setState({
                    loadingMedicalDetails: false,
                })
            });

        }, (errorCode, errorMessage) => {
            console.log(errorMessage);
            if(errorCode !== 401) {
                NotificationManager.error("Error in loading Counselling details", "Error")
            }
            setState({
                loadingMedicalDetails: false,
                hasAccess : errorCode === 401 ? true : false,
                loadingInProgress : false
            })
        })
    }

    const _handleTabChange = (event, newValue) => {
        setState({ ...state, selectedTab: newValue })
    };

    const _handleNotesChange = (event, value) => {
        alert(value);
    }
    useEffect(() => {
        var istate = { ...state, loadingInProgress: true }
        setState(istate);
        _getGeneralDetailsForProbableCase(istate);
    }, [props.match.params.recordId]);

   
    const _addCouncellingSession = () => {
        DateSelectionDialog.show(false, "Select planned session date","",false, state.campDate, new Date(), (dt, notes) => {
            console.log(dt, notes);
            var req = {
                plannedDate: dt.getTime(),
                notes: notes
            }
            HttpHandler.sendPost('/api/v1/treatment/' + props.match.params.recordId + '/session', req, (res) => {
                NotificationManager.success('Session created', 'Counselling session')
                _loadSessions(state, false);
            }, (errorCode, errorMessage) => {
                console.log(errorMessage);
                ErrorDialog.show('Error saving counselling session details')
            });
        }, () => {

        })
    }

    const _actualDateChangeClicked = (row) => {
        const date = (row.actualDate)?row.actualDate : new Date()
        const notes = (row.notes)?row.notes : ""
        DateSelectionDialog.show(true, "Select actual session date",notes,true, state.campDate, date, (dt, notes) => {
            var req = {
                actualDate: dt.getTime(),
                notes: notes
            }
            HttpHandler.sendPost('/api/v1/treatment/session/' + row.id + '/actual', req, (res) => {
                NotificationManager.success('Session updated', 'Counselling session')
                _loadSessions(state);
            }, (errorCode, errorMessage) => {
                console.log(errorMessage);
                ErrorDialog.show('Error saving counselling session details')
            });
        }, () => {

        })
    }

    return (
        <div>
            {state.loadingInProgress &&
                    <div style={{ paddingTop: 40, color: '#000', fontFamily: 'Metropolis-Regular', fontSize: 15, textAlign: 'center' }}>Loading Counselling Information.</div>
                }
              
                {(((HttpHandler.USER_TYPE !== 'coordinator' && HttpHandler.USER_TYPE !== 'data entry' && HttpHandler.USER_TYPE !== 'screening' && HttpHandler.USER_TYPE !== 'doctor') || state.hasAccess) && state.loadingInProgress === false) &&
                    <div style={{ paddingTop: 40, color: '#FF0000', fontFamily: 'Metropolis-Regular', fontSize: 15, textAlign: 'center' }}>You are not authorized to access contents of this page.</div>
                }
              
                {(((HttpHandler.USER_TYPE === 'coordinator' || HttpHandler.USER_TYPE === 'data entry' || HttpHandler.USER_TYPE === 'screening' || HttpHandler.USER_TYPE === 'doctor') && !state.hasAccess) && state.loadingInProgress === false) &&
                    <div>
                    <div style={{ marginRight: 20 }}>
                        <PagePath path={['Probale Case', state.general.regNum]}>
                        </PagePath>

                    </div>
                   
                      <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <div style={{ width: 720, paddingTop: 30 }}>

                            <AppBar position="static" style={{ display: 'flex', flexDirection: 'row' }}>
                                <Tabs scrollButtons="auto" variant="scrollable" value={state.selectedTab} onChange={_handleTabChange} aria-label="simple tabs example" style={{ flex: 1 }}>
                                     <Tab style={{ flex : 2.5 }} label="Counselling" {...a11yProps(0)} />
                                </Tabs>

                            </AppBar>
                             <TabPanel value={state.selectedTab} index={0}>
                               <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <div style={{ flex:1}}>
                                            <div style={{ fontFamily: 'Metropolis-Medium', marginLeft: 10, marginTop: 10 }}>Counselling Type : {counsellingTypeMapping[state.general.counsellingType]}</div>
                                            <div style={{ fontFamily: 'Metropolis-Medium', marginLeft: 10, marginTop: 5 }}>Comment : {state.general.counsellingComment}</div>
                                    </div>    
                                    <Button
                                        variant="contained"
                                        onClick={_addCouncellingSession} style={{ marginTop: 5 }}
                                        color="secondary" aria-label="add" size="medium" title="Add sessions">
                                        Add session
                                    </Button>
                                </div>
                               
                                {(state.sessions.length === 0) &&
                                    <div style={{ paddingTop: 30, paddingBottom: 30, display: 'flex', justifyContent: 'center' }}>
                                        <div style={{ fontFamily: 'Metropolis-Medium' }}>No counselling sessions are added</div>
                                    </div>
                                }
                                {state.sessions.map((row, key) => {
                                    return (
                                        <div key={row.id} style={{ marginTop: 10,borderRadius: 5, boxShadow: '1px 1px 2px #999', backgroundColor: '#FFF', }}>
                                            <div style={{
                                                
                                                paddingTop: 10,paddingRight: 10,paddingLeft:10, display: 'flex'
                                            }}>
                                                <div style={{ fontFamily: 'Metropolis-Regular', fontSize: 16, flex: 1 }}>{"Session " + row.sessionSequence}</div>
                                                <div style={{ fontFamily: 'Metropolis-Regular', fontSize: 16, color: '#888', paddingRight: 5 }}>Planned : </div>
                                                <Moment format="DD-MMM-YYYY">{new Date(row.plannedDate)}</Moment>
                                                <div style={{ fontFamily: 'Metropolis-Regular', fontSize: 16, color: '#888', marginLeft: 40 }}>Actual : </div>
                                                {(row.actualDate !== null) &&
                                                 <div style={{display: 'flex'}}>
                                                    <Moment style={{marginRight: 15}} format="DD-MMM-YYYY">{new Date(row.actualDate)}</Moment>
                                                    <div onClick={() => _actualDateChangeClicked(row)}>
                                                    <TodayRoundedIcon style={{ color: "#2962FF", cursor: 'pointer' }} />
                                                    </div>
                                                </div>
                                                }
                                                {(row.actualDate === null) &&
                                                
                                                    <div style={{ display: 'flex' }}>
                                                        <div style={{ fontFamily: 'Metropolis-Regular', fontSize: 16, color: '#888', marginRight: 5 }}>Yet to happen</div>
                                                      
                                                        {(HttpHandler.USER_TYPE === 'coordinator' || HttpHandler.USER_TYPE === 'data entry' || HttpHandler.USER_TYPE === 'screening' || HttpHandler.USER_TYPE === 'doctor') &&
                                                        <div onClick={() => _actualDateChangeClicked(row)}>
                                                            <TodayRoundedIcon style={{ color: "#2962FF", cursor: 'pointer' }} />
                                                        </div>
                                }
                                                    </div>
                                                }
                                                
                                            </div>
                                            <div style={{ paddingBottom: 10, paddingRight: 10, paddingLeft:10 }}>
                                               
                                                <div style={{ fontFamily: 'Metropolis-Medium', marginTop: row.actualDate === null ? 0 : 8}}>Status : {row.actualDate === null ? 'Pending' : 'Completed'}</div>
                                            </div>
                                            <div style={{ paddingBottom: 10, paddingRight: 10, paddingLeft:10 }}>
                                               
                                                <div style={{ fontFamily: 'Metropolis-Medium'}}>Notes : {row.notes ? row.notes:''}</div>
                                            </div>
                                        </div>
                                    )
                                })
                                }
                            </TabPanel>
                            </div>

                    </div>
                </div>
            }
        </div>
    )
}