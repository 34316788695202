import React, { useRef, useEffect, useState } from 'react';

import { Paper, Fab , Tooltip} from '@material-ui/core';
import { useHistory } from 'react-router-dom';


import PersonRoundedIcon from '@material-ui/icons/PersonRounded';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import CloudDownloadRoundedIcon from '@material-ui/icons/CloudDownloadRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';

import { NotificationManager } from 'react-notifications';

import PagePath from './components/PagePath';
import HttpHandler from './HttpHandler'
import CTable from './components/CTable'
import ConfirmationDialog from './components/ConfirmationDialog';
import SurveyRecordEdit from './SurveyRecordEdit';



export default function SurveyData(props) {

    let surveyId = useRef(0);
    const dataTable = useRef(null);
    let history = useHistory();

    const [state, setState] = useState({
        someDate: new Date(),
        recordId: -1,
        loadingInProgress: false,
        hasAccess: true,
    });

    const surveyDataTableDef = [
        {
            title: "Reg No.",
            field: "regNum",
            align: 'left',
            style: { minWidth: 140, width: 140 },
            renderer: (row, data) => {
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'left' }} >
                        {row.regNum}
                    </div>
                )
            }
        },
        {
            title: "Name",
            field: 'name',
            align: 'left',
            style: { minWidth: 250, width: 250 },
            renderer: (row, data) => {
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'left' }} >
                        {row.name}
                    </div>
                )
            }

        },

        {
            title: "Gender",
            field: "gender",
            align: 'left',
            style: { minWidth: 80, width: 80 },
            renderer: (row, data) => {
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'left' }} >
                        {row.gender === 'M' ? 'Male' : (row.gender === 'F' ? 'Female' : 'Others')}
                    </div>
                )
            }
        },
        {
            title: "Age",
            field: 'age',
            align: 'left',
            style: { minWidth: 80, width: 80 },
            renderer: (row, data) => {
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'right' }} >
                        {row.age}
                    </div>
                )
            }

        },
        {
            title: "Phone",
            field: 'phone',
            align: 'left',
            style: { minWidth: 120, width: 120 },
            renderer: (row, data) => {
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'left' }} >
                        {row.phone}
                    </div>
                )
            }

        },
        {
            title: "",
            field: 'id',
            style: { minWidth: 120, width: 120 },
            renderer: (row, data) => {
                return (
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        {(HttpHandler.USER_TYPE === 'coordinator' || HttpHandler.USER_TYPE === 'survey') &&
                            <Tooltip title="Person Details" arrow>
                                <div
                                    onClick={() => _showDetailsClicked(row.id)}
                                    style={{ height: 24, cursor: 'pointer' }}>
                                    <PersonRoundedIcon style={{ color: '#03A9F4' }}></PersonRoundedIcon>
                                </div>
                            </Tooltip>   
                        }
                        {(HttpHandler.USER_TYPE === 'coordinator' || HttpHandler.USER_TYPE === 'survey') &&
                           <Tooltip title="Edit" arrow>
                                <div
                                    onClick={() => _editClicked(row.id)}
                                    style={{ marginLeft: 20, height: 24, cursor: 'pointer' }}>
                                    <EditRoundedIcon style={{ color: '#03A9F4' }}></EditRoundedIcon>
                                </div>
                            </Tooltip>
                        }
                        {(HttpHandler.USER_TYPE === 'coordinator' || HttpHandler.USER_TYPE === 'survey') &&
                            <Tooltip title="Delete" arrow>
                                <div
                                    onClick={() => _deleteClicked(row.id)}
                                    style={{ marginLeft: 20, height: 24, cursor: 'pointer' }}>
                                    <DeleteForeverRoundedIcon style={{ color: '#D32F2F' }}></DeleteForeverRoundedIcon>
                                </div>
                            </Tooltip>    
                        }

                        

                    </div>
                )
            }

        }
    ]

    useEffect(() => {
        surveyId.current = props.match.params.surveyId;
    }, [props.match.params.surveyId]);

    const _confirmDelete = (id) => {
        HttpHandler.sendDelete('/api/v1/survey/' + props.match.params.surveyId + '/data/' + id, (d) => {
            setState({ ...state, someDate: new Date() });
            dataTable.current.reload();
        }, (errorCode, errorMessage) => {
            console.log("Failed");
        })
    }

    const _editClicked = (id) => {
        history.push('/ics/survey/' + surveyId.current + '/' + id);
    }

    const _deleteClicked = (id) => {
        ConfirmationDialog.show('Are you sure you want to delete this survey record ?', () => _confirmDelete(id))
    }

    const _showDetailsClicked = (id) => {
        history.push('/ics/survey/' + props.match.params.surveyId + '/data/' + id);
    }


    const _getSurveyData = (cb, offset, limit, searchString) => {
        HttpHandler.sendGet('/api/v1/survey/' + props.match.params.surveyId + '/data?offset=' + offset + '&limit=' + limit + '&s=' + searchString, (data) => {
            cb(data.data, data.count)
        }, (errorCode, errorMessage) => {
            if (errorCode === 401) {
                setState({ ...state, hasAccess: false })
            }
            console.log(errorMessage);
        })
    }

    const _downloadData = () => {
        history.push('/ics/survey/' + props.match.params.surveyId + '/download/excel');
    }


    return (
        <div>
            {state.loadingInProgress &&
                <div style={{ paddingTop: 40, color: '#000', fontFamily: 'Metropolis-Regular', fontSize: 15, textAlign: 'center' }}>We are loading the survey details.</div>
            }
            {(((HttpHandler.USER_TYPE !== 'coordinator' && HttpHandler.USER_TYPE !== 'survey') || !state.hasAccess) && state.loadingInProgress === false) &&
                <div style={{ paddingTop: 40, color: '#FF0000', fontFamily: 'Metropolis-Regular', fontSize: 15, textAlign: 'center' }}>You are not authorized to access contents of this page.</div>
            }
            {(((HttpHandler.USER_TYPE === 'coordinator' || HttpHandler.USER_TYPE === 'survey') && state.hasAccess) && state.loadingInProgress === false) &&
                <div>
                    <div style={{ marginRight: 20 }}>
                        <PagePath path={['Survey', 'Data']}>
                            {(HttpHandler.USER_TYPE === 'coordinator' ) &&
                                <Fab
                                    color="primary" aria-label="Download" size="medium">
                                    <a href={'/api/v1/survey/' + props.match.params.surveyId + '/download/excel'} download>
                                        <CloudDownloadRoundedIcon style={{ color: '#FFF' }} />
                                    </a>
                                </Fab>
                            }
                        </PagePath>
                    </div>
                    <div style={{ marginTop: 20, paddingBottom: 30, flexDirection: 'row', display: 'flex', justifyContent: 'center' }}>
                        <div>
                            <Paper style={{ padding: 10 }}>
                                <div style={{}}>
                                    <CTable
                                        ref={dataTable}
                                        dt={state.someDate}
                                        pageSize={10}
                                        data={_getSurveyData}
                                        columns={surveyDataTableDef}>
                                    </CTable>
                                </div>
                            </Paper>
                        </div>
                    </div>
                </div>
            }
        </div>
    )

}