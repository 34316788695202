import React, { useEffect, useState, useRef } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';

import { Paper, Button } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import Moment from 'react-moment';
import PagePath from './components/PagePath';
import HttpHandler from './HttpHandler';
import CTable from './components/CTable'

const campStatusMapping = {
    'SC': 'Scheduled',
    'RU': 'Running',
    'CA': 'Cancelled',
    'CO': 'Completed'
}


const useStyles = makeStyles({
});


export default function Sync(props) {


    const classes = useStyles();
    const dataTable = useRef(null);
    const history = useHistory();

    const [state, setState] = useState({
        regTemplates: [],
        medicalTemplates: [],
        currentCampId: -1,
        addEditUIHeading: "Create Camp" ,
        
    });
    const [double, setDouble] = useState(false);


    useEffect(() => {
        setDouble(JSON.parse(window.sessionStorage.getItem("disable")));
      }, []);
    
      useEffect(() => {
        window.sessionStorage.setItem("disable", double);
      }, [double]);


    const campTableDef = [
        {
            title: "Name",
            field: 'name',
            align: 'left',
            renderer: (row, data) => {
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center' }} >
                        {data}
                    </div>
                )
            }

        },
        {
            title: "Camp Date",
            field: 'campDate',
            align: 'left',
            renderer: (row, data) => {
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center' }} >
                        <Moment format="DD-MM-YYYY">{new Date(data)}</Moment>
                    </div>
                )
            }
        },
        {
            title: "Status",
            field: "status",
            align: 'left',
            renderer: (row, data) => {
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center' }} >
                        {campStatusMapping[row.status]}
                    </div>
                )
            }
        },
        {
            title: "Location",
            field: "location",
            align: 'left',
            renderer: (row, data) => {
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center' }} >
                        {row.address.district.name + ', ' + row.address.state.name}
                    </div>
                )
            }
        },
        {
            title: "Sync",
            field: "Sync",
            align: 'left',
            renderer: (row, data) => {
                return (
                    <div  style={{ cursor : (double == true)? 'not-allowed': 'pointer' , flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center' } } >
                        <Button 
                            disabled = {double}
                            onClick={() =>  _syncClicked(row.id) }
                            variant="contained"
                            color="primary"
                            size="small"
                            style={{  }}>Sync</Button>      
                    </div>
                )
            }
        }
    ]

    const _syncClicked = (record) => {
        HttpHandler.sendPost('/api/v1/sync/' + record,{}, (data) => {
            if(data.count == 0)
            NotificationManager.info("No data to sync" , "sync")

            else
            {
            NotificationManager.success(`${data.count} record synced sucessfully`,"sync");
            setDouble(true);
            }
         }, (errorCode, errorMessage) => {
            NotificationManager.error("Unable to sync","sync");
            console.log("error",errorMessage)
        })
    }
    

    useEffect(() => {
        _loadRegTemplatesList();
    }, [props.campId]);

    const _getCampData = (cb, offset, limit, searchString) => {
        HttpHandler.sendGet('/api/v1/camp/list?offset=' + offset + '&limit=' + limit + '&s=' + searchString, (data) => {
            cb(data.camps, data.count)
        }, (errorCode, errorMessage) => {
            console.log(errorMessage);
        })
    }

    const _loadRegTemplatesList = () => {
        HttpHandler.sendGet('/api/v1/template/list?type=CA&offset=0&limit=100', (data) => {
            var istate = {
                ...state,
                regTemplates: data.templates
            };

            _loadMedicalTemplateList(istate);

        }, (errorCode, errorMessage) => {
            NotificationManager.error("Error loading list of trmplates", "Templates")
        })
    };

    const _loadMedicalTemplateList = (istate) => {
        HttpHandler.sendGet('/api/v1/template/list?type=CM&offset=0&limit=100', (data) => {
            setState({
                ...istate,
                medicalTemplates: data.templates
            });

        }, (errorCode, errorMessage) => {
            NotificationManager.error("Error loading list of trmplates", "Templates")
        })
    }
    


    return (
        <div>
          
            {(HttpHandler.USER_TYPE !== 'coordinator' && HttpHandler.USER_TYPE !== 'data entry' && HttpHandler.USER_TYPE !== 'screening' && HttpHandler.USER_TYPE !== 'doctor') &&
                <div style={{ paddingTop: 40, color: '#FF0000', fontFamily: 'Metropolis-Regular', fontSize: 15, textAlign: 'center' }}>You are not authorized to access contents of this page.</div>
               
            }
          
            {(HttpHandler.USER_TYPE === 'coordinator' || HttpHandler.USER_TYPE === 'data entry' || HttpHandler.USER_TYPE === 'doctor' || HttpHandler.USER_TYPE === 'screening') &&
                <div>
                    <div style={{ marginRight: 20 }}>
                        <PagePath path={['Screening Camp', 'All']}>
                          
                        </PagePath>
                    </div>
                    <div style={{ marginTop: 20, paddingBottom: 30 }}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Paper style={{ padding: 10 }}>
                                    <div style={{ width: 800 }}>
                                        <CTable
                                            ref={dataTable}
                                            data={_getCampData}
                                            columns={campTableDef}>
                                        </CTable>
                                    </div>
                                </Paper>
                        </div>
                    </div>
                </div>}
        </div>
    );
}
