import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles'


import { useHistory } from 'react-router-dom';
import { Fab, Paper, TextField, Divider, Button, Collapse } from '@material-ui/core';

import AddIcon from '@material-ui/icons/AddRounded';

import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';

import { NotificationManager } from 'react-notifications';

import commonClasses from './ics.module.css'

import PagePath from './components/PagePath';
import HttpHandler from './HttpHandler'
import ErrorDialog from './components/ErrorDialog';
import ConfirmationDialog from './components/ConfirmationDialog';
import SearchFieldModal from './components/SearchFieldModal';

import CTable from './components/CTable'

const useStyles = makeStyles({
});


const userSearchDef = {
    fullName: {
        title: 'Name',
        align: 'left'
    },
    type: {
        title: 'Type',
        align: 'left',
        renderer: (row, data) => {
            return (
                <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center' }} >
                    {row.typeName}
                </div>
            )
        }
    }
}


export default function SurveyPreTeam(props) {


    const dataTable = useRef(null);
    const history = useHistory();

    const [state, setState] = useState({
        editCreateDialog: false,
        members: [],
        selectedMemberId: -1,
        selectedMember: null,
    });

    const surveyTeamTableDef = [
        {
            title: "Name",
            field: 'name',
            align: 'left',
            renderer: (row, data) => {
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center', fontFamily: 'Metropolis-Regular' }} >
                        {row.fullName}
                    </div>
                )
            }

        },
        {
            title: "Organization",
            field: 'org',
            align: 'left',
            renderer: (row, data) => {
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center', fontFamily: 'Metropolis-Regular' }} >
                        {row.org}
                    </div>
                )
            }

        },
        {
            title: "Phone",
            field: 'phone',
            align: 'left',
            renderer: (row, data) => {
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center', fontFamily: 'Metropolis-Regular' }} >
                        {row.phone}
                    </div>
                )
            }

        },
        // {
        //     title: "",
        //     field: 'id',
        //     style: { minWidth: 120, width: 120 },
        //     renderer: (row, data) => {
        //         return (
        //             <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
        //                 {(HttpHandler.USER_TYPE === 'admin' || HttpHandler.USER_TYPE === 'coordinator') &&
        //                     <div
        //                         onClick={() => _deleteClicked(row)}
        //                         style={{ marginLeft: 20, height: 24, cursor: 'pointer' }}>
        //                         <DeleteForeverRoundedIcon style={{ color: '#D32F2F' }}></DeleteForeverRoundedIcon>

        //                     </div>
        //                 }

        //             </div>
        //         )
        //     }

        // }
    ]

    const _deleteClicked = (row) => {
        ConfirmationDialog.show('Are you sure you want to delete this team member ?', () => {
            HttpHandler.sendDelete('/api/v1/surveyteam/' + row.id, (d) => {
                NotificationManager.success("Record deleted.", row.name);
                dataTable.current.reload();
            }, (errorCode, errorMessage) => {
                ErrorDialog.show("There was error in deleting record");
            })
        });
    }

    useEffect(() => {

    }, []);

    const _createMember = () => {
        SearchFieldModal.show('/api/v1/user/search?', '', userSearchDef, (v) => {
            if (v !== null) {
                var req = {
                    name: v.fullName,
                    userId: v.id
                }
                HttpHandler.sendPost('/api/v1/surveyteam/new', req, () => {
                    NotificationManager.success('Team member added', v.fullName);
                    SearchFieldModal.hide();
                    _memberAdded();
                }, (errorCode, errorMessage) => {
                    ErrorDialog.show("Error in adding team member to camp")
                })
            }

        });

    }

    const _getSurveyTeamTableData = (cb, offset, limit, searchString) => {
        HttpHandler.sendGet('/api/v1/surveyteam/all?offset=' + offset + '&limit=' + limit + '&s=' + searchString, (data) => {
            cb(data.result, data.count)
        }, (errorCode, errorMessage) => {
            console.log(errorMessage);
        })
    }

    const _memberAdded = () => {
        setState({
            ...state,
            editCreateDialog: false
        });
        dataTable.current.reload();
    }


    return (
        <div>
            <div style={{ marginRight: 20 }}>
                <PagePath path={['Survey Team', 'All']}>

                    {/* <Fab
                        onClick={_createMember}
                        color="primary" aria-label="add" size="medium" title="New member">
                        <AddIcon />
                    </Fab> */}
                </PagePath>
            </div>

            <div style={{display:'flex', marginTop: 20, paddingBottom: 30, paddingRight: 10, width:'100%', justifyContent:'center' }}>
                <div style={{display:'flex', justifyContent:'center'}}>
                    <Paper style={{ padding: 10, width: 520 }}>
                        <div style={{ width: 500 }}>
                            <CTable
                                ref={dataTable}
                                data={_getSurveyTeamTableData}
                                columns={surveyTeamTableDef}>
                            </CTable>
                        </div>
                    </Paper>
                </div>
            </div>
        </div>
    )
}

