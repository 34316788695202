import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles'

import { Fab, Paper, TextField, Divider, Button, DialogActions, Dialog } from '@material-ui/core';

import AddIcon from '@material-ui/icons/AddRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';

import { NotificationManager } from 'react-notifications';

import commonClasses from './ics.module.css'

import PagePath from './components/PagePath';
import HttpHandler from './HttpHandler'
import ErrorDialog from './components/ErrorDialog';
import ConfirmationDialog from './components/ConfirmationDialog';
import Utils from './Utils';


import CTable from './components/CTable'

const useStyles = makeStyles({
});


function TestEditCreate(props) {

    const [state, setState] = useState({
        testName: '',
        testNameError: false,
        testNameErrorMessage: '',

    });

    const _saveClicked = () => {
        var valid = true;
        var myState = { ...state };

        var name = state.testName.trim();
        if (name.length === 0) {
            valid = false;
            myState.testNameError = true;
            myState.testNameErrorMessage = "Mandatory";
        } else if (name.length > 120) {
            valid = false;
            myState.testNameError = true;
            myState.testNameErrorMessage = "Name should not exceed 120 characters";
        } else if (name.length < 3) {
            valid = false;
            myState.testNameError = true;
            myState.testNameErrorMessage = "Minimum 3 characters required";
        } else if (!Utils.isValidName(name)) {
            valid = false;
            myState.testNameError = true;
            myState.testNameErrorMessage = "Invalid Name";
        }

        var code = "Dummy";

        if (!valid) {
            setState(myState);
            return;
        }

        //Check for duplicate code or name

        var req = {
            name: name,
            code: code,
        }

        var url = '/api/v1/test/duplicate';
        if (props.testId > 0) {
            url = '/api/v1/test/' + props.testId + '/duplicate';
        }

        HttpHandler.sendPost(url, req, (d) => {

            if (d.nameDuplicate || d.codeDuplicate) {

                var istate = { ...state };
                if (d.nameDuplicate) {
                    istate = { ...istate, testNameError: true, testNameErrorMessage: 'Name already used' };
                }

                setState(istate);

                return;
            }

            var req = {
                name: name,
                code: code,
            }

            var url = '/api/v1/test/new';
            if (props.testId > 0) {
                url = '/api/v1/test/' + props.testId;
            }
            HttpHandler.sendPost(url, req, (data) => {
                if (props.testId > 0) {
                    NotificationManager.success("Test record updated.", name);
                }
                else {
                    NotificationManager.success("Test record created.", name);
                }
                props.onDone();
            }, (errorCode, errorMessage) => {
                console.log(errorMessage);
                ErrorDialog.show("There was error in saving test");
            })

        }, (errorCode, errorMessage) => {
            console.log(errorMessage);
            ErrorDialog.show("There was error in saving test");
        })




    }

    const _closeClicked = () => {
        props.onClose();
    }

    const _loadTestDetails = () => {
        HttpHandler.sendGet('/api/v1/test/' + props.testId, (d) => {
            var istate = {
                ...state,
                testName: d.test.name,
                testNameErrorMesage: '',
                testNameError: false,

            }
            setState(istate);
        }, (errorCoder, errorMessage) => {
            props.onClose();
        })
    }


    useEffect(() => {
        if (props.testId > 0) {
            _loadTestDetails();
        }
        else {
            setState({
                ...state,
                testName: '',
                testNameError: false,
                testNameErrorMessage: '',
            })
        }
    }, [props.testId]);

    return (
        <div>
            <Paper style={{ padding: 10, width: 500 }}>
                <div className={commonClasses.headerBar}>{props.heading}</div>
                <div style={{ paddingBottom: 20 }}>
                    <form noValidate autoComplete="off">
                        <TextField
                            required
                            error={state.testNameError}
                            id='testName'
                            label="Name"
                            fullWidth
                            value={state.testName}
                            margin="normal"
                            helperText={state.testNameErrorMessage}

                            onChange={(v) => {
                                setState({
                                    ...state,
                                    testName: v.target.value,
                                    testNameError: false,
                                    testNameErrorMessage: ''
                                })
                            }}
                        />

                    </form>
                </div>
                <Divider style={{ marginLeft: -10, marginRight: -10 }}></Divider>
                <div style={{ paddingTop: 10, flexDirection: 'row', display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        onClick={_saveClicked}
                        variant="contained" color="primary">Save</Button>
                    <Button
                        onClick={_closeClicked}
                        variant="contained" color="default" style={{ marginLeft: 20 }}>Close</Button>
                </div>
            </Paper>
        </div>
    )
}


export default function Tests(props) {


    const dataTable = useRef(null);

    const [state, setState] = useState({
        editCreateDialog: false,
        tests: [],
        selectedTestId: -1,
        addEditUIHeading: "Register Test"
    });

    const testTableDef = [
        {
            title: "Name",
            field: 'name',
            align: 'left',
            renderer: (row, data) => {
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center', fontFamily: 'Metropolis-Regular' }} >
                        {row.name}
                    </div>
                )
            }

        },
        {
            title: "",
            field: 'id',
            style: { minWidth: 120, width: 120 },
            renderer: (row, data) => {
                return (
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        {(HttpHandler.USER_TYPE === 'admin') &&
                            <div
                                onClick={() => _editClicked(row)}
                                style={{ height: 24, cursor: 'pointer' }}>
                                <EditRoundedIcon style={{ color: '#03A9F4' }}></EditRoundedIcon>
                            </div>
                        }
                        {(HttpHandler.USER_TYPE === 'admin') &&
                            <div
                                onClick={() => _deleteClicked(row)}
                                style={{ marginLeft: 20, height: 24, cursor: 'pointer' }}>
                                <DeleteForeverRoundedIcon style={{ color: '#D32F2F' }}></DeleteForeverRoundedIcon>

                            </div>
                        }

                    </div>
                )
            }

        }
    ]

    const _editClicked = (row) => {
        setState({ ...state, editCreateDialog: true, selectedTestId: row.id, addEditUIHeading: "Modify Test" })
    }

    const _deleteClicked = (row) => {
        ConfirmationDialog.show('Are you sure you want to delete this test?', () => {
            HttpHandler.sendDelete('/api/v1/test/' + row.id, (d) => {
                NotificationManager.success("Test deleted.", row.name);
                dataTable.current.reload();
            }, (errorCode, errorMessage) => {
                ErrorDialog.show("There was error in deleting test");
            })
        });
    }

    useEffect(() => {

    }, []);

    const _createTest = () => {
        setState({
            ...state,
            editCreateDialog: true,
            selectedTestId: -1,
            addEditUIHeading: "Register Test"
        });

    }

    const _getTestData = (cb, offset, limit, searchString) => {
        HttpHandler.sendGet('/api/v1/test/all?offset=' + offset + '&limit=' + limit + '&s=' + searchString, (data) => {
            cb(data.result, data.count)
        }, (errorCode, errorMessage) => {
            console.log(errorMessage);
        })
    }

    const _testCreated = () => {
        setState({
            ...state,
            editCreateDialog: false
        });
        dataTable.current.reload();
    }

    const _onTestViewClose = () => {
        setState({ ...state, isTestViewVisible: false })
    }

    return (
        <div>
            <div style={{ marginRight: 20 }}>
                <PagePath path={['Tests', 'All']}>
                    {(HttpHandler.USER_TYPE === 'admin') &&
                        <Fab
                            onClick={_createTest}
                            color="primary" aria-label="add" size="medium" title="New test">
                            <AddIcon />
                        </Fab>
                    }
                </PagePath>
            </div>

            <div style={{ marginTop: 20, paddingBottom: 30, paddingRight: 10 }}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {state.editCreateDialog &&
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <TestEditCreate testId={state.selectedTestId}
                                heading={state.addEditUIHeading}
                                onDone={_testCreated}
                                onClose={() => { setState({ ...state, editCreateDialog: false }) }} />
                        </div>
                    }
                    {(state.editCreateDialog === false) &&
                        <Paper style={{ padding: 10 }}>
                            <div style={{ width: 600 }}>
                                <CTable
                                    ref={dataTable}
                                    data={_getTestData}
                                    columns={testTableDef}>
                                </CTable>
                            </div>
                        </Paper>
                    }
                </div>
            </div>
        </div>
    )
}

