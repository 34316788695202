import React, { useEffect, useState, useRef } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';

import { Paper } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import Moment from 'react-moment';
import PagePath from './components/PagePath';
import HttpHandler from './HttpHandler';
import CTable from './components/CTable'
const useStyles = makeStyles({
});




export default function FollowUpCamps(props) {
    const dataTable = useRef(null);
    const campTableDef = [
        {
            title: "Name",
            field: 'name',
            align: 'left',
            renderer: (row, data) => {
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center' }} >
                        <Link to={"/ics/followUpInvestigationCamp/" + row.id} >{data}</Link>
                    </div>
                )
            }

        },
        {
            title: "Camp Date",
            field: 'campDate',
            align: 'left',
            renderer: (row, data) => {
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center' }} >
                        <Moment format="DD-MM-YYYY">{new Date(data)}</Moment>
                    </div>
                )
            }
        },
        {
            title: "Location",
            field: "location",
            align: 'left',
            renderer: (row, data) => {
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center' }} >
                        {row.address.district.name + ', ' + row.address.state.name}
                    </div>
                )
            }
        },
        {
            title: "Probable Count",
            field: "probable count",
            align: 'left',
            style: { minWidth: 80, width: 80 },
            renderer: (row, data) => {
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center' }} >
                       {row.summary.probableCount}
                    </div>
                )
            }
        },
        {
            title: "Tests Pending",
            field: "tests pending",
            align: 'left',
            style: { minWidth: 80, width: 80 },
            renderer: (row, data) => {
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignSelf: 'center' }} >
                        {row.summary.testPending}
                    </div>
                )
            }
        },
        {
            title: "Results Pending",
            field: "results Pending",
            align: 'left',
            style: { minWidth: 80, width: 80 },
            renderer: (row, data) => {
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center' }} >
                        {row.summary.resultPending}
                    </div>
                )
            }
        },
        
    ]
    const counsellingTableDef = [
        {
            title: "Name",
            field: 'name',
            align: 'left',
            renderer: (row, data) => {
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center' }} >
                        <Link to={"/ics/followUpCounsellingCamp/" + row.id} >{data}</Link>
                    </div>
                )
            }

        },
        {
            title: "Camp Date",
            field: 'campDate',
            align: 'left',
            renderer: (row, data) => {
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center' }} >
                        <Moment format="DD-MM-YYYY">{new Date(data)}</Moment>
                    </div>
                )
            }
        },
        {
            title: "Location",
            field: "location",
            align: 'left',
            renderer: (row, data) => {
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center' }} >
                        {row.address.district.name + ', ' + row.address.state.name}
                    </div>
                )
            }
        },
        {
            title: "Counselling Cases",
            field: "counselling Cases",
            align: 'left',
            style: { minWidth: 80, width: 80 },
            renderer: (row, data) => {
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center' }} >
                        {row.summary.counsellingCases}
                    </div>
                )
            }
        },
        {
            title: "Counselling Completed",
            field: "counselling Completed",
            align: 'left',
            style: { minWidth: 80, width: 80 },
            renderer: (row, data) => {
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignSelf: 'center' }} >
                        {row.summary.counsellingCompleted}
                    </div>
                )
            }
        },
    ]
   
   
    
    const _getCampData = (cb, offset, limit, searchString) => {
        HttpHandler.sendGet('/api/v1/camp/followUpInvList?offset=' + offset + '&limit=' + limit + '&s=' + searchString, (data) => {
            cb(data.camps, data.count)
        }, (errorCode, errorMessage) => {
            console.log(errorMessage);
        })
    }
   
    const _getCounselling = (cb, offset, limit, searchString) => {
        HttpHandler.sendGet('/api/v1/camp/followUpCounsellingList?offset=' + offset + '&limit=' + limit + '&s=' + searchString, (data) => {
            cb(data.camps, data.count)
        }, (errorCode, errorMessage) => {
            console.log(errorMessage);
        })
    }

    return (
        <div>
            {(HttpHandler.USER_TYPE !== 'coordinator' && HttpHandler.USER_TYPE !== 'screening' && HttpHandler.USER_TYPE !== 'doctor') &&
                <div style={{ paddingTop: 40, color: '#FF0000', fontFamily: 'Metropolis-Regular', fontSize: 15, textAlign: 'center' }}>You are not authorized to access contents of this page.</div>
            }
            {(HttpHandler.USER_TYPE === 'coordinator' || HttpHandler.USER_TYPE === 'doctor' || HttpHandler.USER_TYPE === 'screening') &&
                <div>
                    <div style={{ marginRight: 20 }}>
                        <PagePath path={props.followUpType =='IN'? ['Follow Up Investigation', 'All'] : ['Follow Up Counselling', 'All']}>
                        </PagePath>
                    </div>
                    <div style={{ marginTop: 20, paddingBottom: 30 }}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Paper style={{ padding: 10 }}>
                                <div style={{ width: 800 }}>
                                    <CTable
                                        ref={dataTable}
                                        data={props.followUpType =='IN'?_getCampData : _getCounselling}
                                        columns={props.followUpType =='IN'? campTableDef:counsellingTableDef}>
                                    </CTable>
                                </div>
                            </Paper>
                        </div>
                    </div>
                </div>}
        </div>
    );
}
