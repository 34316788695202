import React, { useEffect, useState, useRef } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';

import { Collapse, Paper, TextField, InputLabel, Select, MenuItem, Divider, Button, FormControl, FormHelperText } from '@material-ui/core';
import { DatePicker } from "@material-ui/pickers";
import Autocomplete from '@material-ui/lab/Autocomplete';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import { Link, useHistory } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';

import moment from 'moment';
import Moment from 'react-moment';

import commonClasses from './ics.module.css'
import PagePath from './components/PagePath';
import Utils from './Utils'
import HttpHandler from './HttpHandler';
import ErrorDialog from './components/ErrorDialog';
import ConfirmationDialog from './components/ConfirmationDialog';

const useStyles = makeStyles({
});


export default function Org(props) {

    const classes = useStyles();
    let orgId = useRef(0);

    const [state, setState] = useState({
        orgName: '',
        orgAddress: {},
        loadingDetails: true,
        phone: '',
        email: '',
        contact: '',
        type: ''
    });


    const _getOrgDetails = () => {
        var istate = { ...state, loadingDetails: true }
        setState(istate)
        HttpHandler.sendGet('/api/v1/org/' + this.props.orgId, (v) => {
            if (v === null) {
                return;
            }
            istate = {
                ...istate,
                orgName: v.org.name,
                orgAddress: v.org.address,
                phone: v.org.phone,
                email: v.org.email,
                contact: v.org.contact,
                type: v.org.type,
                loadingDetails: false
            }

            setState(istate);


        }, (errorCode, errorConsole) => {
            console.log("Error on loading organization details");
            istate = {
                ...istate,
                loadingDetails: false
            }

            setState(istate);
        });
    }

    useEffect(() => {
        orgId.current = props.match.params.orgId;
        _getOrgDetails();
    }, []);


    return (
        <div style={{ width: '100%' }}>
            <PagePath path={['Organization', state.orgName]}>

            </PagePath>

            <div style={{ flexDirection: 'row', width: '100%', alignItems: 'center' }}>

                {state.loadingDetails &&
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                    </div>
                }

                {(state.loadingDetails === false) &&
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                        <Paper style={{ padding: 20, width: 500 }}>
                            <div style={{ fontFamily: 'Metropolis-Medium', fontSize: 17 }}>{state.orgName}</div>
                            <Divider></Divider>
                            <div style={{ display: 'flex', flexDirection: 'row', paddingTop: 20 }}>
                            <div style={{ display: 'flex', flexDirection: 'row', paddingTop: 10 }}>
                                <div style={{ width: 60, flexDirection: 'row', display: 'flex', justifyContent: 'flex-end' }}>
                                    <div style={{ fontFamily: 'Metropolis-Medium', fontSize: 14 }}>Type</div>
                                </div>
                                <div style={{ paddingLeft: 10, paddingRight: 10 }}>:</div>
                                <div style={{ fontFamily: 'Metropolis-Regular', fontSize: 14 }}>
                                    <div>
                                        {state.type}
                                    </div>
                                </div>
                            </div>

                                <div style={{ width: 60 }}>
                                    <div style={{ fontFamily: 'Metropolis-Medium', fontSize: 14 }}>Address</div>
                                </div>
                                <div style={{ paddingLeft: 10, paddingRight: 10 }}>:</div>
                                <div style={{ fontFamily: 'Metropolis-Regular', fontSize: 14 }}>
                                    <div>
                                        {state.orgAddress.address.split("\n").map((i, key) => {
                                            return <div key={key}>{i}</div>;
                                        })}
                                    </div>
                                    <div>
                                        {state.orgAddress.district.name}
                                    </div>
                                    <div>
                                        {state.orgAddress.state.name}
                                    </div>
                                    <div>
                                        {"PIN - " + state.orgAddress.pin}
                                    </div>
                                </div>
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'row', paddingTop: 10 }}>
                                <div style={{ width: 60, flexDirection: 'row', display: 'flex', justifyContent: 'flex-end' }}>
                                    <div style={{ fontFamily: 'Metropolis-Medium', fontSize: 14 }}>Phone</div>
                                </div>
                                <div style={{ paddingLeft: 10, paddingRight: 10 }}>:</div>
                                <div style={{ fontFamily: 'Metropolis-Regular', fontSize: 14 }}>
                                    <div>
                                        {state.phone}
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', paddingTop: 10 }}>
                                <div style={{ width: 60, flexDirection: 'row', display: 'flex', justifyContent: 'flex-end' }}>
                                    <div style={{ fontFamily: 'Metropolis-Medium', fontSize: 14 }}>Email</div>
                                </div>
                                <div style={{ paddingLeft: 10, paddingRight: 10 }}>:</div>
                                <div style={{ fontFamily: 'Metropolis-Regular', fontSize: 14 }}>
                                    <div>
                                        {state.email}
                                    </div>
                                </div>
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'row', paddingTop: 10 }}>
                                <div style={{ width: 60, flexDirection: 'row', display: 'flex', justifyContent: 'flex-end' }}>
                                    <div style={{ fontFamily: 'Metropolis-Medium', fontSize: 14 }}>Contact</div>
                                </div>
                                <div style={{ paddingLeft: 10, paddingRight: 10 }}>:</div>
                                <div style={{ fontFamily: 'Metropolis-Regular', fontSize: 14 }}>
                                    <div>
                                        {state.contact}
                                    </div>
                                </div>
                            </div>
                        </Paper>

                    </div>
                }
            </div>
        </div>
    )
}
