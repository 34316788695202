import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles'
import { Paper, Divider, Fab } from '@material-ui/core';

import { NotificationManager } from 'react-notifications';

import PagePath from './components/PagePath';
import HttpHandler from './HttpHandler';
import DataValue from './components/DataValue'
import TemplateDataView from './TemplateDataView';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import SurveyRecordEdit from './SurveyRecordEdit';

const styles = theme => ({
}
);

const genderMapping = {
    'M': 'Male',
    'F': 'Female',
    'O': 'Others'
}

const maritalMapping = {
    'S': 'Single',
    'M': 'Married',
    'D': 'Divorced',
    'W': 'Widow'
}

class SurveyRecordView extends Component {

    state = {
        name: '',
        surveyName: '-',
        gender: '',
        age: '',
        marital: '',
        regNum: '',
        phone: '',
        email: '',
        sections: [],
        sectionData: {},
        showEditDialog: false,
        hasAccess: false,
        loadingInProgress: true,
        generalAshaName:''
    }

    _loadData() {
        
        var surveyId = this.props.match.params.surveyId;
        var recordId = this.props.match.params.recordId;

        HttpHandler.sendGet('/api/v1/survey/' + surveyId + '/data/' + recordId, (d) => {
            var general = d.general;
            var state = {
                regNum: general.regNum,
                generalAshaName: general.asha,
                name: general.name,
                age: general.age,
                phone: general.phone,
                email: general.email,
                gender: genderMapping[general.gender],
                marital: maritalMapping[general.maritalStatus],
                sections: d.sections,
                sectionData: d.data,
                hasAccess: true,
                loadingInProgress: false
            }

            this.setState(state);

            HttpHandler.sendGet('/api/v1/survey/' + surveyId, (d) => {
                this.setState({
                    surveyName: d.survey.name
                })
            }, (ec, em) => {

            });

        }, (errorCode, errorMessage) => {
            if (errorCode == 401) {
                this.setState({
                    hasAccess: false,
                    loadingInProgress: false
                })
            }
        });
    }

    componentDidMount() {
        this._loadData();
    }

    _editClicked() {
        this.setState({ showEditDialog: true })
    }

    _editCloseClicked() {
        this.setState({ showEditDialog: false })
    }

    _saveDone() {
        NotificationManager.success("Record saved", "Survey");

        this.setState({ showEditDialog: false })
        this._loadData();
    }

    _closeClicked  () {
        window.location = '/ics/survey/'+this.props.match.params.surveyId+'/data/';
    }


    render() {
        const { classes } = this.props;
        return (
            <div style={{ width: '100%' }}>
                {this.state.loadingInProgress &&
                    <div style={{ paddingTop: 40, color: '#000', fontFamily: 'Metropolis-Regular', fontSize: 15, textAlign: 'center' }}>We are loading the survey details.</div>
                }
                {(((HttpHandler.USER_TYPE !== 'coordinator' && HttpHandler.USER_TYPE !== 'survey') || !this.state.hasAccess) && this.state.loadingInProgress === false) &&
                    <div style={{ paddingTop: 40, color: '#FF0000', fontFamily: 'Metropolis-Regular', fontSize: 15, textAlign: 'center' }}>You are not authorized to access contents of this page.</div>
                }
                {(((HttpHandler.USER_TYPE === 'coordinator' || HttpHandler.USER_TYPE === 'survey') && this.state.hasAccess) && this.state.loadingInProgress === false) &&
                    <div>

                        <PagePath path={['Surveys', this.state.surveyName, 'Data', this.state.regNum]}>
                        </PagePath>

                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            {this.state.showEditDialog &&
                                <SurveyRecordEdit
                                    recordId={this.props.match.params.recordId}
                                    onSave={this._saveDone.bind(this)}
                                    onClose={this._editCloseClicked.bind(this)}
                                    surveyId={this.props.match.params.surveyId} />
                            }

                            {(this.state.showEditDialog === false) &&
                                <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 30, flexDirection: 'column' }}>
                                    <div>
                                        <Paper style={{ padding: 10, minWidth: 500 }}>
                                            <div style={{ flexDirection: 'row', display: 'flex', paddingBottom: 5, alignItems: 'center' }}>
                                                <div style={{ fontFamily: 'Metropolis-Regular', flex: 1 }}>Personal</div>
                                            </div>
                                            <Divider></Divider>

                                            <div style={{ paddingTop: 3, flexDirection: 'column', display: 'flex', marginTop: 10 }}>
                                                <DataValue title="Name" value={this.state.name} />
                                                <DataValue title="ASHA/Volunteer" value={this.state.generalAshaName} />
                                                <DataValue title="Gender" value={this.state.gender} />
                                                <DataValue title="Age" value={this.state.age} />
                                                <DataValue title="Phone" value={this.state.phone} />
                                            </div>
                                        </Paper>
                                    </div>
                                    <TemplateDataView sectionData={this.state.sectionData} sections={this.state.sections} />

                                </div>
                            }
                            
                        </div>

                    </div>
                }
            </div>
        )
    }
}


SurveyRecordView.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SurveyRecordView);