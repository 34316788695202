import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles'
import {TextField} from '@material-ui/core'


import SearchRoundedIcon from '@material-ui/icons/SearchRounded';

import SearchFieldModal from './SearchFieldModal'

const styles = theme => ({
    buttonContainer : {
        height:32,
        width:32, 
        border:'1px solid #1E88E5', 
        display:'flex', 
        justifyContent:'center',
        alignItems:'center', 
        backgroundColor:'#1E88E5',
        borderRadius:5, 
        marginBottom:7, 
        marginLeft:5,
        cursor:'pointer'
    }
});

class SearchableSelect extends Component {

    selectedItem = null;

    state = {
        value : ''
    }

    _onButtonClick() {
        var value = '';
        if(this.props.value !== null) {
            value = typeof this.props.value[this.props.displayField] !== 'undefined' ? this.props.value[this.props.displayField] : this.props.value;
        }
        SearchFieldModal.show(this.props.url,value, this.props.defs, (v) => {
            SearchFieldModal.hide();
            this.selectedItem = v;
            this.setState({
                value : v[this.props.displayField]
            });

            this.props.onSelect(v);
        });
    }

    _searchFieldChange(v) {
        this.setState({
            value : v.target.value
        });

        this.props.onSelect(null);
    }

    render() {
        const { classes } = this.props;
        return (
            <div style={{flexDirection:'row', display:'flex', alignItems:'flex-end', width:'100%'}}>
                 
                    <TextField
                        required = {this.props.isRequired}
                        error={this.props.error}
                        id={this.props.id}
                        label={this.props.label}
                        style={[this.props.textFieldStyle], {flex:1}}
                        value={ typeof this.props.value === 'undefined' || this.props.value === null || 
                             typeof this.props.value[this.props.displayField] === 'undefined' || this.props.value[this.props.displayField] === null
                               ? '' :  this.props.value[this.props.displayField]}
                        margin="normal"
                        helperText={this.props.helperText}
                        onChange={this._searchFieldChange.bind(this)}
                    />
                <div 
                    onClick={this._onButtonClick.bind(this)}
                    className={classes.buttonContainer}>
                    <SearchRoundedIcon style={{color:'#FFF'}}></SearchRoundedIcon>
                </div>
            </div>
        )
    }
}

SearchableSelect.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SearchableSelect);