import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles'


import { useHistory } from 'react-router-dom';
import { Fab, Paper, TextField, Divider, Button, Collapse } from '@material-ui/core';

import AddIcon from '@material-ui/icons/AddRounded';
import PersonAddRoundedIcon from '@material-ui/icons/PersonAddRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';

import { NotificationManager } from 'react-notifications';

import commonClasses from './ics.module.css'

import PagePath from './components/PagePath';
import HttpHandler from './HttpHandler'
import ErrorDialog from './components/ErrorDialog';
import ConfirmationDialog from './components/ConfirmationDialog';
import CampTeamTypeSearchSelect from './components/CampTeamTypeSearchSelect';
import UserSearchSelect from './components/UserSearchSelect';
import Utils from './Utils'

import CTable from './components/CTable'

const useStyles = makeStyles({
});


function CampPreTeamEditCreate(props) {

    const [state, setState] = useState({
        name: '',
        nameError: false,
        nameErrorMessage: '',
        type: '',
        typeError: false,
        typeErrorMessage: '',
        org: props.registeredUser ? "Registered User" : '',
        orgError: false,
        orgErrorMessage: '',
        email: '',
        emailError: false,
        emailErrorMessage: '',
        phoneError: false,
        phone: '',
        phoneErrorMessage: '',
        regUser: ''
    });

    const _onPhoneChanged = (v) => {
        var val = v.target.value;
        if (val.length > 11) {
            val = state.phone
        }
        else if (val.indexOf('.') >= 0) {
            val = state.phone;
        }
        else if (isNaN(val)) {
            val = state.phone;
        }
        setState({
            ...state,
            phone: val,
            phoneError: false,
            phoneErrorMessage: ''
        })
    }

    const _saveClicked = () => {
        var valid = true;
        var myState = { ...state };

        var name = state.name.trim();

        if (!props.registeredUser) {
            if (name.length === 0) {
                valid = false;
                myState.nameError = true;
                myState.nameErrorMessage = "Mandatory";
            }

            if (name.length > 100) {
                valid = false;
                myState.nameError = true;
                myState.nameErrorMessage = "Name should not exceed 100 characters";
            }

            var org = state.org.trim();
            if (org.length === 0) {
                valid = false;
                myState.orgError = true;
                myState.orgErrorMessage = "Mandatory";
            }
            else if (org.length > 100) {
                valid = false;
                myState.orgError = true;
                myState.orgErrorMessage = "Organization should not exceed 100 characters";
            }
        }

        var phone = state.phone.trim();
        if (phone === '') {
            valid = false;
            myState.phoneError = true;
            myState.phoneErrorMessage = 'Mandatory'
        }
        else if (!Utils.isValidPhone(phone)) {
            valid = false;
            myState.phoneError = true;
            myState.phoneErrorMessage = 'Invalid phone'
        }

        var type = state.type;
        if (typeof type === 'undefined' || type === null) {
            valid = false;
            myState.typeError = true;
            myState.typeErrorMessage = 'Mandatory'
        }

        if (props.registeredUser && (state.regUser === '' || state.regUser === null)) {
            valid = false;
            myState.regUserError = true;
            myState.regUserErrorMessage = 'Mandatory'
        }

        if ((state.type === '' || state.type === null)) {
            valid = false;
            myState.typeError = true;
            myState.typeErrorMessage = 'Mandatory'
        }

        var email = state.email.trim();
        if (email.length > 150 || (email.length !== 0 && !Utils.isValidEmail(email))) {
            myState.emailError = true;
            myState.emailErrorMessage = "Invalid Email";
            valid = false;
        }


        if (!valid) {
            setState(myState);
            return;
        }

        //Check for duplicate code or name
        console.log(state.regUser);
        var req = {
            name: props.registeredUser ? state.regUser.fullName : name,
            type: state.type.name,
            typeId: state.type.id,
            organization: state.org,
            email: state.email.trim(),
            phone: phone,
            userId: state.regUser === '' || state.regUser === null ? null : state.regUser.id

        }

        var url = '/api/v1/campteam/new';
        if (props.memberId > 0) {
            url = '/api/v1/campteam/' + props.memberId;
        }
        HttpHandler.sendPost(url, req, (data) => {
            if (props.memberId > 0) {
                NotificationManager.success("Team member record updated.", name);
            }
            else {
                NotificationManager.success("Team member record created.", name);
            }
            props.onDone();
        }, (errorCode, errorMessage) => {
            console.log(errorMessage);
            ErrorDialog.show("There was error in saving team member record");
        })

    }

    const _closeClicked = () => {
        props.onClose();
    }

    const _loadDetails = (record) => {
        var istate = {
            ...state,
            name: record.name,
            nameError: false,
            nameErrorMessage: '',
            type: record.type,
            typeError: false,
            typeErrorMessage: '',
            org: record.organization,
            orgError: false,
            orgErrorMessage: '',
            email: record.email,
            emailError: false,
            emailErrorMessage: '',
            phone: record.phone,
            phoneError: false,
            phoneErrorMessage: '',
            regUser: record.userId === null ? "" : { fullName: record.name, id: record.userId }

        }
        setState(istate);
    }


    useEffect(() => {

        if (props.memberId > 0) {
            _loadDetails(props.record);
        }
        else {
            setState({
                ...state,
                name: '',
                nameError: false,
                nameErrorMessage: '',
                type: '',
                typeError: false,
                typeErrorMessage: '',
                org: props.registeredUser ? "Registered User" : '',
                orgError: false,
                orgErrorMessage: '',
                email: '',
                emailError: false,
                emailErrorMessage: '',
                phone: '',
                phoneError: false,
                phoneErrorMessage: '',
                regUser: ''
            })
        }
    }, [props]);

    const _typeSelected = (v) => {
        setState({
            ...state,
            type: v
        })
    }

    const _userSelected = (v) => {
        setState({
            ...state,
            regUser: v,
            phone: v === null ? "" : v.phone === null ? "" : v.phone,
            org: v === null ? "" : v.org === null ? "" : v.org,
            email: v === null ? "" : v.emailId === null ? "" : v.emailId
        })
    }


    return (
        <div>
            <Paper style={{ padding: 10, width: 500 }}>
                <div className={commonClasses.headerBar}>{props.heading}</div>
                <div style={{ paddingBottom: 20 }}>
                    <form noValidate autoComplete="off">
                        {props.registeredUser &&
                            <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'flex-end' }}>
                                <div style={{ flex: 1, paddingRight: 0 }}>
                                    <UserSearchSelect
                                        style={{ flex: 1 }}
                                        label="User"
                                        value={state.regUser}
                                        error={state.regUserError}
                                        helperText={state.regUserErrorMessage}
                                        userSelected={_userSelected}
                                    ></UserSearchSelect>
                                </div>

                            </div>
                        }

                        {(props.registeredUser === false) &&
                            <TextField
                                required
                                error={state.nameError}
                                id='name'
                                label="Name"
                                fullWidth
                                value={state.name}
                                margin="normal"
                                helperText={state.nameErrorMessage}
                                inputProps={{ maxLength: 100 }}
                                onChange={(v) => {
                                    setState({
                                        ...state,
                                        name: v.target.value,
                                        nameError: false,
                                        nameErrorMessage: ''
                                    })
                                }}
                            />
                        }



                        <div style={{ flex: 1, paddingLeft: 0 }}>
                            <CampTeamTypeSearchSelect
                                style={{ flex: 1 }}
                                label="Type"
                                value={state.type}
                                error={state.typeError}
                                helperText={state.typeErrorMessage}
                                typeSelected={_typeSelected}
                            ></CampTeamTypeSearchSelect>
                        </div>


                        <TextField
                            error={state.orgError}
                            id='org'
                            required
                            disabled={props.registeredUser}
                            label="Organization"
                            fullWidth
                            value={state.org}
                            margin="normal"
                            helperText={state.orgErrorMessage}
                            inputProps={{ maxLength: 100 }}
                            onChange={(v) => {
                                setState({
                                    ...state,
                                    org: v.target.value,
                                    orgError: false,
                                    orgErrorMessage: ''
                                })
                            }}
                        />

                        <div style={{ width: '100%', flexDirection: 'row', display: 'flex' }}>
                            <TextField
                                disabled={props.registeredUser}
                                error={state.emailError}
                                id='email'
                                label="Email"
                                style={{ flex: 1 }}
                                value={state.email}
                                margin="normal"
                                inputProps={{ maxLength: 200 }}
                                helperText={state.emailErrorMessage}
                                onChange={(v) => {
                                    setState({
                                        ...state,
                                        email: v.target.value,
                                        emailError: false,
                                        emailErrorMessage: ''
                                    })
                                }}
                            />
                            <TextField
                                required
                                disabled={props.registeredUser}
                                error={state.phoneError}
                                id='phone'
                                label="Phone"
                                style={{ width: 200, marginLeft: 20 }}
                                value={state.phone}
                                margin="normal"
                                inputProps={{ maxLength: 15 }}
                                helperText={state.phoneErrorMessage}
                                onChange={_onPhoneChanged}
                            />
                        </div>

                    </form>
                </div>
                <Divider style={{ marginLeft: -10, marginRight: -10 }}></Divider>
                <div style={{ paddingTop: 10, flexDirection: 'row', display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        onClick={_saveClicked}
                        variant="contained" color="primary">Save</Button>
                    <Button
                        onClick={_closeClicked}
                        variant="contained" color="default" style={{ marginLeft: 20 }}>Close</Button>
                </div>
            </Paper>
        </div>
    )
}

const userSearchDef = {
    fullName: {
        title: 'Name',
        align: 'left'
    },
    type: {
        title: 'Type',
        align: 'left',
        renderer: (row, data) => {
            return (
                <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center' }} >
                    {row.typeName}
                </div>
            )
        }
    }
}


export default function CampPreTeam(props) {


    const dataTable = useRef(null);
    const history = useHistory();

    const [state, setState] = useState({
        editCreateDialog: false,
        members: [],
        selectedMemberId: -1,
        selectedMember: null,
        registeredUser: false,
        addEditUIHeading: "Register team member"
    });

    const campTeamTableDef = [
        {
            title: "Name",
            field: 'name',
            align: 'left',
            renderer: (row, data) => {
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center', fontFamily: 'Metropolis-Regular' }} >
                        {row.name}
                    </div>
                )
            }

        },
        {
            title: "Type",
            field: 'type',
            align: 'left',
            renderer: (row, data) => {
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center' }} >
                        {row.type.name}
                    </div>
                )
            }
        },
        {
            title: "Organization",
            field: 'organization',
            align: 'left',
            renderer: (row, data) => {
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center' }} >
                        {row.organization}
                    </div>
                )
            }
        },
        {
            title: "Email",
            field: 'email',
            align: 'left',
            renderer: (row, data) => {
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center' }} >
                        {row.email}
                    </div>
                )
            }
        },
        {
            title: "Phone",
            field: 'phone',
            align: 'left',
            renderer: (row, data) => {
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center' }} >
                        {row.phone}
                    </div>
                )
            }
        },
        {
            title: "",
            field: 'id',
            style: { minWidth: 120, width: 120 },
            renderer: (row, data) => {
                return (
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        {(HttpHandler.USER_TYPE === 'admin' || HttpHandler.USER_TYPE === 'coordinator') &&
                            <div
                                onClick={() => _editClicked(row)}
                                style={{ height: 24, cursor: 'pointer' }}>
                                <EditRoundedIcon style={{ color: '#03A9F4' }}></EditRoundedIcon>
                            </div>
                        }
                        {(HttpHandler.USER_TYPE === 'admin' || HttpHandler.USER_TYPE === 'coordinator') &&
                            <div
                                onClick={() => _deleteClicked(row)}
                                style={{ marginLeft: 20, height: 24, cursor: 'pointer' }}>
                                <DeleteForeverRoundedIcon style={{ color: '#D32F2F' }}></DeleteForeverRoundedIcon>

                            </div>
                        }

                    </div>
                )
            }

        }
    ]

    const _editClicked = (row) => {
        setState({
            ...state,
            editCreateDialog: true,
            registeredUser: row.userId !== null && row.userId !== 0,
            selectedMemberId: row.id,
            selectedMember: row,
            addEditUIHeading: "Modify team member"
        })
    }

    const _deleteClicked = (row) => {
        ConfirmationDialog.show('Are you sure you want to delete this team member ?', () => {
            HttpHandler.sendDelete('/api/v1/campteam/' + row.id, (d) => {
                NotificationManager.success("Record deleted.", row.name);
                dataTable.current.reload();
            }, (errorCode, errorMessage) => {
                ErrorDialog.show("There was error in deleting record");
            })
        });
    }

    useEffect(() => {

    }, []);

    const _createMember = () => {
        setState({
            ...state,
            editCreateDialog: true,
            selectedMemberId: -1,
            registeredUser: false,
            addEditUIHeading: "Register team member"
        });

    }

    const _getCampTeamTableData = (cb, offset, limit, searchString) => {
        HttpHandler.sendGet('/api/v1/campteam/all?offset=' + offset + '&limit=' + limit + '&s=' + searchString, (data) => {
            cb(data.result, data.count)
        }, (errorCode, errorMessage) => {
            console.log(errorMessage);
        })
    }

    const _memberAdded = () => {
        setState({
            ...state,
            editCreateDialog: false
        });
        dataTable.current.reload();
    }

    const _teamTypesSelected = () => {
        history.push('/ics/campteamtypes');
    }

    const _addRegisteredUser = () => {
        setState({
            ...state,
            editCreateDialog: true,
            selectedMemberId: -1,
            registeredUser: true,
            addEditUIHeading: "Register team member"
        });
    }


    return (
        <div>
            {(HttpHandler.USER_TYPE !== 'admin' && HttpHandler.USER_TYPE !== 'coordinator') &&
                <div style={{ paddingTop: 40, color: '#FF0000', fontFamily: 'Metropolis-Regular', fontSize: 15, textAlign: 'center' }}>You are not authorized to access contents of this page.</div>
            }
            {(HttpHandler.USER_TYPE === 'admin' || HttpHandler.USER_TYPE === 'coordinator') &&
                <div>
                    <div style={{ marginRight: 20 }}>
                        <PagePath path={['Screening Team', 'All']}>
                            {(HttpHandler.USER_TYPE === 'admin') &&
                                <Button
                                    onClick={_teamTypesSelected}
                                    style={{ marginRight: 20 }} variant="contained" color="secondary">Member types</Button>
                            }
                            <Fab
                                onClick={_addRegisteredUser}
                                style={{ marginRight: 20 }}
                                color="primary" aria-label="add" size="medium" title="Add Registered member">
                                <PersonAddRoundedIcon />
                            </Fab>
                            <Fab
                                onClick={_createMember}
                                color="primary" aria-label="add" size="medium" title="Add New member">
                                <AddIcon />
                            </Fab>
                        </PagePath>
                    </div>

                    <div style={{ marginTop: 20, paddingBottom: 30, paddingRight: 10 }}>
                        <div >
                            <Collapse in={state.editCreateDialog} style={{ display: 'flex', justifyContent: 'center' }}>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <CampPreTeamEditCreate memberId={state.selectedMemberId}
                                        heading={state.addEditUIHeading}
                                        record={state.selectedMember}
                                        onDone={_memberAdded}
                                        registeredUser={state.registeredUser}
                                        onClose={() => { setState({ ...state, editCreateDialog: false }) }} />
                                </div>
                            </Collapse>
                            <Collapse in={!state.editCreateDialog} style={{ display: 'flex', justifyContent: 'center' }}>
                                <Paper style={{ padding: 10, width: 920 }}>
                                    <div style={{ width: 900 }}>
                                        <CTable
                                            ref={dataTable}
                                            data={_getCampTeamTableData}
                                            columns={campTeamTableDef}>
                                        </CTable>
                                    </div>
                                </Paper>
                            </Collapse>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

