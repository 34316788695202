export default class HttpHandler {

    //static BASE_URL = 'http://192.168.0.22/ics'
    //static BASE_URL = 'http://35.154.224.107/ics'
    static BASE_URL = 'http://localhost:8080'
    static SESSION_SECRET = "";
    static FULL_NAME = "";
    static USER_TYPE = "";
    static USER_ID = "";
    static DEVICE_ID = "";
    static SCREENING_MEMBER_TYPE = ""

    static _handleResponse(res) {
        var statusCode = res.status;
        var data = null;    
        if (statusCode === 401) {
            //Possibly we do not have a message. Even otherwise send a custom message.
            data = { 'status': 'FAILED', 'message': 'Not authorized' };
        }
        else {
            data = res.json();
        }

        return Promise.all([statusCode, data]).
            then(res => ({
                statusCode: res[0],
                data: res[1]
            }));
    }

    static _handleResponse_excel(res) {
        var statusCode = res.status;
        var data = null;    
        if (statusCode === 401) {
            //Possibly we do not have a message. Even otherwise send a custom message.
            
            data = { 'status': 'FAILED', 'message': 'Not authorized' };
        }
        else {
            data = res.blob();
        }

        return Promise.all([statusCode, data]).
            then(res => ({
                statusCode: res[0],
                data: res[1]
            }));
    }

    static sendPost(urlPart, data, successHandler, errorHandler, teamId) {

        var url = this.BASE_URL + urlPart;

        var headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };

        headers['x-session-secret'] = HttpHandler.SESSION_SECRET;
        headers['x-user-id'] = HttpHandler.USER_ID;

        fetch(url, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(data)
        })
            .then(this._handleResponse)
            .then((result) => {
                const { statusCode, data } = result;
                if (statusCode !== 200) {
                    errorHandler(statusCode, data)
                }
                else {
                    successHandler(data)
                }
            })
            .catch((error) => {
                errorHandler(-1, error);
            });
    }
      

    static sendGet(urlPart, successHandler, errorHandler, teamId) {

        var url = this.BASE_URL + urlPart;

        var headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };

        headers['x-session-secret'] = HttpHandler.SESSION_SECRET;
        headers['x-user-id'] = HttpHandler.USER_ID;
        fetch(url, {
            method: 'GET',
            headers: headers
        })
            .then(this._handleResponse)
            .then((result) => {
                const { statusCode, data } = result;
                if (statusCode !== 200) {
                    errorHandler(statusCode, data)
                    console.log("succes")
                }
                else {
                    successHandler(data)
                    console.log("fail")
                }
            })
            .catch((error) => {
                errorHandler(-1, error);
            });
    }


    static sendExcelGet(urlPart, successHandler, errorHandler, teamId) {

        var url = this.BASE_URL + urlPart;
        var headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            
        };

        headers['x-session-secret'] = HttpHandler.SESSION_SECRET;
        headers['x-user-id'] = HttpHandler.USER_ID;
        
        fetch(url, {
            method: 'GET',
            headers: headers,
            responseType: 'application/octet-stream',
              
        })
        .then(this._handleResponse_excel)
        .then((result) => {
            const { statusCode, data } = result;
            if (statusCode !== 200) {
                errorHandler(statusCode, data)
                console.log("fail")
            }
            else {
                successHandler(data)
                console.log("success")
            }
        })
        
            .catch((error) => {
                errorHandler(-1, error);
            });
    }

    static sendDelete(urlPart, successHandler, errorHandler, teamId) {

        var url = this.BASE_URL + urlPart;

        var headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };

        headers['x-session-secret'] = HttpHandler.SESSION_SECRET;
        headers['x-user-id'] = HttpHandler.USER_ID;

        fetch(url, {
            method: 'DELETE',
            headers: headers
        })
            .then(this._handleResponse)
            .then((result) => {
                const { statusCode, data } = result;
                if (statusCode !== 200) {
                    errorHandler(statusCode, data)
                }
                else {
                    successHandler(data)
                }
            })
            .catch((error) => {
                errorHandler(-1, error);
            });
    }
}
