import React, { useRef, useEffect, useState } from 'react';

import { Paper, Fab, Tooltip, Button, Divider, Dialog, DialogActions, Switch, FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { useHistory } from 'react-router-dom';


import PersonRoundedIcon from '@material-ui/icons/PersonRounded';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import CloudDownloadRoundedIcon from '@material-ui/icons/CloudDownloadRounded';
import AddIcon from '@material-ui/icons/AddRounded';
import LocalHospitalRoundedIcon from '@material-ui/icons/LocalHospitalRounded';

import { NotificationManager } from 'react-notifications';

import PagePath from './components/PagePath';
import HttpHandler from './HttpHandler'
import CTable from './components/CTable'
import ConfirmationDialog from './components/ConfirmationDialog';
import CampRegister from './CampRegister';
import CampMedicalRecord from './CampMedicalRecord'
import ProbableCaseDialog from './ProbableCaseDialog';
import ErrorDialog from './components/ErrorDialog'
import commonClasses from './ics.module.css'
import { useLocation } from "react-router-dom";
import { useLayoutEffect } from 'react';


export default function CampData(props) {

    let campId = useRef(0);
    const dataTable = useRef(null);
    let history = useHistory();
    const location = useLocation();


    let isMedicalUi = false
    if (location.state && location.state.row.showMedicalUi) {
        isMedicalUi = true

    }

    const [state, setState] = useState({
        someDate: new Date(),
        showRegisterUI: false,
        showMedicalUI: isMedicalUi,
        recordId: -1,
        medicalAdded: false,
        regNum: '',
        showProbaleCaseDialog: false,
        loadingInProgress: false,
        hasAccess: true,
        campStatus: 'SC',
        showCounselling: false,
        counsellingType: '',
        counsellingComment: '',
        testType: '',
        testComment: ''
    });



    useEffect(() => {

        if (location.state && location.state.row) {
            _medicalRecordClicked(location.state.row)

        }

    }, [])

    const campDataTableDef = [
        {
            title: "Reg No.",
            field: "regNum",
            align: 'left',
            style: { minWidth: 140, width: 140 },
            renderer: (row, data) => {
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'left' }} >
                        {row.regNum}
                    </div>
                )
            }
        },
        {
            title: "Name",
            field: 'name',
            align: 'left',
            style: { minWidth: 250, width: 250 },
            renderer: (row, data) => {
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'left' }} >
                        {row.name}
                    </div>
                )
            }

        },

        {
            title: "Gender",
            field: "gender",
            align: 'left',
            style: { minWidth: 80, width: 80 },
            renderer: (row, data) => {
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'left' }} >
                        {row.gender === 'M' ? 'Male' : (row.gender === 'F' ? 'Female' : 'Others')}
                    </div>
                )
            }
        },
        {
            title: "Age",
            field: 'age',
            align: 'left',
            style: { minWidth: 80, width: 80 },
            renderer: (row, data) => {
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'right' }} >
                        {row.age}
                    </div>
                )
            }

        },
        {
            title: "Mobile Phone",
            field: 'phone',
            align: 'left',
            style: { minWidth: 120, width: 120 },
            renderer: (row, data) => {
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'left' }} >
                        {row.phone}
                    </div>
                )
            }

        },
        {
            title: "",
            field: 'id',
            style: { minWidth: 120, width: 120 },
            renderer: (row, data) => {
                return (
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <Tooltip title="Person Details" arrow>
                            <div
                                onClick={() => _showDetailsClicked(row.id)}
                                style={{ height: 24, cursor: 'pointer' }}>
                                <PersonRoundedIcon style={{ color: '#03A9F4' }}></PersonRoundedIcon>
                            </div>
                        </Tooltip>
                        {(HttpHandler.USER_TYPE === 'doctor' ) && (state.campStatus === 'RU') &&
                            <Tooltip title="Medical Record" arrow>
                                <div
                                    onClick={() => _medicalRecordClicked(row)}
                                    style={{ marginLeft: 20, height: 24, cursor: 'pointer' }}>
                                    <LocalHospitalRoundedIcon style={{ color: '#03A9F4' }}></LocalHospitalRoundedIcon>
                                </div>
                            </Tooltip>
                        }
                        {(HttpHandler.USER_TYPE === 'coordinator') && (state.campStatus != 'RU') &&
                            <Tooltip title="Medical Record" arrow>
                                <div
                                    onClick={() => _medicalRecordClicked(row)}
                                    style={{ marginLeft: 20, height: 24, cursor: 'pointer' }}>
                                    <LocalHospitalRoundedIcon style={{ color: '#03A9F4' }}></LocalHospitalRoundedIcon>
                                </div>
                            </Tooltip>
                        }
                        {(HttpHandler.USER_TYPE === 'coordinator' || HttpHandler.USER_TYPE === 'screening') && (state.campStatus === 'RU') &&
                            <Tooltip title="Delete" arrow>
                                <div
                                    onClick={() => _deleteClicked(row.id)}
                                    style={{ marginLeft: 20, height: 24, cursor: 'pointer' }}>
                                    <DeleteForeverRoundedIcon style={{ color: '#D32F2F' }}></DeleteForeverRoundedIcon>
                                </div>
                            </Tooltip>
                        }


                        {(row.probableCase === 1 && (HttpHandler.USER_TYPE === 'doctor' || HttpHandler.USER_TYPE === 'data entry' || HttpHandler.USER_TYPE === 'coordinator' || HttpHandler.USER_TYPE === 'screening')) &&
                            <Tooltip title="Probable case" arrow>
                                <div
                                    onClick={() => _showProbableDetailsCliced(row)}
                                    style={{ marginLeft: 20, height: 24, cursor: 'pointer' }}>
                                    <img src={require('./images/cancer_probable.png')} style={{ height: 24, width: 24 }}></img>
                                </div>
                            </Tooltip>
                        }
                             {/* Making cancel button */}
                        {(row.probableCase === (1 || 2) && (HttpHandler.USER_TYPE === 'doctor' )) &&
                            <Tooltip title="Probable case" arrow>
                                <div
                                    onClick={()=>_cancelProbableDetails(row)}
                                    style={{ marginLeft: 20, height: 24, cursor: 'pointer' }}>
                                    <img src={require('./images/cross.gif')} style={{ height: 20, width: 20, opacity: 0.7 }}></img>
                                </div>
                            </Tooltip>
                        }
                        {(row.probableCase === 2 && (HttpHandler.USER_TYPE === 'doctor' || HttpHandler.USER_TYPE === 'data entry' || HttpHandler.USER_TYPE === 'coordinator' || HttpHandler.USER_TYPE === 'screening')) &&
                            <div
                                onClick={() => _showProbableDetailsCliced(row)}
                                style={{ marginLeft: 20, height: 24, cursor: 'pointer' }}>
                                <img src={require('./images/cancer_confirm.png')} style={{ height: 24, width: 24 }}></img>
                            </div>
                        }

                        {(row.probableCase === 0 && (HttpHandler.USER_TYPE === 'doctor' || HttpHandler.USER_TYPE === 'data entry' || HttpHandler.USER_TYPE === 'admin' || HttpHandler.USER_TYPE === 'screening')) &&
                            <div
                                style={{ marginLeft: 20, height: 24, width: 24 }}>
                            </div>
                        }

                    </div>
                )
            }

        }
    ]


    const _cancelProbableDetails=(row)=>{
        ConfirmationDialog.show('Are you sure you want to mark for no cancer?', ()=>cancelProbableCase(row));
        // console.log(props.match.params.campId );
        // console.log(row.id);

    }
    const cancelProbableCase=(row)=>{
        var req = { cancerType : "NO", tests:[]}
        HttpHandler.sendPost('/api/v1/camp/' + props.match.params.campId + '/' + row.id + '/probable', req , (d) => {
            NotificationManager.success('Marked as Cancer Free','Done');
              setTimeout(()=>{window.location.reload()},4000);

            // props.onSave();
        }, (errorCode, errorMessage) => {
            ErrorDialog.show("There was an error saving test recommendation.");
        });

    }


    const _showProbableDetailsCliced = (row) => {
        history.push('/ics/probable/' + row.id);
    }

    const _medicalRecordClicked = (row) => {
        setState({ ...state, showMedicalUI: true, recordId: row.id, medicalAdded: row.medicalDone, regNum: row.regNum, probableCase: row.probableCase, counsellingReq: row.counsellingReq })

    }





    useEffect(() => {
        campId.current = props.match.params.campId;
    }, [props.match.params.campId]);

    const _confirmDelete = (id) => {
        HttpHandler.sendDelete('/api/v1/camp/' + props.match.params.campId + '/data/' + id, (d) => {
            setState({ ...state, someDate: new Date() });
            dataTable.current.reload();
        }, (errorCode, errorMessage) => {
            console.log("Failed");
        })
    }

    const _deleteClicked = (id) => {
        ConfirmationDialog.show('Are you sure you want to delete this camp record ?', () => _confirmDelete(id))
    }

    const _showDetailsClicked = (id) => {
        history.push('/ics/camp/' + props.match.params.campId + '/data/' + id);
    }

    const _getCampData = (cb, offset, limit, searchString) => {
        HttpHandler.sendGet('/api/v1/camp/' + props.match.params.campId + '/data?offset=' + offset + '&limit=' + limit + '&s=' + searchString, (data) => {
            cb(data.data, data.count)


            HttpHandler.sendGet('/api/v1/camp/' + props.match.params.campId, (d) => {
                setState({
                    ...state,
                    campStatus: d.camp.status,
                });
            }, (ec, em) => {
                console.log(em);
            });

        }, (errorCode, errorMessage) => {
            console.log(errorMessage);
            if (errorCode === 401) {
                setState({ ...state, hasAccess: false });
            }
        })
    }


    const _closeRegister = () => {
        setState({ ...state, showRegisterUI: false })
    }

    const _onRegisterSave = () => {
        setState({ ...state, showRegisterUI: false })
    }

    const _closeMedical = () => {
        setState({ ...state, showMedicalUI: false })

    
        history.push({pathname : '/ics/camp/' + campId.current , 
        state : {setShowRegisterAfterMedical : true}})

        

                                   
    }

    const _onMedicalSave = () => {
        setState({ ...state, showMedicalUI: true })
    }

    const _onShowProbaleCaseDialogClicked = () => {
        setState({ ...state, showProbaleCaseDialog: true })
    }

    const _onProbaleCauseSave = () => {
        setState({ ...state, showProbaleCaseDialog: false })
    }

    const _showRegisterDialog = () => {
        setState({ ...state, showRegisterUI: true, recordId: -1 })
    }

    const _closeCounselling = () => {
        setState({ ...state, showCounselling: false })
    }
    const _openCounselling = () => {
        let counsellingType = ''
        let counsellingComment = ''
        HttpHandler.sendGet(`/api/v1/camp/data/${state.recordId}/counsellingInfo`, (d) => {
            if (d.status === 'success') {
                counsellingType = d.counsellingType
                counsellingComment = d.counsellingComment
                setState({ ...state, showCounselling: true, testType: counsellingType, testComment: counsellingComment, counsellingType, counsellingComment })
            } else {
                setState({ ...state, showCounselling: true, testType: state.counsellingType, testComment: state.counsellingComment })
            }
        }, (errorCode, errorMessage) => {
            console.log(errorMessage);
            setState({ ...state, showCounselling: true, testType: state.counsellingType, testComment: state.counsellingComment })
        })
    }
    const _handleCounsellingTypeChange = (v) => {
        setState({
            ...state,
            testType: v.target.value
        })
    }

    const _saveCounselling = () => {
        var req = {
            counsellingType: state.testType,
            counsellingComment: state.testComment
        }

        HttpHandler.sendPost('/api/v1/camp/' + state.recordId + '/submitCounselling', req, (res) => {
            setState({ ...state, counsellingType: '', counsellingComment: '', showCounselling: false });
        }, (errorCode, errorMessage) => {
            console.log(errorMessage);
            ErrorDialog.show('Error while adding counselling session type');
        });
    }

    return (
        <div>
            {state.loadingInProgress &&
                <div style={{ paddingTop: 40, color: '#000', fontFamily: 'Metropolis-Regular', fontSize: 15, textAlign: 'center' }}>We are loading the camp details.</div>
            }

            {(((HttpHandler.USER_TYPE !== 'coordinator' && HttpHandler.USER_TYPE !== 'data entry' && HttpHandler.USER_TYPE !== 'screening' && HttpHandler.USER_TYPE !== 'doctor') || !state.hasAccess) && state.loadingInProgress === false) &&
                <div style={{ paddingTop: 40, color: '#FF0000', fontFamily: 'Metropolis-Regular', fontSize: 15, textAlign: 'center' }}>You are not authorized to access contents of this page.</div>
            }
            {(((HttpHandler.USER_TYPE === 'coordinator' || HttpHandler.USER_TYPE === 'data entry' || HttpHandler.USER_TYPE === 'screening' || HttpHandler.USER_TYPE === 'doctor') && state.hasAccess) && state.loadingInProgress === false) &&
                <div>
                    <div style={{ marginRight: 20 }}>
                        <PagePath path={state.showMedicalUI === true ? ['Screening Camp', 'Data', state.regNum, 'Screening'] : ['Screening Camp', 'Data']}>
                            {(HttpHandler.USER_TYPE === 'coordinator') &&
                                <Fab
                                    color="primary" aria-label="Download" size="medium" title='Download Data'>
                                    <a href={'/api/v1/camp/' + props.match.params.campId + '/download/excel'} download>
                                        <CloudDownloadRoundedIcon style={{ color: '#FFF' }} />
                                    </a>
                                </Fab>
                            }
                        </PagePath>
                    </div>


                    <Dialog open={state.showCounselling} onClose={_closeCounselling}>
                        <div style={{ padding: 5, width: 560 }}>
                            <div className={commonClasses.headerBar}>Counselling</div>
                        </div>
                        <div style={{ width: '100%', backgroundColor: '#FAFAFA', padding: 20, display: 'flex' }}>
                            <div style={{ flex: 1 }}>

                                <FormControl style={{ flex: 1, marginTop: 16, width: 170, marginBottom: 10 }}>
                                    <InputLabel id="probableCancerType-label">Counselling Type</InputLabel>
                                    <Select
                                        labelId="probableCancerType-label"
                                        id="probableCancerType-select"
                                        value={state.testType}
                                        onChange={_handleCounsellingTypeChange}
                                    >
                                        <MenuItem value="FI">Financial</MenuItem>
                                        <MenuItem value="RE">Refusal</MenuItem>
                                        <MenuItem value="FE">Fear</MenuItem>
                                        <MenuItem value="TC">Tobacco Cessation Session</MenuItem>
                                        <MenuItem value="OT">Others</MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField
                                    id='comment'
                                    label="Comment"
                                    fullWidth
                                    multiline={true}
                                    rows={2}
                                    value={state.testComment}
                                    margin="normal"
                                    inputProps={{ maxLength: 100 }}
                                    onChange={(v) => {
                                        setState({
                                            ...state,
                                            testComment: v.target.value,
                                        })
                                    }}
                                />
                            </div>

                        </div>
                        <Divider />
                        <DialogActions>
                            <div style={{ paddingTop: 10, flexDirection: 'row', display: 'flex', justifyContent: 'flex-end' }}>
                                <Button
                                    onClick={_saveCounselling}
                                    variant="contained" color="primary" style={{ marginLeft: 20 }}>Save</Button>
                                <Button
                                    onClick={() => { setState({ ...state, showCounselling: false, }) }}
                                    variant="contained" color="default" style={{ marginLeft: 20 }}>Close</Button>
                            </div>
                        </DialogActions>
                    </Dialog>



                    <ProbableCaseDialog
                        campId={props.match.params.campId}
                        recordId={state.recordId}
                        onSave={_onProbaleCauseSave}
                        isVisible={state.showProbaleCaseDialog}
                        onClose={() => { setState({ ...state, showProbaleCaseDialog: false }) }} />
                    <div style={{ marginTop: 20, paddingBottom: 30, flexDirection: 'row', display: 'flex', justifyContent: 'center' }}>
                        {state.showRegisterUI &&
                            <div>
                                <CampRegister
                                    recordId={state.recordId}
                                    onSave={_onRegisterSave}
                                    onClose={_closeRegister}
                                    campId={campId.current} />
                            </div>
                        }



                        {state.showMedicalUI &&
                            <div>

                                <CampMedicalRecord
                                    probableCase={state.probableCase}
                                    onProbaleCase={_onShowProbaleCaseDialogClicked}
                                    openCouncil={_openCounselling}
                                    recordId={state.recordId}
                                    medicalAdded={state.medicalAdded}
                                    onSave={_onMedicalSave}
                                    onClose={_closeMedical}
                                    campId={campId.current}
                                    campStatus={state.campStatus}
                                    counsellingReq={state.counsellingReq} />
                            </div>
                        }

                        {(state.showRegisterUI === false && state.showMedicalUI === false) &&

                            <div>

                                <Paper style={{ padding: 10 }}>
                                    <div style={{}}>
                                        <CTable
                                            ref={dataTable}
                                            dt={state.someDate}
                                            pageSize={10}
                                            data={_getCampData}
                                            columns={campDataTableDef}>
                                        </CTable>
                                    </div>
                                </Paper>
                            </div>
                        }
                    </div>
                </div>
            }
        </div>
    )

}