import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles'
import { Paper, TextField, CircularProgress } from '@material-ui/core'
import HttpHandler from './HttpHandler';


const styles = theme => ({
    leftPaneContainer: {
        width: 400,
        backgroundColor: '#263238'
    },
    leftPaneOverlay: {
        position: 'relative',
        top: -500,
        left: 0,
        zIndex: 999
    },
    logoContainer: {
        width: 110,
        height: 110,
        borderRadius: 100,
        backgroundColor: '#FFF',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        marginLeft: 145,
        marginTop: 20
    },
    logoTitle: {
        fontFamily: 'Metropolis-SemiBold',
        color: '#FFF',
        fontSize: 16
    },
    rightContainer: {
        paddingLeft: 30,
        paddingTop: 30,
        flex: 1,
        display: 'flex',
        flexDirection: 'column'
    },
    welcomeStyle: {
        fontFamily: 'Metropolis-Light',
        fontSize: 32,
        color: '#335fa5',
        marginTop: 20
    },
    buttonsContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        paddingTop: 20
    },
    loginButton: {
        boxShadow: '1px 1px 2px #777',
        cursor: 'pointer',
        padding: 10,
        width: 100,
        textAlign: 'center',
        backgroundColor: '#3760a3',
        border: '0D47A1',
        borderRadius: 3,
        color: '#FFF',
        fontFamily: 'Metropolis-Medium'
    },
    forgotButton: {
        padding: 10,
        paddingRight: 0,
        color: '#3760a3',
        fontFamily: 'Metropolis-Regular'
    },
    desclaimer: {
        fontFamily: 'Metropolis-Regular',
        fontSize: 13,
        marginBottom: 10
    },
    authErrorMessage : {
        fontFamily: 'Metropolis-Regular',
        color:'#FF5252',
        marginTop:20
    }
});


class LoginUI extends Component {


    state = {
        userIdError: false,
        userIdErrorMessage: '',
        userId: '',
        password: '',
        passwordError: false,
        passwordErrorMessage: '',
        loginInProgress: false,
        authErrorMessage : ''
    }

    _getDeviceId(){
        HttpHandler.sendGet('/api/v1/device/deviceid/my', (d) => {
          HttpHandler.DEVICE_ID = d.deviceId;
          
        }, (errorCode, errorMessage) => {
          console.log(errorMessage);
        })
      }

    _loginClicked() {

        var state = {}
        var valid = true;

        var userName = this.state.userId.trim();
        if(userName.length === 0) {
            state.userIdError = true;
            state.userIdErrorMessage = "User Id cannot be empty";
            valid = false;
        }

        var password = this.state.password.trim();
        if(password.length === 0) {
            state.passwordError = true;
            state.passwordErrorMessage = "Password cannot be empty";
            valid = false;
        }

        if(!valid) {
            this.setState(state);   
            return;
        }

        this.setState({
            loginInProgress : true
        });

        var req = {userId : userName, password : password};
        HttpHandler.sendPost('/api/v1/auth/login', req, (data) => {
            
            this.setState({
                loginInProgress:false
            })

            //Save in the DB
            HttpHandler.SESSION_SECRET = data.session.sessionSecret;
            HttpHandler.FULL_NAME = data.session.fullName;
            HttpHandler.USER_ID = data.session.userId;
            HttpHandler.USER_TYPE = data.session.userType;
            HttpHandler.SCREENING_MEMBER_TYPE = data.session.screeningMemberType

            this._getDeviceId();

            window.sessionStorage.setItem('SESSION_SECRET', data.session.sessionSecret);
            window.sessionStorage.setItem('FULL_NAME', data.session.fullName);
            window.sessionStorage.setItem('USER_ID', data.session.userId);

            this.props.onSuccess();


        }, (errorCode, errorMessage) => {
            console.log(errorCode);
            console.log(errorMessage);
            if(errorCode === 401) {
                this.setState({
                    authErrorMessage: 'Login failed. Access denied',
                    loginInProgress:false
                })
            }
            else {
                this.setState({
                    authErrorMessage: 'There was an error in login. Try again later.',
                    loginInProgress:false
                })
            }
        });
    }

   

    render() {
        const { classes } = this.props;
        return (
            <main style={{
                height: '100vh', width: '100%', backgroundColor: '#F7F7F7', display: 'flex',
                justifyContent: 'center', alignContent: 'center', alignItems: 'center'
            }}>
                <Paper style={{ overflow: 'hidden', borderRadius: 10 }}>
                    <div style={{ width: 800, height: 500, display: 'flex', flexDirection: 'row' }}>
                        <div className={classes.leftPaneContainer}>
                            <img src={require('./images/loginbg.jpg')} style={{ opacity: 0.1 }} />
                            <div className={classes.leftPaneOverlay}>
                                <div className={classes.logoContainer}>
                                    <img src={require('./images/logo.png')} style={{ width: 98, width: 98 }} />
                                </div>
                                <div style={{ textAlign: 'center', marginTop: 10 }}>
                                    <span className={classes.logoTitle}> RISE AGAINST CANCER</span>
                                </div>
                            </div>
                        </div>
                        <div className={classes.rightContainer}>
                            <div className={classes.welcomeStyle}><span>Welcome</span></div>
                            <div style={{ paddingRight: 20, paddingTop: 10 }}>
                                <form noValidate autoComplete="off">
                                    <TextField
                                        fullWidth
                                        required
                                        error={this.state.userIdError}
                                        id='user-name'
                                        label="User Id"
                                        value={this.state.userId}
                                        margin="normal"
                                        helperText={this.state.userIdErrorMessage}
                                        onChange={(v) => {
                                            this.setState({
                                                userId: v.target.value,
                                                userIdError: false,
                                                userIdErrorMessage: ''
                                            })
                                        }}
                                    />
                                    <TextField
                                        fullWidth
                                        required
                                        error={this.state.passwordError}
                                        InputLabelProps={{ className: classes.inputLabel }}
                                        type="password"
                                        id='password'
                                        label="Password"
                                        value={this.state.password}
                                        margin="normal"
                                        helperText={this.state.passwordErrorMessage}
                                        onChange={(v) => {
                                            this.setState({
                                                password: v.target.value,
                                                passwordError: false,
                                                passwordErrorMessage: ''
                                            })
                                        }}
                                    >
                                    </TextField>
                                </form>
                                <div className={classes.buttonsContainer}>
                                    {(this.state.loginInProgress === false) &&
                                        <div
                                            onClick={this._loginClicked.bind(this)}
                                            className={classes.loginButton}>
                                            <span >LOGIN</span>
                                        </div>
                                    }
                                    {this.state.loginInProgress &&
                                        <CircularProgress size={30} color="secondary" />
                                    }
                                    <div style={{ flex: 1 }}></div>
                                    <div className={classes.forgotButton}>
                                        <span>Forgot password</span>
                                    </div>
                                </div>
                                <div className={classes.authErrorMessage}>{this.state.authErrorMessage}</div>
                            </div>
                            <div style={{ flex: 1 }}></div>
                            <div style={{ width: '100%' }}>
                                <div className={classes.desclaimer}>
                                    <span>By proceeding beyond this point, you are agreeing for the terms and conditions of this application. </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Paper>
            </main>
        )
    }
}


LoginUI.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LoginUI);