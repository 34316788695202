import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles'
import { Paper, Divider, Fab, CircularProgress, Dialog, FormControl, InputLabel, Select, MenuItem, TextField, DialogActions, Button } from '@material-ui/core';
import LocalHospitalRoundedIcon from '@material-ui/icons/LocalHospitalRounded';

import { NotificationManager } from 'react-notifications';

import PagePath from './components/PagePath';
import HttpHandler from './HttpHandler';
import DataValue from './components/DataValue'
import TemplateDataView from './TemplateDataView';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import CampMedicalRecord from './CampMedicalRecord';
import CampRegister from './CampRegister';
import ProbableCaseDialog from './ProbableCaseDialog';
import ErrorDialog from './components/ErrorDialog';
import CopyrightIcon from '@material-ui/icons/Copyright';
import commonClasses from './ics.module.css'

const styles = theme => ({
}
);

const genderMapping = {
    'M': 'Male',
    'F': 'Female',
    'O': 'Others'
}

const maritalMapping = {
    'S': 'Single',
    'M': 'Married',
    'D': 'Divorced',
    'W': 'Widow'
}

class CampRecordView extends Component {

    state = {
        name: '',
        campName: '-',
        gender: '',
        age: '',
        marital: '',
        regNum: '',
        phone: '',
        email: '',
        regSections: [],
        regSectionData: {},
        medicalSections: [],
        medicalSectionData: {},
        showEditDialog: false,
        loadingRegistrationDetails: false,
        loadingMedicalDetails: false,
        medicalDone: false,
        showMedicalEditUI: false,
        showRegEditUI: false,
        loadingInProgress: true,
        hasAccess: false,
        campStatus: 'SC',
        showProbaleCaseDialog: false,
        showCounselling: false,
        counsellingType: '',
        counsellingComment: '',
        testType: '',
        testComment: ''
    }


    _loadData(loadMedicalData) {
        var campId = this.props.match.params.campId;
        var recordId = this.props.match.params.recordId;

        this.setState({
            loadingMedicalDetails: true,
            loadingRegistrationDetails: loadMedicalData
        })

        HttpHandler.sendGet('/api/v1/camp/' + campId + '/data/' + recordId + '/register', (d) => {
            var general = d.general;
            var state = {
                regNum: general.regNum,
                name: general.name,
                age: general.age,
                phone: general.phone,
                email: general.email,
                gender: genderMapping[general.gender],
                marital: maritalMapping[general.maritalStatus],
                regSections: d.sections,
                regSectionData: d.data,
                medicalDone: general.medicalDone,
                hasAccess: true
            }

            this.setState(state);

            HttpHandler.sendGet('/api/v1/camp/' + campId, (d) => {
                this.setState({
                    campName: d.camp.name,
                    loadingRegistrationDetails: false,
                    loadingMedicalDetails: general.medicalDone,
                    loadingInProgress: false,
                    campStatus: d.camp.status
                });

                if (general.medicalDone && loadMedicalData) {
                    this._getMedicalData(campId, recordId);
                }

            }, (ec, em) => {
                this.setState({
                    loadingRegistrationDetails: false,
                    loadingMedicalDetails: false
                })
            });

        }, (errorCode, errorMessage) => {
            console.log(errorMessage)
            this.setState({
                loadingRegistrationDetails: false,
                loadingMedicalDetails: false,
                loadingInProgress: false,
                hasAccess: errorCode !== 401
            })
        });
    }

    _getMedicalData(campId, recordId) {
        HttpHandler.sendGet('/api/v1/camp/' + campId + '/data/' + recordId + '/medical', (d) => {
            var state = {
                medicalSections: d.sections,
                medicalSectionData: d.data,
                loadingMedicalDetails: false
            }



            this.setState(state);

        }, (errorCode, errorMessage) => {
            console.log(errorMessage)

            this.setState({
                loadingMedicalDetails: false
            })
        });
    }

    componentDidMount() {
        this._loadData(true);
    }

    _editRegistrationClicked() {
        this.setState({ showRegEditUI: true })
    }

    _registrationUIClosed() {
        this.setState({ showRegEditUI: false })
    }

    _registrationSaveDone() {
        NotificationManager.success("Registration details saved", "Camp");

        this.setState({ showRegEditUI: false })
        this._loadData(true);
    }

    _addMedicalDataClicked() {
        this.setState({
            showMedicalEditUI: true
        })
    }

    _editMedicalRecordClicked() {
        this.setState({
            showMedicalEditUI: true
        })
    }

    _medicalRecordUIClosed() {
        this.setState({
            showMedicalEditUI: false
        })
    }

    _medicalRecordSaved() {
        NotificationManager.success("Medical screening details saved", "Camp");
        this.setState({
            showMedicalEditUI: false,
            medicalDone: true
        });

        this._getMedicalData(this.props.match.params.campId, this.props.match.params.recordId)
    }

    _onShowProbaleCaseDialogClicked = () => {
        this.setState({ showProbaleCaseDialog: true })
    }

    _onProbaleCauseSave = () => {
        this.setState({ showProbaleCaseDialog: false })
    }

    _closeCounselling = () => {
        this.setState({ showCounselling: false })
    }
    _openCounselling = () => {
        let counsellingType = ''
        let counsellingComment = ''
        HttpHandler.sendGet(`/api/v1/camp/data/${this.props.match.params.recordId}/counsellingInfo`, (d) => {
            if (d.status === 'success') {
                counsellingType = d.counsellingType
                counsellingComment = d.counsellingComment
                this.setState({ showCounselling: true, testType: counsellingType, testComment: counsellingComment, counsellingType, counsellingComment })
            } else {
                this.setState({ showCounselling: true, testType: this.state.counsellingType, testComment: this.state.counsellingComment })
            }
        }, (errorCode, errorMessage) => {
            console.log(errorMessage);
            this.setState({ showCounselling: true, testType: this.state.counsellingType, testComment: this.state.counsellingComment })
        })
    }

    _handleCounsellingTypeChange = (v) => {
        this.setState({
            testType: v.target.value
        })
    }

    _saveCounselling = () => {
        var req = {
            counsellingType: this.state.testType,
            counsellingComment: this.state.testComment
        }

        HttpHandler.sendPost('/api/v1/camp/' + this.props.match.params.recordId + '/submitCounselling', req, (res) => {
            this.setState({ counsellingType: '', counsellingComment: '', showCounselling: false });
        }, (errorCode, errorMessage) => {
            console.log(errorMessage);
            ErrorDialog.show('Error while adding counselling session type');
        });
    }

    render() {
        const { classes } = this.props;
        return (

            <div style={{ width: '100%' }}>
                {this.state.loadingInProgress &&
                    <div style={{ paddingTop: 40, color: '#000', fontFamily: 'Metropolis-Regular', fontSize: 15, textAlign: 'center' }}>We are loading the camp details.</div>
                }


                {(((HttpHandler.USER_TYPE !== 'coordinator' && HttpHandler.USER_TYPE !== 'data entry' && HttpHandler.USER_TYPE !== 'screening' && HttpHandler.USER_TYPE !== 'doctor') || !this.state.hasAccess) && this.state.loadingInProgress === false) &&
                    <div style={{ paddingTop: 40, color: '#FF0000', fontFamily: 'Metropolis-Regular', fontSize: 15, textAlign: 'center' }}>You are not authorized to access contents of this page.</div>
                }

                {(((HttpHandler.USER_TYPE === 'coordinator' || HttpHandler.USER_TYPE === 'data entry' || HttpHandler.USER_TYPE === 'screening' || HttpHandler.USER_TYPE === 'doctor') && this.state.hasAccess) && this.state.loadingInProgress === false) &&
                    <div>
                        <PagePath path={['Camps', this.state.campName, 'Data', this.state.regNum]}>
                        </PagePath>

                        {this.state.showRegEditUI &&
                            <div>
                                <CampRegister
                                    recordId={this.props.match.params.recordId}
                                    onClose={this._registrationUIClosed.bind(this)}
                                    onSave={this._registrationSaveDone.bind(this)}
                                    campId={this.props.match.params.campId} />
                            </div>
                        }

                        {this.state.showMedicalEditUI &&
                            <div>
                                <CampMedicalRecord
                                    recordId={this.props.match.params.recordId}
                                    medicalAdded={this.state.medicalDone}
                                    onSave={this._medicalRecordSaved.bind(this)}
                                    onClose={this._medicalRecordUIClosed.bind(this)}
                                    campId={this.props.match.params.campId} />
                            </div>
                        }
                        {(this.state.showMedicalEditUI === false && this.state.showRegEditUI === false) &&
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ display: 'flex', justifyContent: 'flex-start', paddingTop: 30, flexDirection: 'column', alignItems: 'center' }}>
                                        <div style={{ display: 'flex', width: '100%', padding: 5, backgroundColor: '#2196F3', borderRadius: 3 }}>
                                            <div style={{ fontFamily: 'Metropolis-Medium', fontSize: 16, color: '#FFF' }}>Registration Details</div>
                                        </div>
                                        {this.state.loadingRegistrationDetails &&
                                            <div style={{ padding: 10, paddingTop: 20, width: 500, display: 'flex', justifyContent: 'center' }}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <CircularProgress></CircularProgress>
                                                    <div style={{ fontFamily: 'Metropolis-Regular', paddingLeft: 10, fontSize: 14 }}>Loading registration details</div>
                                                </div>

                                            </div>
                                        }
                                        {(this.state.loadingRegistrationDetails === false) &&
                                            <div>
                                                <Paper style={{ padding: 10, minWidth: 500, marginTop: 10 }}>
                                                    <div style={{ flexDirection: 'row', display: 'flex', paddingBottom: 5, alignItems: 'center' }}>
                                                        <div style={{ fontFamily: 'Metropolis-Regular', flex: 1 }}>Personal</div>
                                                    </div>
                                                    <Divider></Divider>

                                                    <div style={{ paddingTop: 3, flexDirection: 'column', display: 'flex', marginTop: 10 }}>
                                                        <DataValue title="Name" value={this.state.name} />
                                                        <DataValue title="Gender" value={this.state.gender} />
                                                        <DataValue title="Age" value={this.state.age} />
                                                        <DataValue title="Marital Status" value={this.state.marital} />
                                                        <DataValue title="Mobile Phone" value={this.state.phone} />
                                                        <DataValue title="Email" value={this.state.email} />
                                                    </div>
                                                </Paper>
                                            </div>
                                        }
                                        {(this.state.loadingRegistrationDetails === false) &&
                                            <TemplateDataView sectionData={this.state.regSectionData} sections={this.state.regSections} />
                                        }

                                    </div>
                                    {(this.state.showEditDialog === false && this.state.loadingRegistrationDetails === false) &&
                                        <div style={{ paddingTop: 30, paddingLeft: 10 }}>

                                            {(HttpHandler.USER_TYPE === 'staff' || HttpHandler.USER_TYPE === 'doctor' || HttpHandler.USER_TYPE === 'data entry' || HttpHandler.USER_TYPE === 'admin' || (HttpHandler.USER_TYPE === 'screening' && (HttpHandler.SCREENING_MEMBER_TYPE != 'Follow Up' && HttpHandler.SCREENING_MEMBER_TYPE!='Counsellor')) || HttpHandler.USER_TYPE === 'coordinator') && (this.state.campStatus === 'RU') &&
                                                <Fab
                                                    onClick={this._editRegistrationClicked.bind(this)}
                                                    style={{ marginRight: 20 }}
                                                    color="primary" aria-label="add" size="medium">
                                                    <EditRoundedIcon />
                                                </Fab>
                                            }
                                        </div>
                                    }
                                </div>
                                <div style={{ width: 50 }}></div>

                                {(HttpHandler.USER_TYPE === 'doctor' || HttpHandler.USER_TYPE === 'data entry' || HttpHandler.USER_TYPE === 'admin' || HttpHandler.USER_TYPE === 'coordinator' || HttpHandler.USER_TYPE === 'screening') &&
                                    <div style={{ display: 'flex' }}>

                                        <div style={{ display: 'flex', justifyContent: 'flex-start', paddingTop: 30, flexDirection: 'column' }}>
                                            <div style={{ display: 'flex', width: '100%', padding: 5, backgroundColor: '#2196F3', borderRadius: 3, width: 500 }}>
                                                <div style={{ fontFamily: 'Metropolis-Medium', fontSize: 16, color: '#FFF' }}>Medical Screening</div>
                                            </div>

                                            {(this.state.medicalDone === false) &&
                                                <div style={{
                                                    padding: 10, paddingTop: 20, width: 500,
                                                    display: 'flex', justifyContent: 'center'
                                                }}>
                                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                        <div style={{ fontFamily: 'Metropolis-Regular', fontSize: 14 }}>Medicals screening not done</div>

                                                        {(HttpHandler.USER_TYPE === 'doctor' || HttpHandler.USER_TYPE === 'data entry' || HttpHandler.USER_TYPE === 'admin') && (this.state.campStatus === 'RU') &&
                                                            <React.Fragment>
                                                                <div style={{ cursor: 'pointer' }}
                                                                    onClick={this._addMedicalDataClicked.bind(this)}
                                                                >
                                                                    <LocalHospitalRoundedIcon
                                                                        style={{ height: 32, width: 32, color: '#1E88E5' }}></LocalHospitalRoundedIcon>
                                                                </div>
                                                                <div style={{ fontFamily: 'Metropolis-Regular', fontSize: 14 }}>Click here for medical screening </div>
                                                            </React.Fragment>
                                                        }
                                                    </div>

                                                </div>
                                            }

                                            {this.state.loadingMedicalDetails &&
                                                <div style={{ padding: 10, paddingTop: 20, width: 500, display: 'flex', justifyContent: 'center' }}>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <CircularProgress></CircularProgress>
                                                        <div style={{ fontFamily: 'Metropolis-Regular', paddingLeft: 10, fontSize: 14 }}>Loading medical screening details</div>
                                                    </div>

                                                </div>
                                            }
                                            {(this.state.loadingMedicalDetails === false && this.state.medicalDone) &&
                                                <TemplateDataView sectionData={this.state.medicalSectionData} sections={this.state.medicalSections} />
                                            }

                                        </div>
                                        {(this.state.showEditDialog === false && this.state.loadingMedicalDetails === false && this.state.medicalDone) &&
                                            <div style={{ paddingTop: 30, paddingLeft: 10 }}>

                                                {(HttpHandler.USER_TYPE === 'doctor' || HttpHandler.USER_TYPE === 'data entry' || HttpHandler.USER_TYPE === 'admin') && (this.state.campStatus === 'RU') &&
                                                    <Fab
                                                        onClick={this._editMedicalRecordClicked.bind(this)}
                                                        color="primary" aria-label="add" size="medium">
                                                        <EditRoundedIcon />
                                                    </Fab>
                                                }
                                            </div>
                                        }
                                        {(this.state.loadingRegistrationDetails === false && this.state.medicalDone) &&
                                            <div style={{ paddingTop: 30, paddingLeft: 10 }}>

                                                {(HttpHandler.USER_TYPE === 'doctor' || HttpHandler.USER_TYPE === 'data entry' || HttpHandler.USER_TYPE === 'coordinator') && (this.state.campStatus === 'RU') &&
                                                    <Fab
                                                        onClick={this._onShowProbaleCaseDialogClicked}
                                                        color="primary" aria-label="add" size="medium">
                                                        <img src={require('./images/cancer_check.png')} style={{ width: 24, height: 24 }} />
                                                    </Fab>
                                                }
                                            </div>
                                        }
                                        {(this.state.loadingRegistrationDetails === false && this.state.medicalDone) &&
                                            <div style={{ paddingTop: 30, paddingLeft: 10 }}>

                                                {(HttpHandler.USER_TYPE === 'doctor' || HttpHandler.USER_TYPE === 'data entry' || HttpHandler.USER_TYPE === 'coordinator') && (this.state.campStatus === 'RU') &&
                                                    <Fab
                                                        onClick={this._openCounselling}
                                                        color="primary" aria-label="add" size="medium">
                                                        <CopyrightIcon style={{ fontSize: 35 }} />
                                                    </Fab>
                                                }
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                        }
                        <ProbableCaseDialog
                            campId={this.props.match.params.campId}
                            recordId={this.props.match.params.recordId}
                            onSave={this._onProbaleCauseSave}
                            isVisible={this.state.showProbaleCaseDialog}
                            onClose={() => { this.setState({ showProbaleCaseDialog: false }) }} />

                        <Dialog open={this.state.showCounselling} onClose={this._closeCounselling}>
                            <div style={{ padding: 5, width: 560 }}>
                                <div className={commonClasses.headerBar}>Counselling</div>
                            </div>
                            <div style={{ width: '100%', backgroundColor: '#FAFAFA', padding: 20, display: 'flex' }}>
                                <div style={{ flex: 1 }}>

                                    <FormControl style={{ flex: 1, marginTop: 16, width: 170, marginBottom: 10 }}>
                                        <InputLabel id="probableCancerType-label">Counselling Type</InputLabel>
                                        <Select
                                            labelId="probableCancerType-label"
                                            id="probableCancerType-select"
                                            value={this.state.testType}
                                            onChange={this._handleCounsellingTypeChange}
                                        >
                                            <MenuItem value="FI">Financial</MenuItem>
                                            <MenuItem value="RE">Refusal</MenuItem>
                                            <MenuItem value="FE">Fear</MenuItem>
                                            <MenuItem value="TC">Tobacco Cessation Session</MenuItem>
                                            <MenuItem value="OT">Others</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <TextField
                                        id='comment'
                                        label="Comment"
                                        fullWidth
                                        multiline={true}
                                        rows={2}
                                        value={this.state.testComment}
                                        margin="normal"
                                        inputProps={{ maxLength: 100 }}
                                        onChange={(v) => {
                                            this.setState({
                                                testComment: v.target.value,
                                            })
                                        }}
                                    />
                                </div>

                            </div>
                            <Divider />
                            <DialogActions>
                                <div style={{ paddingTop: 10, flexDirection: 'row', display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button
                                        onClick={this._saveCounselling}
                                        variant="contained" color="primary" style={{ marginLeft: 20 }}>Save</Button>
                                    <Button
                                        onClick={() => { this.setState({ showCounselling: false, }) }}
                                        variant="contained" color="default" style={{ marginLeft: 20 }}>Close</Button>
                                </div>
                            </DialogActions>
                        </Dialog>
                    </div>
                }
            </div>
        )
    }
}


CampRecordView.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CampRecordView);