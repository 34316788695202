import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles'

import { Fab, Paper, TextField, Divider, Button, DialogActions, Dialog, FormControl, Select, MenuItem, InputLabel, } from '@material-ui/core';

import AddIcon from '@material-ui/icons/AddRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';

import { NotificationManager } from 'react-notifications';

import commonClasses from './ics.module.css'

import PagePath from './components/PagePath';
import HttpHandler from './HttpHandler'
import ErrorDialog from './components/ErrorDialog';
import ConfirmationDialog from './components/ConfirmationDialog';
import Utils from './Utils';


import CTable from './components/CTable'

const useStyles = makeStyles({
});


function DistrictCreateEdit(props) {

    const [thisState, setState] = useState({
        districtName: '',
        districtNameError: false,
        districtNameErrorMessage: '',
        districtState: Utils.states[0].id
    });


    const _saveClicked = () => {
        var valid = true;
        var myState = { ...thisState };

        var name = thisState.districtName.trim();
        if (name.length === 0) {
            valid = false;
            myState.districtNameError = true;
            myState.districtNameErrorMessage = "Mandatory";
        }

        if (name.length > 100) {
            valid = false;
            myState.districtNameError = true;
            myState.districtNameErrorMessage = "Name should not exceed 100 characters";
        }


        if (!valid) {
            setState(myState);
            return;
        }

        //Check for duplicate code or name

        var req = {
            name: name,
        }

        //Duplicate check can be done locally.
        var duplicate = false;
        for (var i = 0; i < Utils.statesAndDistricts.length; i++) {
            var district = Utils.statesAndDistricts[i];
            if (district.id !== props.districtId && district.title.toUpperCase() === name.toUpperCase() && district.stateId === thisState.districtState) {
                duplicate = true;
                break;
            }
        }

        if (duplicate) {
            setState({ ...thisState, districtNameError: true, districtNameErrorMessage: 'Name already used' });
            return;
        }

        var url = '/api/v1/location/state/' + myState.districtState + "/district/new";
        if (props.districtId > 0) {
            req.state = myState.districtState;
            url = '/api/v1/location/state/' + props.stateId + "/district/" + props.districtId;
        }

        HttpHandler.sendPost(url, req, (data) => {
            if (props.districtId > 0) {
                NotificationManager.success("District record updated.", name);

                //See if we have changed the state.
                var newDist = { id: props.districtId, title: name, stateId: thisState.districtState };
                if (thisState.districtState !== props.stateId) {
                    //We moved to a new state.

                    //Remove from old and add to new
                    var oldStateName = null;
                    for (var i = 0; i < Utils.states.length; i++) {
                        if (Utils.states[i].id === props.stateId) {
                            oldStateName = Utils.states[i].name;
                            break;
                        }
                    }

                    var dists = Utils.statesAndDistrictsMap[oldStateName].districts;
                    let indexToRemove = -1;
                    for (var i = 0; i < dists.length; i++) {
                        if (dists[i].id === props.districtId) {
                            indexToRemove = i;
                            break;
                        }
                    }

                    if (indexToRemove >= 0) {
                        dists.splice(indexToRemove, 1);
                        Utils.statesAndDistrictsMap[oldStateName].districtCount--;
                    }

                    //Add to new
                    var newStateName = null;
                    for (var i = 0; i < Utils.states.length; i++) {
                        if (Utils.states[i].id === thisState.districtState) {
                            newStateName = Utils.states[i].name;
                            break;
                        }
                    }

                    Utils.statesAndDistrictsMap[newStateName].districtCount++;
                    Utils.statesAndDistrictsMap[newStateName].districts.push(newDist);

                    Utils.sortDistricts(newStateName);
                }
                else {
                    let indexToRemove = -1;
                    for (var i = 0; i < Utils.statesAndDistricts.length; i++) {
                        if (Utils.statesAndDistricts[i].id === props.districtId) {
                            indexToRemove = i;
                            break;
                        }
                    }

                    Utils.statesAndDistricts[indexToRemove] = newDist;

                    var oldStateName = null;
                    for (var i = 0; i < Utils.states.length; i++) {
                        if (Utils.states[i].id === props.stateId) {
                            oldStateName = Utils.states[i].name;
                            break;
                        }
                    }

                    var dists = Utils.statesAndDistrictsMap[oldStateName].districts;
                    indexToRemove = -1;
                    for (var i = 0; i < dists.length; i++) {
                        if (dists[i].id === props.districtId) {
                            indexToRemove = i;
                            break;
                        }
                    }

                    if (indexToRemove >= 0) {
                        dists[indexToRemove] = newDist;
                    }

                    Utils.sortDistricts(oldStateName);
                }

            }
            else {
                NotificationManager.success("District record created.", name);

                //Find the statename.
                var newDist = { id: data.id, title: name, stateId: thisState.districtState };
                for (var i = 0; i < Utils.states.length; i++) {
                    var st = Utils.states[i];
                    if (st.id === thisState.districtState) {
                        Utils.statesAndDistrictsMap[st.name].districtCount++;
                        newDist.stateName = st.name;
                        Utils.statesAndDistrictsMap[st.name].districts.push(newDist);
                        break;
                    }
                }

                //Add to Utils state
                Utils.statesAndDistricts.push(newDist);
                Utils.sortDistricts(newDist.stateName);
            }
            props.onDone();
        }, (errorCode, errorMessage) => {
            console.log(errorMessage);
            ErrorDialog.show("There was error in saving district record");
        })

    }

    const _closeClicked = () => {
        props.onClose();
    }

    const _handleDistrictStateChange = (v) => {
        var istate = {
            ...thisState,
            districtState: v.target.value
        };
        setState(istate);
    }

    useEffect(() => {
        if (props.districtId > 0) {
            for (var i = 0; i < Utils.statesAndDistricts.length; i++) {
                var district = Utils.statesAndDistricts[i];
                if (district.id === props.districtId) {
                    setState({
                        ...thisState,
                        districtName: district.title,
                        districtNameError: false,
                        districtNameErrorMessage: '',
                        districtState: district.stateId
                    })
                    break;
                }
            }

        }
        else {
            setState({
                ...thisState,
                districtName: '',
                districtNameError: false,
                districtNameErrorMessage: '',
                districtState: Utils.states[0].id
            })
        }
    }, [props.stateId]);

    return (
        <div>
            <Paper style={{ padding: 10, width: 500 }}>
                <div className={commonClasses.headerBar}>District</div>
                <div style={{ paddingBottom: 20 }}>
                    <form noValidate autoComplete="off">
                        <FormControl style={{ flex: 1, marginTop: 16, minWidth: 200 }}>
                            <InputLabel id="districtState-label">State</InputLabel>
                            <Select
                                labelId="districtState-label"
                                id="districtState-select"
                                value={thisState.districtState}
                                onChange={_handleDistrictStateChange}
                            >
                                {Utils.states.map((row, key) => {
                                    return (
                                        <MenuItem value={row.id} key={row.id}>{row.name}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>

                        <TextField
                            required
                            error={thisState.districtNameError}
                            id='districtName'
                            label="Name"
                            fullWidth
                            value={thisState.districtName}
                            margin="normal"
                            helperText={thisState.districtNameErrorMessage}

                            onChange={(v) => {
                                setState({
                                    ...thisState,
                                    districtName: v.target.value,
                                    districtNameError: false,
                                    districtNameErrorMessage: ''
                                })
                            }}
                        />
                    </form>
                </div>
                <Divider style={{ marginLeft: -10, marginRight: -10 }}></Divider>
                <div style={{ paddingTop: 10, flexDirection: 'row', display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        onClick={_saveClicked}
                        variant="contained" color="primary">Save</Button>
                    <Button
                        onClick={_closeClicked}
                        variant="contained" color="default" style={{ marginLeft: 20 }}>Close</Button>
                </div>
            </Paper>
        </div>
    )

}


export default function Districts(props) {


    const dataTable = useRef(null);

    useEffect(() => { }, []);

    const districtTableDef = [
        {
            title: "Name",
            field: 'name',
            align: 'left',
            renderer: (row, data) => {
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center', fontFamily: 'Metropolis-Regular' }} >
                        {row.title}
                    </div>
                )
            }

        },
        {
            title: "",
            field: 'id',
            style: { minWidth: 120, width: 120 },
            renderer: (row, data) => {
                return (
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        {(HttpHandler.USER_TYPE === 'admin') &&
                            <div
                                onClick={() => _editClicked(row)}
                                style={{ height: 24, cursor: 'pointer' }}>
                                <EditRoundedIcon style={{ color: '#03A9F4' }}></EditRoundedIcon>
                            </div>
                        }
                        {(HttpHandler.USER_TYPE === 'admin') &&
                            <div
                                onClick={() => _deleteClicked(row)}
                                style={{ marginLeft: 20, height: 24, cursor: 'pointer' }}>
                                <DeleteForeverRoundedIcon
                                    style={{ color: '#D32F2F' }}></DeleteForeverRoundedIcon>

                            </div>
                        }

                    </div>
                )
            }

        }
    ]

    const [state, setState] = useState({
        districtState: Utils.states[0].id,
        editCreateDialog: false,
        selectedDistrictId: -1
    });

    const _editClicked = (row) => {
        setState({ ...state, editCreateDialog: true, selectedDistrictId: row.id })
    }

    const _deleteClicked = (row) => {
        ConfirmationDialog.show('Are you sure you want to delete this district ?. You will see errors if this district is in use by any record.', () => {
            HttpHandler.sendDelete('/api/v1/location/state/' + state.districtState + '/district/' + row.id, (d) => {
                if (d.alreadyInUse) {
                    ErrorDialog.show(d.ErrMessage);
                    return;
                }
                NotificationManager.success("District deleted.", row.title);

                var oldStateName = null;
                for (var i = 0; i < Utils.states.length; i++) {
                    if (Utils.states[i].id === state.districtState) {
                        oldStateName = Utils.states[i].name;
                        break;
                    }
                }

                var dists = Utils.statesAndDistrictsMap[oldStateName].districts;



                //Delete
                var indexToDelete = -1;
                for (var i = 0; i < dists.length; i++) {
                    var dist = dists[i];
                    if (dist.id === row.id) {
                        indexToDelete = i;
                        break;
                    }
                }

                dists.splice(indexToDelete, 1);
                Utils.statesAndDistrictsMap[oldStateName].districtCount--;

                dataTable.current.reload();
            }, (errorCode, errorMessage) => {
                console.log(errorMessage);
                ErrorDialog.show("There was error in deleting district");
            })
        });
    }

    const _handleDistrictListStateChange = (v) => {
        var istate = {
            ...state,
            districtState: v.target.value
        };
        setState(istate);
        window.setTimeout(() => {
            dataTable.current.reload();
        }, 200);

    }

    const _loadDataForSelectedState = (searchString) => {
        if (state.districtState == null) {
            return [];
        }
        var stateName = null;
        for (var i = 0; i < Utils.states.length; i++) {
            if (Utils.states[i].id === state.districtState) {
                stateName = Utils.states[i].name;
                break;
            }
        }

        var dists = Utils.statesAndDistrictsMap[stateName].districts;
        var finalRes = [];
        for (var i = 0; i < dists.length; i++) {
            var dist = dists[i];
            if (dist.title.toLowerCase().indexOf(searchString) >= 0) {
                finalRes.push(dist);
            }
        }

        return finalRes;
    }

    const _getDistrictData = (cb, offset, limit, searchString) => {

        var res = _loadDataForSelectedState(searchString.toLowerCase());

        if (res.length < offset) {
            cb([], res.length);
        }
        else {
            var finalRes = [];
            var off = offset >= 0 ? offset : 0;
            var diff = res.length - off;
            diff = diff > limit ? limit : diff;
            for (var i = 0; i < diff; i++) {
                finalRes.push(res[off + i]);
            }
            cb(finalRes, res.length);
        }

    }


    const _createDistrict = () => {
        setState({
            ...state,
            editCreateDialog: true,
            selectedDistrictId: -1
        });

    }

    const _districtCreated = () => {
        setState({
            ...state,
            editCreateDialog: false,
            selectedDistrictId: -1
        });
        dataTable.current.reload();
    }



    return (
        <div>
            <div style={{ marginRight: 20 }}>
                <PagePath path={['District', 'All']}>
                    {(HttpHandler.USER_TYPE === 'admin') &&
                        <Fab
                            onClick={_createDistrict}
                            color="primary" aria-label="add" size="medium" title="New district">
                            <AddIcon />
                        </Fab>
                    }
                </PagePath>
            </div>

            <div style={{ marginTop: 20, paddingBottom: 30, paddingRight: 10 }}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {state.editCreateDialog &&
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <DistrictCreateEdit districtId={state.selectedDistrictId} stateId={state.districtState}
                                onDone={_districtCreated}
                                onClose={() => { setState({ ...state, editCreateDialog: false }) }} />
                        </div>
                    }
                    {(state.editCreateDialog === false) &&
                        <Paper style={{ padding: 10, minWidth: 600 }}>
                            <FormControl style={{ flex: 1, marginTop: 16, minWidth: 200 }}>
                                <InputLabel id="districtState-label">State</InputLabel>
                                <Select
                                    labelId="districtState-label"
                                    id="districtState-select"
                                    value={state.districtState}
                                    onChange={_handleDistrictListStateChange}
                                >
                                    {Utils.states.map((row, key) => {
                                        return (
                                            <MenuItem value={row.id} key={row.id}>{row.name}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>

                            <div style={{ width: 600 }}>
                                <CTable
                                    ref={dataTable}
                                    data={_getDistrictData}
                                    columns={districtTableDef}>
                                </CTable>
                            </div>

                        </Paper>
                    }
                </div>
            </div>
        </div>
    )
}

