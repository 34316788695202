import React, { createRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Paper, FormHelperText, Divider, Dialog, DialogActions, Select, FormControl, MenuItem, Button, InputLabel, TextField } from '@material-ui/core';
import { RadioGroup, FormControlLabel, Radio, InputAdornment, CircularProgress, Fab } from '@material-ui/core';

import { useHistory } from 'react-router-dom';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { DatePicker } from "@material-ui/pickers";


import { NotificationManager } from 'react-notifications';
import Moment from 'react-moment';
import moment from 'moment';

import AssessmentRoundedIcon from '@material-ui/icons/AssessmentRounded';
import AttachFileRoundedIcon from '@material-ui/icons/AttachFileRounded';
import DescriptionRoundedIcon from '@material-ui/icons/DescriptionRounded';
import AddIcon from '@material-ui/icons/Add';
import TodayRoundedIcon from '@material-ui/icons/TodayRounded';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';


import TemplateDataView from './TemplateDataView'
import DataValue from './components/DataValue'

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PagePath from './components/PagePath';
import HttpHandler from './HttpHandler';
import ErrorDialog from './components/ErrorDialog'
import DateSelectionDialog from './components/DateSelectionDialog'
import AddTestsDialog from './AddTestsDialog'
import TreatmentInitiateDialog from './TreatmentInitiateDialog'
import ConfirmationDialog from './components/ConfirmationDialog';

import commonClasses from './ics.module.css'

const genderMapping = {
    'M': 'Male',
    'F': 'Female',
    'O': 'Others'
}

const maritalMapping = {
    'S': 'Single',
    'M': 'Married',
    'D': 'Divorced',
    'W': 'Widow'
}

const cancerTypeMapping = {
    'OR': 'Oral',
    'BR': 'Breast',
    'CE': 'Cervical',
    'OT': 'Others',
    'NO':'None'
}


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box >
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};


export default function ProbableDetails(props) {

    const [state, setState] = useState({
        general: {},
        regSections: [],
        regSectionData: {},
        medicalSections: [],
        medicalSectionData: {},
        tests: [],
        selectedTab: 0,
        showTestLabSelection: false,
        labs: [],
        testLab: '',
        testLabError: false,
        testLabErrorMessage: '',
        selectedTestRow: null,
        showResultAvailableDialog: false,
        resultDate: moment(new Date()),
        testResult: '',
        testResultError: false,
        testResultErrorMessage: '',
        testReportResult: '',
        showDocumentsUI: false,
        docAddMode: 'upload',
        addDocURL: '',
        addDocURLError: false,
        addDocURLErrorMessage: '',
        docFileSelectionError: false,
        docFileSelectionErrorMessage: '',
        docFileSelection: '',
        docFileSelectionText: '',
        documentDesc: '',
        documentDescError: false,
        documentDescErrorMessage: '',
        documentName: '',
        documentNameError: false,
        documentNameErrorMessage: '',
        showReportUI: false,
        loadingDocsInprogress: false,
        loadingDocsError: false,
        loadingDocsErrorMessage: '',
        docs: [],
        showDocumentUploadUI: false,
        uploadInProgress: false,
        showAddTestsDialog: false,
        sessions: [],
        treatments: [],
        showTreatmentDetailsDialog: false,
        loadTreatmentDetailsInProgress: false,
        loadTreatmentDetailsError: false,
        loadTreatmentDetailsErrorMessage: '',
        loadingInProgress: false,
        hasAccess: false,
        campDate:''
    });

    const docFileRef = createRef();

    const loadTreatmentDetails = (istate) => {
        istate = { ...istate, loadTreatmentDetailsInProgress: true, loadTreatmentDetailsError: false }
        setState(istate);
        HttpHandler.sendGet('/api/v1/treatment/' + props.match.params.recordId + '/treatments', (d) => {
            istate = { ...istate, treatments: d.result, loadTreatmentDetailsInProgress: false };
            setState(istate);
        }, (errorCode, errorMessage) => {
            istate = { ...istate, loadTreatmentDetailsInProgress: false, loadTreatmentDetailsError: true, loadTreatmentDetailsErrorMessage: 'Error loading treatment details', }
            setState(istate);
            console.log(errorMessage);
            NotificationManager.error('Error loading treatment details', "Session")
        })
    }

    const _loadSessions = (istate, loadTreatment) => {
        HttpHandler.sendGet('/api/v1/treatment/' + props.match.params.recordId + '/sessions', (d) => {
            istate = { ...istate, sessions: d.sessions };
            setState(istate);
            if (loadTreatment) {
                loadTreatmentDetails(istate);
            }

        }, (errorCode, errorMessage) => {
            setState(istate);
            console.log(errorMessage);
            NotificationManager.error('Error loading counsessling session details', "Session")
        })
    }

    const _getGeneralDetailsForProbableCase = (istate) => {
        HttpHandler.sendGet('/api/v1/camp/data/' + props.match.params.recordId + '/register', (d) => {
            istate = { ...istate };

            istate.general = d.general;
            istate.regSections = d.sections;
            istate.regSectionData = d.data;
            istate.campDate = new Date(d.campDate);

            setState(istate);

            HttpHandler.sendGet('/api/v1/camp/data/' + props.match.params.recordId + '/medical', (d) => {
                var state = {
                    ...istate,
                    medicalSections: d.sections,
                    medicalSectionData: d.data,
                    loadingMedicalDetails: false,
                    hasAccess: true
                }
                setState(state);

                _loadTestDetails(state, true);

            }, (errorCode, errorMessage) => {
                setState({
                    loadingMedicalDetails: false,
                })
            });

        }, (errorCode, errorMessage) => {
            console.log(errorMessage);
            if (errorCode !== 401) {
                NotificationManager.error("Error in loading general details", "Error")
            }
            setState({
                loadingMedicalDetails: false,
                hasAccess: errorCode !== 401,
                loadingInProgress: false
            })
        })
    }

    const _loadTestDetails = (istate, loadLabs) => {
        HttpHandler.sendGet('/api/v1/test/' + props.match.params.recordId + '/probable', (d) => {
            var state = {
                ...istate,
                tests: d.tests
            }

            if (loadLabs) {
                setState(state);
                _loadListOfLabs(state, true);
            }
            else {
                state = { ...state, showAddTestsDialog: false }
                setState(state);
            }

        }, (errrCode, errorMessage) => {
            console.log(errorMessage);
            NotificationManager.error("Error in loading test details", "Error")
        })
    }

    const _handleTabChange = (event, newValue) => {
        setState({ ...state, selectedTab: newValue })
    };


    useEffect(() => {
        var istate = { ...state, loadTreatmentDetailsInProgress: true }
        setState(istate);
        _getGeneralDetailsForProbableCase(istate);
    }, [props.match.params.recordId]);

    const _changeLabClicked = (row) => {
        setState({ ...state, showTestLabSelection: true, selectedTestRow: row })
    }

    const _closeTestLabSelection = () => {
        setState({ ...state, showTestLabSelection: false })
    }

    const _changeLabSelection = (v) => {
        setState({
            ...state,
            testLab: v.target.value,
            testLabError: false,
            testLabErrorMessage: ''
        })
    }

    const _saveLabAssign = () => {
        if (typeof state.testLab === 'undefined' || state.testLab === '') {
            setState({ ...state, testLabError: true, testLabErrorMessage: 'Select a lab' });
            return;
        }

        var req = {
            lab: parseInt(state.testLab)
        }

        HttpHandler.sendPost('/api/v1/test/' + state.selectedTestRow.id + '/assignlab', req, (d) => {
            NotificationManager.success('Lab assigned succesfully');
            var labName = '';
            for (var i = 0; i < state.labs.length; i++) {
                if (state.labs[i].id === state.testLab) {
                    state.selectedTestRow.labName = state.labs[i].name;
                    state.selectedTestRow.labId = state.labs[i].id
                }
            }
            setState({
                ...state,
                showTestLabSelection: false,
                selectedTestRow: null
            })
        }, (errorCode, errorMessage) => {
            console.log(errorMessage);
            ErrorDialog.show('Error in assigning the lab');
        })
    }

    const _loadListOfLabs = (istate, loadSessions) => {
        HttpHandler.sendGet('/api/v1/lab/all?offset=0&limit=500', (d) => {
            istate = { ...istate, labs: d.result }

            if (loadSessions) {
                setState(istate);
                _loadSessions(istate, true);
            }
            else {
                setState(istate);
            }
        }, (errorConsole, errorMessage) => {
            console.log(errorMessage)
            NotificationManager.error('Error loading lab details', 'Case')
        })
    }

    const _testSubmitClicked = (d) => {
        console.log(d);
        if (typeof d.labId === 'undefined' || d.labId === null || d.labId === 0) {
            ErrorDialog.show('No lab is assigned. Assign a lab before starting the test');
            return;
        }
        setState({ ...state, selectedTestRow: d })
        DateSelectionDialog.show(false, "Select test date", true, state.campDate, new Date(), (dt) => {
            HttpHandler.sendPost('/api/v1/test/' + d.id + '/submittest', { date: dt.getTime() }, (res) => {
                d.testDoneDate = dt.getTime();
                NotificationManager.success('Test date updated', 'Case')
                setState({ ...state });
            }, (errorCode, errorMessage) => {
                console.log(errorMessage);
                ErrorDialog.show('Error saving test submit date')
            });
        }, () => {

        })
    }

    const _testResultAvailableClicked = (d) => {
        setState({ ...state, showResultAvailableDialog: true, selectedTestRow: d })
    }

    const _closeResultAvailableDialog = () => {
        setState({ ...state, false: true })
    }

    const _handleResultDateChange = (v) => {
        setState({
            ...state,
            resultDate: v,
        })
    }

    const _saveReportClicked = () => {
        var row = state.selectedTestRow;
        var report = state.testResult.trim();
        if (report === '') {
            setState({ ...state, testResultError: true, testResultErrorMessage: 'Mandatory' });
            return;
        }


        var req = {
            date: state.resultDate.toDate().getTime(),
            report: report
        }

        HttpHandler.sendPost('/api/v1/test/' + row.id + '/testresult', req, (res) => {
            row.testResultDate = new Date(req.date);
            NotificationManager.success('Test result updated', 'Case')
            setState({ ...state, showResultAvailableDialog: false, resultDate: moment(new Date()),
                testResult: "" });
        }, (errorCode, errorMessage) => {
            console.log(errorMessage);
            ErrorDialog.show('Error saving test submit date');
        });
    }

    const _showTestReportUI = (row) => {

        HttpHandler.sendGet('/api/v1/test/' + row.id + '/testresult', (d) => {
            setState({ ...state, showReportUI: true, testReportResult: d.report.result, selectedTestRow: row });
        }, (errorCode, errorMessage) => {
            ErrorDialog.show('Error fetching test result details');
        })

    }

    const _closeReportUI = () => {
        setState({ ...state, showReportUI: false, selectedTestRow: null })
    }

    const _showDocumentsClicked = (row) => {
        var istate = { ...state, showDocumentsUI: true, selectedTestRow: row };
        setState(istate)
        _listDocs(istate);
    }

    const _closeDocsUI = (row) => {
        setState({ ...state, showDocumentsUI: false, selectedTestRow: null, selectedTestRow: row })
    }

    const _handleDocOptionChange = (v) => {
        var istate = { ...state }
        if (v.target.value === 'url') {
            istate = { ...istate, docFileSelectionError: false, docFileSelectionErrorMessage: '' };

        }
        else {
            istate = { ...istate, addDocURLError: false, addDocURLErrorMessage: '' };
        }
        setState({ ...istate, docAddMode: v.target.value })
    }

    const _docAttachClicked = () => {
        setState({
            ...state,
            docFileSelectionError: false,
            docFileSelectionErrorMessage: '',
        })
        docFileRef.current.click();
    }

    const _docFileSelectionChanged = (v) => {
        setState({
            ...state,
            docFileSelectionText: v.target.files[0].name
        })
    }

    const _processUploadResponse = (response) => {
        const statusCode = response.status;
        const data = response.json();
        return Promise.all([statusCode, data]).then(res => ({
            statusCode: res[0],
            data: res[1]
        }));
    }

    const _uploadFileClicked = () => {

        var valid = true;
        var name = state.documentName.trim();
        var istate = { ...state };
        if (name === '') {
            istate = { ...istate, documentNameError: true, documentNameErrorMessage: 'Mandatory' };
            valid = false;
        }
        if (state.docAddMode === 'url') {
            var url = state.addDocURL.trim();

            if (url === '') {
                istate = { ...istate, addDocURLError: true, addDocURLErrorMessage: 'Mandatory' };
                valid = false;
            }

            if (!valid) {
                setState(istate);
                return;
            }

            var req = {
                url: url,
                description: state.documentDesc.trim(),
                type: 'testReport',
                objectId: state.selectedTestRow.id,
                name: name
            }

            HttpHandler.sendPost('/api/v1/doc/newurl', req, (d) => {
                NotificationManager.success('Document details saved', 'Document');
                var istate = { ...state, documentName: '', documentDesc: '', addDocURL: '', showDocumentUploadUI: false };
                setState(istate);
                _listDocs(istate);
            }, (errorCode, errorMessage) => {
                console.log(errorMessage);
                ErrorDialog.show('Error in saving the document details');
            })
        }
        else {
            var file = state.docFileSelectionText.trim();
            if (file === '') {
                istate = { ...istate, docFileSelectionError: true, docFileSelectionErrorMessage: 'Mandatory' };
                valid = false;

            }

            if (!valid) {
                setState(istate);
                return;
            }

            const data = new FormData();
            var req = {
                fileName: state.docFileSelectionText, type: 'testReport',
                folder: state.selectedTestRow.regNum,
                name: name,
                description: state.documentDesc.trim(),
                id: state.selectedTestRow.id
            };
            data.append("form", JSON.stringify(req));

            data.append('fileupload',
                docFileRef.current.files[0], '__RENAME__L',
            );
            var headers = {
                'Accept': 'application/json',
            };

            headers['x-session-secret'] = HttpHandler.SESSION_SECRET;
            headers['x-user-id'] = HttpHandler.USER_ID;
            var url = HttpHandler.BASE_URL + "/api/v1/icsfile/upload";
            setState({ ...istate, uploadInProgress: true })
            fetch(url, {
                method: 'post',
                headers: headers,
                body: data
            }).then(_processUploadResponse).then(res => {
                if (res.statusCode !== 200) {
                    ErrorDialog.show('There was an error uploading the file');
                    setState({ ...istate, uploadInProgress: false, documentName: '', docFileSelectionText: '' })
                }
                else {
                    console.log(res);
                    NotificationManager.success('File uploaded successfully');
                    istate = { ...istate, showDocumentUploadUI: false, uploadInProgress: false, documentName: '', docFileSelectionText: '' };
                    setState(istate);
                    _listDocs(istate);
                }
            });
        }
    }


    const _listDocs = (istate) => {
        setState({ ...istate, loadingDocsInprogress: true, loadingDocsError: false });
        HttpHandler.sendGet('/api/v1/doc/all?objectType=testReport&objectId=' + istate.selectedTestRow.id, (d) => {
            setState({ ...istate, loadingDocsInprogress: false, loadingDocsError: false, docs: d.documents });
        }, (errorCode, errorMessage) => {
            setState({ ...istate, loadingDocsInprogress: false, loadingDocsError: true, loadingDocsErrorMessage: 'Error loading documents list' });
        })
    }

    const _showAddDocusUI = () => {
        var istate = { ...state, showDocumentUploadUI: true };
        setState(istate)
    }

    const _closeAddDocsUI = () => {
        var istate = { ...state, showDocumentUploadUI: false };
        setState(istate)
    }

    const _onAddTestsDone = () => {
        _loadTestDetails(state, false);
    }

    const _addTest = () => {
        setState({ ...state, showAddTestsDialog: true })
    }

    const _addCouncellingSession = () => {
        DateSelectionDialog.show(false, "Select planned session date", false, state.campDate, new Date(), (dt) => {
            var req = {
                plannedDate: dt.getTime()
            }
            HttpHandler.sendPost('/api/v1/treatment/' + props.match.params.recordId + '/session', req, (res) => {
                NotificationManager.success('Session created', 'Counselling session')
                _loadSessions(state, false);
            }, (errorCode, errorMessage) => {
                console.log(errorMessage);
                ErrorDialog.show('Error saving counselling session details')
            });
        }, () => {

        })
    }

    const _actualDateChangeClicked = (row) => {
        DateSelectionDialog.show(true, "Select actual session date", true, state.campDate, new Date(), (dt, notes) => {
            var req = {
                actualDate: dt.getTime(),
                notes: notes
            }
            HttpHandler.sendPost('/api/v1/treatment/session/' + row.id + '/actual', req, (res) => {
                NotificationManager.success('Session updated', 'Counselling session')
                _loadSessions(state);
            }, (errorCode, errorMessage) => {
                console.log(errorMessage);
                ErrorDialog.show('Error saving counselling session details')
            });
        }, () => {

        })
    }

    const _treatmentDetailsSaved = () => {
        var istate = { ...state, showTreatmentDetailsDialog: false }
        setState(istate);
        loadTreatmentDetails(istate);
    }

    const _addTreatmentDetails = () => {
        setState({ ...state, showTreatmentDetailsDialog: true })
    }

    const _deleteProbableTestClicked = (row) => {
        ConfirmationDialog.show('Are you sure you want to delete this test ?', () => {
            HttpHandler.sendDelete('/api/v1/test/' + row.id + "/probable", () => {
                _loadTestDetails(state, false);
            }, () => {
                ErrorDialog.show('Error deleting test record')
            })
        });
    }

    return (
        <div>
            {state.loadingInProgress &&
                <div style={{ paddingTop: 40, color: '#000', fontFamily: 'Metropolis-Regular', fontSize: 15, textAlign: 'center' }}>We are loading the camp details.</div>
            }
            {/* {(((HttpHandler.USER_TYPE !== 'coordinator' && HttpHandler.USER_TYPE !== 'screening' && HttpHandler.USER_TYPE !== 'doctor') || !state.hasAccess) && state.loadingInProgress === false) &&
                    <div style={{ paddingTop: 40, color: '#FF0000', fontFamily: 'Metropolis-Regular', fontSize: 15, textAlign: 'center' }}>You are not authorized to access contents of this page.</div>
                } */}

              
            {(((HttpHandler.USER_TYPE === 'coordinator' || HttpHandler.USER_TYPE === 'screening' || HttpHandler.USER_TYPE === 'data entry' || HttpHandler.USER_TYPE === 'doctor') && state.hasAccess) && state.loadingInProgress === false) &&
                <div>
                    <div style={{ marginRight: 20 }}>
                        <PagePath path={['Probale Case', state.general.regNum]}>
                        </PagePath>

                    </div>
                    <TreatmentInitiateDialog
                        campdate={state.campDate}
                        recordId={props.match.params.recordId}
                        onSave={_treatmentDetailsSaved}
                        isVisible={state.showTreatmentDetailsDialog}
                        onClose={() => { setState({ ...state, showTreatmentDetailsDialog: false }) }}
                    >

                    </TreatmentInitiateDialog>
                    <AddTestsDialog
                        recordId={props.match.params.recordId}
                        onSave={_onAddTestsDone}
                        isVisible={state.showAddTestsDialog}
                        onClose={() => { setState({ ...state, showAddTestsDialog: false }) }} />

                    <Dialog open={state.showDocumentUploadUI} onClose={_closeAddDocsUI}>
                        <div style={{ padding: 5, width: 560 }}>
                            <div className={commonClasses.headerBar}>Add Document</div>
                        </div>
                        {(state.uploadInProgress) &&
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 40, marginBottom: 40, alignItems: 'center' }}>
                                <CircularProgress></CircularProgress>
                                <div style={{ fontFamily: 'Metropolis-Regular', marginLeft: 10 }}>Upload in progress</div>
                            </div>
                        }
                        {(state.uploadInProgress === false) &&
                            <div style={{ width: '100%', backgroundColor: '#FAFAFA', padding: 20, paddingTop: 0, display: 'flex', flexDirection: 'column' }}>
                                <RadioGroup style={{ width: '100%' }} aria-label="docAddMode" name="docAddMode" value={state.docAddMode} onChange={_handleDocOptionChange}>
                                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                        <FormControlLabel style={{ paddingTop: 30, width: 100 }} value="url" control={<Radio />} label="URL" />
                                        <TextField
                                            style={{ flex: 1 }}
                                            required
                                            error={state.addDocURLError}
                                            id='addDocURL'
                                            label="URL"
                                            fullWidth
                                            value={state.addDocURL}
                                            margin="normal"
                                            helperText={state.addDocURLErrorMessage}
                                            onChange={(v) => {
                                                setState({
                                                    ...state,
                                                    addDocURL: v.target.value,
                                                    addDocURLError: false,
                                                    addDocURLErrorMessage: ''
                                                })
                                            }}
                                        />
                                    </div>

                                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center' }}>
                                        <FormControlLabel style={{ paddingTop: 30, width: 100 }} value="upload" control={<Radio />} label="Upload" />
                                        <input
                                            onChange={_docFileSelectionChanged}
                                            type="file" style={{ display: 'none' }} ref={docFileRef} />
                                        <TextField
                                            onChange={(v) => {
                                                setState({
                                                    ...state,
                                                    docFileSelectionError: false,
                                                    docFileSelectionErrorMessage: '',
                                                    docFileSelection: v.target.value
                                                })
                                            }}
                                            inputProps={{ readOnly: true }}
                                            error={state.docFileSelectionError}
                                            helperText={state.docFileSelectionErrorMessage}
                                            fullWidth
                                            label="File to upload"
                                            style={{ height: 50 }}
                                            required
                                            value={state.docFileSelectionText}
                                            id="docFileSelectionText"
                                            margin="normal"
                                            InputProps={{
                                                classes: {

                                                },
                                                endAdornment: (
                                                    <InputAdornment variant="filled" position="end">
                                                        <DescriptionRoundedIcon
                                                            onClick={_docAttachClicked}
                                                            style={{
                                                                width: 32, height: 32,
                                                                color: '#0D47A1', cursor: 'pointer'
                                                            }}
                                                        />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </div>
                                </RadioGroup>
                                <TextField
                                    error={state.documentNameError}
                                    id='documentName'
                                    label="Report Name"
                                    fullWidth
                                    multiline={false}
                                    value={state.documentName}
                                    margin="normal"
                                    helperText={state.documentNameErrorMessage}
                                    inputProps={{ maxLength: 100 }}
                                    onChange={(v) => {
                                        setState({
                                            ...state,
                                            documentName: v.target.value,
                                            documentNameError: false,
                                            documentNameErrorMessage: ''
                                        })
                                    }}
                                />

                                <TextField
                                    error={state.documentDescError}
                                    id='documentDesc'
                                    label="Finding Summary"
                                    fullWidth
                                    multiline={true}
                                    rows={3}
                                    value={state.documentDesc}
                                    margin="normal"
                                    helperText={state.documentDescErrorMessage}
                                    inputProps={{ maxLength: 500 }}
                                    onChange={(v) => {
                                        setState({
                                            ...state,
                                            documentDesc: v.target.value,
                                            documentDescError: false,
                                            documentDescErrorMessage: ''
                                        })
                                    }}
                                />


                            </div>
                        }
                        <Divider />
                        <DialogActions>
                            <div style={{ paddingTop: 10, flexDirection: 'row', display: 'flex', justifyContent: 'flex-end' }}>
                                <Button
                                    onClick={_uploadFileClicked}
                                    variant="contained" color="primary" style={{ marginLeft: 20 }}>Save</Button>
                                <Button
                                    onClick={_closeAddDocsUI}
                                    variant="contained" color="default" style={{ marginLeft: 20 }}>Close</Button>
                            </div>
                        </DialogActions>
                    </Dialog>

                    <Dialog open={state.showDocumentsUI} onClose={_closeDocsUI}>
                        <div style={{ padding: 5, width: 560 }}>
                            <div className={commonClasses.headerBar} style={{ display: 'flex', height: 40, alignItems: 'center' }}><span style={{ flex: 1 }}>Documents </span>
                          
                                {(HttpHandler.USER_TYPE === 'screening' || HttpHandler.USER_TYPE === 'data entry' || HttpHandler.USER_TYPE === 'doctor' || HttpHandler.USER_TYPE === 'coordinator') &&
                                    <Button size="small"
                                        onClick={_showAddDocusUI}
                                        variant="contained" color="default" style={{ marginLeft: 20, height: 30, }}>Add</Button>
                                }
                            </div>
                        </div>
                        <div style={{ width: '100%', backgroundColor: '#FAFAFA', padding: 20, paddingTop: 0, display: 'flex', flexDirection: 'column' }}>
                            <div style={{ flex: 1 }}>
                                {(state.loadingDocsInprogress) &&
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 40, alignItems: 'center' }}>
                                        <CircularProgress></CircularProgress>
                                        <div style={{ fontFamily: 'Metropolis-Regular', marginLeft: 10 }}>Loading document list</div>
                                    </div>
                                }
                                {(state.loadingDocsInprogress === false && state.loadingDocsError) &&
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 40, alignItems: 'center' }}>
                                        <div style={{ fontFamily: 'Metropolis-Regular', marginLeft: 10, color: '#D32F2F' }}>{state.loadingDocsErrorMessage}</div>
                                    </div>
                                }
                                {(state.loadingDocsInprogress === false && state.loadingDocsError === false && state.docs.length === 0) &&
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 40, alignItems: 'center', padding: 10, marginBottom: 40 }}>
                                        <div style={{ fontFamily: 'Metropolis-Regular', marginLeft: 10, color: '#000' }}>No document uploaded.</div>
                                    </div>
                                }
                                {(state.loadingDocsInprogress === false && state.loadingDocsError === false && state.docs.length > 0) &&
                                    <div style={{ width: '100%', backgroundColor: '#FAFAFA', overflow: 'auto', height: 450, paddingRight: 5, paddingBottom: 5 }}>
                                        {state.docs.map((row, key) => {
                                            return (
                                                <div style={{ paddingTop: 10, width: '100%' }} key={key}>
                                                    <div style={{
                                                        backgroundColor: '#FFF', display: 'flex', width: '100%',
                                                        padding: 10, borderRadius: 5, boxShadow: "1px 1px 2px #CCC"
                                                    }}>
                                                        <div style={{ fontFamily: 'Metropolis-Regular', flex: 1 }}>
                                                            <a href={row.url} target='_blank'>{row.name}</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                }
                            </div>
                        </div>
                        <Divider />
                        <DialogActions>
                            <div style={{ paddingTop: 10, flexDirection: 'row', display: 'flex', justifyContent: 'flex-end' }}>
                                <Button
                                    onClick={() => _closeDocsUI(state.selectedTestRow)}
                                    variant="contained" color="default" style={{ marginLeft: 20 }}>Close</Button>
                            </div>
                        </DialogActions>
                    </Dialog>

                    <Dialog open={state.showReportUI} onClose={_closeReportUI}>
                        <div style={{ padding: 5, width: 560 }}>
                            <div className={commonClasses.headerBar}>{state.selectedTestRow === null ? 'Test Result' : state.selectedTestRow.testName}
                            <span
                                    onClick={() => { _showDocumentsClicked(state.selectedTestRow) }}
                                    style={{ cursor: 'pointer', marginLeft: 10, alignItems: "flex-end", position: 'absolute', right: 20, top: 10 }}>
                                    <AttachFileRoundedIcon style={{ color: '#fff' }} />
                                </span>
                            </div>
                        </div>
                        <div style={{ width: '100%', backgroundColor: '#FAFAFA', padding: 20, display: 'flex' }}>
                            <div style={{ flex: 1 }}>
                                {state.testReportResult.split("\n").map((i, key) => {
                                    return <div key={key}>{i}</div>;

                                })}
                            </div>

                        </div>
                        <Divider />
                        <DialogActions>
                            <div style={{ paddingTop: 10, flexDirection: 'row', display: 'flex', justifyContent: 'flex-end' }}>
                                <Button
                                    onClick={() => { setState({ ...state, showReportUI: false, selectedTestRow: null }) }}
                                    variant="contained" color="default" style={{ marginLeft: 20 }}>Close</Button>
                            </div>
                        </DialogActions>
                    </Dialog>

                    <Dialog open={state.showResultAvailableDialog} onClose={_closeResultAvailableDialog}>
                        <div style={{ padding: 5, width: 560 }}>
                            <div className={commonClasses.headerBar}>
                                Test result
                            <span
                                    onClick={() => { _showDocumentsClicked(state.selectedTestRow) }}
                                    style={{ cursor: 'pointer', marginLeft: 10, alignItems: "flex-end", position: 'absolute', right: 20, top: 10 }}>
                                    <AttachFileRoundedIcon style={{ color: '#fff' }} />
                                </span></div>
                        </div>
                        {(state.selectedTestRow !== null) &&
                            <div style={{ width: '100%', backgroundColor: '#FAFAFA', padding: 20, display: 'flex' }}>
                                <div style={{ flex: 1 }}>
                                    <DatePicker
                                        label="Result date"
                                        format="DD/MMM/yyyy"
                                        value={state.resultDate}
                                        minDate={new Date(state.selectedTestRow.testDoneDate)}
                                        maxDate={new Date()}
                                        onChange={_handleResultDateChange}
                                        animateYearScrolling
                                    />
                                    <TextField
                                        required
                                        error={state.testResultError}
                                        id='testResult'
                                        label="Test Result"
                                        fullWidth
                                        multiline={true}
                                        rows={10}
                                        value={state.testResult}
                                        margin="normal"
                                        helperText={state.testResultErrorMessage}
                                        inputProps={{ maxLength: 1000 }}
                                        onChange={(v) => {
                                            setState({
                                                ...state,
                                                testResult: v.target.value,
                                                testResultError: false,
                                                testResultErrorMessage: ''
                                            })
                                        }}
                                    />
                                </div>

                            </div>
                        }
                        <Divider />
                        <DialogActions>
                            <div style={{ paddingTop: 10, flexDirection: 'row', display: 'flex', justifyContent: 'flex-end' }}>
                                <Button
                                    onClick={_saveReportClicked}
                                    variant="contained" color="primary" style={{ marginLeft: 20 }}>Save</Button>
                                <Button
                                    onClick={() => { setState({ ...state, showResultAvailableDialog: false, selectedTestRow: null }) }}
                                    variant="contained" color="default" style={{ marginLeft: 20 }}>Close</Button>
                            </div>
                        </DialogActions>
                    </Dialog>


                    <Dialog open={state.showTestLabSelection} onClose={_closeTestLabSelection}>
                        <div style={{ padding: 5, width: 560 }}>
                            <div className={commonClasses.headerBar}>Assign a lab</div>
                        </div>
                        <div style={{ width: '100%', backgroundColor: '#FAFAFA', padding: 20, display: 'flex' }}>
                            <FormControl style={{ flex: 1, marginTop: 16, marginLeft: 10, marginBottom: 10 }} error={state.testLabError}>
                                <InputLabel id="probableCancerType-label" >Select a lab</InputLabel>

                                <Select
                                    labelId="testLab-label"
                                    id="testLab-select"
                                    value={state.testLab}
                                    style={{ width: '100%' }}
                                    onChange={_changeLabSelection}
                                >
                                    {state.labs.map((row, key) => {
                                        return (
                                            <MenuItem key={row.id} value={row.id}>{row.name}</MenuItem>
                                        )
                                    })}
                                </Select>
                                <FormHelperText>{state.testLabErrorMessage}</FormHelperText>
                            </FormControl>


                        </div>
                        <Divider />
                        <DialogActions>
                            <div style={{ paddingTop: 10, flexDirection: 'row', display: 'flex', justifyContent: 'flex-end' }}>
                                <Button
                                    onClick={_saveLabAssign}
                                    variant="contained" color="primary" style={{ marginLeft: 20 }}>Save</Button>
                                <Button
                                    onClick={() => { setState({ ...state, showTestLabSelection: false, selectedTestRow: null }) }}
                                    variant="contained" color="default" style={{ marginLeft: 20 }}>Close</Button>
                            </div>
                        </DialogActions>
                    </Dialog>

                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <div style={{ width: 720, paddingTop: 30 }}>

                            <AppBar position="static" style={{ display: 'flex', flexDirection: 'row' }}>
                                <Tabs scrollButtons="auto" variant="scrollable" value={state.selectedTab} onChange={_handleTabChange} aria-label="simple tabs example" style={{ flex: 1 }}>
                                    <Tab label="General"  {...a11yProps(0)} style={{ minWidth: 100 }} />
                                    <Tab label="Screening" {...a11yProps(1)} />
                                    <Tab label="Tests" {...a11yProps(2)} />
                                    <Tab label="Councelling" {...a11yProps(3)} />
                                    <Tab label="Treatment" {...a11yProps(4)} />
                                </Tabs>

                            </AppBar>
                            <TabPanel value={state.selectedTab} index={0}>
                                <div style={{}}>
                                    <Paper style={{ padding: 10, marginTop: 10 }}>
                                        <div style={{ flexDirection: 'row', display: 'flex', paddingBottom: 5, alignItems: 'center' }}>
                                            <div style={{ fontFamily: 'Metropolis-Regular', flex: 1 }}>Personal</div>
                                        </div>
                                        <Divider></Divider>
                                        <DataValue title="Name" value={state.general.name} />
                                        <DataValue title="Gender" value={genderMapping[state.general.gender]} />
                                        <DataValue title="Age" value={state.general.age} />
                                        <DataValue title="Marital Status" value={maritalMapping[state.general.maritalStatus]} />
                                        <DataValue title="Phone" value={state.general.phone} />
                                        <DataValue title="Email" value={state.general.email} />
                                    </Paper>
                                    <TemplateDataView sectionData={state.regSectionData} sections={state.regSections} />
                                </div>
                            </TabPanel>
                            <TabPanel value={state.selectedTab} index={1}>
                                <Paper style={{ padding: 10, marginTop: 10 }}>
                                    <div style={{ flexDirection: 'row', display: 'flex', paddingBottom: 5, alignItems: 'center' }}>
                                        <div style={{ fontFamily: 'Metropolis-Regular', flex: 1 }}>Probable case</div>
                                    </div>
                                    <Divider></Divider>

                                    {/* <DataValue title="Probale case" value={cancerTypeMapping[state.general.cancerType]=='None'?'No':(state.general.probableCase === 1 ? 'Yes' : (state.general.probableCase === 2 ? 'Confirmed' : 'No'))} /> */}
                                    <DataValue title="Probale case" value={state.general.probableCase === 1 ? 'Yes' : (state.general.probableCase === 2 ? 'Confirmed' : 'No')} />
                                    <DataValue title="Cancer Type" value={cancerTypeMapping[state.general.cancerType]} />
                                </Paper>
                                <TemplateDataView sectionData={state.medicalSectionData} sections={state.medicalSections} />
                            </TabPanel>
                            <TabPanel value={state.selectedTab} index={2}>
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                  
                                    {(HttpHandler.USER_TYPE === 'coordinator' || HttpHandler.USER_TYPE === 'data entry' || HttpHandler.USER_TYPE === 'screening' || HttpHandler.USER_TYPE === 'doctor') &&
                                        <Button
                                            variant="contained" color="primary"
                                            onClick={_addTest} style={{ marginTop: 5 }}
                                            color="secondary" aria-label="add" size="medium" title="Add tests">
                                            Add tests
                        </Button>
                                    }
                                </div>
                                <div style={{ flex: 1, paddingTop: 5 }}>
                                    {(state.tests.length === 0) &&
                                        <div style={{ paddingTop: 30, paddingBottom: 30, display: 'flex', justifyContent: 'center' }}>
                                            <div style={{ fontFamily: 'Metropolis-Medium' }}>No tests are added</div>
                                        </div>
                                    }
                                    {state.tests.map((row, key) => {
                                        return (
                                            <div key={row.id} style={{ paddingBottom: 5, paddingTop: 5, }}>
                                                <div style={{
                                                    borderRadius: 5, boxShadow: '1px 1px 2px #999', backgroundColor: '#FFF',
                                                    padding: 10,
                                                }}>

                                                    <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', marginBottom: 5 }}>

                                                        <div style={{ flex: 1, fontFamily: 'Metropolis-Medium' }}>{row.testName}</div>
                                                        <div
                                                            onClick={() => { _deleteProbableTestClicked(row) }}
                                                            style={{ cursor: 'pointer', marginLeft: 10 }}>
                                                            <DeleteForeverRoundedIcon style={{ color: '#FF0000' }} />
                                                        </div>


                                                        {(typeof row.testResultDate !== 'undefined' && row.testResultDate !== null) &&
                                                            <div
                                                                onClick={() => { _showTestReportUI(row) }}
                                                                style={{ cursor: 'pointer' }}>
                                                                <AssessmentRoundedIcon style={{ color: '#01579B' }} />
                                                            </div>
                                                        }




                                                    </div>
                                                    <Divider />
                                                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                                        <div style={{ flex: 1 }}>
                                                            <div style={{ width: '100%', display: 'flex', marginTop: 10 }}>
                                                                <div style={{ fontFamily: 'Metropolis-Regular' }}>Lab</div>
                                                                <div style={{ paddingLeft: 5, paddingRight: 5 }}>:</div>
                                                                <div style={{ fontFamily: 'Metropolis-Medium' }}>
                                                                    {typeof row.labId === 'undefined' || row.labId === 0 || row.labId === null ? 'Not assigned' : row.labName}
                                                                </div>
                                                              
                                                                {(HttpHandler.USER_TYPE === 'coordinator' || HttpHandler.USER_TYPE === 'data entry' || HttpHandler.USER_TYPE === 'screening' || HttpHandler.USER_TYPE === 'doctor') &&
                                                                    <div style={{ fontFamily: 'Metropolis-Regular', marginLeft: 3, marginRight: 3 }}> ( </div>
                                                                }
                                                              
                                                                {(HttpHandler.USER_TYPE === 'coordinator' || HttpHandler.USER_TYPE === 'data entry' || HttpHandler.USER_TYPE === 'screening' || HttpHandler.USER_TYPE === 'doctor') &&
                                                                    <div
                                                                        onClick={() => { _changeLabClicked(row) }}
                                                                        style={{
                                                                            fontFamily: 'Metropolis-Regular', color: '#4139B9',
                                                                            cursor: 'pointer',
                                                                            borderBottom: '1px solid #4139B9'
                                                                        }}>Change</div>
                                                                }
                                                              
                                                                {(HttpHandler.USER_TYPE === 'coordinator' || HttpHandler.USER_TYPE === 'data entry' || HttpHandler.USER_TYPE === 'screening' || HttpHandler.USER_TYPE === 'doctor') &&
                                                                    <div style={{ fontFamily: 'Metropolis-Regular', marginLeft: 3, marginRight: 3 }}> ) </div>
                                                                }
                                                            </div>


                                                            <div style={{ width: '100%', display: 'flex', marginTop: 20 }}>
                                                                {/* <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                                                                    <div style={{ fontFamily: 'Metropolis-Regular' }}>Assign date</div>
                                                                    <div style={{ fontFamily: 'Metropolis-Medium' }}>
                                                                        <Moment format="DD-MMM-YYYY">{new Date(row.testRecommendationDate)}</Moment>
                                                                    </div>
                                                                </div> */}

                                                                <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                                                                    <div style={{ fontFamily: 'Metropolis-Regular' }}>Test date</div>
                                                                    <div style={{ fontFamily: 'Metropolis-Medium' }}>
                                                                        {(row.testDoneDate === null) &&
                                                                            <span style={{ color: '#E53935' }}>Not done</span>
                                                                        }
                                                                        {(row.testDoneDate != null) &&
                                                                            <Moment format="DD-MMM-YYYY">{new Date(row.testDoneDate)}</Moment>
                                                                        }
                                                                    </div>
                                                                </div>

                                                                <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                                                                    <div style={{ fontFamily: 'Metropolis-Regular' }}>Result date</div>
                                                                    <div style={{ fontFamily: 'Metropolis-Medium' }}>
                                                                        {(row.testResultDate === null) &&
                                                                            <span style={{ color: '#E53935' }}>Not done</span>
                                                                        }
                                                                        {(row.testResultDate != null) &&
                                                                            <Moment format="DD-MMM-YYYY">{new Date(row.testResultDate)}</Moment>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                      
                                                        {(HttpHandler.USER_TYPE === 'coordinator' || HttpHandler.USER_TYPE === 'data entry' || HttpHandler.USER_TYPE === 'screening' || HttpHandler.USER_TYPE === 'doctor') &&
                                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                                                {(row.testDoneDate === null) &&
                                                                    <Button
                                                                        onClick={() => { _testSubmitClicked(row); }}
                                                                        size="small" style={{ width: 160 }}
                                                                        variant="contained" color="primary">Test submitted</Button>
                                                                }
                                                                {(row.testDoneDate !== null && row.testResultDate === null) &&
                                                                    <Button
                                                                        onClick={() => { _testResultAvailableClicked(row); }}
                                                                        size="small" style={{ width: 160 }}
                                                                        variant="contained" color="primary">Update result</Button>
                                                                }
                                                            </div>
                                                        }
                                                    </div>


                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </TabPanel>
                            <TabPanel value={state.selectedTab} index={3}>
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                  
                                    {(HttpHandler.USER_TYPE === 'coordinator' || HttpHandler.USER_TYPE === 'data entry' || HttpHandler.USER_TYPE === 'screening' || HttpHandler.USER_TYPE === 'doctor') &&
                                        <Button
                                            variant="contained" color="primary"
                                            onClick={_addCouncellingSession} style={{ marginTop: 5 }}
                                            color="secondary" aria-label="add" size="medium" title="Add sessions">
                                            Add session
                        </Button>
                                    }
                                </div>
                                {(state.sessions.length === 0) &&
                                    <div style={{ paddingTop: 30, paddingBottom: 30, display: 'flex', justifyContent: 'center' }}>
                                        <div style={{ fontFamily: 'Metropolis-Medium' }}>No counselling sessions are added</div>
                                    </div>
                                }
                                {state.sessions.map((row, key) => {
                                    return (
                                       <>
                                         <div key={row.id} style={{ paddingBottom: 5, paddingTop: 5, }}>
                                            <div style={{
                                                borderRadius: 5, boxShadow: '1px 1px 2px #999', backgroundColor: '#FFF',
                                                padding: 10
                                            }}>
                                                <div style ={{display: 'flex'}}>
                                                <div style={{ fontFamily: 'Metropolis-Regular', fontSize: 16, flex: 1 }}>{"Session " + row.sessionSequence}</div>
                                                <div style={{ fontFamily: 'Metropolis-Regular', fontSize: 16, color: '#888', paddingRight: 5 }}>Planned : </div>
                                                <Moment format="DD-MMM-YYYY">{new Date(row.plannedDate)}</Moment>
                                                <div style={{ fontFamily: 'Metropolis-Regular', fontSize: 16, color: '#888', marginLeft: 40 }}>Actual : </div>
                                                {(row.actualDate !== null) &&
                                                    <Moment format="DD-MMM-YYYY">{new Date(row.actualDate)}</Moment>
                                                }
                                                {(row.actualDate === null) &&
                                                    <div style={{ display: 'flex' }}>
                                                        <div style={{ fontFamily: 'Metropolis-Regular', fontSize: 16, color: '#888', marginRight: 5 }}>Yet to happen</div>
                                                      
                                                        {(HttpHandler.USER_TYPE === 'coordinator' || HttpHandler.USER_TYPE === 'data entry' || HttpHandler.USER_TYPE === 'screening' || HttpHandler.USER_TYPE === 'doctor') &&
                                                            <div onClick={() => _actualDateChangeClicked(row)}>
                                                                <TodayRoundedIcon style={{ color: "#2962FF", cursor: 'pointer' }} />
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                                </div>
                                                <div style={{
                                                   display: 'flex'
                                                }}>
                                                <div style={{ fontFamily: 'Metropolis-Regular', fontSize: 16 }}>Notes: {row.notes? row.notes: ''}</div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        
                                       </>
                                    )
                                })
                                }
                            </TabPanel>
                            <TabPanel value={state.selectedTab} index={4}>

                                {(state.loadTreatmentDetailsInProgress) &&
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 40, alignItems: 'center' }}>
                                        <CircularProgress></CircularProgress>
                                        <div style={{ fontFamily: 'Metropolis-Regular', marginLeft: 10 }}>Loading treatment details</div>
                                    </div>
                                }
                                {(state.loadTreatmentDetailsInProgress === false && state.loadTreatmentDetailsError) &&
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 40, alignItems: 'center' }}>
                                        <div style={{ fontFamily: 'Metropolis-Regular', marginLeft: 10, color: '#D32F2F' }}>{state.loadTreatmentDetailsErrorMessage}</div>
                                    </div>
                                }
                                {(state.loadTreatmentDetailsInProgress === false && state.loadTreatmentDetailsError === false && state.treatments.length === 0) &&
                                    <div style={{ paddingTop: 30, paddingBottom: 30, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <div style={{ fontFamily: 'Metropolis-Medium' }}>Treatment not initiated</div>
                                      
                                        {(HttpHandler.USER_TYPE === 'coordinator' || HttpHandler.USER_TYPE === 'data entry' || HttpHandler.USER_TYPE === 'screening' || HttpHandler.USER_TYPE === 'doctor') &&
                                            <Button
                                                variant="contained" color="primary"
                                                onClick={_addTreatmentDetails} style={{ marginTop: 15 }}
                                                color="secondary" aria-label="add" size="medium" title="Initiate tratment">
                                                Initiate tratment
                                </Button>
                                        }
                                    </div>
                                }

                                {(state.loadTreatmentDetailsInProgress === false && state.loadTreatmentDetailsError === false && state.treatments.length > 0) &&
                                    <div style={{ paddingBottom: 5, paddingTop: 5, }}>
                                        <div style={{
                                            borderRadius: 5, boxShadow: '1px 1px 2px #999', backgroundColor: '#FFF',
                                            padding: 10, paddingTop: 20
                                        }}>

                                            <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', marginBottom: 5 }}>
                                                <DataValue title="Start Date" value={moment(new Date(state.treatments[0].startDate)).format("DD-MMM-YYYY")} />
                                            </div>
                                            <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', marginBottom: 5 }}>
                                                <DataValue title="Hospital" value={state.treatments[0].hospital.name} />
                                            </div>

                                            <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', marginBottom: 5 }}>
                                                <DataValue title="Donor" value={state.treatments[0].donor.name} />
                                            </div>

                                            <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', marginBottom: 5 }}>
                                                <DataValue title="Description" value={state.treatments[0].description.split("\n").map((i, key) => {
                                                    return <div key={key}>{i}</div>;
                                                })} />
                                            </div>

                                        </div>
                                    </div>
                                }
                            </TabPanel>
                        </div>

                    </div>
                </div>
            }
        </div>
    )
}