import HttpHandler from "./HttpHandler";




export default class Utils {

    static USER_TYPE_ADMIN = "admin";
    static USER_TYPE_SURVEY = "survey";
    static USER_TYPE_SCREENING = "screening";
    static USER_TYPE_COORDINATOR = "coordinator";
    static USER_TYPE_DOCTOR = "doctor";
    static USER_TYPE_DATAENTRY = "data entry" 
   

    static USER_TYPE_MAP = {
        'admin': 'Administrator',
        'survey': 'Survey',
        'screening': 'Screening',
        'coordinator': 'Coordinator',
        'doctor': 'Doctor',
        'data entry': 'Data Entry'
     
    }

    static statesAndDistricts = [];
    static statesAndDistrictsMap = {};
    static states = [];

    static sortStates() {
        Utils.states.sort(function (a, b) {
            let s1 = a.name.toUpperCase()
            let s2 = b.name.toUpperCase()
            if (s1 === s2) {
                return 0;
            }
            else if (s1 > s2) {
                return 1;
            }
            else {
                return -1;
            }

        });
    }


    static sortDistricts(stateName) {
        Utils.statesAndDistrictsMap[stateName].districts.sort(function (a, b) {
            let d1 = a.title.toUpperCase()
            let d2 = b.title.toUpperCase()
            if (d1 === d2 ){
                return 0;
            }
            else if (d1 > d2) {
                return 1;
            }
            else {
                return -1;
            }

        });
    }





    static loadDistrictsAndStates(successCB, errorCB) {

        Utils.statesAndDistricts = [];

        HttpHandler.sendGet('/api/v1/location/state/list', (data) => {

            Utils.states = data.states;
            Utils.sortStates();

            for (var i = 0; i < data.states.length; i++) {
                Utils.statesAndDistrictsMap[data.states[i].name] = { id: data.states[i].id, districtCount: 0, districts: [] };
            }


            HttpHandler.sendGet('/api/v1/location/district/all', (data) => {

                var states = data.states;

                for (var state in states) {
                    var districts = states[state];
                    for (var i = 0; i < districts.length; i++) {
                        var d = districts[i];
                        var newDist = { id: d.id, title: d.name, stateName: state, stateId: Utils.statesAndDistrictsMap[state].id };
                        Utils.statesAndDistricts.push(newDist)
                        Utils.statesAndDistrictsMap[state].districtCount++;
                        Utils.statesAndDistrictsMap[state].districts.push(newDist);
                    }
                }

                //Sort districts in the map.
                for (var i = 0; i < Utils.states.length; i++) {
                    Utils.sortDistricts(Utils.states[i].name);
                }

                successCB();


            }, (errorCode, errorMessage) => {
                errorCB();
            })


        }, (errorCode, errorMessage) => {
            errorCB();
        })


    }


    static isNumber(v) {
        var re = new RegExp('^[0-9]*$');
        return v.match(re);
    }

    static isValidPhone(v) {
        v = v.trim();
        if (v.length < 8 || v.length > 11) {
            return false
        }
        return Utils.isNumber(v);
    }

    static isValidMobilePhone(v) {
        v = v.trim();
        if (v.length != 10 ) {
            return false
        }
        return Utils.isNumber(v);
    }

    static isValidEmail(v) {
        var re = new RegExp(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/);
        return v.match(re);
    }

    static isValidName(v) {
        var re = new RegExp(/^[a-zA-Z ]+$/);
        return v.match(re);
    }
     
    static isValidDotAccept(v){
        var re = new RegExp(/^[a-zA-Z\. ]+$/);
        return v.match(re);
    }

    static organizationValid(v){
        var re = new RegExp(/^[a-zA-Z-\._() ]+$/);
        return v.match(re);
    }

    static contactValid(v){
        var re = new RegExp(/^[a-zA-Z\._ ]+$/);
        return v.match(re);
    }

    static checkAndUpdateURL() {
        var url = window.location.href;
        var protocol = 'http://';
        if (url.startsWith("https://")) {
            protocol = 'https://';
        }
        var index = url.indexOf(':');
        index = index + 3;
        url = url.substring(index);
        index = url.indexOf('/');
        url = url.substring(0, index);
        HttpHandler.BASE_URL = protocol + url + ""
    }

    static generateIdFromName(name) {
        let len = name.length;
        var returnValue = '';
        for (var i = 0; i < len; i++) {
            let code = name.charCodeAt(i);
            if (!(code > 47 && code < 58) && // numeric (0-9)
                !(code > 64 && code < 91) && // upper alpha (A-Z)
                !(code > 96 && code < 123)) { // lower alpha (a-z)
                returnValue = returnValue + '_';
            }
            else {
                returnValue = returnValue + name.charAt(i);
            }
        }

        return returnValue;
    }

}
