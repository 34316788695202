import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';

const styles = theme => ({

    mainContainer: {
        borderRadius: 5,
        display: 'flex',
        flexDirection: 'row',
        overflow: 'hidden',
        backgroundColor : '#EEE',
        alignItems : 'center',
        boxShadow : '2px 2px 5px #DDD'
    }
});

class FullPageErrorMessage extends Component {

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.mainContainer}>
                <div style={{ padding: 20, backgroundColor: '#B00020' }}>
                    <ErrorOutlineRoundedIcon fontSize="large" style={{ color: '#FFF' }} />

                </div>
                <div style={{ padding: 20, fontFamily : 'Metropolis-Regular', fontSize:16 }}>
                    {this.props.message}
                </div>
            </div>
        )
    }
}

FullPageErrorMessage.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FullPageErrorMessage);