import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles'

import SearchableSelect from './SearchableSelect.js';

const styles = theme => ({
});

const defs = {
    name : {
        title : 'Name',
        align : 'left'
    }
    
}

class CampTeamTypeSearchSelect extends Component {

    selectedType = null;

    _campTeamTypeSelected(v) {
        this.props.typeSelected(v);
        this.selectedType = v;
    }

    render() {
        return (
            <SearchableSelect
                style={this.props.style}
                error={this.props.error}
                helperText={this.props.helperText}
                defs = {defs}
                value={this.props.value}
                url='/api/v1/camp/team/type/all?'
                displayField='name'
                onSelect={this._campTeamTypeSelected.bind(this)}
                label={this.props.label}
                isRequired = {true}
            ></SearchableSelect>
        );
    }
}

CampTeamTypeSearchSelect.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CampTeamTypeSearchSelect)