import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles'

import { Fab, Paper, TextField, Divider, Button, DialogActions, Dialog } from '@material-ui/core';

import Autocomplete from '@material-ui/lab/Autocomplete';
import AddIcon from '@material-ui/icons/AddRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';

import { NotificationManager } from 'react-notifications';

import commonClasses from './ics.module.css'

import PagePath from './components/PagePath';
import HttpHandler from './HttpHandler'
import ErrorDialog from './components/ErrorDialog';
import ConfirmationDialog from './components/ConfirmationDialog';
import Utils from './Utils';

import CTable from './components/CTable'

const useStyles = makeStyles({
});


function CampTeamTypeNewEdit(props) {

    const [state, setState] = useState({
        typeName: '',
        typeNameError: false,
        typeNameErrorMessage: '',

    });


    const _saveClicked = () => {
        var valid = true;
        var myState = { ...state };

        var name = state.typeName.trim();
        if (name.length === 0) {
            valid = false;
            myState.typeNameError = true;
            myState.typeNameErrorMessage = "Name cannot be empty";
        }

        if (name.length > 100) {
            valid = false;
            myState.typeNameError = true;
            myState.typeNameErrorMessage = "Name should not exceed 100 characters";
        }


        if (!valid) {
            setState(myState);
            return;
        }

        var req = {
            name: name,
            id: typeof (props.typeId !== 'undefined' && props.typeId !== null) ? props.typeId : -1
        }

        //Check for duplicate
        var url = '/api/v1/camp/team/type/duplicate';
        HttpHandler.sendPost(url, req, (data) => {
            
            if (data.duplicate) {
                setState({ ...state, typeNameError: true, typeNameErrorMessage: 'Already used' });
                return;
            }
            req = {
                name: name,
            }

            url = '/api/v1/camp/team/type/new';
            if (props.typeId > 0) {
                url = '/api/v1/camp/team/type/' + props.typeId;
            }
            HttpHandler.sendPost(url, req, (data) => {
                if (props.labId > 0) {
                    NotificationManager.success("Team member type record updated.", name);
                }
                else {
                    NotificationManager.success("Team member type record created.", name);
                }
                props.onDone();
            }, (errorCode, errorMessage) => {
                console.log(errorMessage);
                ErrorDialog.show("There was error in saving team member type");
            })
        }, (errorCode, errorMessage) => {
            console.log(errorMessage);
            ErrorDialog.show("There was error in saving team member type");
        })


    }

    const _closeClicked = () => {
        props.onClose();
    }


    useEffect(() => {
        if (props.typeId > 0) {
            setState({
                ...state,
                typeName: props.typeName,
                typeNameError: false,
                typeNameErrorMessage: '',
            })
        }
        else {
            setState({
                ...state,
                typeName: '',
                typeNameError: false,
                typeNameErrorMessage: '',
            })
        }
    }, [props.typeId, props.typeName]);

    return (
        <div>
            <Paper style={{ padding: 10, width: 500 }}>
                <div className={commonClasses.headerBar}>Register screening member type</div>
                <div style={{ paddingBottom: 20 }}>
                    <form noValidate autoComplete="off">
                        <TextField
                            required
                            error={state.typeNameError}
                            id='typeName'
                            label="Name"
                            fullWidth
                            value={state.typeName}
                            margin="normal"
                            helperText={state.typeNameErrorMessage}

                            onChange={(v) => {
                                setState({
                                    ...state,
                                    typeName: v.target.value,
                                    typeNameError: false,
                                    typeNameErrorMessage: ''
                                })
                            }}
                        />
                    </form>
                </div>
                <Divider style={{ marginLeft: -10, marginRight: -10 }}></Divider>
                <div style={{ paddingTop: 10, flexDirection: 'row', display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        onClick={_saveClicked}
                        variant="contained" color="primary">Save</Button>
                    <Button
                        onClick={_closeClicked}
                        variant="contained" color="default" style={{ marginLeft: 20 }}>Close</Button>
                </div>
            </Paper>
        </div>
    )
}



export default function CampTeamType(props) {

    const dataTable = useRef(null);

    const _createType = () => {
        setState({
            ...state,
            editCreateDialog: true
        });
    }

    const [state, setState] = useState({
        editCreateDialog: false,
        selectedTypeId: -1,
    });

    const _typeCreated = (row) => {
        setState({
            ...state,
            editCreateDialog: false,
            selectedTypeId: -1
        });
        dataTable.current.reload();
    }

    const _editClicked = (row) => {
        setState({ ...state, editCreateDialog: true, selectedTypeId: row.id, selectedTypeName: row.name })
    }

    const _deleteClicked = (row) => {
        ConfirmationDialog.show('Are you sure you want to delete this team member type?', () => {
            HttpHandler.sendDelete('/api/v1/camp/team/type/' + row.id, (d) => {
                NotificationManager.success("Screen team member type deleted.", row.name);
                dataTable.current.reload();
            }, (errorCode, errorMessage) => {
                ErrorDialog.show("There was error in deleting Screen team member type");
            })
        });
    }


    const typeTableDef = [
        {
            title: "Name",
            field: 'name',
            align: 'left',
            renderer: (row, data) => {
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center' }} >
                        <div
                            style={{
                                fontFamily: 'Metropolis-Regular', color: '#000',
                            }}>{row.name}</div>
                    </div>
                )
            }

        },
        {
            title: "",
            field: 'id',
            style: { minWidth: 120, width: 120 },
            renderer: (row, data) => {
                return (
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <div
                            onClick={() => _editClicked(row)}
                            style={{ height: 24, cursor: 'pointer' }}>
                            <EditRoundedIcon style={{ color: '#03A9F4' }}></EditRoundedIcon>
                        </div>
                        {(HttpHandler.USER_TYPE === 'admin') &&
                            <div
                                onClick={() => _deleteClicked(row)}
                                style={{ marginLeft: 20, height: 24, cursor: 'pointer' }}>
                                <DeleteForeverRoundedIcon style={{ color: '#D32F2F' }}></DeleteForeverRoundedIcon>
                            </div>
                        }

                    </div>
                )
            }

        }
    ];


    const _getTypeData = (cb, offset, limit, searchString) => {
        HttpHandler.sendGet('/api/v1/camp/team/type/all?offset=' + offset + '&limit=' + limit + '&s=' + searchString, (data) => {
            cb(data.result, data.count)
        }, (errorCode, errorMessage) => {
            console.log(errorMessage);
        })
    }


    return (
        <div>
            {(HttpHandler.USER_TYPE !== 'admin') &&
                <div style={{ paddingTop: 40, color: '#FF0000', fontFamily: 'Metropolis-Regular', fontSize: 15, textAlign: 'center' }}>You are not authorized to access contents of this page.</div>
            }
            {(HttpHandler.USER_TYPE === 'admin') &&
                <div>
                    <div style={{ marginRight: 20 }}>
                        <PagePath path={['Screening team', 'Team member types']}>
                            <Fab
                                onClick={_createType}
                                color="primary" aria-label="add" size="medium" title="New type">
                                <AddIcon />
                            </Fab>
                        </PagePath>
                    </div>

                    <div style={{ marginTop: 20, paddingBottom: 30, paddingRight: 10 }}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            {state.editCreateDialog &&
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <CampTeamTypeNewEdit typeId={state.selectedTypeId}
                                        typeName={state.selectedTypeName}
                                        onDone={_typeCreated}
                                        onClose={() => { setState({ ...state, selectedTypeId: -1, editCreateDialog: false }) }} />
                                </div>
                            }
                            {(state.editCreateDialog === false) &&
                                <Paper style={{ padding: 10 }}>
                                    <div style={{ width: 600 }}>
                                        <CTable
                                            ref={dataTable}
                                            data={_getTypeData}
                                            columns={typeTableDef}>
                                        </CTable>
                                    </div>
                                </Paper>
                            }
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}