import React, { useEffect, useState, useRef } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';

import { Fab, Paper, TextField, InputLabel, Select, MenuItem, Divider, Button, FormControl, FormHelperText } from '@material-ui/core';
import { DatePicker } from "@material-ui/pickers";
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddIcon from '@material-ui/icons/AddRounded';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import CloudDownloadRoundedIcon from '@material-ui/icons/CloudDownloadRounded';

import { Link, useHistory } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';

import moment from 'moment';
import Moment from 'react-moment';

import commonClasses from './ics.module.css'
import PagePath from './components/PagePath';
import UserSearchSelect from './components/UserSearchSelect';
import DonorSearchSelect from './components/DonorSearchSelect';
import OrgSearchSelect from './components/OrgSearchSelect';

import Utils from './Utils'
import HttpHandler from './HttpHandler';
import ErrorDialog from './components/ErrorDialog';
import CTable from './components/CTable'
import ConfirmationDialog from './components/ConfirmationDialog';
import { orange } from '@material-ui/core/colors';

const campStatusMapping = {
    'SC': 'Scheduled',
    'RU': 'Running',
    'CA': 'Cancelled',
    'CO': 'Completed'
}


const useStyles = makeStyles({
});


function CampCreateEditUI(props) {

    const classes = useStyles();

    const [state, setState] = useState({
        campName: '',
        campNameError: false,
        campNameErrorMessage: '',
        address: '',
        addressError: false,
        addressErrorMessage: '',
        campDate: moment(),
        district: -1,
        districtName: '',
        districtError: false,
        districtErrorMessage: '',
        campState: ' - Select a district - ',
        campStateError: false,
        campStateErrorMessage: '',
        campPIN: '',
        campPINError: false,
        campPINErrorMessage: '',
        organizer: null,
        organizerError: false,
        organizerErrorMessage: '',
        donor: null,
        donorError: false,
        donorErrorMessage: '',
        regTemplate: '',
        regTemplateError: false,
        regTemplateErrorMessage: '',
        medicalTemplate: '',
        medicalTemplateError: false,
        medicalTemplateErrorMessage: '',
        diablebutton: false,
        paidScreening: 'Yes'
    });

    const _loadCampDetails = () => {
        HttpHandler.sendGet('/api/v1/camp/' + props.campId, (d) => {
         
            var donor = d.camp.donor;
            var istate = {
                ...state,
                campName: d.camp.name,
                address: d.camp.address.address,
                campDate: moment(new Date(d.camp.campDate)),
                campPIN: d.camp.address.pin,
                regTemplate: d.camp.regTemplate.id,
                medicalTemplate: d.camp.medicalTemplate.id,
                paidScreening: d.camp.paidScreening !== null ? d.camp.paidScreening : 'Yes',
                
            };

            


            istate = _setDistrictData(istate, d.camp.address.district.id)
            setState(istate);
            





            if (d.camp.organizer.id == -1) {
                istate = istate;
                istate = { ...istate, organizer: -1 };
                

                HttpHandler.sendGet('/api/v1/donor/' + donor.id, (d) => {
                    istate = istate;
                    setState({ ...istate, donor: d.donor });


                }, (errorCode, errorMessage) => {
                    console.log(errorMessage);
                    NotificationManager.error("Error loading details for edit.", "Camp")
                    props.onClose();
                })

            }



            else {
                HttpHandler.sendGet('/api/v1/org/' + d.camp.organizer.id, (d) => {
                    istate = istate;
                   
                    istate = { ...istate, organizer: d.org };

                    HttpHandler.sendGet('/api/v1/donor/' + donor.id, (d) => {
                        istate = istate;
                        setState({ ...istate, donor: d.donor });


                    }, (errorCode, errorMessage) => {
                        console.log(errorMessage);
                        NotificationManager.error("Error loading details for edit.", "Camp")
                        props.onClose();
                    })

                }, (errorCode, errorMessage) => {
                    console.log(errorMessage);
                    NotificationManager.error("Error loading details for edit.", "Camp")
                    props.onClose();
                })

            }
            








        }, (errorCode, errorMessage) => {
            console.log(errorMessage);
            NotificationManager.error("Error loading details for edit.", "Camp")
            props.onClose();
        })
    }

    const _setDistrictData = (istate, id) => {
        if (id === null) {
            istate = {
                ...istate,
                district: '',
                districtName: '',
                districtError: false,
                districtErrorMessage: '',
                campState: ' - Select a district - '
            };
            return istate;
        }
        var len = Utils.statesAndDistricts.length;
        for (var i = 0; i < len; i++) {
            if (Utils.statesAndDistricts[i].id === id) {
                istate = {
                    ...istate,
                    district: id,
                    districtName: Utils.statesAndDistricts[i].title,
                    districtError: false,
                    districtErrorMessage: '',
                    campState: Utils.statesAndDistricts[i].stateName
                };
                return istate;
            }
        }

    }

    useEffect(() => {
        if (props.campId > 0) {
            _loadCampDetails();
            
        }
        else {
            setState({
                ...state,
                campName: '',
                campNameError: false,
                campNameErrorMessage: '',
                address: '',
                addressError: false,
                addressErrorMessage: '',
                campDate: moment(),
                district: -1,
                districtError: false,
                districtErrorMessage: '',
                campState: ' - Select a district - ',
                campStateError: false,
                campStateErrorMessage: '',
                campPIN: '',
                campPINError: false,
                campPINErrorMessage: '',
                organizer: null,
                organizerError: false,
                organizerErrorMessage: '',
                regTemplate: '',
                regTemplateError: false,
                regTemplateErrorMessage: '',
                medicalTemplate: '',
                medicalTemplateError: false,
                medicalTemplateErrorMessage: '',
                donor: null,
                donorError: false,
                donorErrorMessage: '',
                paidScreening: 'Yes'
            });
        }
    }, [props.campId]);



    const _handleCampDateChange = (v) => {
        setState({
            ...state,
            campDate: v,
            campDateError: false,
            campDateErrorMessage: ''
        });
    };

    // const _userSelected = (v) => {
    //     if (v !== null) {
    //         setState({
    //             ...state,
    //             organizer: v,
    //             organizerError: false,
    //             organizerErrorMessage: ''
    //         })
    //     }
    //     else {
    //         setState({
    //             ...state,
    //             organizer: v,
    //             organizerError: true,
    //             organizerErrorMessage: "Organizer is mandatory"
    //         })
    //     }
    // }






    const _userSelected = (v) => {
        if (v !== null) {
            setState({
                ...state,
                organizer: v,
                organizerError: false,
                organizerErrorMessage: ''
            })
        }
        else {
            setState({
                ...state,
                organizer: v,
                organizerError: false,
                organizerErrorMessage: ''
            })
        }
    }


    const _districtChanged = (v, newValue) => {
        setState({
            ...state,
            district: newValue === null ? -1 : newValue.id,
            districtError: false,
            districtErrorMessage: '',
            organizerError: false,
            organizerErrorMessage: '',
            campState: newValue == null ? ' - Select a district - ' : newValue.stateName
        })
    }

    const _onInputChanged = (v, newValue) => {
        setState({ ...state, districtName: newValue })
    }

    const _onPinChanged = (v) => {
        var val = v.target.value;
        if (val.length > 6) {
            val = state.campPIN
        }
        else if (val.indexOf('.') >= 0) {
            val = state.campPIN;
        }
        else if (isNaN(val)) {
            val = state.campPIN;
        }
        setState({
            ...state,
            campPINErrorMessage: '',
            campPINError: false,
            campPIN: val
        });
    }
    const _disableClick = () => {
        setState({
            ...state,
            diablebutton: !state.diablebutton

        });
    }

    const _saveClicked = () => {


        //Validate.
        _disableClick()
        var valid = true;
        var myState = { ...state };

        var name = state.campName.trim();
        if (name.length === 0) {
            valid = false;
            myState.campNameError = true;
            myState.campNameErrorMessage = "Name cannot be empty";
        }

        var address = state.address.trim();
        if (address.length === 0) {
            valid = false;
            myState.addressError = true;
            myState.addressErrorMessage = "Address cannot be empty";
        }
        else if (address.length < 3 || address.length > 500) {
            valid = false;
            myState.addressError = true;
            myState.addressErrorMessage = "Length should be in range 3-500";
        }

        if (state.district === -1) {
            valid = false;
            myState.districtError = true;
            myState.districtErrorMessage = "Dsitrict cannot be empty";
        }

        if (state.campPIN === '') {
            valid = false;
            myState.campPINError = true;
            myState.campPINErrorMessage = "Mandatory"
        }
        else if ((state.campPIN + "").length != 6) {
            valid = false;
            myState.campPINError = true;
            myState.campPINErrorMessage = "Invalid PIN"
        }

       







        // if (state.organizer === null) {
        //     valid = false;
        //     myState.organizerError = true;
        //     myState.organizerErrorMessage = "Organizer is mandatory"
            
        // }





        // if (state.organizer === -1) {
        //     valid = true;
        //     myState.organizerError = false;
        //     myState.organizerErrorMessage = ''
        //     console.log("hello")
        // }


        if (state.donor === null) {
            valid = false;
            myState.donorError = true;
            myState.donorErrorMessage = "Donor is mandatory"
        }

        var regTemplate = state.regTemplate;
        if (regTemplate === null || regTemplate === '') {
            myState.regTemplateError = true;
            myState.regTemplateErrorMessage = "Registration template cannot be empty"
            valid = false;
        }

        var medicalTemplate = state.medicalTemplate;
        if (medicalTemplate === null || medicalTemplate === '') {
            myState.medicalTemplateError = true;
            myState.medicalTemplateErrorMessage = "Medical template cannot be empty"
            valid = false;
        }

        if (!valid) {
            setState(myState);
            return;
        }

        var req = {
            name: name,
            paidScreening: state.paidScreening,
            regTemplate: state.regTemplate,
            medicalTemplate: state.medicalTemplate,
            address: state.address,
            district: state.district,
            pin: parseInt(state.campPIN),
            //organizer: state.organizer.id,
            organizer: (state.organizer === null || state.organizer === -1) ? -1 : state.organizer.id,
            donor: state.donor.id,
            address: state.address.trim(),
            campDate: state.campDate.toDate().getTime()
        }

        var url = '/api/v1/camp/new';
        if (props.campId > 0) {
            url = '/api/v1/camp/' + props.campId;
        }
        
        HttpHandler.sendPost(url, req, (data) => {
            
            props.onSave(data.id, name);
        }, (errorCode, errorMessage) => {
            console.log(errorMessage);
            ErrorDialog.show('There was an error in saving the camp details.')
        })
        _disableClick()

    }

    const _closeDialog = () => {
        props.onClose();
    }

    const _handleRegTemplateChange = (e) => {
        setState({
            ...state,
            regTemplate: e.target.value,
            regTemplateError: false,
            regTemplateErrorMessage: ''
        })
    }

    const _handleMedicalTemplateChange = (e) => {
        setState({
            ...state,
            medicalTemplate: e.target.value,
            medicalTemplateError: false,
            medicalTemplateErrorMessage: ''
        })
    }

    const _donorSelected = (v) => {
        if (v !== null) {
            setState({
                ...state,
                donor: v,
                donorError: false,
                donorErrorMessage: ''
            })
        }
        else {
            setState({
                ...state,
                donor: v,
                donorError: true,
                donorErrorMessage: "Donor is mandatory"
            })
        }
    }

    return (
        <Paper style={{ padding: 10, width: 500 }}>
            <div className={commonClasses.headerBar}>{props.heading}</div>
            <div style={{ paddingBottom: 20 }}>
                <form noValidate autoComplete="off">
                    <TextField
                        required
                        error={state.campNameError}
                        id='campName'
                        label="Camp Name"
                        fullWidth
                        value={state.campName}
                        margin="normal"
                        helperText={state.campNameErrorMessage}
                        onChange={(v) => {
                            setState({
                                ...state,
                                campName: v.target.value,
                                campNameError: false,
                                campNameErrorMessage: ''
                            })
                        }}
                    />

                    <FormControl style={{ flex: 1, marginTop: 16, minWidth: '100%' }}>
                        <InputLabel id="paidScreening-label">Paid Screening *</InputLabel>
                        <Select
                            labelId="paidScreening-label"
                            id="paidScreening-select"
                            value={state.paidScreening}
                            onChange={(v) => {
                                setState({
                                    ...state,
                                    paidScreening: v.target.value
                                })
                            }}
                        >
                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                        </Select>
                    </FormControl>

                    <TextField
                        required
                        error={state.addressError}
                        id='address'
                        label="Location"
                        fullWidth
                        multiline={true}
                        rowsMax={4}
                        rows={4}
                        value={state.address}
                        margin="normal"
                        helperText={state.addressErrorMessage}
                        onChange={(v) => {
                            setState({
                                ...state,
                                address: v.target.value,
                                addressError: false,
                                addressErrorMessage: ''
                            })
                        }}
                    />
                    <Autocomplete
                        onChange={_districtChanged}
                        id="campDistrict"
                        onInputChange={_onInputChanged}
                        inputValue={state.districtName}
                        getOptionLabel={(v) => v.title}
                        options={Utils.statesAndDistricts.map((option) => option)}
                        renderInput={(params) => (
                            <TextField {...params} required label="District" margin="normal"
                                helperText={state.districtErrorMessage}
                                error={state.districtError} />
                        )}
                    />

                    <div style={{ width: '100%', flexDirection: 'row', display: 'flex' }}>
                        <TextField
                            required
                            error={state.campStateError}
                            id='campState'
                            label="State"
                            style={{ flex: 1 }}
                            value={state.campState}
                            margin="normal"
                            InputProps={{
                                readOnly: true,
                            }}
                            helperText={state.campStateErrorMessage}
                            onChange={(v) => {
                                setState({
                                    ...state,
                                    campState: v.target.value,
                                    campStateError: false,
                                    campStateErrorMessage: ''
                                })
                            }}
                        />
                        <TextField
                            required
                            error={state.campPINError}
                            id='campPIN'
                            label="PIN"
                            style={{ width: 100, marginLeft: 20 }}
                            value={state.campPIN}
                            margin="normal"
                            helperText={state.campPINErrorMessage}
                            onChange={_onPinChanged}
                        />
                    </div>


                    <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'flex-end' }}>
                        <div style={{ flex: 1, paddingRight: 10 }}>
                             
                            <OrgSearchSelect
                                style={{ flex: 1 }}
                                label="Organizer"
                                value={state.organizer == -1 ? null : state.organizer}
                                //value={state.organizer}
                                error={state.organizerError}
                                helperText={state.organizerErrorMessage}
                                orgSelected={_userSelected}
                                isRequired={false}


                            ></OrgSearchSelect>
                        </div>
                        <div style={{ flex: 1, paddingLeft: 10 }}>
                            <DonorSearchSelect
                                style={{ flex: 1 }}
                                label="Donor"
                                value={state.donor}
                                error={state.donorError}
                                helperText={state.donorErrorMessage}
                                donorSelected={_donorSelected}

                            ></DonorSearchSelect>
                        </div>

                    </div>
                    <DatePicker
                        style={{ marginBottom: 8, }}
                        helperText={state.campDateErrorMessage}
                        error={state.campDateError}
                        label="Camp date"
                        format="DD/MMM/yyyy"
                        minDateMessage={"Date should be in future"}
                        value={state.campDate}
                        onChange={_handleCampDateChange}
                        animateYearScrolling
                    />
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <FormControl style={{ minWidth: 200, flex: 1, 
                            cursor : (props.campStatus == 'RU')? 'not-allowed': 'pointer' } } error={state.regTemplateError}>
                            
                            <InputLabel id="regTemplateSelection-label" >Reg. Template *</InputLabel>
                            <Select
                                labelId="regTemplateSelection-label"
                                readOnly = {(props.campStatus == 'RU') ? true : false} 
                                style = {{backgroundColor : (props.campStatus == 'RU')? '#E5E4E2' : '',
                                pointerEvents:(props.campStatus == 'RU')? 'none': ''}}   
                                
                                id="regTemplateSelection"
                                value={state.regTemplate}
                                onChange={_handleRegTemplateChange}
                            >
                                {props.regTemplates.map((row, key) => {
                                    return (
                                        <MenuItem value={row.id} key={key}>{row.name}</MenuItem>
                                    )
                                })}
                            </Select>
                            <FormHelperText>{state.regTemplateErrorMessage} </FormHelperText>
                        </FormControl>
                        <div style={{ width: 20 }}>

                        </div>
                        <FormControl style={{ minWidth: 200, flex: 1,
                        cursor : (props.campStatus == 'RU')? 'not-allowed': 'pointer' }} error={state.medicalTemplateError}>
                            <InputLabel id="rmedicalTemplateSelection-label">Medical Template *</InputLabel>
                            <Select
                                labelId="medicalTemplateSelection-label"
                                readOnly = {(props.campStatus == 'RU') ? true : false}
                                style = {{backgroundColor : (props.campStatus == 'RU')? '#E5E4E2' : '',
                                pointerEvents:(props.campStatus == 'RU')? 'none': ''}}  
                                
                                id="medicalTemplateSelection"
                                value={state.medicalTemplate}
                                onChange={_handleMedicalTemplateChange}
                            >
                                {props.medicalTemplates.map((row, key) => {
                                    return (
                                        <MenuItem value={row.id} key={key}>{row.name}</MenuItem>
                                    )
                                })}
                            </Select>
                            <FormHelperText>{state.medicalTemplateErrorMessage} </FormHelperText>
                        </FormControl>
                    </div>
                    <div style={{ marginTop: 10 }}>

                    </div>
                </form>
            </div>
            <Divider style={{ marginLeft: -10, marginRight: -10 }}></Divider>
            <div style={{ paddingTop: 10, flexDirection: 'row', display: 'flex', justifyContent: 'flex-end' }}>
                <Button disabled={state.diablebutton} onClick={_saveClicked} variant="contained" color="primary">Save</Button>
                <Button
                    onClick={_closeDialog}
                    variant="contained" color="default" style={{ marginLeft: 20 }}>Close</Button>
            </div>

        </Paper>
    )
}



export default function Camps(props) {


    const classes = useStyles();
    const dataTable = useRef(null);
    const history = useHistory();

    const [state, setState] = useState({
        showCreateCampDialog: false,
        regTemplates: [],
        medicalTemplates: [],
        campStatus : '' ,   
        currentCampId: -1,
        addEditUIHeading: "Create Camp"
    });


    const campTableDef = [
        {
            title: "Name",
            field: 'name',
            align: 'left',
            renderer: (row, data) => {
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center' }} >

                        <Link to={"/ics/camp/" + row.id} >{data}</Link>
                    </div>
                )
            }

        },
        {
            title: "Camp Date",
            field: 'campDate',
            align: 'left',
            renderer: (row, data) => {
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center' }} >
                        <Moment format="DD-MM-YYYY">{new Date(data)}</Moment>
                    </div>
                )
            }
        },
        {
            title: "Status",
            field: "status",
            align: 'left',
            renderer: (row, data) => {
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center' }} >
                        {campStatusMapping[row.status]}
                    </div>
                )
            }
        },
        {
            title: "Location",
            field: "location",
            align: 'left',
            renderer: (row, data) => {
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center' }} >
                        {row.address.district.name + ', ' + row.address.state.name}
                    </div>
                )
            }
        },
        {
            title: "",
            field: 'id',
            style: { minWidth: 120, width: 120 },
            renderer: (row, data) => {
                return (
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        {(HttpHandler.USER_TYPE === 'coordinator') &&
                            <div
                                onClick={() => _editClicked(row)}
                                style={{ height: 24, cursor: (row.status === 'RU' || row.status === 'SC' || row.status == 'CA') ? 'pointer' : 'not-allowed' }}>
                                <EditRoundedIcon style={{ color: (row.status === 'SC' || row.status == 'CA' || row.status === 'RU') ? '#03A9F4' : '#CCC' }}></EditRoundedIcon>
                            </div>
                        }
                        {(HttpHandler.USER_TYPE === 'coordinator') &&
                            <div
                                onClick={() => _deleteClicked(row)}
                                style={{ marginLeft: 20, height: 24, cursor: (row.status === 'SC' || row.status == 'CA') ? 'pointer' : 'not-allowed' }}>
                                <DeleteForeverRoundedIcon
                                    style={{ color: (row.status === 'SC' || row.status == 'CA') ? '#D32F2F' : '#CCC' }}></DeleteForeverRoundedIcon>
                            </div>
                        }

                    </div>
                )
            }

        }
    ]

    const _editClicked = (row) => {
        if (row.status === 'SC' || row.status == 'CA' || row.status === 'RU') {
            //setState({ ...state, currentCampId: row.id, showCreateCampDialog: true, addEditUIHeading: "Modify Camp" })
            setState({ ...state, currentCampId: row.id, showCreateCampDialog: true, addEditUIHeading: "Modify Camp" , campStatus : row.status })  
        }
    }

    function triggerDownload(url, fileName) {
        let a = document.createElement('a');
        a.setAttribute('href', url);
        a.setAttribute('download', fileName);
        a.click();
    }
     
    function _downloadExcel(){
        HttpHandler.sendExcelGet('/api/v1/camp/download/excel', (d) => {

            // FileSaver.saveAs(new Blob([d], {type: headers['content-type']}), filename);
            
            let blob = new Blob([d], {type: 'application/octet-stream'});
            let fileUrl = window.URL.createObjectURL(blob);
            triggerDownload(fileUrl, "CampData.xlsx");

        }, (errorCode, errorMessage) => {
           console.error("Could not Download the Excel report from the backend.", errorCode,errorMessage);
          
        });
    }

    function _downloadExcelCampAll(){
        HttpHandler.sendExcelGet('/api/v1/camp/downloadall/excel', (d) => {

            // FileSaver.saveAs(new Blob([d], {type: headers['content-type']}), filename);
            
            let blob = new Blob([d], {type: 'application/octet-stream'});
            let fileUrl = window.URL.createObjectURL(blob);
            triggerDownload(fileUrl, "CallData.xlsx");

        }, (errorCode, errorMessage) => {
           console.error("Could not Download the Excel report from the backend.", errorCode,errorMessage);
          
        });
    }
    function _downloadExcelRegCampAll(){
        HttpHandler.sendExcelGet('/api/v1/camp/downloadallreg/excel', (d) => {

            // FileSaver.saveAs(new Blob([d], {type: headers['content-type']}), filename);
            
            let blob = new Blob([d], {type: 'application/octet-stream'});
            let fileUrl = window.URL.createObjectURL(blob);
            triggerDownload(fileUrl, "RegisterData.xlsx");

        }, (errorCode, errorMessage) => {
           console.error("Could not Download the Excel report from the backend.", errorCode,errorMessage);
          
        });
    }
    function _downloadExcelMedCampAll(){
        HttpHandler.sendExcelGet('/api/v1/camp/downloadallmed/excel', (d) => {

            // FileSaver.saveAs(new Blob([d], {type: headers['content-type']}), filename);
            
            let blob = new Blob([d], {type: 'application/octet-stream'});
            let fileUrl = window.URL.createObjectURL(blob);
            triggerDownload(fileUrl, "ScreeningData.xlsx");

        }, (errorCode, errorMessage) => {
           console.error("Could not Download the Excel report from the backend.", errorCode,errorMessage);
          
        });
    }

    const _deleteClicked = (row) => {
        if (row.status === 'SC' || row.status == 'CA') {
            ConfirmationDialog.show('Are you sure you want to delete this camp?', () => {
                HttpHandler.sendDelete('/api/v1/camp/' + row.id, (d) => {
                    NotificationManager.success("Camp deleted.", row.name);
                    dataTable.current.reload();
                }, (errorCode, errorMessage) => {
                    ErrorDialog.show("There was error in deleting camp");
                })
            });
        }
    }

    const _createCamp = () => {
        setState({
            ...state,
            showCreateCampDialog: true,
            addEditUIHeading: "Create Camp"
        })
    }

    const _closeCreateDialog = () => {
        setState({
            ...state,
            showCreateCampDialog: false
        })
    }

    useEffect(() => {
        _loadRegTemplatesList();
    }, [props.campId]);

    const _getCampData = (cb, offset, limit, searchString) => {
        HttpHandler.sendGet('/api/v1/camp/list?offset=' + offset + '&limit=' + limit + '&s=' + searchString, (data) => {
            cb(data.camps, data.count)
        }, (errorCode, errorMessage) => {
            console.log(errorMessage);
        })
    }
    // const _getCampData1 = (cb, offset, limit, searchString) => {
    //     HttpHandler.sendGet('/api/v1/camp/list?offset=' + offset + '&limit=' + limit + '&s=' + searchString, (data) +'&d=' + searchString, (date)=> {
    //         cb(data.camps, data.count)
    //     }, (errorCode, errorMessage) => {
    //         console.log(errorMessage);
    //     })
    // }

    const _loadRegTemplatesList = () => {
        HttpHandler.sendGet('/api/v1/template/list?type=CA&offset=0&limit=100', (data) => {
            var istate = {
                ...state,
                regTemplates: data.templates
            };

            _loadMedicalTemplateList(istate);

        }, (errorCode, errorMessage) => {
            NotificationManager.error("Error loading list of trmplates", "Templates")
        })
    };

    const _loadMedicalTemplateList = (istate) => {
        HttpHandler.sendGet('/api/v1/template/list?type=CM&offset=0&limit=100', (data) => {
            setState({
                ...istate,
                medicalTemplates: data.templates
            });

        }, (errorCode, errorMessage) => {
            NotificationManager.error("Error loading list of trmplates", "Templates")
        })
    }

    const _saveCamp = (id, name) => {
        if (state.currentCampId > 0) {
            NotificationManager.success("Camp record updated.", name);
            _closeCreateDialog();
        }
        else {
            history.push('/ics/camp/' + id);
            NotificationManager.success("Camp record created.", name);
        }
    }


    return (
        <div>

            {(HttpHandler.USER_TYPE !== 'coordinator' && HttpHandler.USER_TYPE !== 'data entry' && HttpHandler.USER_TYPE !== 'screening' && HttpHandler.USER_TYPE !== 'doctor'&& HttpHandler.USER_TYPE !== 'admin') &&
                <div style={{ paddingTop: 40, color: '#FF0000', fontFamily: 'Metropolis-Regular', fontSize: 15, textAlign: 'center' }}>You are not authorized to access contents of this page.</div>
            }

            {(HttpHandler.USER_TYPE === 'coordinator' || HttpHandler.USER_TYPE === 'data entry' || HttpHandler.USER_TYPE === 'doctor' || HttpHandler.USER_TYPE === 'screening'|| HttpHandler.USER_TYPE === 'admin') &&
                <div style={{position:'relative'}}>
                    <div style={{ marginRight: 20 ,}}>
                        <PagePath path={['Screening Camp', 'All']}>

                            {(HttpHandler.USER_TYPE === 'coordinator' && state.showCreateCampDialog === false) &&
                                <Fab
                                    onClick={_createCamp}
                                    color="primary" aria-label="add" size="medium" title="New camp">
                                    <AddIcon />
                                </Fab>
                            }
                          {((HttpHandler.USER_TYPE === 'coordinator' || HttpHandler.USER_TYPE === 'admin')  && state.showCreateCampDialog === false) &&
                                <Fab
                                    color="secondary" aria-label="Download" size="medium" title='Download Camp Summary' style={{ marginLeft: 10 }} >
                                        
                                    <a onClick={()=>_downloadExcel()} download>
                                        <CloudDownloadRoundedIcon style={{ color: '#FFF', }} />
                                    </a>
                                </Fab>
                            }
                            {(HttpHandler.USER_TYPE === 'coordinator' && state.showCreateCampDialog === false) &&
                                <Fab
                                    color="primary" aria-label="Download" size="medium" title='Download Beneficiary Summary' style={{ marginLeft: 10 }} >
                                        
                                    <a onClick={()=>_downloadExcelCampAll()} download>
                                        <CloudDownloadRoundedIcon style={{ color: 'white' }} />
                                        
                                    </a>
                                </Fab>
                            } 
                            {(HttpHandler.USER_TYPE === 'coordinator' && state.showCreateCampDialog === false) &&
                                <Fab
                                    color="primary" aria-label="Download" size="medium" title='Download Lifestyle Health' style={{ marginLeft: 10 }} >
                                        
                                    <a onClick={()=>_downloadExcelRegCampAll()} download>
                                        <CloudDownloadRoundedIcon style={{ color: 'white' }} />
                                        
                                    </a>
                                </Fab>
                            }
                            {(HttpHandler.USER_TYPE === 'coordinator' && state.showCreateCampDialog === false) &&
                                <Fab
                                    color="primary" aria-label="Download" size="medium" title='Download Screening Details' style={{ marginLeft: 10 }} >
                                        
                                    <a onClick={()=>_downloadExcelMedCampAll()} download>
                                        <CloudDownloadRoundedIcon style={{ color: 'white' }} />
                                        
                                    </a>
                                </Fab>
                            } 
                            
                        </PagePath>
                    </div>
                    <div style={{ marginTop: 20, paddingBottom: 30 ,}}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            {state.showCreateCampDialog &&
                                <CampCreateEditUI
                                    campId={state.currentCampId}
                                    heading={state.addEditUIHeading}
                                    campStatus = {state.campStatus}   
                                    regTemplates={state.regTemplates}
                                    medicalTemplates={state.medicalTemplates}
                                    onSave={_saveCamp}
                                    onClose={_closeCreateDialog} />
                            }
                            {(state.showCreateCampDialog === false) &&
                                <Paper style={{ padding: 10 }}>
                                    <div style={{ width: 800 }}>
                                        <CTable
                                            ref={dataTable}
                                            data={_getCampData}
                                            columns={campTableDef}
                                            >
                                        </CTable>
                                    </div>
                                </Paper>
                            }
                        </div>
                    </div>
                </div>}
        </div>
    );
}
