import React, { Component } from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import { DialogActions, Button, TextField, InputLabel } from "@material-ui/core";

import { DatePicker } from "@material-ui/pickers";

import moment from "moment";

import commonClasses from "./../ics.module.css";
import { Divider } from "@material-ui/core";

const styles = (theme) => ({});

class DateSelection extends Component {
    super(props){
        this.props = props;
    }
  static currentRef = null;

  onDateSelect = null;
  onClose = null;
  state = {
    dialogOpen: false,
    title: "",
    currentDate: new Date(),
    max: false,
    min: false,
    notes: "",
    isActual: false
  };

  showDialog(title,notes, maxDate, minDate, currentDate, onDateSelect, onClose, isActual) {
    this.setState({
      dialogOpen: true,
      title: title,
      notes:notes,
      currentDate: moment(currentDate),
      max: maxDate,
      min: minDate,
      isActual: isActual
    });
    this.onDateSelect = onDateSelect;
    this.onClose = onClose;
  }

  static show(isActual, title,notes, maxDate, minDate, currentDate, onDateSelect, onClose) {
    DateSelection.currentRef.showDialog(
      title,
      notes,
      maxDate,
      minDate,
      currentDate,
      onDateSelect,
      onClose,
      isActual
    );
  }

  componentDidMount() {
    DateSelection.currentRef = this;
  }

  _closeClicked() {
    this.onClose();
    this.setState({
      dialogOpen: false,
    });
  }

  _onChange(v) {
    this.setState({
      currentDate: v,
    });
  }

  _okClicked() {
    this.onDateSelect(this.state.currentDate.toDate(), this.state.notes);
    this.setState({
      dialogOpen: false,
    });
  }

  render() {
    return (
      <Dialog onClose={this.onClose} open={this.state.dialogOpen}>
        <div style={{ padding: 5 }}>
          <div className={commonClasses.headerBar}>{this.state.title}</div>
        </div>
        <div style={{ padding: 5 }}>
          <DatePicker
            autoOk
            orientation="landscape"
            variant="static"
            openTo="date"
            value={this.state.currentDate}
            onChange={this._onChange.bind(this)}
            minDate={this.state.min ? this.state.min : undefined}
            maxDate={this.state.max ? new Date() : undefined}
          />
        </div>
        <Divider />
        {this.state.isActual ? <div style={{ padding: 5 }}>
          <InputLabel id="notes-label">Notes</InputLabel>
          <TextField
                style={{ width: 400 }}
                placeholder="Add notes"
                multiline
                rows={2}
                rowsMax={4}
                value = {this.state.notes}
                onChange= {(v)=>{this.setState({notes: v.target.value})}}
            />
        </div>: null}
        <Divider />
        <DialogActions>
          <div
            style={{
              paddingTop: 10,
              flexDirection: "row",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              onClick={this._okClicked.bind(this)}
              variant="contained"
              color="primary"
              style={{ marginLeft: 20 }}
            >
              Save
            </Button>
            <Button
              onClick={this._closeClicked.bind(this)}
              variant="contained"
              color="default"
              style={{ marginLeft: 20 }}
            >
              Close
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    );
  }
}

DateSelection.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DateSelection);
