import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import { Divider, Button, Paper, DialogTitle } from '@material-ui/core';
import HttpHandler from '../HttpHandler';
import CTable from './CTable';
import commonClasses from '../ics.module.css'

const styles = theme => ({

    searchInputComponent: {
        border: 0,
        lineHeight: '20px'
    }

});


class SearchFieldModal extends Component {

    static currentRef = null;
    currentOffset = 0;
    currentLimit = 0
    searchField = '';
    callback = null;
    searchURL = null;

    defs = {};
    tableColumns = [];

    state = {
        dialogOpen: false
    }

    static show(url, s, defs, cb) {
        SearchFieldModal.currentRef.showDialog(url, s, defs, cb);
    }

    static hide() {
        SearchFieldModal.currentRef._hide();
    }

    _hide() {
        this.setState({
            dialogOpen: false
        })
    }

    _searchContent(s, cb) {
        var url = this.searchURL;
        var url = this.searchURL + 's=' + s + '&offset=' + this.currentOffset + '&limit=' + this.currentLimit;
        HttpHandler.sendGet(url, (data) => {
            cb(data.result, data.count);
        }, (errorCode, errorMessage) => {

        });
    }

    showDialog(url, s, defs, cb) {
        this.defs = defs;
        this.currentLimit = 10;
        this.currentOffset = 0;
        this.searchField = s;

        this.callback = cb;
        this.searchURL = url;

        //Prepare table columns
        this.tableColumns = [];
        for (var key in defs) {
            var column = {
                title: defs[key].title,
                field: key,
                align : defs[key].align,
                renderer: defs[key].renderer,
            };

            this.tableColumns.push(column);
        }
        this.setState({
            dialogOpen: true,
        });

    }

    componentDidMount() {
        SearchFieldModal.currentRef = this;
    }

    _closeClicked() {
        this.setState({
            dialogOpen: false
        })
    }

    _getData(cb, offset, limit, s) {
        this.currentOffset = offset;
        this.currentLimit = limit;
        this._searchContent(s, cb)
    }

    _rowClicked(v) {
        this.callback(v);
    }

    render() {
        const { classes } = this.props;

        return (
            <Dialog open={this.state.dialogOpen} onClose={this.props.dialogClosed}>
                <DialogContent style={{ }}>
                    <div style={{
                        width: '100%',  alignItems: 'center', alignContent: 'center',
                        justifyContent: 'center', display: 'flex', flexDirection: 'row'
                    }}>
                        <CTable
                            onRowClick={this._rowClicked.bind(this)}
                            pageSize={10}
                            data={this._getData.bind(this)}
                            columns={this.tableColumns}>

                        </CTable>
                    </div>
                </DialogContent>
                <Divider></Divider>
                <div style={{ padding: 10, display: 'flex', flexDirection: 'row' }}>
                    <div style={{ flex: 1 }}></div>
                    <div>
                        <Button

                            onClick={this._closeClicked.bind(this)}
                            variant="contained" color="default"><span style={{ fontFamily: 'Metropolis-Regular' }}>Close</span></Button>
                    </div>
                </div>
            </Dialog>
        )
    }
}


SearchFieldModal.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SearchFieldModal);