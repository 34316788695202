import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles'

import { Fab, Paper, TextField, Select, MenuItem, FormControl, InputLabel, Divider, Button, Collapse } from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddRounded';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';

import { NotificationManager } from 'react-notifications';

import PagePath from './components/PagePath';
import commonClasses from './ics.module.css'
import ErrorDialog from './components/ErrorDialog';
import UserProfile from './UserProfile';
import HttpHandler from './HttpHandler';
import CTable from './components/CTable'
import Utils from './Utils'
import ConfirmationDialog from './components/ConfirmationDialog';



const useStyles = makeStyles({

});



function UserCreateEditUI(props) {

    const [state, setState] = useState({
        email: '',
        emailError: false,
        emailErrorMessage: '',
        fullName: '',
        fullNameError: false,
        fullNameErrorMessage: '',
        initialPassword: '',
        initialPasswordError: false,
        initialPasswordErrorMessage: '',
        reTypePassword: '',
        reTypePasswordError: false,
        reTypePasswordErrorMessage: '',
        userType: Utils.USER_TYPE_DOCTOR,
        phoneError: false,
        phone: '',
        phoneErrorMessage: '',
        userOrg: '',
        userOrgError: false,
        userOrgErrorMessage: '',
        userEmail: '',
        userEmailError: false,
        userEmailErrorMessage: '',

    });

    const _loadUserDetails = () => {
        HttpHandler.sendGet('/api/v1/user/' + props.userId, (d) => {

            setState({
                ...state,
                email: d.user.userName,
                fullName: d.user.fullName,
                phone: d.user.phone === null ? '' : d.user.phone,
                userType: d.user.userType,
                emailError: false,
                emailErrorMessage: '',
                initialPassword: '',
                reTypePassword: '',
                reTypePasswordError: false,
                reTypePasswordErrorMessage: '',
                initialPasswordError: false,
                initialPasswordErrorMessage: '',
                fullNameError: false,
                fullNameErrorMessage: '',
                phoneError: false,
                phoneErrorMessage: '',
                userOrg: d.user.org === null ? "" : d.user.org,
                userOrgError: false,
                userOrgErrorMessage: '',
                userEmail: d.user.emailId === null ? "" : d.user.emailId,
                userEmailError: false,
                userEmailErrorMessage: '',

            })
        }, (errorCode, errorMessage) => {
            NotificationManager.error("There was an error in loading user details for edit.", "User");
            this.props.onClose();
        })
    }

    useEffect(() => {
        if (props.userId > 0) {
            _loadUserDetails();
        }
        else {
            setState({
                ...state,
                email: '',
                emailError: false,
                emailErrorMessage: '',
                fullName: '',
                fullNameError: false,
                fullNameErrorMessage: '',
                initialPassword: '',
                initialPasswordError: false,
                initialPasswordErrorMessage: '',
                reTypePassword: '',
                reTypePasswordError: false,
                reTypePasswordErrorMessage: '',
                userType: Utils.USER_TYPE_DOCTOR,
                phoneError: false,
                phone: '',
                phoneErrorMessage: '',
                userOrg: '',
                userOrgError: false,
                userOrgErrorMessage: '',
                userEmail: '',
                userEmailError: false,
                userEmailErrorMessage: '',
            })
        }
    }, [props]);

    const _handleUserTypeChange = (v) => {
        setState({
            ...state,
            userType: v.target.value
        })
    }

    const _onPhoneChanged = (v) => {
        var val = v.target.value;
        if (val.length > 11) {
            val = state.phone
        }
        else if (val.indexOf('.') >= 0) {
            val = state.phone;
        }
        else if (isNaN(val)) {
            val = state.phone;
        }
        setState({
            ...state,
            phone: val,
            phoneError: false,
            phoneErrorMessage: ''
        })
    }

    const _saveClicked = () => {
        var myState = { ...state };
        var valid = true;

        var email = state.email.trim();
        if (email.length === 0) {
            valid = false;
            myState.emailError = true;
            myState.emailErrorMessage = "Mandatory"
        }
        else if (email.length < 3 || email.length > 120) {
            valid = false;
            myState.emailError = true;
            myState.emailErrorMessage = "Length should be between 3 and 120"
        }

        var fullName = state.fullName.trim();
        if (fullName.length === 0) {
            valid = false;
            myState.fullNameError = true;
            myState.fullNameErrorMessage = "Full name cannot be empty"
        } else if (fullName.length < 3 || fullName.length > 120) {
            valid = false;
            myState.fullNameError = true;
            myState.fullNameErrorMessage = "Length should be between 3 and 120"
        } else if (!Utils.isValidDotAccept(fullName)) {
            valid = false;
            myState.fullNameError = true;
            myState.fullNameErrorMessage = "Invalid Name";
        }

        var org = state.userOrg.trim();
        if (org.length === 0) {
            valid = false;
            myState.userOrgError = true;
            myState.userOrgErrorMessage = "Mandatory"
        }
        else if (org.length > 100) {
            valid = false;
            myState.userOrgError = true;
            myState.userOrgErrorMessage = "Length should not exceed 100"
        }

        var userEmail = state.userEmail.trim();
        if (userEmail.length > 150 || (userEmail.length !== 0 && !Utils.isValidEmail(userEmail))) {
            myState.userEmailError = true;
            myState.userEmailErrorMessage = "Invalid Email";
            valid = false;
        }


        var initialPassword = state.initialPassword.trim();
        var reTypePassword = state.reTypePassword.trim();

        if (props.userId > 0) {
            if (initialPassword !== '' || reTypePassword !== '') {
                if (initialPassword.length === 0) {
                    valid = false;
                    myState.initialPasswordError = true;
                    myState.initialPasswordErrorMessage = "Password cannot be empty"
                }
                else if (initialPassword.length < 3 || initialPassword.length > 20) {
                    valid = false;
                    myState.initialPasswordError = true;
                    myState.initialPasswordErrorMessage = "Length should be between 3 and 20"
                }
                else if (initialPassword !== reTypePassword) {
                    valid = false;
                    myState.reTypePasswordError = true;
                    myState.reTypePasswordErrorMessage = "Passwords do not match"
                }
            }
            else {
                initialPassword = null;
            }
        }
        else {
            if (initialPassword.length === 0) {
                valid = false;
                myState.initialPasswordError = true;
                myState.initialPasswordErrorMessage = "Password cannot be empty"
            }
            else if (initialPassword.length < 3 || initialPassword.length > 20) {
                valid = false;
                myState.initialPasswordError = true;
                myState.initialPasswordErrorMessage = "Length should be between 3 and 20"
            }
            else if (initialPassword !== reTypePassword) {
                valid = false;
                myState.reTypePasswordError = true;
                myState.reTypePasswordErrorMessage = "Passwords do not match"
            }
        }

        var phone = state.phone.trim();
        if (phone === '') {
            valid = false;
            myState.phoneError = true;
            myState.phoneErrorMessage = 'Mandatory'
        }
        else if (!Utils.isValidPhone(phone)) {
            valid = false;
            myState.phoneError = true;
            myState.phoneErrorMessage = 'Invalid phone'
        }

        if (!valid) {
            setState(myState);
            return;
        }

        //Send a request to check for duplicate.
        var req = {
            email: email,
            userId: -1
        }

        var ireq = {
            email: email,
            fullName: fullName,
            password: initialPassword,
            userType: state.userType,
            phone: phone,
            org: org,
            emailId: state.userEmail
        }

        if (props.userId > 0) {
            //No validation of userId. We are not allowing o change.
            _processToCreateSaveUser(ireq);
            return;
        }

        HttpHandler.sendPost('/api/v1/user/duplicate', req, (data) => {
            if (data.duplicate) {
                setState({
                    ...state,
                    emailError: true,
                    emailErrorMessage: "User already exists"
                })
            }
            else {
                _processToCreateSaveUser(ireq)

            }
        }, (errorCode, errorMessage) => {
            ErrorDialog.show("There was an error in validing the user");
        })
    }

    const _processToCreateSaveUser = (req) => {
        var url = '/api/v1/user/new';
        if (props.userId > 0) {
            url = '/api/v1/user/' + props.userId;
        }
        HttpHandler.sendPost(url, (req), (data) => {
            //Success.
            if (props.userId > 0) {
                NotificationManager.success("User record updated.", req.fullName);
            }
            else {
                NotificationManager.success("User record created.", req.fullName);
            }
            props.onDone();
        }, (errorCode, errorMessage) => {
            ErrorDialog.show("There was an error in saving user");
        })
    }

    const _closeClicked = () => {
        props.onClose();
    }

    return (
        <Paper style={{ padding: 10, width: 600 }}>
            <div className={commonClasses.headerBar}>{props.heading}</div>
            <div style={{ paddingBottom: 20 }}>
                <form noValidate autoComplete="off">
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                        <TextField
                            required
                            error={state.emailError}
                            id='email'
                            label="User id"
                            disabled={props.userId > 0}
                            style={{ flex: 1, paddingRight: 10 }}
                            value={state.email}
                            margin="normal"
                            helperText={state.emailErrorMessage}
                            onChange={(v) => {
                                setState({
                                    ...state,
                                    email: v.target.value,
                                    emailError: false,
                                    emailErrorMessage: ''
                                })
                            }}
                        />

                        <TextField
                            required
                            error={state.phoneError}
                            id='phone'
                            label="Phone"
                            tyle={{ flex: 1, paddingLeft: 10 }}
                            value={state.phone}
                            margin="normal"
                            helperText={state.phoneErrorMessage}
                            onChange={_onPhoneChanged}
                        />
                    </div>

                    <TextField
                        required
                        error={state.fullNameError}
                        id='full-name'
                        label="Full Name"
                        style={{ width: 400 }}
                        value={state.fullName}
                        margin="normal"
                        helperText={state.fullNameErrorMessage}
                        onChange={(v) => {
                            setState({
                                ...state,
                                fullName: v.target.value,
                                fullNameError: false,
                                fullNameErrorMessage: ''
                            })
                        }}
                    />

                    <TextField
                        required
                        error={state.userOrgError}
                        id='userOrg'
                        label="Organization"
                        style={{ width: 400 }}
                        value={state.userOrg}
                        margin="normal"
                        helperText={state.userOrgErrorMessage}
                        onChange={(v) => {
                            setState({
                                ...state,
                                userOrg: v.target.value,
                                userOrgError: false,
                                userOrgErrorMessage: ''
                            })
                        }}
                    />

                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ flex: 1, paddingRight: 10 }}>
                            <TextField
                                fullWidth
                                required
                                error={state.initialPasswordError}
                                id='initial-password'
                                label="Initial password"
                                type="password"
                                value={state.initialPassword}
                                margin="normal"
                                helperText={state.initialPasswordErrorMessage}
                                onChange={(v) => {
                                    setState({
                                        ...state,
                                        initialPassword: v.target.value,
                                        initialPasswordError: false,
                                        initialPasswordErrorMessage: ''
                                    })
                                }}
                            />
                        </div>
                        <div style={{ flex: 1, paddingLeft: 10 }}>
                            <TextField
                                fullWidth
                                required
                                type="password"
                                error={state.reTypePasswordError}
                                id='full-name'
                                label="Retype password"
                                value={state.reTypePassword}
                                margin="normal"
                                helperText={state.reTypePasswordErrorMessage}
                                onChange={(v) => {
                                    setState({
                                        ...state,
                                        reTypePassword: v.target.value,
                                        reTypePasswordError: false,
                                        reTypePasswordErrorMessage: ''
                                    })
                                }}
                            />
                        </div>

                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <FormControl style={{ marginTop: 16, marginRight: 25, width: 200 }}>
                            <InputLabel id="usertype-type-label">Type</InputLabel>
                            <Select
                                labelId="usertype-type-label"
                                id="usertype-type-select"
                                value={state.userType}
                                onChange={_handleUserTypeChange}
                            >
                                <MenuItem value={Utils.USER_TYPE_ADMIN}>Admin</MenuItem>
                                <MenuItem value={Utils.USER_TYPE_SURVEY}>Survey</MenuItem>
                                <MenuItem value={Utils.USER_TYPE_SCREENING}>Screening</MenuItem>
                                <MenuItem value={Utils.USER_TYPE_COORDINATOR}>Coordinator</MenuItem>
                                <MenuItem value={Utils.USER_TYPE_DOCTOR}>Doctor</MenuItem>

                              
                                <MenuItem value={Utils.USER_TYPE_DATAENTRY}>Data Entry</MenuItem>   
                            </Select>
                        </FormControl>
                        <TextField
                            error={state.userEmailError}
                            id='userEmail'
                            label="Email"
                            style={{ flex: 1 }}
                            value={state.userEmail}
                            margin="normal"
                            helperText={state.userEmailErrorMessage}
                            onChange={(v) => {
                                setState({
                                    ...state,
                                    userEmail: v.target.value,
                                    userEmailError: false,
                                    userEmailErrorMessage: ''
                                })
                            }}
                        />
                    </div>
                </form>
            </div>
            <Divider style={{ marginLeft: -10, marginRight: -10 }}></Divider>
            <div style={{ paddingTop: 10, flexDirection: 'row', display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    onClick={_saveClicked}
                    variant="contained" color="primary">Save</Button>
                <Button
                    onClick={_closeClicked}
                    variant="contained" color="default" style={{ marginLeft: 20 }}>Close</Button>
            </div>
        </Paper>
    );
}

export default function UsersUI(props) {

    const [state, setState] = useState({
        showCreateDialog: false,
        currentUserId: -1,
        addEditUIHeading: "Create User"
    });

    const dataTable = useRef(null);

    const userDataTableDef = [
        {
            title: "User Id",
            field: "userName",
            align: 'left',
            style: { minWidth: 140, width: 140 },
            renderer: (row, data) => {
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'left' }} >
                        {row.userName}
                    </div>
                )
            }
        },
        {
            title: "Name",
            field: 'fullName',
            align: 'left',
            style: { minWidth: 140, width: 140 },
            renderer: (row, data) => {
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'left' }} >
                        {row.fullName}
                    </div>
                )
            }

        },

        {
            title: "User Type",
            field: "userType",
            align: 'left',
            style: { minWidth: 80, width: 80 },
            renderer: (row, data) => {
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'left' }} >
                        {Utils.USER_TYPE_MAP[row.userType]}
                    </div>
                )
            }
        },
        {
            title: "Organization",
            field: 'org',
            align: 'left',
            style: { minWidth: 100, width: 100 },
            renderer: (row, data) => {
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'left' }} >
                        {row.org}
                    </div>
                )
            }

        },
        {
            title: "",
            field: 'id',
            style: { minWidth: 120, width: 120 },
            renderer: (row, data) => {
                return (
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <div
                            onClick={() => _editClicked(row)}
                            style={{ height: 24, cursor: 'pointer' }}>
                            <EditRoundedIcon style={{ color: '#03A9F4' }}></EditRoundedIcon>
                        </div>
                        <div
                            onClick={() => _deleteUserClicked(row.id)}
                            style={{ marginLeft: 10, height: 24, cursor: 'pointer' }}>
                            <DeleteForeverRoundedIcon style={{ color: '#D32F2F' }}></DeleteForeverRoundedIcon>
                        </div>

                    </div>
                )
            }

        }
    ];

    const _editClicked = (row) => {
        setState({ ...state, currentUserId: row.id, showCreateDialog: true, addEditUIHeading: "Modify User" })
    }


    const _confirmDelete = (id) => {
        HttpHandler.sendDelete('/api/v1/user/' + id, (d) => {
            NotificationManager.success("User deleted.", "Delete");
            dataTable.current.reload();
        }, (errorCode, errorMessage) => {
            ErrorDialog.show("There was an error in deleting the user");
        })
    }


    const _deleteUserClicked = (id) => {
        ConfirmationDialog.show('Are you sure you want to delete the user ?', () => _confirmDelete(id))
    }

    const _addUser = () => {
        var dt = new Date();
        setState({
            ...state,
            dt: dt,
            showCreateDialog: true,
            currentUserId: -1,
            addEditUIHeading: "Create User"
        })
    }

    const _getUsersList = (cb, offset, limit, searchString) => {
        HttpHandler.sendGet('/api/v1/user/search?offset=' + offset + '&limit=' + limit + '&s=' + searchString, (data) => {
            cb(data.result, data.count)
        }, (errorCode, errorMessage) => {
            console.log(errorMessage);
        })
    }


    return (
        <div>
            {(HttpHandler.USER_TYPE !== 'admin') &&
                <div style={{ paddingTop: 40, color: '#FF0000', fontFamily: 'Metropolis-Regular', fontSize: 15, textAlign: 'center' }}>You are not authorized to access contents of this page.</div>
            }
            {(HttpHandler.USER_TYPE === 'admin') &&
                <div>
                    <PagePath path={['Administration', 'Users']}>
                        <div style={{ marginRight: 20 }}>
                            <Fab
                                onClick={_addUser}
                                color="primary" aria-label="add" size="medium">
                                <AddIcon />
                            </Fab>
                        </div>
                    </PagePath>
                    <div style={{ marginTop: 20, paddingBottom: 30, display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
                        <div>
                            <Collapse in={state.showCreateDialog} >
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <UserCreateEditUI
                                        userId={state.currentUserId}
                                        heading={state.addEditUIHeading}
                                        onClose={() => { setState({ ...state, showCreateDialog: false }) }}
                                        onDone={() => { setState({ ...state, showCreateDialog: false }); dataTable.current.reload(); }}
                                    />
                                </div>
                            </Collapse>
                            <Collapse in={!state.showCreateDialog}>

                                <Paper style={{ padding: 10 }}>
                                    <div style={{ width: 1000 }}>
                                        <CTable
                                            ref={dataTable}
                                            dt={state.someDate}
                                            pageSize={10}
                                            data={_getUsersList}
                                            columns={userDataTableDef}>
                                        </CTable>
                                    </div>
                                </Paper>
                            </Collapse>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

