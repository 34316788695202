import React, { Component, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import { Button, Divider, DialogActions, Switch, FormControl, InputLabel, MenuItem, CircularProgress } from '@material-ui/core';

import { NotificationManager } from 'react-notifications';

import CancelIcon from '@material-ui/icons/Cancel'
import commonClasses from './ics.module.css'

import HttpHandler from './HttpHandler';
import ErrorDialog from './components/ErrorDialog'

const styles = theme => ({
});


export default function AddTestsDialog(props) {

    const [state, setState] = useState({
        tests: [],
        loadingInProgress: false,
        loadingErrorMessage: '',
        loadingError: false
    });

    const _onClose = () => {
        props.onClose()
    }

    const _loadTestList = () => {
        var istate = { ...state, loadingInProgress: true, loadingError: false };
        HttpHandler.sendGet('/api/v1/test/all?offset=0&limit=200', (d) => {
            for (var i = 0; i < d.result.length; i++) {
                d.result[i].selected = false;
            }
            istate = { ...istate };
            setState(istate)
            _loadCurrentTestsList(istate, d.result);
        }, (errorCode, errorMessage) => {
            istate = { ...istate, loadingInProgress: false, loadingError: true, loadingErrorMessage: 'Eror loading test list' };
            setState(istate)
        })
    }

    const _loadCurrentTestsList = (istate, allTests) => {
        HttpHandler.sendGet('/api/v1/test/' + props.recordId + '/probable', (d) => {
            istate = { ...istate, loadingInProgress: false };

            var tests = d.tests;
            for (var i = 0; i < tests.length; i++) {
                var indexToRemove = -1;
                for (var j = 0; j < allTests.length; j++) {
                    if (allTests[j].id === tests[i].test) {
                        indexToRemove = j;
                        break;
                    }
                }
                if (indexToRemove >= 0) {
                    allTests.splice(indexToRemove, 1);
                }
            }
            istate = { ...istate, tests: allTests };
            setState(istate)
        }, (errorCode, errorMessage) => {
            istate = { ...istate, loadingInProgress: false, loadingError: true, loadingErrorMessage: 'Eror loading test list' };
            setState(istate)
        })
    }

    const _switchFieldSelected = (row) => {
        row.selected = !row.selected;
        setState({ ...state });
    }

    useEffect(() => {
        if (props.isVisible) {
            _loadTestList();
        }
    }, [props.recordId, props.isVisible]);

    const _onSave = () => {

        var testsSelected = [];
        for (var i = 0; i < state.tests.length; i++) {
            if (state.tests[i].selected) {
                testsSelected.push({ id: state.tests[i].id });
            }
        }
        var req = {  tests: testsSelected }


        HttpHandler.sendPost('/api/v1/test/' + props.recordId + '/addtests', req, (d) => {
            NotificationManager.success('Tests added.', 'Case');
            props.onSave();
        }, (errorCode, errorMessage) => {
            ErrorDialog.show("There was an error adding tests.");
        })

    }

    return (
        <Dialog open={props.isVisible} onClose={props.onClose}>
              <DialogContent>
            <div style={{ width: 550 }}>
            <div style={{ padding: 5, width: "100%"  }}>
                <div className={commonClasses.headerBar} style={{ width:'100%'}}>Add tests</div>
            </div>
            {(state.loadingInProgress) &&
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 40, alignItems: 'center', marginBottom:40 }}>
                    <CircularProgress></CircularProgress>
                    <div style={{ fontFamily: 'Metropolis-Regular', marginLeft: 10 }}>Loading tests</div>
                </div>
            }
            {(state.loadingInProgress === false && state.loadingError) &&
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 40, alignItems: 'center' , marginBottom:40}}>
                    <div style={{ fontFamily: 'Metropolis-Regular', marginLeft: 10, color: '#D32F2F' }}>{state.loadingErrorMessage}</div>
                </div>
            }
            {(state.loadingInProgress === false && state.loadingError === false && state.tests.length === 0) &&
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 40, alignItems: 'center', padding:10, marginBottom:40 }}>
                <div style={{ fontFamily: 'Metropolis-Regular', marginLeft: 10, color: '#000' }}>No tests available to be added. Either all are already added, or there is some server error</div>
            </div>
            }       
            {(state.loadingInProgress === false && state.loadingError === false && state.tests.length > 0)  &&
                <div style={{ width: '100%', maxHeight: 800, backgroundColor: '#FAFAFA' }}>
                    {state.tests.map((row, key) => {
                        return (
                            <div key={row.id} style={{ padding: 10, paddingBottom: 5, paddingTop: 5, }}>
                                <div style={{
                                    borderRadius: 5, boxShadow: '1px 1px 2px #999', backgroundColor: '#FFF',
                                    padding: 10, flexDirection: 'row', display: 'flex', alignItems: 'center'
                                }}>
                                    <div style={{ flex: 1, fontFamily: 'Metropolis-Medium' }}>{row.name}</div>
                                    <Switch
                                        checked={row.selected}
                                        onChange={() => _switchFieldSelected(row)}
                                        name={row.name}
                                        color="primary"
                                    />
                                </div>
                            </div>
                        )
                    })}
                </div>
            }
            <Divider />
            </div>
            </DialogContent>
            <DialogActions>
                <div style={{ paddingTop: 10, flexDirection: 'row', display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        onClick={_onSave}
                        variant="contained" color="primary" style={{ marginLeft: 20 }}>Save</Button>
                    <Button
                        onClick={_onClose}
                        variant="contained" color="default" style={{ marginLeft: 20 }}>Close</Button>
                </div>
            </DialogActions>
        </Dialog>
    )
}


