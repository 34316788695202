import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles'

import { FormControl, InputLabel, Select, MenuItem, FormHelperText, TextField } from '@material-ui/core'

import NumericTextField from './components/NumericTextField';

const styles = theme => ({

});


class NestedCondition extends Component {

    state = {
        conditionGroup: this.props.sections[0],
        conditionGroupErrorMessage: '',
        conditionGroupError: false,
        conditionField: '',
        conditionFieldErrorMessage: '',
        conditionFieldError: false,
        conditionOperator: 'eq',
        conditionOperatorError: false,
        conditionOperatorErrorMessage: '',
        conditionBooleanOption: true,
        conditionBooleanOptionError: false,
        conditionBooleanOptionErrorMessage: '',
        conditionOptionsOption: '',
        conditionOptionsOptionError: false,
        conditionOptionsOptionErrorMessage: '',
        conditionTextOption: '',
        conditionTextOptionError: false,
        conditionTextOptionErrorMessage: '',
        conditionNumericOption: '',
        conditionNumericOptionError: false,
        conditionNumericOptionErrorMessage: '',
        conditionDecimalOption: '',
        conditionDecimalOptionError: false,
        conditionDecimalOptionErrorMessage: ''
    }

    _conditionGroupChange(v) {
        this.setState({
            conditionGroupErrorMessage: '',
            conditionGroupError: false,
            conditionGroup: v.target.value,

        })
    }

    _conditionFieldChange(v) {
        this.setState({
            conditionFieldErrorMessage: '',
            conditionFieldError: false,
            conditionField: v.target.value,

        })
    }

    _conditionOperatorChange(v) {
        this.setState({
            conditionOperatorErrorMessage: '',
            conditionOperatorError: false,
            conditionOperator: v.target.value,

        })
    }

    _conditionBooleanOptionChange(v) {
        this.setState({
            conditionBooleanOptionErrorMessage: '',
            conditionBooleanOptionError: false,
            conditionBooleanOption: v.target.value,

        })
    }

    _conditionOptionsOptionChange(v) {
        this.setState({
            conditionOptionsOptionErrorMessage: '',
            conditionOptionsOptionError: false,
            conditionOptionsOption: v.target.value,

        })
    }

    isValid() {
        if (this.state.conditionField === '' || this.state.conditionField === null) {
            this.setState({
                conditionFieldError: true,
                conditionFieldErrorMessage: 'Mandatory'
            });

            return false;
        }

        //If the value type is boolean, there is no need of additional validation. All validations are already done.

        switch (this.state.conditionField.type) {
            case 'text': {
                var val = this.state.conditionTextOption.trim();
                if (val.length < this.state.conditionField.def.minLength) {
                    this.setState({
                        conditionTextOptionError: true,
                        conditionTextOptionErrorMessage: 'Valid length is ' + this.state.conditionField.def.minLength + " - " + this.state.conditionField.def.maxLength
                    });
                    return false;
                }
            }
            break;
            case 'options': {
                var val = this.state.conditionTextOption.trim();
                if (this.state.conditionOptionsOption === null || this.state.conditionOptionsOption === '') {
                    this.setState({
                        conditionOptionsOptionError: true,
                        conditionOptionsOptionErrorMessage: 'Mandatory'
                    });
                    return false;
                }
            }
            break;
            case 'numeric': {
                var val = this.state.conditionNumericOption.trim();
                if (val !== '') {
                    val = parseInt(val);
                    if (val < this.state.conditionField.def.min || val > this.state.conditionField.def.max) {
                        this.setState({
                            conditionNumericOptionError: true,
                            conditionNumericOptionErrorMessage: 'Value out of range'
                        });
                        return false;
                    }
                }
                else {
                    if (this.state.conditionField.optional === false) {
                        this.setState({
                            conditionNumericOptionError: true,
                            conditionNumericOptionErrorMessage: 'Mandatory'
                        });
                        return false;
                    }
                    else {
                        return true;
                    }
                }
            }
            break;
            case 'decimal': {
                var val = this.state.conditionDecimalOption.trim();
                if (val !== '') {
                    val = parseFloat(val);
                    if (val < this.state.conditionField.def.min || val > this.state.conditionField.def.max) {
                        this.setState({
                            conditionDecimalOptionError: true,
                            conditionDecimalOptionErrorMessage: 'Value out of range'
                        });
                        return false;
                    }
                }
                else {
                    if (this.state.conditionField.optional === false) {
                        this.setState({
                            conditionDecimalOptionError: true,
                            conditionDecimalOptionErrorMessage: 'Mandatory'
                        });
                        return false;
                    }
                    else {
                        return true;
                    }
                }
            }
            break;
        }

        return true;
    }


    render() {
        const { classes } = this.props;
        console.log(this.props.sections);
        return (
            <div>
                <FormControl style={{ width: '100%' }} className={classes.formControl} error={this.state.conditionGroupError} >
                    <InputLabel id="nestedConditionSectionLabel">Group</InputLabel>
                    <Select
                        labelId="nestedConditionSectionLabel"
                        id="nestedConditionSection"
                        value={this.state.conditionGroup}
                        style={{ minWidth: 100 }}
                        onChange={this._conditionGroupChange.bind(this)}
                    >
                        {this.props.sections.map((row, key) => {
                            return (
                                <MenuItem key={key} value={row}>{row.name}</MenuItem>
                            )
                        })}
                    </Select>
                    <FormHelperText>{this.state.conditionGroupErrorMessage}</FormHelperText>
                </FormControl>

                <FormControl style={{ width: '100%', marginTop: 5 }} className={classes.formControl} error={this.state.conditionFieldError} >
                    <InputLabel id="nestedConditionFieldLabel">Field</InputLabel>
                    <Select
                        labelId="nestedConditionFieldLabel"
                        id="nestedConditionField"
                        value={this.state.conditionField}
                        style={{ minWidth: 100 }}
                        onChange={this._conditionFieldChange.bind(this)}
                    >
                        {this.state.conditionGroup.rows.map((row, key) => {
                            return (
                                <MenuItem key={key} value={row}>{row.name}</MenuItem>
                            )
                        })}
                    </Select>
                    <FormHelperText>{this.state.conditionFieldErrorMessage}</FormHelperText>

                </FormControl>

                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    {(this.state.conditionField !== '') &&
                        <FormControl style={{ marginTop: 5 }} className={classes.formControl} error={this.state.conditionOperatorError} >
                            <InputLabel id="conditionOperatorLabel">Condition</InputLabel>
                            <Select
                                labelId="conditionOperatorLabel"
                                id="conditionOperator"
                                value={this.state.conditionOperator}
                                style={{ minWidth: 100 }}
                                onChange={this._conditionOperatorChange.bind(this)}
                            >

                                <MenuItem value='eq'>=</MenuItem>
                                <MenuItem value='ne'>!=</MenuItem>
                                {(this.state.conditionField.type === 'text' || this.state.conditionField.type == 'decimal' || this.state.conditionField.type === 'numeric') &&
                                    <MenuItem value='gt'>&gt;</MenuItem>
                                }
                                {(this.state.conditionField.type === 'text' || this.state.conditionField.type == 'decimal' || this.state.conditionField.type === 'numeric') &&
                                    <MenuItem value='ge'>&gt;=</MenuItem>
                                }
                                {(this.state.conditionField.type === 'text' || this.state.conditionField.type == 'decimal' || this.state.conditionField.type === 'numeric') &&
                                    <MenuItem value='lt'>&lt;</MenuItem>
                                }
                                {(this.state.conditionField.type === 'text' || this.state.conditionField.type == 'decimal' || this.state.conditionField.type === 'numeric') &&
                                    <MenuItem value='le'>&lt;=</MenuItem>
                                }
                            </Select>
                            <FormHelperText>{this.state.conditionOperatorErrorMessage}</FormHelperText>
                        </FormControl>
                    }

                    {(this.state.conditionField.type === 'boolean') &&
                        <FormControl style={{ flex: 1, marginLeft: 10, marginTop: 5 }} className={classes.formControl} error={this.state.conditionBooleanOptionError} >
                            <InputLabel id="conditionBooleanOptionLabel">Value</InputLabel>
                            <Select
                                labelId="conditionBooleanOptionLabel"
                                id="conditionBooleanOption"
                                value={this.state.conditionBooleanOption}
                                style={{ minWidth: 100 }}
                                onChange={this._conditionBooleanOptionChange.bind(this)}
                            >


                                <MenuItem value={true}>Yes</MenuItem>
                                <MenuItem value={false}> No</MenuItem>
                            </Select>
                            <FormHelperText>{this.state.conditionBooleanOptionErrorMessage}</FormHelperText>
                        </FormControl>
                    }
                    {(this.state.conditionField.type === 'options') &&
                        <FormControl style={{ flex: 1, marginLeft: 10, marginTop: 5 }} className={classes.formControl}
                            error={this.state.conditionOptionsOptionError} >
                            <InputLabel id="conditionOptionsOptionLabel">Value</InputLabel>
                            <Select
                                labelId="conditionOptionsOptionLabel"
                                id="conditionOptionsOption"
                                value={this.state.conditionOptionsOption}
                                style={{ minWidth: 100 }}
                                onChange={this._conditionOptionsOptionChange.bind(this)}
                            >

                                {this.state.conditionField.def.options.map((row, key) => {
                                    return (
                                        <MenuItem key={row} value={row}>{row}</MenuItem>
                                    )
                                })}

                            </Select>
                            <FormHelperText>{this.state.conditionOptionsOptionErrorMessage}</FormHelperText>
                        </FormControl>
                    }

                    {(this.state.conditionField.type === 'text') &&
                        <FormControl style={{ flex: 1, marginLeft: 10, marginTop: 5 }} className={classes.formControl}>
                            <TextField
                                required={true}
                                error={this.state.conditionTextOptionError}
                                id="conditionTextOption"
                                label={"Value"}
                                inputProps={{ maxLength: this.state.conditionField.def.maxLength }}
                                value={this.state.conditionTextOption}
                                helperText={this.state.conditionTextOptionErrorMessage}
                                onChange={(v) => {
                                    this.setState({
                                        conditionTextOption: v.target.value,
                                        conditionTextOptionError: false,
                                        conditionTextOptionErrorMessage: ''
                                    })
                                }}
                            ></TextField>
                        </FormControl>
                    }

                    {(this.state.conditionField.type === 'numeric') &&
                        <FormControl style={{ flex: 1, marginLeft: 10, marginTop: 5 }} className={classes.formControl}>
                            <NumericTextField
                                required={true}
                                error={this.state.conditionNumericOptionError}
                                id="conditionNumericOption"
                                decimal={false}
                                label="Value"
                                value={this.state.conditionNumericOption}
                                helperText={this.state.conditionNumericOptionErrorMessage}
                                onChange={(v) => {
                                    this.setState({
                                        conditionNumericOption: v,
                                        conditionNumericOptionError: false,
                                        conditionNumericOptionErrorMessage: ''
                                    })
                                }}
                            ></NumericTextField>

                        </FormControl>
                    }
                    {(this.state.conditionField.type === 'decimal') &&
                        <FormControl style={{ flex: 1, marginLeft: 10, marginTop: 5 }} className={classes.formControl}>
                            <NumericTextField
                                required={true}
                                error={this.state.conditionDecimalOptionError}
                                id="conditionDecimalOption"
                                decimal={true}
                                label="Value"
                                value={this.state.conditionDecimalOption}
                                helperText={this.state.conditionDecimalOptionErrorMessage}
                                onChange={(v) => {
                                    this.setState({
                                        conditionDecimalOption: v,
                                        conditionDecimalOptionError: false,
                                        conditionDecimalOptionErrorMessage: ''
                                    })
                                }}
                            ></NumericTextField>

                        </FormControl>
                    }

                </div>
            </div>
        )
    }
}

export default withStyles(styles)(NestedCondition);
