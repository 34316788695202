import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles'

import { Paper, Divider } from '@material-ui/core';
import DataValue from './components/DataValue'

const styles = theme => ({
}
);


class TemplateDataView extends Component {


    render() {
        return (
            <div style={{ width: '100%' }}>
                {this.props.sections.map((row, key) => {
                    return (
                        <div key={key} style={{ marginTop: 10 }}>
                            <Paper style={{ padding: 10, minWidth: 400 }}>
                                <div style={{ flexDirection: 'row', display: 'flex', paddingBottom: 5, alignItems: 'center' }}>
                                    <div style={{ fontFamily: 'Metropolis-Regular', flex: 1 }}>{row.name}</div>
                                </div>
                                <Divider></Divider>

                                <div style={{ paddingTop: 3, flexDirection: 'column', display: 'flex', marginTop: 10 }}>
                                    {row.rows.map((field, rkey) => {
                                        if(field.type ===  'boolean') {
                                            return (
                                                <DataValue key={key+"."+field.id} booleanValue={this.props.sectionData[field.id].value} title={field.name} ></DataValue>
                                            )    
                                        }
                                        else {
                                            return (
                                                <DataValue key={key+"."+field.id} value={this.props.sectionData[field.id].value} title={field.name} ></DataValue>
                                            )    
                                        }
                                    })}
                                </div>
                            </Paper>
                        </div>
                    )
                })
                }
            </div>
        )
    }
}

TemplateDataView.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TemplateDataView);
