import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles'

import { Button, Paper, Divider, CircularProgress } from '@material-ui/core'


import Moment from 'react-moment';
import { Link, useHistory } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';

import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';

import ConfirmationDialog from './components/ConfirmationDialog';
import ErrorDialog from './components/ErrorDialog';
import PagePath from './components/PagePath';
import HttpHandler from './HttpHandler';
import SurveyRecordEdit from './SurveyRecordEdit';
import SearchFieldModal from './components/SearchFieldModal';

const useStyles = makeStyles({
    surveyTopHeaderContainer: {
        flex: 1,
        backgroundColor: '#43A047',
        padding: 10,
        display: 'flex',
        flexDirection: 'column'
    },
    mainTitle: {
        fontFamily: 'Metropolis-Regular',
        color: '#FFF',
        fontSize: 24
    },
    locationTitle: {
        fontFamily: 'Metropolis-Regular',
        color: '#FFF',
        fontSize: 16
    },
    smallCompTitle: {
        fontFamily: 'Metropolis-Regular',
        color: '#FFF',
        fontSize: 13
    },

    smallCompValue: {
        fontFamily: 'Metropolis-Regular',
        color: '#FFF',
        fontSize: 16,
        paddingLeft: 10
    },
    closeButton: {
        background: '#EF6C00',
        color: '#FFF',
        "&:hover": {
            backgroundColor: "#E65100"
        }
    },

    cancelButton: {
        background: '#B71C1C',
        color: '#FFF',
        "&:hover": {
            backgroundColor: "#B71C1C"
        }
    }
});

const surveyStatusMapping = {
    'SC': 'Scheduled',
    'RU': 'Running',
    'CA': 'Cancelled',
    'CO': 'Completed'
}


const registeredUserSearchDef = {
    fullName: {
        title: 'Name',
        align: 'left'
    },
    org: {
        title: 'Organization',
        align: 'left'
    }
}

export default function Survey(props) {

    const classes = useStyles();
    let surveyId = useRef(0);
    let history = useHistory();

    const [state, setState] = useState({
        surveyName: '',
        districtName: '',
        stateName: '',
        startDate: new Date(),
        endDate: new Date(),
        templateName: '',
        templateId: -1,
        address: '',
        districtName: '',
        surveyPHC: '',
        surveyStatus: 'SC',
        totalMale: 0,
        totalFemale: 0,
        totalOthers: 0,
        totalParticipants: 0,

        totalMarried: 0,
        totalSingle: 0,
        totalWidow: 0,
        totalDivorced: 0,

        maxAge: 0,
        minAge: 0,
        avgAge: 0,
        showRegisterUI: false,
        team: [],
        loadingTeamDetails: false,
        teamLoadError: false,
        hasAccess: false,
        loadingInProgress: true

    });


    const _getSurveyDetails = () => {
        HttpHandler.sendGet('/api/v1/survey/' + surveyId.current, (v) => {
            if (v === null) {
                return;
            }
            var istate = {
                ...state,
                surveyName: v.survey.name,
                surveyStatus: v.survey.status,
                address: v.survey.address,
                districtName: v.survey.address.district.name,
                surveyPHC: v.survey.phc,
                stateName: v.survey.address.state.name,
                startDate: new Date(v.survey.startDate),
                endDate: new Date(v.survey.endDate),
                templateName: v.survey.template.name,
                templateId: v.survey.template.id,
                organizer: v.survey.organizer.name,
                hasAccess: true,
                loadingInProgress: false,
                donor: v.survey.donor == null ? 'Not assigned' : v.survey.donor.name,
            }

            setState(istate);

            _getSummaryDetails(istate);

        }, (errorCode, errorConsole) => {
            if (errorCode === 401) {
                setState({ ...state, hasAccess: false, loadingInProgress: false })
            }
        });
    }

    const _getSummaryDetails = (istate) => {
        HttpHandler.sendGet('/api/v1/survey/' + surveyId.current + '/summary', (d) => {
            var summary = d.summary;
            istate = {
                ...istate,
                totalMale: summary.gender['M'],
                totalFemale: summary.gender['F'],
                totalOthers: summary.gender['O'],
                totalParticipants: summary.gender['M'] + summary.gender['F'] + summary.gender['O'],
                totalMarried: summary.marital['M'],
                totalSingle: summary.marital['S'],
                totalWidow: summary.marital['W'],
                totalDivorced: summary.marital['D'],

                maxAge: summary.age.max,
                minAge: summary.age.min,
                avgAge: summary.age.avg,
            }

            _loadSurveyTeam(istate)
        }, (errorCode, errorMessage) => {

        })
    }

    const _submitStartRequest = () => {
        HttpHandler.sendPost('/api/v1/survey/' + surveyId.current + '/status', { status: 'RU' }, () => {
            NotificationManager.success("Survey started", state.surveyName);
            setState({ ...state, surveyStatus: 'RU' })
        }, (errorCode, errorMessage) => {
            ErrorDialog.show("Internal error starting the survey");
        })
    }

    const _startClicked = () => {
        if (state.team.length === 0) {
            ErrorDialog.show("Survey team does not have any member.");
            return;
        }
        //If start date is not same as current date, then survey start confirmation should be shown.
        var cdate = new Date();
        var cstr = cdate.getFullYear + "" + cdate.getMonth() + "" + cdate.getDate();
        var cdstr = state.startDate.getFullYear + "" + state.startDate.getMonth() + "" + state.startDate.getDate();
        if (cstr !== cdstr) {
            ConfirmationDialog.show('Survey start date is not today. Do you really want to start ?', () => {
                _submitStartRequest();
            })
        }
        else {
            _submitStartRequest();
        }
    }

    const _closeClicked = () => {
        ConfirmationDialog.show("Are you sure you want to close the survey ?", () => {
            HttpHandler.sendPost('/api/v1/survey/' + surveyId.current + '/status', { status: 'CO' }, () => {
                NotificationManager.success("Survey closed", state.surveyName);
                setState({ ...state, surveyStatus: 'CO' })
            }, (errorCode, errorMessage) => {
                ErrorDialog.show("Internal error closing the survey");
            })
        })
    }

    const _dataClicked = () => {
        history.push('/ics/survey/' + surveyId.current + '/data');
    }

    useEffect(() => {
        surveyId.current = props.match.params.surveyId;
        _getSurveyDetails();
    }, []);

    const _registerClicked = () => {
        setState({ ...state, showRegisterUI: true })
    }

    const _closeRegister = () => {
        setState({ ...state, showRegisterUI: false })
    }

    const _onRegisterSave = () => {
        setState({ ...state, showRegisterUI: false })
        NotificationManager.success("Record saved.", state.surveyName);
    }

    const _loadSurveyTeam = (istate) => {
        setState({ ...istate, loadingTeamDetails: true, teamLoadError: false });
        HttpHandler.sendGet('/api/v1/survey/' + surveyId.current + '/team', (d) => {
            setState({ ...istate, loadingTeamDetails: false, team: d.team });
        }, (errorCode, errorConsole) => {
            setState({ ...istate, loadingTeamDetails: false, teamLoadError: true });
        })
    }

    const _addRegisteredUserClicked = () => {
        SearchFieldModal.show('/api/v1/survey/' + surveyId.current + '/search?', '', registeredUserSearchDef, (v) => {
            if (v !== null) {
                var req = {
                    registered: true,
                    regId: v.id,
                    name: v.fullName,
                    userId: v.id
                }
                HttpHandler.sendPost('/api/v1/survey/' + surveyId.current + '/team/new', req, () => {
                    NotificationManager.success('Team member assigned to team', v.fullName);
                    SearchFieldModal.hide();
                    _loadSurveyTeam(state);
                }, (errorCode, errorMessage) => {
                    ErrorDialog.show("Error in assigning team member to team")
                })
            }
        });
    }

    const _deleteTeamMemberClicked = (row) => {
        ConfirmationDialog.show('Are you sure you want to delete this entry?', () => {
            HttpHandler.sendDelete('/api/v1/survey/' + surveyId.current + '/team/' + row.id, (d) => {
                NotificationManager.success("Team member removed.", row.name);
                _loadSurveyTeam(state);
            }, (errorCode, errorMessage) => {
                ErrorDialog.show("There was error in deleting");
            })
        });
    }


    return (
        <div style={{ width: '100%' }}>
            {state.loadingInProgress &&
                <div style={{ paddingTop: 40, color: '#000', fontFamily: 'Metropolis-Regular', fontSize: 15, textAlign: 'center' }}>We are loading the survey details.</div>
            }
            {(((HttpHandler.USER_TYPE !== 'coordinator' && HttpHandler.USER_TYPE !== 'survey') || !state.hasAccess) && state.loadingInProgress === false) &&
                <div style={{ paddingTop: 40, color: '#FF0000', fontFamily: 'Metropolis-Regular', fontSize: 15, textAlign: 'center' }}>You are not authorized to access contents of this page.</div>
            }
            {(((HttpHandler.USER_TYPE === 'coordinator' || HttpHandler.USER_TYPE === 'survey') && state.hasAccess) && state.loadingInProgress === false) &&
                <div>
                    <PagePath path={['Surveys', state.surveyName + " " + (state.surveyStatus === 'CA' ? "(Cancelled)" : "")]}>
                        {(state.showRegisterUI === false) &&
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                {(state.surveyStatus === 'RU' && (HttpHandler.USER_TYPE === 'survey' || HttpHandler.USER_TYPE === 'doctor')) &&
                                    <Button
                                        color="primary"
                                        onClick={_registerClicked}
                                        variant="contained" style={{ marginRight: 10 }}>Register</Button>
                                }
                                <Button
                                    onClick={_dataClicked}
                                    variant="contained"
                                    color="primary"
                                    style={{ marginRight: 10 }}>Data</Button>
                                {(state.surveyStatus !== 'CA') &&
                                    <div style={{ marginRight: 10 }}>
                                        {(state.surveyStatus === 'SC' && (HttpHandler.USER_TYPE === 'coordinator')) &&
                                            <Button
                                                onClick={_startClicked}
                                                variant="contained" color="primary" style={{ marginRight: 10 }}>Start</Button>
                                        }
                                        {(state.surveyStatus === 'RU' && (HttpHandler.USER_TYPE === 'coordinator')) &&
                                            <Button
                                                classes={{ root: classes.closeButton }}
                                                onClick={_closeClicked}
                                                variant="contained" style={{ marginRight: 10 }}>Close Survey</Button>
                                        }
                                    </div>
                                }
                            </div>
                        }

                    </PagePath>
                    <div style={{ marginTop: 30, paddingLeft: 10, display: 'flex', justifyContent: 'center' }}>
                        {state.showRegisterUI &&
                            <div>
                                <SurveyRecordEdit
                                    onSave={_onRegisterSave}
                                    onClose={_closeRegister}
                                    surveyId={surveyId.current} />
                            </div>
                        }
                        {(state.showRegisterUI === false) &&
                            <div style={{ width: 1000, maxWidth: 1000 }}>
                                <div style={{ width: '100%' }}>
                                    <div style={{ paddingBottom: 10, display: 'flex', flexDirection: 'row', paddingLeft: 0 }}>
                                        <Paper className={classes.surveyTopHeaderContainer}>
                                            <div className={classes.mainTitle} style={{ flex: 1 }}>{state.surveyName}</div>
                                            <div className={classes.locationTitle} style={{ marginTop: 10 }}>{state.districtName}</div>
                                            <div className={classes.locationTitle}>{state.stateName}</div>
                                        </Paper>
                                        <div style={{ width: 20 }}>

                                        </div>
                                        <Paper className={classes.surveyTopHeaderContainer}>
                                            <div>
                                                <div style={{ flexDirection: 'row', display: 'flex' }}>
                                                    <div style={{ flex: 1 }}>
                                                        <div className={classes.smallCompTitle}>When</div>
                                                        <div className={classes.smallCompValue} style={{ display: 'flex', flexDirection: 'row', fontSize: 14 }}>
                                                            <Moment format="DD-MMM-YYYY">{state.startDate}</Moment>
                                                            <div style={{ paddingLeft: 5, paddingRight: 5 }}> - </div>
                                                            <Moment format="DD-MMM-YYYY">{state.endDate}</Moment>
                                                        </div>
                                                    </div>

                                                    <div style={{ flex: 1 }}>
                                                        <div className={classes.smallCompTitle}>Status</div>
                                                        <div className={classes.smallCompValue}>
                                                            <div>{surveyStatusMapping[state.surveyStatus]}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{ flexDirection: 'row', display: 'flex', paddingTop: 10 }}>
                                                    <div style={{ flex: 1 }}>
                                                        <div className={classes.smallCompTitle}>Organizer</div>
                                                        <div className={classes.smallCompValue}>
                                                            <div>{state.organizer}</div>
                                                        </div>
                                                    </div>
                                                    <div style={{ flex: 1 }}>
                                                        <div className={classes.smallCompTitle}>Donor</div>
                                                        <div className={classes.smallCompValue}>
                                                            <div>{state.donor}</div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div style={{ flexDirection: 'row', display: 'flex', paddingTop: 10 }}>
                                                    <div style={{ flex: 1 }}>
                                                        <div className={classes.smallCompTitle}>Template</div>
                                                        <div className={classes.smallCompValue}>
                                                            <div>{state.templateName}</div>
                                                        </div>
                                                    </div>
                                                    <div style={{ flex: 1 }}>
                                                        <div className={classes.smallCompTitle}>Primary Health Center(PHC)</div>
                                                        <div className={classes.smallCompValue}>
                                                            <div>{state.surveyPHC}</div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </Paper>
                                    </div>


                                </div>
                                <div style={{ width: '100%' }}>
                                    <Paper style={{ padding: 10, width: '100%' }}>
                                        <div style={{ paddingBottom: 10 }}>
                                            <div style={{ fontFamily: 'Metropolis-Medium', paddingLeft: 10, paddingTop: 5, paddingBottom: 5 }}>Survey Team</div>
                                            <Divider />
                                            {state.loadingTeamDetails &&
                                                <div style={{
                                                    paddingTop: 20, paddingBottom: 20,
                                                    display: 'flex', fontFamily: 'Metropolis-Medium', fontSize: 15, justifyContent: 'center',
                                                    alignItems: 'center'
                                                }}>
                                                    <CircularProgress></CircularProgress><div style={{ paddingLeft: 10 }}>Loading survey team details</div>
                                                </div>
                                            }
                                            {(state.loadingTeamDetails === false && state.teamLoadError) &&
                                                <div style={{
                                                    paddingTop: 20, paddingBottom: 20,
                                                    display: 'flex', fontFamily: 'Metropolis-Medium', fontSize: 15, justifyContent: 'center', color: '#FF0000',
                                                    alignItems: 'center'
                                                }}>There was an error in loading team details
                                                </div>
                                            }


                                            {(state.loadingTeamDetails === false && state.teamLoadError === false) &&
                                                <div style={{ fontFamily: 'Metropolis-Regular' }}>
                                                    {(HttpHandler.USER_TYPE === 'coordinator' && (state.surveyStatus !== 'CO' && state.surveyStatus !== 'CA')) &&
                                                        <div style={{ paddingBottom: 10, display: 'flex', justifyContent: 'flex-end', paddingTop: 10 }}>
                                                            <Button onClick={_addRegisteredUserClicked} variant="contained" color="primary" style={{ marginRight: 10 }}>Add survey team member</Button>
                                                        </div>
                                                    }
                                                    {(state.team.length === 0) &&
                                                        <div style={{
                                                            paddingTop: 20, paddingBottom: 20,
                                                            display: 'flex', fontFamily: 'Metropolis-Regular', fontSize: 15, justifyContent: 'center', color: '#000',
                                                            alignItems: 'center'
                                                        }}>There are no members in the survey team. Please add few.
                                            </div>
                                                    }


                                                    {(state.team.length > 0) &&
                                                        <table style={{ width: '100%' }} cellPadding={0} cellSpacing={0}>
                                                            <thead>
                                                                <tr>
                                                                    <th style={{ textAlign: 'left', paddingLeft: 10, fontWeight: 'normal', backgroundColor: '#37474F', color: '#FFF', paddingTop: 7, paddingBottom: 7 }}>Name</th>

                                                                    {(HttpHandler.USER_TYPE === 'coordinator' && (state.surveyStatus !== 'CO' && state.surveyStatus !== 'CA')) &&
                                                                        <th style={{ paddingLeft: 1, width: 50, textAlign: 'left' }}>
                                                                            <div style={{ paddingLeft: 10, fontWeight: 'normal', backgroundColor: '#37474F', color: '#37474F', paddingTop: 7, paddingBottom: 7 }}>Actions</div>
                                                                        </th>
                                                                    }
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {state.team.map((row, key) => {
                                                                    return (
                                                                        <tr key={key}>
                                                                            <td style={{ paddingBottom: 1, textAlign: 'left' }}>
                                                                                <div style={{ paddingLeft: 10, fontWeight: 'normal', backgroundColor: '#F7F7F7', color: '#000', paddingTop: 7, paddingBottom: 7 }}>{row.name}</div>
                                                                            </td>



                                                                            <td style={{ paddingBottom: 1, overflow: 'hidden' }}>
                                                                                {(HttpHandler.USER_TYPE === 'coordinator' && (state.surveyStatus !== 'CO' && state.surveyStatus !== 'CA')) &&
                                                                                    <div
                                                                                        onClick={() => _deleteTeamMemberClicked(row)}
                                                                                        style={{
                                                                                            height: '100%', cursor: 'pointer', backgroundColor: '#F7F7F7', paddingTop: 5,
                                                                                            paddingBottom: 5, display: 'flex', justifyContent: 'center'
                                                                                        }}>
                                                                                        <DeleteForeverRoundedIcon style={{ color: '#D32F2F' }}></DeleteForeverRoundedIcon>
                                                                                    </div>
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    }

                                                </div>
                                            }


                                        </div>
                                    </Paper>
                                </div>

                            </div>
                        }
                    </div>
                </div>}
        </div>
    );
}
