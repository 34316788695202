import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles'
import { Paper } from '@material-ui/core';

const styles = theme => ({
    roundProfile : {
        width : 150,
        height : 150,
        borderRadius : 150,
        overflow:'hidden',
        padding:5,
        marginTop:-60,
        backgroundColor:'#FFF'
    },
    profileTopBand : {
        width : '100%',
        height : 100,
        backgroundColor:'#262626',
        borderTopLeftRadius:3, 
        borderTopRightRadius:3
    },
    leftPanel : {
        width:250, 
        alignItems:'center', 
        display:'flex', 
        flexDirection:'column', 
    },
    profileImage : {
        width:140,
        height:140, 
        borderRadius:140
    },
    nameContainer : {
        textAlign : 'center',
        fontFamily:'Metropolis-Medium',
        color:'#777',
        fontSize:18,
        marginTop:10
    },
    designation : {
        textAlign : 'center',
        fontFamily:'Metropolis-Medium',
        color:'#000',
        fontSize:14,
        marginTop:10,
        marginBottom:10
    },
    contact : {
        textAlign : 'center',
        fontFamily:'Metropolis-Medium',
        color:'#1565C0',
        fontSize:14,
        marginTop:0
    },

});


class UserProfile extends Component {

    render() {
        const { classes } = this.props;
        return (
            <Paper style={{padding:10,height:400, flexDirection:'row', display:'flex'}}>
                <div className={classes.leftPanel}>
                    <div className={classes.profileTopBand}>
                    </div>
                    <div className={classes.roundProfile}>
                        <img src={require('./images/sample_profile.jpg')} 
                        alt="profile" 
                        className={classes.profileImage}  />
                    </div>
                    <div className={classes.nameContainer}>
                        <span>Somename Sirname</span>
                    </div>
                    <div className={classes.designation}>
                        <span>ICS Volunteer</span>
                    </div>
                    <div className={classes.contact}>
                        <span>+91 9845012345</span>
                    </div>
                    <div className={classes.contact}>
                        <span>myemail@example.org</span>
                    </div>
                </div>
                
            </Paper>
        )
    }
}

UserProfile.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UserProfile);