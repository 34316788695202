import React, { Component, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog'
import { Button, Divider, DialogActions, Switch, FormControl, InputLabel, MenuItem, CircularProgress, TextField } from '@material-ui/core';

import { NotificationManager } from 'react-notifications';
import { DatePicker } from "@material-ui/pickers";
import moment from 'moment';

import CancelIcon from '@material-ui/icons/Cancel'
import commonClasses from './ics.module.css'

import HttpHandler from './HttpHandler';
import ErrorDialog from './components/ErrorDialog'
import HospitalSearchSelect from './components/HospitalSearchSelect';
import DonorSearchSelect from './components/DonorSearchSelect';

const styles = theme => ({
});


export default function TreatmentInitiateDialog(props) {

    const [state, setState] = useState({
        loadingInProgress: false,
        loadingErrorMessage: '',
        loadingError: false,
        startDate: moment(new Date()),
        hospital: '',
        hospitalError: false,
        hospitalErrorMessage: '',
        donor: '',
        donorError: false,
        donorErrorMessage: '',
        treatmentDescription: '',
        treatmentDescriptionError: false,
        treatmentDescriptionErrorMessage: ''
    });

    const _onClose = () => {
        props.onClose()
    }


    const _loadTreatmentDetails = () => {

    }

    useEffect(() => {
        setState({
            ...state,
            loadingInProgress: false,
            loadingErrorMessage: '',
            loadingError: false,
            startDate: moment(new Date()),
            hospital: '',
            hospitalError: false,
            hospitalErrorMessage: '',
            donor: '',
            donorError: false,
            donorErrorMessage: '',
            treatmentDescription: '',
            treatmentDescriptionError: false,
            treatmentDescriptionErrorMessage: ''
        })
    }, [props.recordId, props.isVisible]);

    const _onSave = () => {
        var valid = true;
        var istate = { ...state };

        if (istate.hospital === '') {
            istate.hospitalError = true;
            istate.hospitalErrorMessage = 'Please select hospital';
            valid = false;
        }

        if (istate.donor === '') {
            istate.donorError = true;
            istate.donorErrorMessage = 'Please select donor';
            valid = false;
        }

        var desc = state.treatmentDescription.trim();
        if (desc === '') {
            istate.treatmentDescriptionError = true;
            istate.treatmentDescriptionErrorMessage = 'Please enter description';
            valid = false;
        }

        if (!valid) {
            setState(istate);
            return;
        }
        var req = { startDate: state.startDate.toDate().getTime(), donor: state.donor.id, hospital: state.hospital.id, description: desc }
        HttpHandler.sendPost('/api/v1/treatment/' + props.recordId + '/new', req, (d) => {
            NotificationManager.success('Treatment details saved');
            props.onSave();
        }, (errorCode, errorMessage) => {
            ErrorDialog.show("There was an error saving treatment details.");
        })

    }

    const _handleStartDateChange = (v) => {
        setState({
            ...state,
            startDate: v,
        })
    }

    const _hospitalSelected = (v) => {
        setState({
            ...state,
            hospital: v,
            hospitalError: false,
            hospitalErrorMessage: ''
        })
    }

    const _donorSelected = (v) => {
        setState({
            ...state,
            donor: v,
            donorError: false,
            donorErrorMessage: ''
        })
    }

    return (
        <Dialog open={props.isVisible} onClose={props.onClose}>
            <div style={{ padding: 5, width: 500 }}>
                <div className={commonClasses.headerBar}>Treatment</div>
            </div>

            <div style={{ width: 500, maxHeight: 800, backgroundColor: '#FAFAFA', padding: 20 }}>
                <DatePicker
                    label="Start date"
                    format="DD/MMM/yyyy"
                    value={state.startDate}
                    onChange={_handleStartDateChange}
                    animateYearScrolling
                    minDate={props.campdate}
                />

                <div style={{ width: '100%', display: 'flex' }}>
                    <HospitalSearchSelect
                        style={{ flex: 1 }}
                        label="Hospital"
                        value={state.hospital}
                        error={state.hospitalError}
                        helperText={state.hospitalErrorMessage}
                        hospitalSelected={_hospitalSelected}
                    ></HospitalSearchSelect>
                </div>

                <div style={{ width: '100%', display: 'flex' }}>
                    <DonorSearchSelect
                        style={{ flex: 1 }}
                        label="Donor"
                        value={state.donor}
                        error={state.donorError}
                        helperText={state.donorErrorMessage}
                        donorSelected={_donorSelected}
                    ></DonorSearchSelect>
                </div>

                <TextField
                    required
                    error={state.treatmentDescriptionError}
                    id='treatmentDescription'
                    label="Description"
                    fullWidth
                    multiline={true}
                    rows={5}
                    value={state.treatmentDescription}
                    margin="normal"
                    helperText={state.treatmentDescriptionErrorMessage}
                    inputProps={{ maxLength: 1000 }}
                    onChange={(v) => {
                        setState({
                            ...state,
                            treatmentDescription: v.target.value,
                            treatmentDescriptionError: false,
                            treatmentDescriptionErrorMessage: ''
                        })
                    }}
                />
            </div>
            <Divider />
            <DialogActions>
                <div style={{ paddingTop: 10, flexDirection: 'row', display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        onClick={_onSave}
                        variant="contained" color="primary" style={{ marginLeft: 20 }}>Save</Button>
                    <Button
                        onClick={_onClose}
                        variant="contained" color="default" style={{ marginLeft: 20 }}>Close</Button>
                </div>
            </DialogActions>
        </Dialog>
    )
}


